import Vue from 'vue'
import { AxiosInstance } from 'axios'
import { setup } from 'axios-cache-adapter'
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import { addRequestInterceptors } from './http-interceptors-global';

declare module 'vue/types/vue' {

  interface Vue {
    $customerFormsAxios: AxiosInstance
  }

  interface VueConstructor {
    $customerFormsAxios: AxiosInstance
  }
}

export function CustomerFormsAxios(vueCtor: typeof Vue, store: Store<RootState>): void {
  // No cache by default
  const customerFormsAxios = setup({
    baseURL: vueCtor.$settings.customerFormsApiUrl,
    cache: {
      maxAge: 60 * 60 * 1000,
      ignoreCache: true,
      exclude: {
        query: false
      }
    }
  })

  addRequestInterceptors(customerFormsAxios, store)

  vueCtor.prototype.$customerFormsAxios = customerFormsAxios
}


import { Component } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import { State } from 'vuex-class'
import { InstallationInformation } from '@/models/prosumer-declaration/prosumer-declaration'
import BatteryInformationComponent from '@/components/inputs/ProsumerDeclaration/BatteryInformationComponent.vue'

@Component({
  name: 'battery-step',
  components: {
    BatteryInformationComponent
  }
})
export default class BatteryStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'prosumerDeclaration' }) public requestId!: string
  @State('installation', { namespace: 'prosumerDeclaration/installation' })
  public installationState!: InstallationInformation
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid) {
      this.$appInsights.trackEvent('ProsumerDeclaration_StepBattery_Validated', {
        Segment: 'ProsumerDeclaration',
        WebRef: this.requestId
      })
    }
  }

  //#endregion
}

import {
  ElectricityPowerSupplyType,
  GasMeter,
  MeterDestination,
  MeterElectricityTension,
  MeterElectricityType,
  MeterGasTechnicalCharacteristic,
  ModifiedConnection,
  PackageDTO,
  PackagedElectricityMeter
} from '@/models'

export interface CmhEnergyConfigurationState {
  packagedElectricityMeters: PackagedElectricityMeter[]
  modifiedConnections: ModifiedConnection[]
  gasMeters: GasMeter[]
  tensions: MeterElectricityTension[]
  tarifications: MeterElectricityType[]
  destinations: MeterDestination[]
  technicalCharacteristics: MeterGasTechnicalCharacteristic[],
  defaultPackagedElectricityMeter: PackagedElectricityMeter
  defaultModifiedConnection: ModifiedConnection
  defaultGasMeter: GasMeter
  isDefaultConfigurationOverriden: boolean
  meterElectricityPackages: PackageDTO[]
}

const tensions: MeterElectricityTension[] = ['mono230V', 'tri230V', 'tri400V', 'custom']
const tarifications: MeterElectricityType[] = ['simple', 'dual', 'nightOnly', 'budget']
const destinations: MeterDestination[] = ['residential', 'professional', 'common', 'other']
const technicalCharacteristics: MeterGasTechnicalCharacteristic[] = ['standard', 'custom']

const defaultPackagedElectricityMeter: PackagedElectricityMeter = {
  count: 1,
  connectionType: ElectricityPowerSupplyType.monophase,
  remarks: null,
  package: {
    key: 'Essentiel',
    maximumPower: {
      voltAmpere: 11600,
      kiloVoltAmpere: 11.6
    },
    homePrice: {
      exVat: 971.98,
      partialVat0: 971.98,
      partialVat06: 1030.3,
      partialVat12: 1088.62,
      vat21: 1176.1
    },
    buildingPrice: {
      exVat: 2299.26,
      partialVat0: 2715.72,
      partialVat06: 2734.69,
      partialVat12: 2753.66,
      vat21: 2782.1
    }
  },
  customPower: false,
  wantedPower: null,
  destination: 'residential'
}

const defaultModifiedConnection: ModifiedConnection = {
  type: 'dual',
  remarks: null,
  price: {
    exVat: 260,
    partialVat0: 260,
    partialVat06: 245.28,
    partialVat12: 291.2,
    vat21: 314.6
  },
  count: 1,
}
const defaultGasMeter: GasMeter = {
  count: 1, technicalCharacteristic: 'standard',
  destination: 'residential', power: 60, remarks: null
}
const meterElectricityPackages: PackageDTO[] = []

export const InitialState: CmhEnergyConfigurationState = {
  packagedElectricityMeters: [],
  modifiedConnections: [],
  gasMeters: [],
  tensions,
  tarifications,
  destinations,
  technicalCharacteristics,
  defaultPackagedElectricityMeter,
  defaultModifiedConnection,
  defaultGasMeter,
  isDefaultConfigurationOverriden: false,
  meterElectricityPackages
}

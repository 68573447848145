
import { Component } from 'vue-property-decorator'
import { helper, idGenerator } from '@/services'
import Step from '@/components/steps/Step'
import SynergridNewRequestForm from '@/components/inputs/Configuration/SynergridNewRequestForm.vue'
import SynergridMoveRequestForm from '@/components/inputs/Configuration/SynergridMoveRequestForm.vue'
import SynergridEnhanceRequestForm from '@/components/inputs/Configuration/SynergridEnhanceRequestForm.vue'
import SynergridRemoveRequestForm from '@/components/inputs/Configuration/SynergridRemoveRequestForm.vue'
import SynergridModifyRefRequestForm from '@/components/inputs/Configuration/SynergridModifyRefRequestForm.vue'
import SynergridRequestTypeSelector from '@/components/inputs/Configuration/SynergridRequestTypeSelector.vue'
import { State, Mutation } from 'vuex-class'
import { SynergridConfigurationInfo } from '@/models'

@Component({
  name: 'configuration-info-step',
  components: {
    SynergridNewRequestForm,
    SynergridMoveRequestForm,
    SynergridEnhanceRequestForm,
    SynergridRemoveRequestForm,
    SynergridModifyRefRequestForm,
    SynergridRequestTypeSelector,
  }
})
export default class ConfigurationInfoStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'synergridConnection' })
  public requestId!: string
  @State('configurationInfo', { namespace: 'synergridConnection/configurationInfo' })
  public configurationInfoState!: SynergridConfigurationInfo | null

  public configurationInfo: SynergridConfigurationInfo = {
    changeType: null,
    newRequestInfo: { synergridRef: null, group: null, power: null },
    moveRequestInfo: { ean: null },
    enhanceRequestInfo: { group: null, power: null },
    removeRequestInfo: { ean: null },
    modifyRefRequestInfo: { oldRef: null, oldGroup: null, newRef: null, newGroup: null },

    month: null,
    year: null,
    remark: null
  }

  public fieldId: string = idGenerator.getId()
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'synergridConnection/configurationInfo' })
  private store!: (index: SynergridConfigurationInfo | null) => void
  //#endregion

  //#region [Computed]
  public get years(): Array<{ text: string; value: string }> {
    const years = [] as Array<{ text: string; value: string }>
    const currentYear = new Date().getFullYear()
    for (let i = 0; i < 5; i++) {
      years.push({ text: (currentYear + i).toString(), value: (currentYear + i).toString() })
    }
    return years
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    if (this.configurationInfoState !== null) {
      this.configurationInfo = helper.clone(this.configurationInfoState)
    }
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid) {
      this.$appInsights.trackEvent('WR_SC_StepConfigurationInfo_Validated', {
        Segment: 'WR_SC',
        WebRef: this.requestId
      })
    }

    this.store(this.configurationInfo)
  }
  //#endregion
}

import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { InitialState, ConstructionConnectionState } from './types'
import { address } from './address'
import { configurationInfo } from './configuration-info'
import { contactInfo } from './contact-info'
import { vatInfo } from './vat-info'
import { RootState } from '@/store/types';

const namespaced: boolean = true

export const temporaryConnection: Module<ConstructionConnectionState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations,
  modules: {
    address,
    configurationInfo,
    contactInfo,
    vatInfo
  }
}


import { ElectricMobilityPackageDto } from '@/models'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { pricingService } from '@/services/pricing-service'

@Component({
  name: 'power-increase-packages-modal',
})
export default class PowerIncreasePackagesModal extends Vue {
  //#region [Property]
  @Prop({ type: Boolean, required: true })
  public readonly value!: boolean
  @Prop({ type: Boolean, required: true })
  public readonly isLoading!: boolean
  //#endregion

  //#region [Data]
  @State('powerIncreasePackages', { namespace: 'eMobility/powerIncreasePackages' })
  public meterElectricityPackages!: ElectricMobilityPackageDto[]
  //#endregion

  //#region [Computed]
  public get IsOpen(): boolean {
    return this.value
  }

  public set IsOpen(val: boolean) {
    this.$emit('input', val)
  }

  public getPrice(price: number): string {
    return pricingService.formatNumberToLocaleStringFormat(price)
  }
  //#endregion
}

export interface DialogsState {
  active: boolean
  text: string
  title: string
  callback: ((isConfirmed: boolean) => void) | null
}

export const initialState: DialogsState = {
  active: false,
  text: '',
  title: '',
  callback: null
}

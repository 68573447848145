
import { Vue, Component, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class FindMeterLastDigitsModalDialog extends Vue {
  //#region [Property]
  @Prop({ type: Boolean, required: true })
  public readonly value!: boolean
  //#endregion

  //#region [Data]
  @State('lang')
  public lang!: string | null
  //#endregion

  //#region [Computed]
  public get IsOpen(): boolean {
    return this.value
  }

  public set IsOpen(val: boolean) {
    this.$emit('input', val)
  }
  //#endregion
}

import Vue from 'vue';
import VueI18n from 'vue-i18n';

export function isObject(item: any) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target: any, ...sources: any[]): any {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

function configure(): VueI18n {
  Vue.use(VueI18n);
  const resourcesFR = require('./resources/fr.json');
  const resourcesDE = require('./resources/de.json');
  const resourcesNL = require('./resources/nl.json');

  mergeDeep(resourcesFR, require('./resources/smart-meter/fr.json'));
  mergeDeep(resourcesDE, require('./resources/smart-meter/de.json'));
  mergeDeep(resourcesNL, require('./resources/smart-meter/nl.json'));

  mergeDeep(resourcesFR, require('./resources/work-file-tracking/fr.json'));
  mergeDeep(resourcesDE, require('./resources/work-file-tracking/de.json'));
  mergeDeep(resourcesNL, require('./resources/work-file-tracking/nl.json'));

  mergeDeep(resourcesFR, require('./resources/error/fr.json'));
  mergeDeep(resourcesDE, require('./resources/error/de.json'));
  mergeDeep(resourcesNL, require('./resources/error/nl.json'));

  mergeDeep(resourcesFR, require('./resources/appointment/fr.json'));
  mergeDeep(resourcesDE, require('./resources/appointment/de.json'));
  mergeDeep(resourcesNL, require('./resources/appointment/nl.json'));

  mergeDeep(resourcesFR, require('./resources/e-mobility/fr.json'));
  mergeDeep(resourcesDE, require('./resources/e-mobility/de.json'));
  mergeDeep(resourcesNL, require('./resources/e-mobility/nl.json'));

  mergeDeep(resourcesFR, require('./resources/charging-station-declaration/fr.json'));
  mergeDeep(resourcesDE, require('./resources/charging-station-declaration/de.json'));
  mergeDeep(resourcesNL, require('./resources/charging-station-declaration/nl.json'));

  mergeDeep(resourcesFR, require('./resources/prosumer-declaration/fr.json'));
  mergeDeep(resourcesDE, require('./resources/prosumer-declaration/de.json'));
  mergeDeep(resourcesNL, require('./resources/prosumer-declaration/nl.json'));

  return new VueI18n({
    locale: 'fr',
    messages: {
      fr: resourcesFR,
      nl: resourcesNL,
      de: resourcesDE,
    },
  });
}

export const i18n = configure();

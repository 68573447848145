
import Component from 'vue-class-component'
import { Emit, Vue } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import ApiErrors from '@/components/display/ApiErrors.vue'
import {
  CheckEanSmartMeterResult,
  CompanyVatInfoSelection,
  ContactInfo,
  EnergyProductionInfoModel,
  FileRequestToken,
  FreelanceVatInfoSelection,
  IndividualVatInfoSelection,
  SmartMeterFileCreationViewModel,
  SmartMeterFileCreationResultViewModel
} from '@/models'
import { AxiosResponse } from 'axios'

@Component({ components: { ApiErrors } })
export default class WorkFileCreation extends Vue {
  //#region [Props]
  //#endregion

  //#region [Data]
  @State('requestId', { namespace: 'smartMeterConnection' })
  public requestId!: string

  @State('workRequesterId', { namespace: 'smartMeterConnection/stakeholder' })
  public workRequesterId!: string | null

  @State('eanCode', { namespace: 'smartMeterConnection/identification' })
  public eanCodeState!: string

  @State('checkEanResult', { namespace: 'smartMeterConnection/identification' })
  public checkEanResultState!: CheckEanSmartMeterResult

  @State('energyProductionInfo', { namespace: 'smartMeterConnection/energyProduction' })
  public energyProductionInfoState!: EnergyProductionInfoModel

  @State('searchStartDate', { namespace: 'smartMeterConnection/appointment' })
  public searchStartDate!: Date

  @State('contactInfo', { namespace: 'smartMeterConnection/contactInfo' })
  public contactInfoState!: ContactInfo

  @State('individualVatInfoSelection', { namespace: 'smartMeterConnection/vatInfo' })
  public individualVatInfoSelectionState!: IndividualVatInfoSelection | null
  @State('freelanceVatInfoSelection', { namespace: 'smartMeterConnection/vatInfo' })
  public freelanceVatInfoSelectionState!: FreelanceVatInfoSelection | null
  @State('companyVatInfoSelection', { namespace: 'smartMeterConnection/vatInfo' })
  public companyVatInfoSelectionState!: CompanyVatInfoSelection | null

  private loading: boolean = false

  //#endregion

  //#region [Computed]
  //#endregion

  //#region [Mutations]
  @Mutation('STORE', { namespace: 'smartMeterConnection' })
  private storeNoAppointment!: (value: boolean) => void

  @Mutation('STORE_FILENUMBER', { namespace: 'smartMeterConnection/stakeholder' })
  private store!: (value: FileRequestToken | null) => void
  //#endregion

  //#region [Methods]

  // send request and return success indicator
  public async createWorkFile(): Promise<void> {
    this.loading = true
    try {
      const data: SmartMeterFileCreationViewModel = {
        requestId: this.requestId,
        workRequesterId: this.workRequesterId,
        eanCode: this?.eanCodeState!,
        stepEnergyProduction: {
          hasEnergyProduction: !!this.energyProductionInfoState.hasEnergyProduction
        },
        stepAppointmentDatePreselection: {
          desiredStartDate: this.searchStartDate
        },
        stepContactInfo: this.contactInfoState,
        stepVatInfoSelection: {
          individualVatInfoSelection: this.individualVatInfoSelectionState,
          freelanceVatInfoSelection: this.freelanceVatInfoSelectionState,
          companyVatInfoSelection: this.companyVatInfoSelectionState
        }
      }

      const token = sessionStorage.getItem('token')
      const response: AxiosResponse<SmartMeterFileCreationResultViewModel> = await this.$api.post(
        'api/worksrequest/smart-meter-request',
        data,
        {
          headers: {
            Authorization: token
          }
        }
      )
      this.loading = false
      if (response.status >= 200 && response.status < 300) {
        this.storeNoAppointment(false)
        this.store({ fileNumber: response.data.worksFileId!, requestId: this.requestId, hash: '' })
        this.$appInsights.trackEvent('WR_SMC_WorkFile_Created', {
          Segment: 'WR_SMC',
          WebRef: this.requestId,
          EnergyProductionInfo:
            this.energyProductionInfoState !== null && this.energyProductionInfoState.hasEnergyProduction !== null
              ? this.energyProductionInfoState.hasEnergyProduction.toString()
              : '0',
          ContactType: this.contactInfoState.contactType!.toString(),
          SimpleTariff: 'false',
          DualTariff: 'true'
        })
      } else if (response.status === 400) {
        this.storeNoAppointment(true)
        const errorResponse = response as any
        if (errorResponse.data.errors && errorResponse.data.errors.length) {
          if (errorResponse.data.errors[0].key === 'WorkFileNotCreated') {
            this.$store.dispatch('apiErrors/customManage', 'workFileNotCreatedError')
          } else if (errorResponse.data.errors[0].key === 'VerbalOfferNotCreated') {
            this.$store.dispatch('apiErrors/customManage', 'verbalOfferNotCreatedError')
          } else {
            this.$store.dispatch('apiErrors/customManage', 'default')
          }
        } else {
          this.$store.dispatch('apiErrors/customManage', 'default')
        }
      } else {
        this.$store.dispatch('apiErrors/customManage', 'default')
      }
    } catch (err) {
      console.error(err)
      this.loading = false
      this.$store.dispatch('apiErrors/customManage', 'default')
    }
  }
  //#endregion
}


import { Component } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import { State, Mutation } from 'vuex-class'
import { FileInfo } from '@ores/vue-library'
import { AttachmentState } from '@/store/construction-connection/attachment/types'
import WorksInputFile from '@/components/inputs/Files/WorksInputFile.vue'

@Component({
  name: 'attachment-step',
  components: {
    WorksInputFile
  }
})
export default class AttachmentStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'constructionConnection' })
  public readonly requestId!: string

  @State('attachDocuments', { namespace: 'constructionConnection/attachment' })
  public attachDocumentsState!: boolean
  @State('buildingLocationFilesInfo', { namespace: 'constructionConnection/attachment' })
  public buildingLocationFilesInfoState!: FileInfo[]

  public attachDocuments: boolean = false
  public buildingLocationFilesInfo: FileInfo[] = []
  //#endregion

  //#region [Computed]
  public get fileInError(): boolean {
    return this.buildingLocationFilesInfo.some((v) => v.error !== null)
  }

  public get yesChecked(): boolean {
    return this.attachDocuments !== null && !!this.attachDocuments
  }

  public set yesChecked(newVal: boolean) {
    this.attachDocuments = newVal
  }

  public get noChecked(): boolean {
    return this.attachDocuments !== null && !this.attachDocuments
  }

  public set noChecked(newVal: boolean) {
    this.attachDocuments = !newVal
  }
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'constructionConnection/attachment' })
  private store!: (attachments: AttachmentState) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    this.attachDocuments = this.attachDocumentsState
    this.buildingLocationFilesInfo = this.buildingLocationFilesInfoState
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean> {
    if (isValid) {
      this.$appInsights.trackEvent('WR_CC_StepAttachment_Validated', {
        Segment: 'WR_CC',
        WebRef: this.requestId
      })
    }

    const attachments = this.buildingLocationFilesInfo.filter((a) => a.error === null)
    this.store({ attachDocuments: this.attachDocuments, buildingLocationFilesInfo: attachments })
    return Promise.resolve(true)
  }
  //#endregion
}

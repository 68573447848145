import { Module } from 'vuex'
import { mutations } from './mutations'
import { GetInitialState, MeterReadingState } from './types'
import { RootState } from '@/store/types'

export const meterReading: Module<MeterReadingState, RootState> = {
  namespaced: true,
  state: GetInitialState(),
  mutations
}

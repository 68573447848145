
import ElectricVehicleSelectedForm from '@/components/inputs/EMobility/Car/ElectricVehicleSelectedForm.vue'
import Step from '@/components/steps/Step'
import { ElectricVehicle } from '@/models'
import { Component } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  components: {
    ElectricVehicleSelectedForm
  },
  name: 'car-selected-step'
})
export default class CarSelectedStep extends Step {
  //#region [Data]
  @State('electricVehicle', { namespace: 'eMobility/carSpecification' })
  public stateElectricVehicle!: ElectricVehicle | null
  @State('isBack')
  public isBack!: boolean
  //#endregion

  //#region [Method]
  public mounted(): void {
    if (this.stateElectricVehicle === null) {
      if (this.isBack) {
        this.previous()
      } else {
        this.setIsBack(false)
        this.next()
      }
    }
  }
  //#endregion
}


import { ElectricVehicle } from '@/models'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  name: 'car-model-selector'
})
export default class CarModelSelector extends Vue {
  //#region [Property]
  @Prop({ type: Object }) public readonly value!: ElectricVehicle | null
  @Prop({ type: Boolean, required: true }) public readonly required!: boolean
  //#endregion

  //#region [Data]
  public brand: string | null = null
  public model: string | null = null
  public version: string | null = null
  public capacity: string | null = null

  public vehicleBrands: string[] = []
  public vehicleModels: string[] = []

  public vehicleData: ElectricVehicle[] = []
  public selectedVehicle: ElectricVehicle | null = null

  @State('requestId', { namespace: 'eMobility' })
  public requestId!: string
  //#endregion

  //#region [Computed]
  public get vehicleVersions(): string[] {
    return [...new Set(this.vehicleData.map((car) => car.version ?? '').sort())]
  }
  public get vehicleCapacities(): string[] {
    if ((this.withCarVersion && !!this.version) || !this.withCarVersion) {
      return [
        ...new Set(
          this.vehicleData
            .filter((v) => v.version === this.version)
            .map((car) => car.batteryTotalCapacity + '' ?? '')
            .sort()
        )
      ]
    } else {
      return []
    }
  }
  public get withCarVersion(): boolean {
    return this.vehicleVersions.length > 1
  }

  public get withCarCapacity(): boolean {
    return this.vehicleCapacities.length > 1
  }
  //#endregion

  //region [Method]
  public mounted() {
    this.$api
      .get<string[]>('api/ElectricMobility/vehicles/brands')
      .then((response) => (this.vehicleBrands = response.data.sort()))

    if (!!this.value) {
      this.selectedVehicle = this.value
      this.brand = this.selectedVehicle.brand
      this.version = this.selectedVehicle.version
      this.capacity = this.selectedVehicle.batteryTotalCapacity + ''
    }
  }

  public onCarModelNotFound() {
    this.$appInsights.trackEvent('EMobility_linkClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Link: 'Je ne retrouve pas mon vehicule'
    })
    this.$emit('carModelNotFound')
  }
  //#endregion

  //region [Watch]
  @Watch('brand')
  public onBrandChanged(brand: string | null) {
    // Don't call api with null parameter
    if (brand === null) {
      this.vehicleModels = []
      this.vehicleData = []
    } else {
      this.$api
        .get<string[]>(`api/ElectricMobility/vehicles/models?brand=${brand}`)
        .then((response) => (this.vehicleModels = response.data.sort()))
    }

    if (!!this.selectedVehicle && this.brand === this.selectedVehicle.brand) {
      this.model = this.selectedVehicle.model
    } else {
      this.model = null
    }
  }

  @Watch('model')
  public onModelChanged(model: string | null) {
    // Don't call api with null parameter
    // model can be '0'
    if (model === null) {
      this.vehicleData = []
    } else {
      this.$api
        .get<ElectricVehicle[]>(`api/ElectricMobility/vehicles?brand=${this.brand}&model=${model}`)
        .then((response) => {
          this.vehicleData = response.data
          if (!this.withCarVersion && !this.withCarCapacity) {
            this.selectedVehicle = response.data[0]
          }
        })
    }

    if (!!this.selectedVehicle && this.model === this.selectedVehicle.model) {
      this.version = this.selectedVehicle.version
      this.capacity = this.selectedVehicle.batteryTotalCapacity + ''
    } else {
      this.version = null
      this.capacity = null
    }
  }

  @Watch('capacity')
  public onCapacityChanged(capacity: string | null) {
    if (this.capacity === null) {
      this.selectedVehicle = null
    } else {
      this.selectedVehicle = this.vehicleData.filter(
        (v) => v.version === this.version && this.capacity === v.batteryTotalCapacity + ''
      )[0]
    }
  }

  @Watch('version')
  public onVersionChanged(version: string | null) {
    if (version === null) {
      this.selectedVehicle = null
      this.capacity = null
    } else {
      if (this.selectedVehicle && version !== this.selectedVehicle.version) {
        this.capacity = null
      }

      if (!this.withCarCapacity) {
        this.selectedVehicle = this.vehicleData.filter((v) => v.version === version)[0]
      } else {
        this.selectedVehicle = null
      }
    }
  }

  @Watch('vehicleData')
  public onVehicleDataChanged() {
    if (this.vehicleData.length === 1) {
      this.selectedVehicle = this.vehicleData[0]
    }
  }

  @Watch('selectedVehicle')
  public onSelected() {
    this.$emit('input', this.selectedVehicle)
  }
  //#endregion
}

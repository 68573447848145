import { MutationTree } from 'vuex';
import { AddressState } from './types';
import { AddressPoint } from '@/models';

export const mutations: MutationTree<AddressState> = {
  STORE(state, data: AddressPoint) {
    state.address = data
  },
  CLEAR(state) {
    state.address = null
  }
}

import { MutationTree } from 'vuex';
import { EnergyProductionState } from './types';
import { EnergyProductionInfoModel } from '@/models';

export const mutations: MutationTree<EnergyProductionState> = {
  STORE(state, data: EnergyProductionInfoModel) {
    state.energyProductionInfo = data
  },
  CLEAR(state) {
    state.energyProductionInfo = null
  }
}


import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'price-disclaimer'
})
export default class PriceDisclaimer extends Vue {
  public isPackageDisclaimerModalOpen: boolean = false
  public openPackageDisclaimerModal() {
    this.isPackageDisclaimerModalOpen = true
  }
}

import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { idGenerator } from '@/services'
import { ValidationFlags } from 'vee-validate/dist/types/types'

type ValidationState = ValidationFlags & {
  validate: (v: any) => Promise<never>,
}

@Component
export default class FieldBox extends Vue {
  //#region [Property]
  @Prop({ type: String, default: () => idGenerator.getId() }) public readonly fieldId!: string
  @Prop({ type: Boolean, default: false }) public loading!: boolean
  @Prop({ type: Boolean, default: false }) public readonly disabled!: boolean
  //#endregion

  //#region [Data]
  public isLoading: boolean = false
  public isValidated: boolean = false
  //#endregion

  //#region [Method]
  public validationState(state: ValidationState): string[] {
    if (this.disabled) {
      return ['lib__field--disabled']
    }

    if (this.isLoading) {
      return ['lib__field--loading']
    }

    const value = (this as any).value
    const isEmpty = value === null || value === ''

    if (!isEmpty) {
      if (!state.validated && !this.isValidated) {
        this.isValidated = true;
        this.$nextTick(() => state.validate(value));
      } else if (state.passed) {
        return ['lib__field--valid']
      } else if (state.failed) {
        return ['lib__field--error']
      }
    }

    return []
  }

  public mounted() {
    this.onLoadingChanged(this.loading)
  }
  //#endregion

  //#region [Watch]
  @Watch('loading')
  public onLoadingChanged(value: boolean) {
    this.isLoading = value
  }
  //#endregion
}

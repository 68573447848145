import { actions } from '@/store/new-connection-cmh/cmh-contact/actions';
import { getters } from '@/store/new-connection-cmh/cmh-contact/getters';
import { mutations } from '@/store/new-connection-cmh/cmh-contact/mutations';
import { CmhContactState, InitialState } from '@/store/new-connection-cmh/cmh-contact/types';
import { RootState } from '@/store/types';
import { Module } from 'vuex';

const namespaced: boolean = true;

export const cmhContact: Module<CmhContactState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations
}


import Component from 'vue-class-component'
import { WizardStepDefinition } from '@/models/wizard-step-definition'
import WizardDisplay from '@/components/display/WizardDisplay.vue'
import ZipCodeLocalitySelector from '@/components/inputs/Address/ZipCodeLocalitySelector.vue'
import AvailableServiceDisplay from '@/components/display/AvailableServiceDisplay.vue'
import { AvailableServiceDefinition } from '@/models/available-service-definition'
import { Prop, Watch } from 'vue-property-decorator'
import { SubCity } from '@/models'
import Step from '@/components/steps/Step'
import { debounce } from 'ts-debounce'
import { State, Mutation } from 'vuex-class'
import CmhProvidersExternalLinks from '@/components/display/CmhProvidersExternalLinks.vue'
import FaqDisplay from '@/components/display/FaqDisplay.vue'
import { routes } from '@/router'
import { dateService } from '@ores/vue-library'

@Component({
  components: {
    FaqDisplay,
    CmhProvidersExternalLinks,
    WizardDisplay,
    AvailableServiceDisplay,
    ZipCodeLocalitySelector
  },
  name: 'cmh-landing-page-step'
})
export default class CmhLandingPageStep extends Step {
  //#region [Property]
  @Prop({ type: Boolean, required: false, default: false }) public readonly comingFromOresWebSite!: boolean
  @Prop({ type: String, required: true }) public readonly lang!: string
  //#endregion

  //#region [Data]
  public zipCodeOrSubCity: string = ''
  public wizardStepDefinitions: WizardStepDefinition[] = [
    {
      imageFileName: 'rectangle-wizard-step1.svg',
      bodyTextPath: 'new-connection-wizard.firstStep'
    },
    {
      imageFileName: 'rectangle-wizard-step2.svg',
      bodyTextPath: 'new-connection-wizard.secondStep'
    },
    {
      imageFileName: 'rectangle-wizard-step3.svg',
      bodyTextPath: 'new-connection-wizard.thirdStep'
    }
  ]
  public availableServiceDefinitions: AvailableServiceDefinition[] = []
  public subCity: SubCity | null = null
  public isOverlayDisplayed: boolean = false
  public isPagingActivated: boolean = false
  public isServiceCalled: boolean = false

  public isMainTitleModalOpen: boolean = false
  public isServiceInfoModalOpen: boolean = false
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'newConnectionCmh/landingPage' })
  private store!: (index: SubCity | null) => void
  //#endregion

  //#region [Methods]
  public created() {
    this.setWizardPaging()
    this.setWizardPaging = debounce(this.setWizardPaging, 200)
    window.addEventListener('resize', this.setWizardPaging)
  }

  public mounted() {
    const mainContainer = document.getElementsByClassName('lib__main__container')[0] as HTMLElement
    mainContainer.style.maxWidth = '1440px'
    mainContainer.style.paddingLeft = '0px'
    mainContainer.style.paddingRight = '0px'
  }

  public destroyed() {
    window.removeEventListener('resize', this.setWizardPaging)
    const mainContainer = document.getElementsByClassName('lib__main__container')[0] as HTMLElement
    mainContainer.style.maxWidth = ''
    mainContainer.style.paddingLeft = ''
    mainContainer.style.paddingRight = ''
    if (this.subCity) {
      this.store(this.subCity)
    }
  }

  public scrollToZipCodeInput(): void {
    const zipcodeContainer = document.getElementById('zipcode-input-container')
    if (zipcodeContainer) {
      zipcodeContainer.scrollIntoView()
      this.isOverlayDisplayed = true
    }
  }

  public scrollToCmhWizard(): void {
    const connectionBottomContainer = document.getElementById('connection-bottom-container')
    if (connectionBottomContainer) {
      connectionBottomContainer.scrollIntoView()
    }
  }

  @Watch('subCity')
  public onSubCityChanged() {
    this.isServiceCalled = true
    if (this.subCity === null) {
      this.isOverlayDisplayed = false
    } else {
      this.setAvailableServiceDefinitions(this.subCity)
    }
  }

  public setAvailableServiceDefinitions(subCity: SubCity): void {
    this.availableServiceDefinitions = [
      {
        imageFileName: window.innerWidth > 576 ? 'ores-logo-2x.png' : 'ores-logo.png',
        isAvailable: subCity?.gridManagers?.electricityManager?.isOres || false,
        imageStyle: {}
      },
      {
        imageFileName: window.innerWidth > 576 ? 'swde-logo-2x.png' : 'swde-logo.png',
        isAvailable: subCity?.connectMyHomeInformation?.swdeAvailable || false,
        imageStyle: {}
      },
      {
        imageFileName: window.innerWidth > 576 ? 'proximus-logo-2x.png' : 'proximus-logo.png',
        isAvailable: subCity?.connectMyHomeInformation?.proximusAvailable || false,
        imageStyle: { marginTop: '8px' }
      },
      {
        imageFileName: window.innerWidth > 576 ? 'voo-logo-2x.png' : 'voo-logo.png',
        isAvailable: subCity?.connectMyHomeInformation?.vooAvailable || false,
        imageStyle: { marginTop: '15px' }
      }
    ]
  }

  public deactivateOverlay(): void {
    this.isOverlayDisplayed = false
  }

  public setWizardPaging(): void {
    this.isPagingActivated = !(window.innerWidth >= 1024)
  }

  public openMainTitleModal(): void {
    this.isMainTitleModalOpen = true
  }

  public openServiceInfoModal(): void {
    this.isServiceInfoModalOpen = true
  }

  public getUrl(lang: string): string {
    return routes.find((r) => r.name === 'new-connection-cmh')!.paths[lang]
  }
  //#endregion

  //#region [Computed]

  private get isAvailable() {
    console.log(this.subCity)
    console.log(this.isServiceCalled)

    if (this.subCity) {
      return this.subCity.connectMyHomeActivated && this.subCity.connectMyHomeInformation
    }
    if (this.isServiceCalled && this.subCity == null) {
      return false
    }

    return true
  }

  private get isFullyAvailable() {
    if (this.subCity?.connectMyHomeInformation !== null) {
      return (
        this.isAvailable &&
        this.subCity?.connectMyHomeInformation?.proximusAvailable &&
        this.subCity?.connectMyHomeInformation?.vooAvailable &&
        this.subCity?.connectMyHomeInformation?.swdeAvailable
      )
    }
    return false
  }

  private get isElectricityManaged(): boolean {
    return this.subCity?.gridManagers?.electricityManager?.isOres ?? false
  }

  private get isGasManaged(): boolean {
    return this.subCity?.gridManagers?.gasManager?.isOres ?? false
  }

  private get containerBottomStyle() {
    return window.innerWidth <= 576
      ? { paddingTop: '0px' }
      : this.subCity
      ? { paddingTop: '0px' }
      : { paddingTop: '120px' }
  }

  private get availabilityActivationDate(): Date | null {
    if (this.subCity) {
      return this.subCity.connectMyHomeActivationDate
    }
    return null
  }

  private get availabilityActivationText(): string {
    return this.$t('new-connection-cmh.serviceWillBeAvailableOn', [
      dateService.displayDate(this.subCity!.connectMyHomeActivationDate!)
    ]).toString()
  }
  //#endregion
}

import { BatteryRechargingCapacity } from '@/models';
import { MutationTree } from 'vuex';
import { BatteryRechargingCapacityState } from './types';

export const mutations: MutationTree<BatteryRechargingCapacityState> = {
  STORE(state, data: BatteryRechargingCapacity[]) {
    state.batteryResult = data
  },
  CLEAR(state) {
    state.batteryResult = null
  }
}

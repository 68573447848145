import { OfferingSelectionState } from '@/store/new-connection-cmh/offerings-selection/types'
import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'

export const getters: GetterTree<OfferingSelectionState, RootState> = {
  isElectricitySelected(state: OfferingSelectionState, gets, rs: RootState, rg) {
    return state.selections.some((x: string) => x === 'electricity')
  },
  isGasSelected(state: OfferingSelectionState, gets, rs: RootState, rg) {
    return state.selections.some((x: string) => x === 'gas')
  },
  isSwdeSelected(state: OfferingSelectionState, gets, rs: RootState, rg) {
    return state.selections.some((x: string) => x === 'swde')
  },
  isProximusSelected(state: OfferingSelectionState, gets, rs: RootState, rg) {
    return state.selections.some((x: string) => x === 'proximus' || x === 'proximusAndVoo')
  },
  isVooSelected(state: OfferingSelectionState, gets, rs: RootState, rg) {
    return state.selections.some((x: string) => x === 'voo' || x === 'proximusAndVoo')
  },
}

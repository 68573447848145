
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElectricityMeter, MeterElectricityTension, MeterElectricityType, MeterDestination } from '@/models'
import { meterService } from '@/services/meter-service'
import PowerSelectBox from '@/components/inputs/Configuration/PowerSelectBox.vue'
import { helper } from '@/services'

@Component({
  components: {
    PowerSelectBox
  },
  name: 'cmh-electricity-popup-form'
})
export default class CmhElectricityPopupForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public electricityMeter!: ElectricityMeter
  @Prop({ type: Array, required: true }) public tarifications!: MeterElectricityType[]
  @Prop({ type: Array, required: true }) public destinations!: MeterDestination[]
  @Prop({ type: Array, required: true }) public tensions!: MeterElectricityTension[]
  //#endregion

  //#region [Data]
  public elecMeter: ElectricityMeter = {
    tension: 'mono230V',
    count: 1,
    power: { ampere: 40, kva: 9.2 },
    destination: 'residential',
    type: 'dual',
    remarks: null,
    wantEmergencyPower: false,
    price: 765
  }
  //#endregion

  //#region [Method]
  public mounted() {
    if (this.electricityMeter != null) {
      this.elecMeter = helper.clone(this.electricityMeter)
    }
  }

  public validateMeter() {
    this.$emit('validateMeter', this.elecMeter)
  }
  //#endregion

  //#region [Computed]
  public get powers(): number[] {
    return meterService.getIntensities(this.elecMeter.tension)
  }

  public get isCustom(): boolean {
    return this.elecMeter.tension === 'custom'
  }

  public get ampere(): number | null {
    return this.elecMeter.power ? this.elecMeter.power.ampere : null
  }

  public set ampere(value: number | null) {
    if (!value) {
      this.elecMeter.power = null
    } else if (this.elecMeter.tension !== 'custom') {
      this.elecMeter.power = {
        ampere: value,
        kva: meterService.getPower(this.elecMeter.tension, value)
      }
    }
  }

  public get kva(): number | null {
    return this.elecMeter.power ? this.elecMeter.power.kva : null
  }

  public set kva(value: number | null) {
    if (!value) {
      this.elecMeter.power = null
    } else if (this.elecMeter.tension === 'custom') {
      this.elecMeter.power = {
        ampere: 0,
        kva: value
      }
    }
  }

  public get tarificationsOptions(): Array<{ text: string; value: string }> {
    return this.tarifications.map((t) => {
      return { text: this.$t('meter.type.' + t) as string, value: t }
    })
  }
  //#endregion

  //#region [Watch]
  @Watch('electricityMeter')
  public electricityMeterChange(value: ElectricityMeter) {
    this.elecMeter = helper.clone(this.electricityMeter)
  }

  @Watch('elecMeter.tension')
  public onTensionChange(value: MeterElectricityTension) {
    if (value === 'custom') {
      this.elecMeter.destination = null
      this.elecMeter.type = null
      if (this.ampere) {
        this.elecMeter.power = null
      }
    } else {
      const ampere = this.ampere
      const intensities = meterService.getIntensities(value)

      if (!ampere || intensities.indexOf(ampere) === -1) {
        this.ampere = intensities[0]
      } else {
        this.ampere = ampere
      }
    }
  }
  //#endregion
}

import { MutationTree } from 'vuex'
import { FilesState } from '@/store/prosumer-declaration/files/types'

export const mutations: MutationTree<FilesState> = {
  STORE(state, data: FilesState) {
    state.rapportFileInfo = data.rapportFileInfo
    state.schemaFileInfo = data.schemaFileInfo
    state.pictureFileInfo = data.pictureFileInfo
  },
  CLEAR(state) {
    state.rapportFileInfo = []
    state.schemaFileInfo = []
    state.pictureFileInfo = []
  }
}

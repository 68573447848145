import { ActionTree } from 'vuex'
import { VatInfoState } from './types';
import { RootState } from '@/store/types';
import { ContactInfo } from '@/models';

export const actions: ActionTree<VatInfoState, RootState> = {
  validate({ commit, state }, contactInfo: ContactInfo) {
    let change = false

    if (state.individualVatInfoSelection != null && contactInfo.contactType !== 'individual') {
      change = true
      state.individualVatInfoSelection = null
    }

    if (state.freelanceVatInfoSelection != null && contactInfo.contactType !== 'freelance') {
      change = true
      state.freelanceVatInfoSelection = null
    }

    if (state.companyVatInfoSelection != null && contactInfo.contactType !== 'company') {
      change = true
      state.companyVatInfoSelection = null
    }

    if (change) {
      commit('STORE', state)
    }

    return !change
  }
}

import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { InitialState, ChargingStationDeclarationState } from './types'
import { identification } from './identification'
import { contactInfo } from './contact-info'
import { information } from './information'
import { RootState } from '@/store/types'

const namespaced: boolean = true

export const chargingStationDeclaration: Module<ChargingStationDeclarationState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations,
  modules: {
    identification,
    contactInfo,
    information
  }
}

import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import { StepDefinition } from '@ores/vue-library'
import { localize } from 'vee-validate'
import { Mutation, State } from 'vuex-class'
import moment from 'moment';

@Component
export class Form extends Vue {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly lang!: string
  //#endregion

  //#region [Data]
  public stepDefinitions!: StepDefinition[]
  public currentStep: number = 0
  public lastStepSeen: number = 1
  public loading: string | null = null

  public canContinue: boolean = true;
  public stepComponent: Step | null = null
  public next: boolean | null = null
  public status: 1 | 0 | -1 | undefined = undefined
  public stepToGoAfterValidation: number = 0
  public stepsToValidate: number[] = []
  public canNavigateAfterLastStep: boolean = false

  @State('interceptorId', { namespace: 'apiErrors' })
  public interceptorId!: number | null
  //#endregion

  //#region [Mutation]
  @Mutation('SET_INTERCEPTOR_ID', { namespace: 'apiErrors' })
  public setInterceptorId!: (data: number) => void

  //#endregion

  //#region [Computed]
  public get lastStep(): number {
    return Math.max.apply(Math, this.stepDefinitions.map((o) => o.step))
  }

  public get firstStep(): number {
    return Math.min.apply(Math, this.stepDefinitions.map((o) => o.step))
  }

  public get canNavigate(): boolean {
    return (this.canNavigateAfterLastStep || this.lastStepSeen < this.lastStep) && !this.loading
  }
  //#endregion

  //#region [Watch]
  @Watch('lang')
  public onLangChanged(value: string) {
    this.$i18n.locale = value
    localize(value)
    moment.locale(value);
    this.$store.commit('SET_LANGUAGE', value)
  }

  @Watch('currentStep')
  public onCurrentStepChanged(currentStep: number) {
    if (this.lastStepSeen < currentStep) {
      this.lastStepSeen = currentStep
    }
    /* tslint:disable-next-line:no-string-literal */
    if (!(this.$route.query['step'] && this.$route.query['step'] === currentStep.toString())) {
      this.$router.replace({ path: this.$route.path, query: { step: currentStep.toString() } })
    }
  }
  //#endregion

  //#region [Method]
  public mounted() {
    /* tslint:disable-next-line:no-string-literal */
    if (!(this.$route.query['step'] && this.$route.query['step'] === this.currentStep.toString())) {
      let query: any = { step: this.currentStep.toString() }
      if (this.$route.query['package']) {
        query['package'] = this.$route.query['package']
      }
      if (this.$route.query['desiredPower']) {
        query['desiredPower'] = this.$route.query['desiredPower']
      }
      if (this.$route.query['isProsumer']) {
        query['isProsumer'] = this.$route.query['isProsumer']
      }
      this.$router.replace({ path: this.$route.path, query })
    }
  }

  /**
   * Gets the step component for the step number
   * Virtual method
   */
  public getStepComponent(step: number): Step | null {
    return null
  }

  public async goToStep(step: number) {
    if (!this.canNavigate || step > this.lastStepSeen || step === this.currentStep) {
      return
    }

    this.loading = 'goto'
    const stepComponent = this.getStepComponent(step)

    const next = step > this.currentStep
    if (stepComponent === null) {
      this.currentStep = step
      this.canContinue = true
      this.loading = null
      return
    }

    const status = await stepComponent.change(next)
    switch (status) {
      case 1:
        this.currentStep = step
        this.canContinue = true
        this.scrollTop()
        break
      case 0:
        this.lastStepSeen = this.currentStep
        if (!next) {
          this.currentStep = step
          this.canContinue = true
          this.scrollTop()
        }
        break
      case -1:
        this.lastStepSeen = this.currentStep
        break
    }

    this.loading = null
  }

  public async nextStep() {
    this.currentStep++
    this.scrollTop()
  }

  public async previousStep(valid: boolean) {
    if (!valid) {
      this.lastStepSeen = this.currentStep
    }

    this.currentStep--
    this.canContinue = true
    this.scrollTop()
  }

  public async tryNextStep() {
    this.loading = 'next'
    const step = this.getStepComponent(this.currentStep)
    if (step != null) {
      step.next()
        .finally(() => this.loading = null)
    }
  }

  public async tryPreviousStep() {
    this.loading = 'previous'
    const step = this.getStepComponent(this.currentStep)
    if (step != null) {
      step.previous()
        .finally(() => this.loading = null)
    }
  }

  public async saveStep() {
    this.loading = 'save'
    const step = this.getStepComponent(this.currentStep)
    if (step != null) {
      step.saveStep()
        .finally(() => {
          this.loading = null
        })
    }
  }

  public ensureLastStep(step: number, isValid: boolean) {
    if (!isValid && this.lastStepSeen > step) {
      this.lastStepSeen = step
    }
  }

  public scrollTop() {
    this.$el.scrollIntoView()
  }

  public async goToStepWithValidation(stepToGo: number): Promise<void> {
    if (this.currentStep === stepToGo || !this.canContinue) {
      return;
    }
    if (this.stepsToValidate.some((x: number) => x === this.currentStep)) {
      document.addEventListener('afterEnsured', this.afterEnsured)
      this.stepComponent = this.getStepComponent(this.currentStep)
      this.next = stepToGo > this.currentStep
      this.status = await this.stepComponent?.change(this.next)
      this.stepToGoAfterValidation = stepToGo
    } else {
      if (stepToGo > this.lastStepSeen) {
        await this.goToStep(this.lastStepSeen)
      } else {
        await this.goToStep(stepToGo)
      }
    }
  }

  public async afterEnsured(): Promise<void> {
    switch (this.status) {
      case 1:
        if (this.lastStepSeen === this.stepToGoAfterValidation) {
          this.currentStep = this.stepToGoAfterValidation
        } else if (this.currentStep > this.stepToGoAfterValidation) {
          this.currentStep = this.currentStep - 1
        } else {
          this.currentStep = this.currentStep + 1
        }
        this.canContinue = true
        this.scrollTop()
        break
      case 0:
        this.lastStepSeen = this.currentStep
        if (!this.next) {
          this.currentStep = this.stepToGoAfterValidation
          this.canContinue = true
          this.scrollTop()
        }
        break
      case -1:
        this.lastStepSeen = this.currentStep
        break
    }
    this.loading = null
    document.removeEventListener('afterEnsured', this.afterEnsured)
  }
  //#endregion
}

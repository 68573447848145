
import { Vue, Component, Emit, Prop } from 'vue-property-decorator'
import { AppointmentInfo } from '@/models'
import { dateService, timeService } from '@/services'

@Component({ name: 'SendConfirmation' })
export default class SendConfirmation extends Vue {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly title!: string
  @Prop({ type: String, required: true }) public readonly workFileReference!: string
  @Prop({ type: String, required: false }) public readonly email!: string
  @Prop({ type: Object, required: true }) public readonly appointmentInfo!: AppointmentInfo
  @Prop({ type: Boolean, required: false }) public readonly loading!: boolean
  //#endregion

  //#region [Data]
  //#endregion

  //#region [Watch]
  //#endregion

  //#region [Method]
  //#endregion

  //#region [Mutation]
  //#endregion

  //#region [Computed]
  private get appointmentDate(): string {
    return dateService.displayDateText(this.appointmentInfo.selectedSlot!.startDate!)
  }

  private get appointmentPeriod(): string {
    const fromTime = timeService.displayTime(this.appointmentInfo.selectedSlot!.startDate!)
    const toTime = timeService.displayTime(this.appointmentInfo.selectedSlot!.endDate!)
    return this.$t('appointment.confirmation.fromTo', [fromTime, toTime]).toString()
  }

  //#endregion

  //#region [Methods]
  //#endregion

  //#region [Emit]
  @Emit('close')
  private close(value: boolean): boolean {
    return value
  }
  //#endregion
}


import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import TemporaryElectricityMeterForm from '@/components/inputs/Configuration/TemporaryElectricityMeterForm.vue'
import { MeterElectricityTension, TemporaryElectricityMeter } from '@/models'
import { idGenerator, helper, meterService } from '@/services'

@Component({
  name: 'electricity-meter-forms',
  components: {
    TemporaryElectricityMeterForm
  }
})
export default class ElectricityMeterForms extends Vue {
  //#region [Property]
  @Prop({ type: Array, required: true }) public readonly value!: TemporaryElectricityMeter[]

  @Prop({ type: Array, required: true })
  public readonly tensions!: MeterElectricityTension[]
  //#endregion

  //#region [Method]
  public changeMeter(meter: TemporaryElectricityMeter, index: number) {
    this.value.splice(index, 1, meter)
  }

  public removeMeter(index: number) {
    this.$confirm(this.$t('dialogs.deleteMeter') as string).then((v) => {
      if (v) {
        this.value.splice(index, 1)

        if (this.value.length === 0) {
          this.addMeter()
        }
      }
    })
  }

  public addMeter() {
    this.value.push(meterService.getDefaultTemporaryElectricityMeter('mono230V'))
  }

  public mounted() {
    if (this.value.length === 0) {
      this.addMeter()
    }
  }
  //#endregion
}

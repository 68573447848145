
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'SmartMeterPriceCalculator'
})
export default class SmartMeterPriceCalculator extends Vue {
  @Prop({ type: Boolean, required: true }) public readonly isIndividual!: boolean

  public is25S60Opened: boolean = false
  public readonly prime: number = 243.22
}

import { MutationTree } from 'vuex';
import { CmhEnergyProductionConfigurationState } from './types';

export const mutations: MutationTree<CmhEnergyProductionConfigurationState> = {
  STORE(state, data: CmhEnergyProductionConfigurationState) {
    state.kwcPower = data.kwcPower
    state.kvaPower = data.kvaPower
    state.yesNoMaybeSelection = data.yesNoMaybeSelection
    },
  CLEAR(state) {
    state.kvaPower = null
    state.kwcPower = null
    state.yesNoMaybeSelection = null
  }
}

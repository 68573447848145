import { SubCity } from '@/models'
import { MutationTree } from 'vuex'
import { LandingPageState } from './types'

export const mutations: MutationTree<LandingPageState> = {
  STORE(state, data: SubCity) {
    state.subCity = data
  },
  CLEAR(state) {
    state.subCity = null
  },
  STORE_PACKAGE_FROM_SIMULATOR(state, desiredPackage: string) {
    state.packageFromSimulator = desiredPackage
  },
  STORE_DESIRED_POWER_FROM_SIMULATOR(state, desiredPower: number) {
    state.packageFromSimulator = '69+'
    state.desiredPowerFromSimulator = desiredPower
  },
  CLEAR_SIMULATOR_VALUES(state) {
    state.packageFromSimulator = null
    state.desiredPowerFromSimulator = null
  }
}


import Step from '@/components/steps/Step'
import Component from 'vue-class-component'
import { WorkFile, WorkRequest } from '@/models'
import WorkFileAddressAndContact from '@/components/display/WorkFileTracking/WorkFileDetails/WorkFileAddressAndContact.vue'
import WorkRequestSummary from '@/components/display/WorkFileTracking/WorkFileDetails/WorkRequestSummary.vue'
import WorkRequestStatus from '@/components/display/WorkFileTracking/WorkFileDetails/WorkRequestStatus.vue'
import CancelWorkModal from '@/components/inputs/WorkFileTracking/CancelWorkModal.vue'
import AppointmentModificationModal from '@/components/inputs/WorkFileTracking/AppointmentModificationModal.vue'
import { State } from 'vuex-class'

@Component({
  name: 'work-file-tracking-step',
  components: {
    WorkFileAddressAndContact,
    WorkRequestSummary,
    WorkRequestStatus,
    CancelWorkModal,
    AppointmentModificationModal
  }
})
export default class WorkFileTrackingStep extends Step {
  //#region [Data]
  @State('workFile', { namespace: 'workFileTracking/workFile' })
  public workFile!: WorkFile

  public isCancelWorkModalOpen: boolean = false
  public isAppointmentModificationModalOpen: boolean = false
  //#endregion

  //#region [Mutation]
  //#endregion

  //#region [Computed]
  public authorizedWorkRequest(workRequest: WorkRequest): boolean {
    const authorizedCodes: string[] = [
      'GBBRAPOS',
      'GBBRAPPN',
      'GBBRADEP',
      'GBBRAENL',
      'GBCOMDEP',
      'GBCOMPOS',
      'GBCOMRFC',
      'EBBRAPOS',
      'EBBRADEP',
      'EBBRAPPN',
      'EBBRAENL',
      'EBBRAPRO',
      'EBBRAREM',
      'EBBRARFC',
      'EBCOMDEP',
      'EBCOMPOS',
      'EBCOMPPN',
      'EBCOMRFC',
      'EBBTEPOS',
      'PBBRAPOS',
      'VBBRAPOS',
      'WBBRAPOS',
      'EABRAPOS',
      'EABRADEP',
      'EABRAENL',
      'EABRAPRO',
      'EABRAREM',
      'EABTEPOS',
      'GABRAPOS',
      'GABRADEP',
      'GABRAENL',
      'GABRAREM',
      'EBCOMMES',
      'EBCOMMHS',
      'EBCOMMEM',
      'EBCOMMHM',
      'GBCOMMES',
      'GBCOMMHS',
      'GBCOMMEM',
      'GBCOMMHM',
      'EBBIHDAC',
      'EBBIHACT',
      'EBCOMRET',
      'GBCOMRET',
      'EBSMTPRO',
      'EBSMTREM',
      'EBSMTRNV',
      'EBCABRNV'
    ]
    return authorizedCodes.includes(workRequest.code.toUpperCase())
  }

  //#endregion

  //#region [Methods]
  //#endregion
}


import { PackageDTO } from '@/models/customer-forms-api/index'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class GuaranteedMaximumPowerModal extends Vue {
  //#region [Property]
  @Prop({ type: Boolean, required: true })
  public readonly value!: boolean
  //#endregion

  //#region [Data]
  @State('meterElectricityPackages', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public meterElectricityPackages!: PackageDTO[]
  //#endregion

  //#region [Computed]
  public get IsOpen(): boolean {
    return this.value
  }

  public set IsOpen(val: boolean) {
    this.$emit('input', val)
  }
  //#endregion
}


import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { localize } from 'vee-validate'

@Component({
  name: 'maintenance'
})
export default class Maintenance extends Vue {
  //#region [Property]
  @Prop({ type: String, required: false }) public readonly form!: string | null
  @Prop({ type: String, required: true }) public readonly lang!: string
  //#endregion

  //#region [Watch]
  @Watch('lang')
  public onLangChanged(value: string) {
    this.$i18n.locale = value
    localize(value)
    this.$store.commit('SET_LANGUAGE', value)
  }
  //#endregion
}


import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'price-annexe'
})
export default class PriceAnnexe extends Vue {
  //#region [Property]
  @Prop({ type: Boolean, required: false, default: true }) public showGasFreeCondition!: boolean
  //#endregion
}

export interface GetConnectionContractRequest {
  ean: string;
}

export interface EanConnectionContractResult {
  readingFrequency: ReadingFrequency;
  yearlyReadingMonth: number | null;
}

export enum ReadingFrequency {
  Automatic = 'automatic',
  Daily = 'daily',
  Monthly = 'monthly',
  Other = 'other',
  Unmetered = 'unmetered',
  Yearly = 'yearly'
}

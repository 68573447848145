import { CompanyVatInfoFirstStepSelection, CompanyVatInfoSecondStepSelection, CompanyVatInfoSelection, FreelanceVatInfoSelection, IndividualVatInfoSelection, PackageDTO, PriceDTO } from '@/models'
import { BuildingType } from '@/models/building-type';
import { PackagedElectricityMeter, ModifiedConnection } from '../models/configuration-info';

function getVATInfo(individualVatInfoSelection: IndividualVatInfoSelection, freelanceVatInfoSelection: FreelanceVatInfoSelection, companyVatInfoSelection: CompanyVatInfoSelection): string {
  if (individualVatInfoSelection !== null) {
    return getVATInfoForIndividual(individualVatInfoSelection)
  }
  if (freelanceVatInfoSelection !== null) {
    return getVATInfoForFreelance(freelanceVatInfoSelection)
  }
  if (companyVatInfoSelection.primaryInfo !== null) {
    return getVATInfoForCompany(companyVatInfoSelection)
  }
  return '';
}

function getVATInfoForIndividual(vatInfoSelection: IndividualVatInfoSelection): string {
  if (vatInfoSelection === IndividualVatInfoSelection.LessThanTenYears) {
    return "vat21"
  }
  if (vatInfoSelection === IndividualVatInfoSelection.MoreThanTenYears) {
    return "partialVat6"
  }
  return ''
}

function getVATInfoForFreelance(freelanceVatInfoSelection: FreelanceVatInfoSelection): string {
  if (freelanceVatInfoSelection === FreelanceVatInfoSelection.WithoutDeclaration || freelanceVatInfoSelection === FreelanceVatInfoSelection.NotSubject) {
    return "vat21"
  }
  if (freelanceVatInfoSelection === FreelanceVatInfoSelection.WithDeclaration) {
    return "partialVat0"
  }
  return ''
}

function getVATInfoForCompany(companyVatInfoSelection: CompanyVatInfoSelection): string {
  if (companyVatInfoSelection.primaryInfo === CompanyVatInfoFirstStepSelection.SubjectToVat) {
    if (companyVatInfoSelection.secondaryInfo === CompanyVatInfoSecondStepSelection.WithDeclaration) {
      return "partialVat0"
    }
    if (companyVatInfoSelection.secondaryInfo === CompanyVatInfoSecondStepSelection.WithoutDeclaration) {
      return "vat21"
    }
  }
  if (companyVatInfoSelection.primaryInfo === CompanyVatInfoFirstStepSelection.TownWithoutSocialCharacter) {
    if (companyVatInfoSelection.secondaryInfo === CompanyVatInfoSecondStepSelection.PublicBuilding) {
      return "vat21"
    }
    if (companyVatInfoSelection.secondaryInfo === CompanyVatInfoSecondStepSelection.PrivateBuildingMoreThanTenYears) {
      return "partialVat6"
    }
    if (companyVatInfoSelection.secondaryInfo === CompanyVatInfoSecondStepSelection.PrivateBuildingLessThanTenYears) {
      return "vat21"
    }
  }
  if (companyVatInfoSelection.primaryInfo === CompanyVatInfoFirstStepSelection.SocialHousing) {
    return "partialVat6"
  }
  if (companyVatInfoSelection.primaryInfo === CompanyVatInfoFirstStepSelection.Embassy) {
    return "exVat"
  }
  if (companyVatInfoSelection.primaryInfo === CompanyVatInfoFirstStepSelection.TownWithSocialCharacter) {
    if (companyVatInfoSelection.secondaryInfo === CompanyVatInfoSecondStepSelection.SocialPrivateBuilding) {
      return "partialVat6"
    }
    if (companyVatInfoSelection.secondaryInfo === CompanyVatInfoSecondStepSelection.PrivateBuildingForDisabled) {
      return "partialVat12"
    }
  }
  if (companyVatInfoSelection.primaryInfo === CompanyVatInfoFirstStepSelection.HousingForDisabled) {
    return "partialVat6"
  }
  if (companyVatInfoSelection.primaryInfo === CompanyVatInfoFirstStepSelection.RestHouse) {
    if (companyVatInfoSelection.secondaryInfo === CompanyVatInfoSecondStepSelection.PublicBuilding) {
      return "vat21"
    }
    if (companyVatInfoSelection.secondaryInfo === CompanyVatInfoSecondStepSelection.PrivateBuildingMoreThanTenYears) {
      return "partialVat6"
    }
    if (companyVatInfoSelection.secondaryInfo === CompanyVatInfoSecondStepSelection.PrivateBuildingLessThanTenYears) {
      return "partialVat12"
    }
  }
  if (companyVatInfoSelection.primaryInfo === CompanyVatInfoFirstStepSelection.School) {
    return "partialVat6"
  }
  return ''
}

function getVatLabel(selectedTVA: string): string {
  switch (selectedTVA) {
    case "exVat":
      return 'meter.excludingVATPrice'
    case "partialVat0":
      return 'meter.0VATPrice'
    case "partialVat6":
      return 'meter.6VATPrice'
    case "partialVat12":
      return 'meter.12VATPrice'
    case "vat21":
      return 'meter.21VATPrice'
    default:
      return 'meter.excludingVATPrice'
  }
}

function getVATPriceForPackage(meter: PackagedElectricityMeter, selectedVAT: string, buildingType: BuildingType): number {
  const prices: PriceDTO = buildingType === BuildingType.house ? meter?.package?.homePrice! : meter?.package?.buildingPrice!
  switch (selectedVAT) {
    case "exVat":
      return prices.exVat * meter.count!
    case "partialVat0":
      return prices.partialVat0 * meter.count!
    case "partialVat6":
      return prices.partialVat06 * meter.count!
    case "partialVat12":
      return prices.partialVat12 * meter.count!
    case "vat21":
      return prices.vat21 * meter.count!
    default:
      return prices.exVat * meter.count!
  }
}

function getVATPriceForAdditionnalConnection(meter: ModifiedConnection, selectedVAT: string): number {
  switch (selectedVAT) {
    case "exVat":
      return meter?.price?.exVat! * meter.count!
    case "partialVat0":
      return meter?.price?.partialVat0! * meter.count!
    case "partialVat6":
      return meter?.price?.partialVat06! * meter.count!
    case "partialVat12":
      return meter?.price?.partialVat12! * meter.count!
    case "vat21":
      return meter?.price?.vat21! * meter.count!
    default:
      return meter?.price?.exVat! * meter.count!
  }
}

export const vatService = {
  getVATInfo,
  getVATInfoForIndividual,
  getVATPriceForAdditionnalConnection,
  getVatLabel,
  getVATPriceForPackage,
}
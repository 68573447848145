import { OfferingSelectionState } from '@/store/new-connection-cmh/offerings-selection/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<OfferingSelectionState> = {
  STORE(state, data: string[]) {
    state.selections = data
  },
  CLEAR(state) {
    state.selections = []
  }
}

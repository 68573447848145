import { CmhFilesState } from '@/store/new-connection-cmh/cmh-files/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<CmhFilesState> = {
  STORE(state, data: CmhFilesState) {
    state.fileInfo = data.fileInfo
    state.showInputFile = data.showInputFile
  },
  CLEAR(state) {
    state.fileInfo = []
    state.showInputFile = state.showInputFile
  }
}

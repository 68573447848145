
import { Component, Prop } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import ContactInfoForm from '@/components/inputs/Contact/ContactInfoForm.vue'
import { State, Mutation } from 'vuex-class'
import {
  ContactInfo,
  ChargingStationDeclarationGridAccessPoint
} from '@/models'
import { helper, contactService } from '@/services'

@Component({
  name: 'contact-info-step',
  components: {
    ContactInfoForm
  }
})
export default class ContactInfoStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'chargingStationDeclaration' })
  public requestId!: string
  @State('gridAccessPoint', { namespace: 'chargingStationDeclaration/identification' })
  public gridAccessPointState!: ChargingStationDeclarationGridAccessPoint | null

  @State('contactInfo', { namespace: 'chargingStationDeclaration/contactInfo' })
  public contactInfoState!: ContactInfo | null

  public contactInfo: ContactInfo = contactService.createContactInfo()
  //#endregion

   //#region [Property]
   @Prop({ type: Boolean, required: false, default: false }) public readonly showCivility!: boolean
  //#endregion


  //#region [Mutation]
  @Mutation('STORE', { namespace: 'chargingStationDeclaration/contactInfo' })
  private store!: (index: ContactInfo | null) => void
  //#endregion

  //#region [Watch]
  public onStateChanged(state: ContactInfo | null) {
    if (state !== null) {
      this.contactInfo = helper.clone(state)
    }
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    this.onStateChanged(this.contactInfoState)
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid) {
      this.$appInsights.trackEvent('ChargingStationDeclaration_StepContact_Validated', {
        Segment: 'ChargingStationDeclaration',
        WebRef: this.requestId
      })
    }

    this.store(this.contactInfo)
  }
  //#endregion
}

import { MutationTree } from 'vuex';
import { ContactInfoState } from './types';
import { ContactInfo } from '@/models';

export const mutations: MutationTree<ContactInfoState> = {
  STORE(state, data: ContactInfo) {
    state.contactInfo = data
  },
  CLEAR(state) {
    state.contactInfo = null
  }
}

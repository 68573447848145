
import Step from '@/components/steps/Step'
import Component from 'vue-class-component'
import { Mutation, State } from 'vuex-class'
import ChargingStationInformationComponent from '@/components/inputs/ChargingStationDeclaration/ChargingStationInformationComponent.vue'
import { ChargingStationInformation } from '@/models/charging-station-declaration'
import { helper } from '@ores/vue-library'

@Component({
  name: 'charging-station-information-step',
  components: {
    ChargingStationInformationComponent
  }
})
export default class ChargingStationInformationStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'chargingStationDeclaration' }) public requestId!: string
  @State('stations', { namespace: 'chargingStationDeclaration/information' })
  public chargingStationsState!: ChargingStationInformation[]

  @Mutation('STORE', { namespace: 'chargingStationDeclaration/information' })
  public store!: (data: ChargingStationInformation[] | null) => void

  public chargingStations: ChargingStationInformation[] = [{
    inService: null,
    brand: null,
    biDirectional: null,
    isSmart: null,
    model: null,
    inServiceDate: null,
    plug: null,
    power: null,
    usage: null
  }]
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    if (this.chargingStationsState) {
      this.chargingStations = helper.clone(this.chargingStationsState)
    }
  }

  public add() {
    this.$confirm(this.$t('chargingStationDeclaration.information.addStationQuestion') as string).then((v) => {
      if (v) {
        this.chargingStations.push(helper.clone(this.chargingStations[this.chargingStations.length - 1]))
      } else {
        this.chargingStations.push({
          inService: null,
          brand: null,
          biDirectional: null,
          isSmart: null,
          model: null,
          inServiceDate: null,
          plug: null,
          power: null,
          usage: null
        })
      }
    })
  }

  public remove(index: number) {
    this.$confirm(
      this.$t('chargingStationDeclaration.information.deleteStation', { count: (index + 1).toString() }) as string)
      .then((v) => {
      if (v) {
        this.chargingStations.splice(index, 1)
      }
    })
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid) {
      this.$appInsights.trackEvent('ChargingStationDeclaration_ChargingStationInformationStep_Validated', {
        Segment: 'ChargingStationDeclaration',
        WebRef: this.requestId
      })
    }

    this.store(this.chargingStations)
  }

  //#endregion
}

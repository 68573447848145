import {
  MeterElectricityTension,
  MeterElectricityType,
  ConstructionConfigurationInfo
} from '@/models'

export interface ConfigurationInfoState {
  configurationInfo: ConstructionConfigurationInfo | null
  electricityTensions: MeterElectricityTension[]
}

const electricityTensions: MeterElectricityTension[] = ['mono230V', 'tri230V', 'tri400V']

export const InitialState: ConfigurationInfoState = {
  configurationInfo: null,
  electricityTensions
}

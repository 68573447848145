
import { Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import { addressService } from '@/services'
import InputWithValidation from './InputWithValidation'
import FieldBox from '@/components/inputs/Shared/FieldBox'
import { AxiosResponse } from 'axios'
import { SubCityStreetAddress } from '@/models'
import Component, { mixins } from 'vue-class-component'

@Component
export default class HouseNumberSelector extends mixins(InputWithValidation, FieldBox) {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly label!: string
  @Prop(Boolean) public readonly hideLabel?: boolean
  @Prop(String) public value!: string | null
  @Prop({ type: Number, required: false }) public readonly streetSubCityId!: number
  @Prop(Boolean) public readonly required?: boolean
  @Prop(Boolean) public readonly withOtherOption?: boolean
  //#endregion

  //#region [Data]
  public houseNumbers: SubCityStreetAddress[] = []
  public selectedHouseNumber: string | null = this.value
  public useCustomHouseNumber: boolean = false
  public withBackButton: boolean = false
  //#endregion

  //#region [Watch]
  @Watch('streetSubCityId')
  public onStreetSubCityIdChanged(): void {
    this.$nextTick(() => {
      this.fetchHouseNumbers()
    })
  }

  @Watch('selectedHouseNumber')
  public onSelectedHouseNumberChanged(newValue: string | null) {
    if (newValue === '-1') {
      this.useCustomHouseNumber = true
      this.selectedHouseNumber = null
    } else {
      this.$emit('input', newValue)
    }
  }

  @Watch('value')
  public onValueChanged(newValue: string | null) {
    this.selectedHouseNumber = newValue
    this.useCustomHouseNumber = !newValue || !this.houseNumbers.find((n) => n.houseNumber === newValue)
  }

  @Watch('useCustomHouseNumber')
  public onUseCustomHouseNumberChanged() {
    // TODO
    // this.$validator.pause()
    // this.$nextTick(() => this.$validator.resume())
  }
  //#endregion

  //#region [Method]
  public fetchHouseNumbers(): void {
    const streetSubCityId = this.streetSubCityId
    if (streetSubCityId === null || isNaN(streetSubCityId)) {
      this.houseNumbers = []
      this.selectedHouseNumber = null
      return
    }
    this.isLoading = true
    this.$addressRepository
      .get(`addresses?streetSubcityId=${streetSubCityId}`, {
        cache: {
          ignoreCache: false
        }
      })
      .then(
        (response: AxiosResponse<SubCityStreetAddress[]>) => {
          const apiHouseNumbers = response.data.sort((x, y) =>
            addressService.compareHouseNumber(x.houseNumber, y.houseNumber)
          )
          const selectedHouseNumber = this.selectedHouseNumber
          if (apiHouseNumbers.length > 0) {
            if (selectedHouseNumber) {
              const found = !!apiHouseNumbers.find((n) => n.houseNumber === this.selectedHouseNumber)
              this.useCustomHouseNumber = !found
            } else if (!this.disabled) {
              this.useCustomHouseNumber = false
            }
            this.withBackButton = true
          } else {
            // no house numbers returned by service
            this.useCustomHouseNumber = true
            this.withBackButton = false
          }
          // update current house numbers after search
          this.houseNumbers = apiHouseNumbers
          this.selectedHouseNumber = selectedHouseNumber
        },
        (error) => {
          this.useCustomHouseNumber = true
          this.withBackButton = false
          this.houseNumbers = []
        }
      )
      .then(() => {
        this.isLoading = false
      })
  }

  public backButtonClicked(): void {
    this.useCustomHouseNumber = false
    this.selectedHouseNumber = null
    this.$nextTick(() => {
      this.fetchHouseNumbers()
    })
  }

  public updateValue(value: string | null) {
    if (value !== null && value.length === 0) {
      value = null
    }
    if (value !== '-1') {
      this.$emit('input', value)
    }
  }

  public firstError(errors: { [key: string]: string[] }): string | null {
    for (const source of Object.values(errors)) {
      if (source.length > 0) {
        return source[0]
      }
    }

    return null
  }

  public mounted() {
    if (this.streetSubCityId !== null) {
      this.fetchHouseNumbers()
    }
  }
  //#endregion
}

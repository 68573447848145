
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'
import { helper } from '@/services'
import {
  ContactInfo,
  IndividualVatInfoSelection,
  FreelanceVatInfoSelection,
  CompanyVatInfoSelection,
  VatInfoSelection
} from '@/models'
import VatInfoSelector from '@/components/inputs/Vat/VatInfoSelector.vue'
import VatInfoBox from '@/components/inputs/Vat/VatInfoBox.vue'
import Step from '@/components/steps/Step'
import { VatInfo } from '@/models'
import { pixelFacebookService } from '@ores/vue-library'

@Component({
  components: {
    VatInfoSelector,
    VatInfoBox
  },
  name: 'cmh-tva-info-step'
})
export default class CmhVatInfoStep extends Step {
  @State('contactInfo', { namespace: 'newConnectionCmh/cmhContact' })
  public contactInfoState!: ContactInfo | null
  @State('individualVatInfoSelection', { namespace: 'newConnectionCmh/cmhVatInfo' })
  public stateIndividualVatInfoSelection!: IndividualVatInfoSelection | null
  @State('freelanceVatInfoSelection', { namespace: 'newConnectionCmh/cmhVatInfo' })
  public stateFreelanceVatInfoSelection!: FreelanceVatInfoSelection | null
  @State('companyVatInfoSelection', { namespace: 'newConnectionCmh/cmhVatInfo' })
  public stateCompanyVatInfoSelection!: CompanyVatInfoSelection | null

  @State('requestId', { namespace: 'newConnectionCmh' })
  public requestId!: string

  public individualVatInfoSelection: IndividualVatInfoSelection | null = null
  public freelanceVatInfoSelection: FreelanceVatInfoSelection | null = null
  public companyVatInfoSelection: CompanyVatInfoSelection | null = null

  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhVatInfo' })
  public store!: (index: VatInfo) => void

  public mounted() {
    pixelFacebookService.trackViewContent({
      content_ids: ['CmhForm'],
      content_name: 'Vat Step',
      content_type: 'product'
    })
    if (this.stateIndividualVatInfoSelection !== null) {
      this.individualVatInfoSelection = helper.clone(this.stateIndividualVatInfoSelection)
    }
    if (this.stateFreelanceVatInfoSelection !== null) {
      this.freelanceVatInfoSelection = helper.clone(this.stateFreelanceVatInfoSelection)
    }
    if (this.stateCompanyVatInfoSelection !== null) {
      this.companyVatInfoSelection = helper.clone(this.stateCompanyVatInfoSelection)
    }
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (isValid) {
      this.store({
        individualVatInfoSelection: this.individualVatInfoSelection,
        freelanceVatInfoSelection: this.freelanceVatInfoSelection,
        companyVatInfoSelection: this.companyVatInfoSelection
      })

      this.saveStep()
      this.$appInsights.trackEvent('WR_NCCMH_Step6_Validated', { Segment: 'WR_NCCMH', WebRef: this.requestId })
    }
  }

  public async saveStep() {
    const data: VatInfoSelection = {
      individualVatInfoSelection: this.individualVatInfoSelection,
      freelanceVatInfoSelection: this.freelanceVatInfoSelection,
      companyVatInfoFirstStepSelection: this.companyVatInfoSelection?.primaryInfo ?? null,
      companyVatInfoSecondStepSelection: this.companyVatInfoSelection?.secondaryInfo ?? null
    }

    this.$api.post<VatInfoSelection>(`NewConnectionRequest/store/${this.requestId}/vat-info`, data)
  }
}

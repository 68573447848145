
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AddressPoint, ConsumptionAddress } from '@/models'

@Component
export default class ResumeInfoItem extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly address!: AddressPoint | ConsumptionAddress
  //#endregion

  //#region [Computed]
  public get isConsumptionAddress(): boolean {
    return (this.address as ConsumptionAddress).cityName !== undefined
  }
  //#endregion
}


import Vue from 'vue'
import { Prop, Watch, Component } from 'vue-property-decorator'
import { SubCity } from '@/models'

@Component({
  name: 'zipCode-locality-selector'
})
export default class ZipCodeLocalitySelector extends Vue {
  //#region [Property]
  @Prop({ type: Boolean, required: false, default: false }) public required!: boolean
  @Prop({ type: String, required: false, default: '' }) public inputClass!: string
  @Prop({ type: String, required: false, default: '' }) public placeHolder!: string
  @Prop({
    type: Function,
    required: false,
    default: () => {
      /* */
    }
  })
  public onClick!: () => void
  @Prop({
    type: Function,
    required: false,
    default: () => {
      /* */
    }
  })
  public onBlur!: () => void
  @Prop({ type: String, required: false, default: '' }) public unorderedListContainerClass!: string
  @Prop({ type: String, required: false, default: '' }) public suggestionItemClass!: string
  @Prop({ type: String, required: false, default: '' }) public suggestionItemTextClass!: string
  @Prop({ type: Object, required: false, default: null }) public value!: SubCity | null
  @Prop({ type: String, required: false, default: '' }) public label!: string
  //#endregion

  //#region [Data]
  public subCities: SubCity[] = []
  public zipCodeOrSubCity: string = ''
  //#endregion

  //#region [Watch]
  @Watch('zipCodeOrSubCity', { deep: false, immediate: false })
  public zipCodeWatcher() {
    this.zipCodeWatcherHandler()
  }

  @Watch('zipCodeOrSubCity')
  public onZipCodeOrSubictyChange() {
    if (this.zipCodeOrSubCity === '') {
      this.subCitySelected(null)
    }
  }

  @Watch('value')
  public onSubCity() {
    if (this.value) {
      this.fillInputValue(this.value)
    }
  }

  //endregion

  //#region [Method]
  public async mounted() {
    await this.zipCodeWatcherHandler()
    if (this.value) {
      this.fillInputValue(this.value)
    }
  }

  public async zipCodeWatcherHandler(): Promise<void> {
    if (this.zipCodeOrSubCity && this.zipCodeOrSubCity.trim() && this.zipCodeOrSubCity.trim().length) {
      this.zipCodeOrSubCity.trim().match(/^\d/)
        ? await this.autoCompleteByZipCode(this.zipCodeOrSubCity)
        : await this.autoCompleteByLocality(this.zipCodeOrSubCity)
    }
  }

  public async autoCompleteByZipCode(zipCode: string): Promise<void> {
    if (zipCode?.length !== 4) {
      return
    }

    const subCities = await this.getSubcities(zipCode)
    if (!!subCities.length) {
      this.subCities = subCities
    } else {
      this.setDefaultSubCity(zipCode, '')
    }
  }

  public async autoCompleteByLocality(locality: string): Promise<void> {
    if (!locality) {
      return
    }

    const subCities = await this.getSubcities()
    if (!!subCities.length) {
      const filteredSubcities: SubCity[] = subCities.filter((subCity: SubCity) =>
        subCity.cityName.startsWith(locality.toUpperCase())
      )
      if (filteredSubcities && filteredSubcities.length) {
        this.subCities =
          filteredSubcities.length > 8
            ? filteredSubcities.filter((subCity: SubCity, index: number) => index <= 8)
            : filteredSubcities
      } else {
        this.setDefaultSubCity('', locality)
      }
    }
  }

  public clearAutocomplete() {
    this.subCities = []
    this.zipCodeOrSubCity = ''
  }

  public fillInputValue(subCity: SubCity): void {
    if (subCity.zipCode === '') {
      this.zipCodeOrSubCity = `${subCity.cityName}`
    } else if (subCity.cityName === '') {
      this.zipCodeOrSubCity = `${subCity.zipCode}`
    } else {
      this.zipCodeOrSubCity = `${subCity.zipCode} - ${subCity.cityName}`
    }
  }

  public subCitySelected(subCity: SubCity | null) {
    this.$emit('input', subCity)
    if (subCity) {
      this.clearAutocomplete()
      this.fillInputValue(subCity)
    } else {
      this.subCities = []
    }
  }

  private async getSubcities(zipCode?: string): Promise<SubCity[]> {
    let url = 'subcities'
    if (zipCode !== undefined) {
      url += `?zipcode=${zipCode}`
    }

    const subCities = await this.$addressRepository
      .get<SubCity[]>(url, {
        cache: {
          ignoreCache: false
        }
      })
      .then((response) => response.data)

    return subCities.filter((sc) => !sc.isFictive)
  }

  private setDefaultSubCity(zipCode: string, cityName: string): void {
    this.subCitySelected(null)
    this.subCities = [
      {
        georesSubcityId: -1,
        zipCode,
        cityName,
        municipality: {
          georesMunicipalityId: -1,
          name: ''
        },
        isFictive: false,
        connectMyHomeActivated: false,
        connectMyHomeActivationDate: null,
        connectMyHomeInformation: null,
        gridOperators: null,
        gridManagers: {
          electricityManager: null,
          gasManager: null
        },
        operationalOffices: {
          electricityOffice: null,
          gasOffice: null
        }
      }
    ]
  }

  //#endregion
}

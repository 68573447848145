import { ConsumptionAddress } from '.';
import { GridAccessPoint } from '@/models/prosumer-declaration'

export interface CheckEanSearch {
  ean: string;
}

export enum MetersError {
  NoError = 'noError',
  NotFound = 'notFound',
  MeterIsAlreadyInDoubleFlow = 'meterIsAlreadyInDoubleFlow',
  DeliveryContractNotActive = 'deliveryContractNotActive',
  NotElectricityGridAccessPoint = 'notElectricityGridAccessPoint',
  MoreThanOneMeter = 'moreThanOneMeter',
  ReadingFrequencyNotYearly = 'readingFrequencyNotYearly',
  BudgetMeterDeactivationRequired = 'budgetMeterDeactivationRequired'
}

export interface CheckEanSmartMeterResult {
  error: MetersError;
  consumptionAddress: ConsumptionAddress;
}

export interface CheckEanForSmartMeterResponse {
  eanInfo: CheckEanSmartMeterResult;
  token: string;
}

export interface CheckEanForChargingStationDeclarationResponse {
  gridAccessPoint: ChargingStationDeclarationGridAccessPoint;
  token: string;
}

export interface ChargingStationDeclarationGridAccessPoint {
  consumptionAddress: ConsumptionAddress;
  ean: string;
}

export interface CheckEanForProsumerDeclarationResponse {
  gridAccessPoint: GridAccessPoint;
  token: string;
}

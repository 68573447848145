
import { Component } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import EnergyProductionInfo from '@/components/inputs/EnergyProduction/EnergyProductionInfo.vue'
import {
  EnergyProductionInfoModel
} from '@/models'
import { State, Mutation } from 'vuex-class'
import { helper } from '@/services'

@Component({
  name: 'energy-production-step',
  components: {
    EnergyProductionInfo
  }
})
export default class EnergyProductionStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'smartMeterConnection' })
  public requestId!: string
  @State('eanCode', { namespace: 'smartMeterConnection/identification' })
  public eanCodeState!: string | null
  @State('energyProductionInfo', { namespace: 'smartMeterConnection/energyProduction' })
  public energyProductionInfoState!: EnergyProductionInfoModel | null
  @State('isBack')
  public isBack!: boolean

  public energyProductionInfo: EnergyProductionInfoModel | null = null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'smartMeterConnection/energyProduction' })
  private store!: (index: EnergyProductionInfoModel | null) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.energyProductionInfo = helper.clone(this.energyProductionInfoState)
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid) {
      this.$appInsights.trackEvent('WR_SMC_StepEnergyProduction_Validated', {
        Segment: 'WR_SMC',
        WebRef: this.requestId,
        EnergyProductionInfo:
        this.energyProductionInfo!.hasEnergyProduction !== null
          ? this.energyProductionInfo!.hasEnergyProduction.toString()
          : '0',
      })
    }
    this.store(this.energyProductionInfo)
  }
  //#endregion
}


import { Component, Prop } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import PriceAnnexe from '@/components/display/PriceAnnexe.vue'
import ElectricityPriceCalculator from '@/components/display/ElectricityPriceCalculator.vue'
import TemporaryPriceCalculator from '@/components/display/TemporaryPriceCalculator.vue'
import GasPriceCalculator from '@/components/display/GasPriceCalculator.vue'
import ResumeContactInfo from '@/components/display/Resume/ResumeContactInfo.vue'
import ResumeVatInfo from '@/components/display/Resume/ResumeVatInfo.vue'
import ResumeAddress from '@/components/display/Resume/ResumeAddress.vue'
import {
  AddressPoint,
  ContactInfo,
  ElectricityMeter,
  IndividualVatInfoSelection,
  FreelanceVatInfoSelection,
  CompanyVatInfoSelection,
  CompanyVatInfoFirstStepSelection,
  CompanyVatInfoSecondStepSelection,
  TemporaryConfigurationInfo,
  TemporaryElectricityMeter
} from '@/models'
import { State } from 'vuex-class'
import { contactService, dateService } from '@/services'

@Component({
  name: 'summary-step',
  components: {
    PriceAnnexe,
    ElectricityPriceCalculator,
    GasPriceCalculator,
    TemporaryPriceCalculator,
    ResumeContactInfo,
    ResumeVatInfo,
    ResumeAddress,
  }
})
export default class SummaryStep extends Step {
  //#region [Property]
  @Prop({ type: Boolean, default: false }) public readonly readOnly!: boolean
  //#endregion

  //#region [Data]
  @State('requestId', { namespace: 'temporaryConnection' })
  public requestId!: string
  @State('address', { namespace: 'temporaryConnection/address' })
  public addressState!: AddressPoint | null
  @State('configurationInfo', { namespace: 'temporaryConnection/configurationInfo' })
  public configurationInfoState!: TemporaryConfigurationInfo | null
  @State('contactInfo', { namespace: 'temporaryConnection/contactInfo' })
  public contactInfoState!: ContactInfo | null
  @State('individualVatInfoSelection', { namespace: 'temporaryConnection/vatInfo' })
  public individualVatInfoSelectionState!: IndividualVatInfoSelection | null
  @State('freelanceVatInfoSelection', { namespace: 'temporaryConnection/vatInfo' })
  public freelanceVatInfoSelectionState!: FreelanceVatInfoSelection | null
  @State('companyVatInfoSelection', { namespace: 'temporaryConnection/vatInfo' })
  public companyVatInfoSelectionState!: CompanyVatInfoSelection | null

  public defaultElecticityMeter: ElectricityMeter = {
    tension: 'mono230V',
    count: 1,
    power: { ampere: 40, kva: 9.2 },
    destination: 'residential',
    type: 'dual',
    remarks: null,
    wantEmergencyPower: false
  }

  public recaptcha: string | null = null
  //#endregion

  //#region [Computed]
  public get siteKey() {
    return process.env.VUE_APP_CAPTCHA_SITE_KEY
  }
  public get meters(): TemporaryElectricityMeter[] {
    return this.configurationInfoState!.electricityMeters
  }

  public get formattedPeriod(): string[] {
    return [
      dateService.displayDateText(this.configurationInfoState!.startDate!),
      dateService.displayDateText(this.configurationInfoState!.endDate!)
    ]
  }

  public get buildingHasMoreThanTenYears(): boolean {
    return (
      this.individualVatInfoSelectionState !== null &&
      this.individualVatInfoSelectionState === IndividualVatInfoSelection.MoreThanTenYears
    )
  }

  public get freelanceDeclaration(): string {
    switch (this.freelanceVatInfoSelectionState) {
      case FreelanceVatInfoSelection.WithDeclaration:
        return this.$t('vatInfo.withDeclarationDescription') as string
      case FreelanceVatInfoSelection.WithoutDeclaration:
        return this.$t('vatInfo.withoutDeclarationDescription') as string
      case FreelanceVatInfoSelection.NotSubject:
        return this.$t('vatInfo.notSubjectDescription') as string
      default:
        return ''
    }
  }

  public get firstStepCompanyVatInfo(): string | undefined {
    if (this.companyVatInfoSelectionState) {
      switch (this.companyVatInfoSelectionState.primaryInfo) {
        case CompanyVatInfoFirstStepSelection.SubjectToVat:
          return this.$t('vatInfo.subjectToVatDescription') as string

        case CompanyVatInfoFirstStepSelection.TownWithoutSocialCharacter:
          return this.$t('vatInfo.townWithoutSocialCharacterDescription') as string

        case CompanyVatInfoFirstStepSelection.SocialHousing:
          return this.$t('vatInfo.socialHousingDescription') as string

        case CompanyVatInfoFirstStepSelection.Embassy:
          return this.$t('vatInfo.embassyDescription') as string

        case CompanyVatInfoFirstStepSelection.TownWithSocialCharacter:
          return this.$t('vatInfo.townWithSocialCharacterDescription') as string

        case CompanyVatInfoFirstStepSelection.HousingForDisabled:
          return this.$t('vatInfo.housingForDisabledDescription') as string

        case CompanyVatInfoFirstStepSelection.RestHouse:
          return this.$t('vatInfo.restHouseDescription') as string

        case CompanyVatInfoFirstStepSelection.School:
          return this.$t('vatInfo.schoolDescription') as string

        default:
          break
      }
    }
    return ''
  }

  public get secondStepCompanyVatInfo(): string {
    if (this.companyVatInfoSelectionState) {
      switch (this.companyVatInfoSelectionState.secondaryInfo) {
        case CompanyVatInfoSecondStepSelection.WithDeclaration:
          return this.$t('vatInfo.withDeclarationDescription') as string

        case CompanyVatInfoSecondStepSelection.WithoutDeclaration:
          return this.$t('vatInfo.withoutDeclarationDescription') as string

        case CompanyVatInfoSecondStepSelection.PublicBuilding:
          return this.$t('vatInfo.publicBuildingDescription') as string

        case CompanyVatInfoSecondStepSelection.PrivateBuildingLessThanTenYears:
          return this.$t('vatInfo.privateBuildingLessThaTenYearsDescription') as string

        case CompanyVatInfoSecondStepSelection.PrivateBuildingMoreThanTenYears:
          return this.$t('vatInfo.privateBuildingMoreThaTenYearsDescription') as string

        case CompanyVatInfoSecondStepSelection.SocialPrivateBuilding:
          return this.$t('vatInfo.socialPrivateBuildingDescription') as string

        case CompanyVatInfoSecondStepSelection.PrivateBuildingForDisabled:
          return this.$t('vatInfo.privateBuildingForDisabledDescription') as string

        default:
          break
      }
    }
    return ''
  }

  public get contactName(): string {
    if (this.contactInfoState) {
      switch (this.contactInfoState.contactType) {
        case 'individual':
          if (this.contactInfoState.contactIndividual) {
            let civility = null
            if (this.contactInfoState.contactIndividual.civility === 1) {
              civility = this.$t('contact.civility.mr')
            } else if (this.contactInfoState.contactIndividual.civility === 2) {
              civility = this.$t('contact.civility.mrs')
            }
            return `${civility} ${this.contactInfoState.contactIndividual.firstname} ${this.contactInfoState.contactIndividual.lastname}`
          }
          break
        case 'freelance':
          if (this.contactInfoState.contactFreelance) {
            let civility = null
            if (this.contactInfoState.contactFreelance.civility === 1) {
              civility = this.$t('contact.civility.mr')
            } else if (this.contactInfoState.contactFreelance.civility === 2) {
              civility = this.$t('contact.civility.mrs')
            }
            return `${civility} ${this.contactInfoState.contactFreelance.firstname} ${this.contactInfoState.contactFreelance.lastname}`
          }
          break
        case 'company':
          if (this.contactInfoState.contactCompany) {
            return `${this.contactInfoState.contactCompany.firstname} ${this.contactInfoState.contactCompany.lastname}`
          }
          break
        default:
          return ''
      }
    }
    return ''
  }

  public get contactMail(): string {
    if (this.contactInfoState) {
      switch (this.contactInfoState.contactType) {
        case 'individual':
          if (this.contactInfoState.contactIndividual) {
            return this.contactInfoState.contactIndividual.email!
          }
          break
        case 'freelance':
          if (this.contactInfoState.contactFreelance) {
            return this.contactInfoState.contactFreelance.email!
          }
          break
        case 'company':
          if (this.contactInfoState.contactCompany) {
            return this.contactInfoState.contactCompany.email!
          }
          break
        default:
          return ''
      }
    }
    return ''
  }

  public get contactPhones(): string[] {
    if (this.contactInfoState) {
      switch (this.contactInfoState.contactType) {
        case 'individual':
          if (this.contactInfoState.contactIndividual) {
            return [this.contactInfoState.contactIndividual.mobile, this.contactInfoState.contactIndividual.phone]
              .filter((n) => !!n)
              .map((n) => n!)
          }
          break
        case 'freelance':
          if (this.contactInfoState.contactFreelance) {
            return [this.contactInfoState.contactFreelance.mobile, this.contactInfoState.contactFreelance.phone]
              .filter((n) => !!n)
              .map((n) => n!)
          }
          break
        case 'company':
          if (this.contactInfoState.contactCompany) {
            return [this.contactInfoState.contactCompany.phone!]
          }
          break
        default:
          return []
      }
    }
    return []
  }

  public get contactAddress(): string {
    if (this.contactInfoState) {
      if (this.contactInfoState.useWorksAddress) {
        if (this.contactInfoState.contactType === 'company') {
          const address = this.contactInfoState.contactCompany!.address
          return `${address.street} ${address.streetNumber} ${address.streetBox || ''} ${address.zipCode} ${
            address.locality
          }`
        } else if (this.addressState) {
          return this.addressState.isMapLocation
            ? `${this.addressState.mapLocation!.lat} ${this.addressState.mapLocation!.lng} ${
                this.addressState.subCity.zipCode
              } ${this.addressState.subCity.cityName}`
            : `${this.addressState.streetSubCity!.name} ${this.addressState.houseNumber} ${
                this.addressState.subCity.zipCode
              } ${this.addressState.subCity.cityName}`
        }
      } else if (this.contactInfoState.otherAddress) {
        return `${this.contactInfoState.otherAddress.street} ${this.contactInfoState.otherAddress.streetNumber} ${this.contactInfoState.otherAddress.zipCode} ${this.contactInfoState.otherAddress.locality} ${this.contactInfoState.otherAddress.country}`
      }
    }
    return ''
  }
  //#endregion

  //#region [Method]
  public goToStep(step: number) {
    this.$emit('goToStep', step)
  }

  public async save(next: boolean, valid: boolean): Promise<boolean> {
    if (!next || !valid) {
      return true
    }

    if (this.addressState && this.addressState.subCity) {
      contactService.cleanContactInfo(this.contactInfoState!)

      const data = {
        requestId: this.requestId,
        stepAddress: {
          zipCode: this.addressState.subCity.zipCode,
          subCityId: this.addressState.subCity.georesSubcityId,
          streetSubCityId: this.addressState.streetSubCity
            ? this.addressState.streetSubCity.georesStreetSubcityId
            : null,
          houseNumber: this.addressState.houseNumber,
          isMapLocation: this.addressState.isMapLocation,
          mapLocation: this.addressState.mapLocation
        },
        stepConfigurationInfo: this.configurationInfoState,
        stepContactInfo: this.contactInfoState,
        stepVatInfoSelection: {
          individualVatInfoSelection: this.individualVatInfoSelectionState,
          freelanceVatInfoSelection: this.freelanceVatInfoSelectionState,
          companyVatInfoSelection: this.companyVatInfoSelectionState
        }
      }
      const response = await this.$api.post('api/worksrequest/temporary-connection-request', data, {
        headers: {
          'g-recaptcha-response': this.recaptcha
        }
      })
      if (response.status >= 200 && response.status < 300) {
        let use = ''

        for (const meter of this.configurationInfoState!.electricityMeters) {
          if (meter.use !== null) {
            use = use + meter.use + ' --//-- '
          }
        }
        this.$appInsights.trackEvent('WR_TC_StepSummary_Validated', {
          Segment: 'WR_TC',
          WebRef: this.requestId,
          Use: use
        })

        return true
      }
    }
    return false
  }
  //#endregion
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import { FileInfo } from '@ores/vue-library'
import ReadonlyMobileBox from '@/components/display/ReadonlyMobileBox.vue'

@Component({
  components: {
    ReadonlyMobileBox
  },
  name: 'resume-files-cmh'
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) public mobileLayoutApplied!: boolean
  @Prop({ type: Array, required: true }) public fileInfo!: FileInfo[]

  public getFileInfoDescription(fileInfo: FileInfo) {
    return `${this.getExtension(fileInfo)} - ${this.getSize(fileInfo)}`
  }

  public getExtension(fileInfo: FileInfo): string {
    return fileInfo.name.slice(fileInfo.name.length - 3).toUpperCase()
  }

  public getSize(fileInfo: FileInfo): string | null {
    let size = fileInfo.size
    let count = 0
    while (size > 1024) {
      count += 1
      size = size / 1024
    }

    switch (count) {
      case 0:
        return size.toFixed(2) + ' b'
        break

      case 1:
        return size.toFixed(2) + ' kb'
        break

      case 2:
        return size.toFixed(2) + ' Mb'
        break

      default:
        return null
    }
  }
}

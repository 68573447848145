
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import { idGenerator } from '@/services'
import { MoveRequestInfo } from '@/models'

@Component({
  name: 'synergrid-move-request-form',
  components: {}
})
export default class SynergridNewRequestForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true })
  public readonly value!: MoveRequestInfo
  //#endregion

  //#region [Data]
  public fieldId: string = idGenerator.getId()
  //#endregion
}


import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import FieldBox from '../../Shared/FieldBox'

@Component({
  name: 'has-ean-selector'
})
export default class HasEanSelector extends FieldBox {
  //#region [Property]
  @Prop({ type: String, default: '' }) public readonly value!: string | null
  //#endregion

  //#region [Computed]
  public get hasEanSelection(): string | null {
    return this.value
  }
  public set hasEanSelection(value: string | null) {
    this.change(value)
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(value: string | null): string | null {
    return value
  }
  //#endregion
}

import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { EMobilityConnectionState } from './types';

export const actions: ActionTree<EMobilityConnectionState, RootState> = {
  reset({ commit }) {
    commit('batteryRechargingCapacity/CLEAR')
    commit('carSpecification/CLEAR')
    commit('consumingDevices/CLEAR')
    commit('electricityInstallation/CLEAR')
    commit('identification/CLEAR')
    commit('powerIncreasePackages/CLEAR')
    commit('RESET')
  }
}

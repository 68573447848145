
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'gas-price-calculator',
})
export default class GasPriceCalculator extends Vue {
  //#region [Property]
  @Prop({ type: Boolean, required: false, default: false }) public readonly item!: boolean
  //#endregion
}

import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { DialogsState } from './types';

export const actions: ActionTree<DialogsState, RootState> = {
  close({ commit }): void {
    commit('ACTIVE', false)
  },
  alert({ commit }, content: { text: string, title: string }): void {
    commit('CALLBACK', null)
    commit('TEXT', content.text)
    commit('TITLE', content.title)
    commit('ACTIVE', true)
  },
  confirm({ commit }, content: { text: string, title: string }): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      commit('CALLBACK', (isConfirmed: boolean) => resolve(isConfirmed))
      commit('TEXT', content.text)
      commit('TITLE', content.title)
      commit('ACTIVE', true)
    })
  }
}


import { Form } from '@/views/forms/Form'
import CmhLandingPageStep from '@/components/steps/new-connection-cmh/CmhLandingPageStep.vue'
import Component from 'vue-class-component'
import { googleService } from '@/services'
import { dateService, FileInfo, StepDefinition } from '@ores/vue-library'
import DialogBox from '@/components/shared/DialogBox.vue'
import ApiErrors from '@/components/display/ApiErrors.vue'
import CmhEmailRegistrationStep from '@/components/steps/new-connection-cmh/CmhEmailRegistrationStep.vue'
import StepActions from '@/components/shared/StepActions.vue'
import Step from '@/components/steps/Step'
import CmhAddressStep from '@/components/steps/new-connection-cmh/CmhAddressStep.vue'
import CmhOfferingsSelectionStep from '@/components/steps/new-connection-cmh/CmhOfferingsSelectionStep.vue'
import CmhEnergyConfigurationStep from '@/components/steps/new-connection-cmh/CmhEnergyConfigurationStep.vue'
import CmhEnergyProductionConfigurationStep from '@/components/steps/new-connection-cmh/CmhEnergyProductionConfigurationStep.vue'
import CmhContactStep from '@/components/steps/new-connection-cmh/CmhContactStep.vue'
import CmhVatInfoStep from '@/components/steps/new-connection-cmh/CmhVatInfoStep.vue'
import CmhFilesStep from '@/components/steps/new-connection-cmh/CmhFilesStep.vue'
import CmhSummaryStep from '@/components/steps/new-connection-cmh/CmhSummaryStep.vue'
import CmhConfirmationStep from '@/components/steps/new-connection-cmh/CmhConfirmationStep.vue'
import { State, Action, Mutation, Getter } from 'vuex-class'
import { Prop, Watch } from 'vue-property-decorator'
import { CmhAddressPoint } from '@/models/cmh-address-point'
import {
  PackageDTO,
  ContactInfo,
  GasMeter,
  GasMeterConfiguration,
  MeterDestination,
  MeterElectricityType,
  NewConnectionRequest,
  PackagedElectricityMeter,
  PackagedElectricityMeterConfiguration,
  StreetSubCity,
  SubCity,
  VatInfo,
  ModifiedConnection,
  ModifiedConnectionConfiguration,
} from '@/models'
import { pixelFacebookService } from '@ores/vue-library'
import { removeInterceptors } from '@/plugins/http-interceptors-global'
import { addWorksResponseInterceptors } from '@/plugins/http-response-interceptors-works'
import store from '@/store/index'
import { CmhConfigurationStoreData } from '@/store/new-connection-cmh/cmh-energy-configuration/mutations'
import { CmhEnergyProductionConfigurationState } from '@/store/new-connection-cmh/cmh-energy-production-configuration/types'
import { CmhFilesState } from '@/store/new-connection-cmh/cmh-files/types'
import { helper } from '@/services'
import { YesNoMaybe, YesNoMaybeConverter } from '@/models/YesNoMaybe'

@Component({
  components: {
    CmhLandingPageStep,
    CmhEmailRegistrationStep,
    CmhAddressStep,
    CmhOfferingsSelectionStep,
    CmhEnergyConfigurationStep,
    CmhEnergyProductionConfigurationStep,
    CmhContactStep,
    CmhVatInfoStep,
    CmhFilesStep,
    CmhSummaryStep,
    CmhConfirmationStep,
    DialogBox,
    ApiErrors,
    StepActions
  },
  name: 'new-connection-form'
})
export default class NewConnectionForm extends Form {
  //#region [Property]
  @Prop({ type: String, default: null })
  public readonly requestId!: string
  //#endregion

  //#region [Data]
  @State('requestId', { namespace: 'newConnectionCmh' })
  public requestIdentifier!: string
  @State('addressPoint', { namespace: 'newConnectionCmh/cmhAddress' })
  public stateAddressPoint!: CmhAddressPoint
  @State('selections', { namespace: 'newConnectionCmh/offeringsSelection' })
  public stateOfferingSelections!: string[]
  @State('contactInfo', { namespace: 'newConnectionCmh/cmhContact' })
  public stateContactInfo!: ContactInfo
  @State('showInputFile', { namespace: 'newConnectionCmh/cmhFiles' })
  public stateShowInputFile!: boolean | null
  @State('fileInfo', { namespace: 'newConnectionCmh/cmhFiles' })
  public stateFileInfo!: FileInfo[]

  public stepDefinitions: StepDefinition[] = [
    new StepDefinition('address', 1),
    new StepDefinition('request', 2, [new StepDefinition('', 3), new StepDefinition('', 4)]),
    new StepDefinition('contact', 5),
    new StepDefinition('vat', 6, [new StepDefinition('', 7)]),
    new StepDefinition('summary', 8),
    new StepDefinition('thankYou', 9)
  ]

  public pixelId: string = ''
  public customerReminderLaoding = false

  public filesDirectory: string = 'new-connection';
  //#endregion

  @Getter('isElectricitySelected', { namespace: 'newConnectionCmh/offeringsSelection' })
  public isElectricitySelected!: boolean

  //region [Mutation]
  @Mutation('STORE', { namespace: 'newConnectionCmh' })
  public storeRequestId!: (requestId: string) => void

  @Mutation('STORE', { namespace: 'newConnectionCmh/mailRegistration' })
  public storeMailRegistration!: (email: string) => void

  @Mutation('STORE', { namespace: 'newConnectionCmh/landingPage' })
  public storeSubCity!: (subCIty: SubCity) => void

  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhAddress' })
  public storeAddress!: (address: CmhAddressPoint) => void

  @Mutation('STORE', { namespace: 'newConnectionCmh/offeringsSelection' })
  public storeOfferingSelection!: (offeringSelection: string[]) => void

  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public storeEnergyConfiguration!: (index: CmhConfigurationStoreData) => void

  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhEnergyProductionConfiguration' })
  public storeEnergyProduction!: (index: CmhEnergyProductionConfigurationState) => void

  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhContact' })
  public storeContact!: (index: ContactInfo) => void

  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhVatInfo' })
  public storeVat!: (index: VatInfo) => void

  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhFiles' })
  public storeFiles!: (index: CmhFilesState) => void

  @Mutation('CLEAR', { namespace: 'newConnectionCmh/cmhFiles' })
  public clearFilesFromStore!: () => void

  @Mutation('STORE_PACKAGE', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public storePackage!: (packages: PackageDTO[]) => void

  @Mutation('STORE_PACKAGE_FROM_SIMULATOR', { namespace: 'newConnectionCmh/landingPage' })
  public storePackageFromSimulator!: (desiredPackage: string) => void

  @Mutation('STORE_DESIRED_POWER_FROM_SIMULATOR', { namespace: 'newConnectionCmh/landingPage' })
  public storeDesiredPowerFromSimulator!: (desiredPower: number) => void

  //#endregion

  //#region [Action]
  @Action('validate', { namespace: 'newConnectionCmh/offeringsSelection' })
  public validateOfferings!: (address: CmhAddressPoint) => Promise<boolean>
  @Action('validate', { namespace: 'newConnectionCmh/cmhAddress' })
  public validateSelection!: (address: CmhAddressPoint) => Promise<boolean>
  @Action('validate', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public validateEnergyConfiguration!: (offeringSelections: string[]) => Promise<boolean>
  @Action('validate', { namespace: 'newConnectionCmh/cmhVatInfo' })
  public validateVatInfo!: (contactInfo: ContactInfo) => Promise<boolean>
  //#endregion

  //#region [Watch]
  @Watch('stateAddressPoint', { deep: true })
  public async onStateAddressChange(address: CmhAddressPoint | null) {
    await this.fetchCustomerFormsPackages()
    if (address === null) {
      return
    }
    const isValidateOfferings = await this.validateOfferings(address);
    const isValidateSelection = await this.validateSelection(address);
    if (!isValidateOfferings) {
      this.ensureLastStep(2, this.customerReminderLaoding ? true : isValidateOfferings)
    } else if (!isValidateSelection) {
      this.ensureLastStep(3, this.customerReminderLaoding ? true : isValidateSelection)
    }

    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100)
  }

  @Watch('stateOfferingSelections', { deep: true })
  public async onStateOfferingSelectionChange(offeringSelections: string[]) {
    if (offeringSelections === null) {
      return
    }
    this.ensureLastStep(
      3,
      this.customerReminderLaoding ? true : await this.validateEnergyConfiguration(offeringSelections)
    )
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100)
  }

  @Watch('stateContactInfo', { deep: true })
  public async onStateContactInfoChange(contactInfo: ContactInfo) {
    if (contactInfo === null) {
      return
    }
    this.ensureLastStep(6, this.customerReminderLaoding ? true : await this.validateVatInfo(contactInfo))
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100)
  }

  @Watch('stateFileInfo', { deep: true })
  public async onStateFileInfoChange() {
    await this.handleFilesDeletion()
  }
  //#endregion

  //#region [Computed]
  public get hasSave(): boolean {
    return this.currentStep !== this.lastStep
  }
  public get isVisible(): boolean {
    return this.currentStep !== 4 || this.isElectricitySelected
  }
  //#endregion

  //#region [Method]
  public async mounted() {
    if (this.$route.query['package']) {
      this.storePackageFromSimulator(this.$route.query['package'].toString())
    }
    if (this.$route.query['desiredPower']) {
      const desiredPower = parseFloat(this.$route.query['desiredPower'].toString())
      this.storeDesiredPowerFromSimulator(desiredPower)
    }
    if (this.$route.query['isProsumer']) {
      const isProsumer = this.$route.query['isProsumer'] as keyof typeof YesNoMaybe;
      const isProsumerStatus: YesNoMaybe = YesNoMaybe[isProsumer];
      const energyProductionData: CmhEnergyProductionConfigurationState = {
        yesNoMaybeSelection: YesNoMaybeConverter(isProsumerStatus),
        kvaPower: null,
        kwcPower: null
      }
      this.storeEnergyProduction(energyProductionData)
    }
    if (this.interceptorId !== null) {
      removeInterceptors(this.$api, this.interceptorId)
    }
    const interceptorId = addWorksResponseInterceptors(this.$api, store)
    this.setInterceptorId(interceptorId)

    // Check maintenance
    await this.$api.get('api/maintenanceManagement/new-connection-request')

    let currentStep = 0
    try {
      if (this.$route.query.requestId) {
        const response = await this.$api.get<NewConnectionRequest>(
          `newconnectionrequest/${this.$route.query.requestId}`
        )
        const ncr = response.data

        if (ncr) {
          this.customerReminderLaoding = true
          this.storeRequestId(ncr.requestId)
          this.storeMailRegistration(ncr.email)
          currentStep = 1

          if (ncr.address && ncr.address.subCityId) {
            this.storeSubCity((await this.$addressRepository.get<SubCity>(`subcities/${ncr.address.subCityId}`)).data)
            this.storeAddress({
              addressPoint: {
                subCity: (await this.$addressRepository.get<SubCity>(`subcities/${ncr.address.subCityId}`)).data,
                streetSubCity: ncr.address.streetSubCityId
                  ? (await this.$addressRepository.get<StreetSubCity>(`streets/${ncr.address.streetSubCityId}`)).data
                  : null,
                houseNumber: ncr.address.houseNumber,
                isMapLocation: ncr.address.isMapLocation,
                isNonFinalHouseNumber: ncr.address.isNonFinalHouseNumber,
                mapLocation: ncr.address.isMapLocation
                  ? { lat: ncr.address.latitude!, lng: ncr.address.longitude! }
                  : null
              },
              selection: ncr.address.buildingType,
              flatNumber: ncr.address.flatNumber,
              commonElectricMeterNumber: ncr.address.commonElectricMeterNumber
            })

            if (ncr.offeringSelection) {
              const offeringSelection: string[] = []
              if (ncr.offeringSelection.electricity) {
                offeringSelection.push('electricity')
              }
              if (ncr.offeringSelection.gas) {
                offeringSelection.push('gas')
              }
              if (ncr.offeringSelection.swde) {
                offeringSelection.push('swde')
              }
              if (ncr.offeringSelection.proximus || ncr.offeringSelection.voo) {
                offeringSelection.push('tvPhoneInternet')
                if (ncr.offeringSelection.proximus && ncr.offeringSelection.voo) {
                  offeringSelection.push('proximusAndVoo')
                } else {
                  if (ncr.offeringSelection.voo) {
                    offeringSelection.push('voo')
                  } else if (ncr.offeringSelection.proximus) {
                    offeringSelection.push('proximus')
                  }
                }
              }

              this.storeOfferingSelection(offeringSelection)
              currentStep = 2

              if (ncr.gasMeterConfigurations || ncr.packagedElectricityMeterConfigurations || ncr.modifiedConnections) {

                let packagedElectricityMeters: PackagedElectricityMeter[] = []
                if (ncr.packagedElectricityMeterConfigurations) {
                  packagedElectricityMeters = this.mapPackagedElectricityMeters(ncr.packagedElectricityMeterConfigurations)
                }

                let modifiedConnections: ModifiedConnection[] = []
                if (ncr.modifiedConnections) {
                  modifiedConnections = this.mapModifiedConnections(ncr.modifiedConnections)
                }

                let gasMeters: GasMeter[] = []
                if (ncr.gasMeterConfigurations) {
                  gasMeters = this.mapGasMeters(ncr.gasMeterConfigurations)
                }

                this.storeEnergyConfiguration({ gasMeters, packagedElectricityMeters, modifiedConnections, isDefaultConfigurationOverriden: true })
                currentStep = 3

                if (ncr.energyProduction) {
                  this.storeEnergyProduction({
                    yesNoMaybeSelection: ncr.energyProduction.hasEnergyProduction,
                    kvaPower: ncr.energyProduction.kvaPower,
                    kwcPower: ncr.energyProduction.kwcPower
                  })
                  currentStep = 4

                  if (ncr.contact) {
                    this.mapContact(ncr.contact)
                    this.storeContact(ncr.contact)
                    currentStep = 5

                    if (ncr.vatInfoSelection) {
                      this.storeVat({
                        individualVatInfoSelection: ncr.vatInfoSelection.individualVatInfoSelection,
                        freelanceVatInfoSelection: ncr.vatInfoSelection.freelanceVatInfoSelection,
                        companyVatInfoSelection: {
                          primaryInfo: ncr.vatInfoSelection.companyVatInfoFirstStepSelection,
                          secondaryInfo: ncr.vatInfoSelection.companyVatInfoSecondStepSelection
                        }
                      })
                      currentStep = 6

                      if (ncr.files) {
                        this.storeFiles({
                          fileInfo: ncr.files.map((f) => {
                            return {
                              name: f,
                              size: 0,
                              key: f,
                              error: null
                            }
                          }),
                          showInputFile: true
                        })
                      }
                      currentStep = 8
                    }
                  }
                }
              }
            }
          }
        }
      }
    } catch (error) {
      // no action
    } finally {
      this.$nextTick(() => (this.customerReminderLaoding = false))
    }

    this.currentStep = currentStep
    this.lastStepSeen = currentStep
    this.stepToGoAfterValidation = 8
    this.stepsToValidate = [1, 2, 5]

    this.onLangChanged(this.lang)
    googleService.includeMapScript(this.$el)
    this.pixelId = pixelFacebookService.oresPixelId
  }

  public async fetchCustomerFormsPackages() {
    try {
      const response = await this.$customerFormsAxios.get('new-connection/packages', {
        cache: {
          ignoreCache: false
        }
      });

      if (response.status >= 200 && response.status < 300) {
        this.storePackage(response.data.packages);
      } else if (response.status === 404) {
        // TODO handle api error
        console.error(response);
      }
    } catch (error) {
      // TODO handle api error
      console.error(error);
    }
  }

  public resetForm() {
    this.currentStep = 0
    this.lastStepSeen = 0
    this.$store.dispatch('newConnectionCmh/reset')
    this.$nextTick(() => (this.currentStep = 1))
  }

  public getStepComponent(step: number): Step | null {
    switch (this.currentStep) {
      case 0:
        return this.$refs.step0 as Step
      case 1:
        return this.$refs.step1 as Step
      case 2:
        return this.$refs.step2 as Step
      case 3:
        return this.$refs.step3 as Step
      case 4:
        return this.$refs.step4 as Step
      case 5:
        return this.$refs.step5 as Step
      case 6:
        return this.$refs.step6 as Step
      case 7:
        return this.$refs.step7 as Step
      case 8:
        return this.$refs.step8 as Step
      case 9:
        return this.$refs.step9 as Step
      case 10:
        return this.$refs.step10 as Step
      default:
        return null
    }
  }

  public async manualSave() {
    if (this.currentStep < 8) {
      await this.saveStep()
    }
    await this.$api.get(`NewConnectionRequest/manual-save/${this.requestIdentifier}`)
  }

  public mapPackagedElectricityMeters(packagedElectricityMeters: PackagedElectricityMeterConfiguration[]): PackagedElectricityMeter[] {
    return packagedElectricityMeters.map((em) => {

      let destination: MeterDestination = 'residential'
      switch (em.destination) {
        case 0:
          destination = 'residential'
          break
        case 1:
          destination = 'professional'
          break
        case 2:
          destination = 'common'
          break
        case 3:
          destination = 'other'
          break
      }

      return {
        count: em.count,
        remarks: em.remarks,
        package: { key: em.packageKey, maximumPower: { voltAmpere: em.ampere, kiloVoltAmpere: em.kva } },
        connectionType: em.connectionType,
        customPower: em.customPower,
        wantedPower: em.wantedPower,
        destination
      }
    })
  }

  public mapModifiedConnections(modifiedConnections: ModifiedConnectionConfiguration[]): ModifiedConnection[] {
    return modifiedConnections.map((em) => {

      let type: MeterElectricityType = 'simple'
      switch (em.type) {
        case 0:
          type = 'simple'
          break
        case 1:
          type = 'dual'
          break
      }

      return {
        count: em.count,
        remarks: em.remarks,
        price: em.price,
        type,
      }
    })
  }

  public mapGasMeters(gasMeters: GasMeterConfiguration[]): GasMeter[] {
    return gasMeters.map((gm) => {
      let destination: MeterDestination = 'residential'
      switch (gm.destination) {
        case 0:
          destination = 'residential'
          break
        case 1:
          destination = 'professional'
          break
        case 2:
          destination = 'common'
          break
        case 3:
          destination = 'other'
          break
      }

      return {
        count: gm.count,
        technicalCharacteristic: gm.power === 60 ? 'standard' : 'custom',
        destination,
        remarks: gm.remarks,
        power: gm.power
      }
    })
  }

  public mapContact(contact: ContactInfo) {
    if (contact.contactIndividual) {
      contact.contactIndividual.civility = contact.contactIndividual.civility?.toString() === 'mr' ? 1 : 2
      if (contact.contactIndividual.birthDate) {
        contact.contactIndividual.birthDate = dateService.parseDate(contact.contactIndividual.birthDate.toString())
      }
    }

    if (contact.contactFreelance) {
      contact.contactFreelance.civility = contact.contactFreelance.civility?.toString() === 'mr' ? 1 : 2
      if (contact.contactFreelance.birthDate) {
        contact.contactFreelance.birthDate = dateService.parseDate(contact.contactFreelance.birthDate.toString())
      }
    }

    if (contact.contactCompany) {
      contact.contactCompany.civility = contact.contactCompany.civility?.toString() === 'mr' ? 1 : 2
      if (contact.contactCompany.birthDate) {
        contact.contactCompany.birthDate = dateService.parseDate(contact.contactCompany.birthDate.toString())
      }
    }
  }

  public async handleFilesDeletion() {
    let fileInfo: FileInfo[] = []
    if (!this.stateShowInputFile && this.stateFileInfo.length) {
      if (this.stateFileInfo) {
        fileInfo = helper.clone(this.stateFileInfo)
        const filesData: string[] = []
        fileInfo.forEach((file: FileInfo) => {
          filesData.push(file.key!)
        })
        const filesBody = {
          data: filesData
        };
        await this.deleteFilesFromNewConnection(filesBody)
        this.clearFilesFromStore()
      }
    }
  }

  public async deleteFilesFromNewConnection(files: { data: string[] }): Promise<void> {
    return await this.$api.delete(`/api/Attachments/work-request-attachment?requestId=${this.requestIdentifier}&directory=${this.filesDirectory}`, files)
  }
  //#endregion
}

import { YesNoMaybeSelection } from '@ores/vue-library';
export interface CmhEnergyProductionConfigurationState {
  yesNoMaybeSelection: YesNoMaybeSelection | null
  kvaPower: number | null
  kwcPower: number | null
}

export const InitialState: CmhEnergyProductionConfigurationState = {
  yesNoMaybeSelection: null,
  kvaPower: null,
  kwcPower: null
}

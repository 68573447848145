
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import { AccountingInformation } from '@/models/prosumer-declaration/prosumer-declaration'

@Component({
  name: 'accounting-information-component'
})
export default class AccountingInformationComponent extends Vue {
  @Prop({ type: Object, required: true }) public readonly value!: AccountingInformation
}

import { ElectricityInstallation } from '@/models';
import { MutationTree } from 'vuex';
import { ElectricityInstallationState } from './types';

export const mutations: MutationTree<ElectricityInstallationState> = {
  STORE(state, data: ElectricityInstallation) {
    state.electricityInstallation = data
  },
  CLEAR(state) {
    state.electricityInstallation = {
      meterType: null,
      electricityPowerSupplyType: null,
      power: null
    }
  }
}


import { Component, Watch } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import WorksInputFile from '@/components/inputs/Files/WorksInputFile.vue'
import { State, Mutation } from 'vuex-class'
import { FileInfo } from '@ores/vue-library'
import MoreInformation from '@/components/display/MoreInformation.vue'
import { helper } from '@/services'
import { FilesState } from '@/store/prosumer-declaration/files/types'
import { nextTick } from 'vue'
import { extend } from 'vee-validate'

@Component({
  components: {
    MoreInformation,
    WorksInputFile
  },
  name: 'files-step'
})
export default class FilesStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'prosumerDeclaration' })
  public requestId!: string

  @State('rapportFileInfo', { namespace: 'prosumerDeclaration/files' })
  public rapportFileInfoState!: FileInfo[]

  @State('schemaFileInfo', { namespace: 'prosumerDeclaration/files' })
  public schemaFileInfoState!: FileInfo[]

  @State('pictureFileInfo', { namespace: 'prosumerDeclaration/files' })
  public pictureFileInfoState!: FileInfo[]

  public rapportFileInfo: FileInfo[] = []
  public schemaFileInfo: FileInfo[] = []
  public pictureFileInfo: FileInfo[] = []

  public isSchemaExampleModalOpen: boolean = false
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'prosumerDeclaration/files' })
  public store!: (index: FilesState) => void
  //#endregion

  //#region [Computed]
  public get required() {
    return this.rapportFileInfo.length === 0 &&
      this.schemaFileInfo.length === 0 &&
      this.pictureFileInfo.length === 0
  }

  public get fileInError(): boolean {
    return (
      this.rapportFileInfo.some((v) => v.error !== null) ||
      this.schemaFileInfo.some((v) => v.error !== null) ||
      this.pictureFileInfo.some((v) => v.error !== null)
    )
  }

  public get filesCount(): number {
    return this.rapportFileInfo.length + this.schemaFileInfo.length + this.pictureFileInfo.length
  }

  //#endregion

  //#region [Method]
  public mounted(): void {
    extend('max_all', {
      validate: (value) => +value <= 10,
      message: 'Maximum 10 fichiers'
    })

    if (this.rapportFileInfoState) {
      this.rapportFileInfo = helper.clone(this.rapportFileInfoState)
    }
    if (this.schemaFileInfoState) {
      this.schemaFileInfo = helper.clone(this.schemaFileInfoState)
    }
    if (this.pictureFileInfoState) {
      this.pictureFileInfo = helper.clone(this.pictureFileInfoState)
    }

    if (this.rapportFileInfo.length > 0 || this.schemaFileInfoState.length > 0 || this.pictureFileInfoState.length > 0) {
      nextTick(() => this.$validatorObs.validate())
    }
  }

  public openSchemaExampleModal() {
    this.isSchemaExampleModalOpen = true
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {

    this.store({
      rapportFileInfo: this.rapportFileInfo.filter((a) => a.error === null),
      schemaFileInfo: this.schemaFileInfo.filter((a) => a.error === null),
      pictureFileInfo: this.pictureFileInfo.filter((a) => a.error === null)
    })
    this.$appInsights.trackEvent('ProsumerDeclaration_FilesStep_Validated', {
      Segment: 'ProsumerDeclaration',
      WebRef: this.requestId
    })
  }

  //#endregion

  @Watch('schemaFileInfo', { deep: true })
  @Watch('rapportFileInfo', { deep: true })
  @Watch('pictureFileInfo', { deep: true })
  public onFileInfoChanged(newVal: FileInfo[]) {
    if (newVal.length > 0) {
      this.$validatorObs.validate()
    }
  }
}


import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { helper, meterService, dateService } from '@/services'
import {
  ConstructionConfigurationInfo,
  ConstructionElectricityPriceRequest,
  ConstructionElectricityPriceResponse
} from '@/models'

@Component({
  name: 'construction-price-calculator',
})
export default class ConstructionPriceCalculator extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public configurationInfo!: ConstructionConfigurationInfo
  @Prop({ type: Boolean, default: false }) public onlyComputable!: boolean
  //#endregion

  //#region [Data]
  public prices: ConstructionElectricityPriceResponse | null = null
  //#endregion

  //#region [Computed]
  public get isCustom(): boolean {
    return this.configurationInfo.electricityMeter.tension === 'custom'
  }

  public get hasPrices(): boolean {
    return this.prices !== null && this.prices.connectionPlacementAndRemovalTaxFreePrice !== null
  }

  public get selectedPeriod(): number | null {
    if (this.configurationInfo.startDate !== null && this.configurationInfo.endDate !== null) {
      return dateService.diffDate(this.configurationInfo.startDate, this.configurationInfo.endDate) + 1
    }

    return null
  }

  public get priceRequest(): ConstructionElectricityPriceRequest | null {
    const value = this.configurationInfo
    if (value.startDate === null || value.endDate === null || value.startDate > value.endDate) {
      return null
    }

    const meter = value.electricityMeter
    if (!meterService.validateConstructionElectricityMeter(meter) || meter.tension === 'custom') {
      return null
    }

    return {
      placementDate: value.startDate,
      removalDate: value.endDate,
      tension: meter.tension,
      ampere: meter.power!.ampere,
      customKvaValue: null,
      powerOutletCount: 1
    }
  }
  //#endregion

  //#region [Watch]
  @Watch('priceRequest', { deep: true })
  public onPriceRequestChanged(value: ConstructionElectricityPriceRequest | null) {
    this.prices = null
    if (value == null) {
      return
    }

    this.$api
      .post<ConstructionElectricityPriceResponse>(
        'prices/work-requests/new-connection/new-construction/construction-site-connection/electricity',
        value
      )
      .then((response) => (this.prices = response.data))
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.onPriceRequestChanged(this.priceRequest)
  }
  //#endregion
}

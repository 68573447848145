import { SubCity } from '@/models'
import { GridAccessPoint } from '@/models/prosumer-declaration'

export interface IdentificationState {
  subCity: SubCity | null,
  eanCode: string | null,
  meterLastFourNumbers: string | null,
  gridAccessPoint: GridAccessPoint | null
}

export const InitialState: IdentificationState = {
  subCity: null,
  eanCode: null,
  meterLastFourNumbers:null,
  gridAccessPoint: null
}

import { AxiosError, AxiosInstance } from 'axios'
import { Store } from 'vuex'
import { RootState } from '@/store/types'

export function addProsumerDeclarationResponseInterceptors(
  axios: AxiosInstance,
  store: Store<RootState>
): number {
  return axios.interceptors.response.use((response) => response, (error: AxiosError) => {
      const response = error.response !== undefined ? error.response : error
      if (!response.config.url?.startsWith('api/assets/check-ean')) {
        store.dispatch('apiErrors/manageProsumerDeclaration', response)
        return Promise.reject(error)
      }
      return response
    }
  )
}

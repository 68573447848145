import { SubCity } from '@/models/sub-city';

export interface LandingPageState {
  subCity: SubCity | null
  packageFromSimulator: string | null
  desiredPowerFromSimulator: number | null
}

export const InitialState: LandingPageState = {
  subCity: null,
  packageFromSimulator: null,
  desiredPowerFromSimulator: null
}

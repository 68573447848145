
import { Component, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'

import Step from '@/components/steps/Step'
import PriceAnnexe from '@/components/display/PriceAnnexe.vue'
import SmartMeterPriceCalculator from '@/components/display/SmartMeterPriceCalculator.vue'
import GasPriceCalculator from '@/components/display/GasPriceCalculator.vue'
import ResumeContactInfo from '@/components/display/Resume/ResumeContactInfo.vue'
import ResumeVatInfo from '@/components/display/Resume/ResumeVatInfo.vue'
import ResumeAddress from '@/components/display/Resume/ResumeAddress.vue'
import {
  EnergyProductionInfoModel,
  ContactInfo,
  IndividualVatInfoSelection,
  FreelanceVatInfoSelection,
  CompanyVatInfoSelection,
  CheckEanSmartMeterResult
} from '@/models'
import { dateService, calendarService, timeService } from '@ores/vue-library'

@Component({
  name: 'summary-step',
  components: {
    PriceAnnexe,
    SmartMeterPriceCalculator,
    GasPriceCalculator,
    ResumeContactInfo,
    ResumeVatInfo,
    ResumeAddress,
  }
})
export default class SummaryStep extends Step {
  //#region [Property]
  @Prop({ type: Boolean, default: false }) public readonly confirmation!: boolean
  @Prop({ type: Boolean, default: false }) public readonly readOnly!: boolean
  //#endregion

  //#region [Data]
  public dateService = dateService
  public calendarService = calendarService
  public timeService = timeService

  @State('noAppointment', { namespace: 'smartMeterConnection' })
  public noAppointment!: boolean

  @State('eanCode', { namespace: 'smartMeterConnection/identification' })
  public eanCodeState!: string

  @State('checkEanResult', { namespace: 'smartMeterConnection/identification' })
  public checkEanResultState!: CheckEanSmartMeterResult

  @State('energyProductionInfo', { namespace: 'smartMeterConnection/energyProduction' })
  public energyProductionInfoState!: EnergyProductionInfoModel

  @State('contactInfo', { namespace: 'smartMeterConnection/contactInfo' })
  public contactInfoState!: ContactInfo

  @State('individualVatInfoSelection', { namespace: 'smartMeterConnection/vatInfo' })
  public individualVatInfoSelectionState!: IndividualVatInfoSelection | null
  @State('freelanceVatInfoSelection', { namespace: 'smartMeterConnection/vatInfo' })
  public freelanceVatInfoSelectionState!: FreelanceVatInfoSelection | null
  @State('companyVatInfoSelection', { namespace: 'smartMeterConnection/vatInfo' })
  public companyVatInfoSelectionState!: CompanyVatInfoSelection | null

  @State('fileNumber', { namespace: 'smartMeterConnection/stakeholder' })
  public fileNumber!: string | null

  @State('isProsumer', { namespace: 'smartMeterConnection/stakeholder' })
  public isProsumer!: boolean

  @State('requestId', { namespace: 'smartMeterConnection' })
  public requestId!: string
  //#endregion


  //#region [Methods]
  public async save(next: boolean, valid: boolean) {
    this.$appInsights.trackEvent('WR_SMC_StepSummary_Validated', {
      Segment: 'WR_SMC',
      WebRef: this.requestId
    })
  }

  public goToStep(step: number) {
    this.$emit('goToStep', step)
  }

  //#endregion
}

import { MutationTree } from 'vuex';
import { DialogsState } from './types';

export const mutations: MutationTree<DialogsState> = {
  ACTIVE(state: DialogsState, isActive: boolean) {
    state.active = isActive
  },
  TEXT(state: DialogsState, text: string) {
    state.text = text
  },
  TITLE(state: DialogsState, title: string) {
    state.title = title
  },
  CALLBACK(state: DialogsState, callback: ((isConfirmed: boolean) => void) | null) {
    state.callback = callback
  }
}

import { AxiosInstance, AxiosError } from 'axios';
import { Store } from 'vuex';
import { RootState } from '@/store/types';

export function addWorksResponseInterceptors(axios: AxiosInstance, store: Store<RootState>): number {
  const worksInterceptor = axios.interceptors.response.use((response) => response, (error: AxiosError) => {
    const response = error.response !== undefined ? error.response : error
    if (!response.config.url?.startsWith('prosumer-bonus/identification')) {
      store.dispatch('apiErrors/manageWorks', response)
      return Promise.reject(error)
    } else {
      return response
    }
  })

  return worksInterceptor
}

export interface MeterReadingState {
  meterReadingDate: Date | null
  confirmation: boolean
}

export function GetInitialState(): MeterReadingState {
  return {
    meterReadingDate: null,
    confirmation: false
  }
}

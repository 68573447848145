
import { Form } from '@/views/forms/Form'
import { Component } from 'vue-property-decorator'
import StepActions from '@/components/shared/StepActions.vue'
import WorkFileIdentificationStep from '@/components/steps/work-file-tracking/WorkFileIdentificationStep.vue'
import WorkFileTrackingStep from '@/components/steps/work-file-tracking/WorkFileTrackingStep.vue'
import DialogBox from '@/components/shared/DialogBox.vue'
import store from '@/store/index'

import Step from '@/components/steps/Step'
import { StepDefinition } from '@ores/vue-library'

import ApiErrors from '@/components/display/ApiErrors.vue'
import { addSmartMeterResponseInterceptors } from '@/plugins/http-response-interceptors-smart-meter'
import { removeInterceptors } from '@/plugins/http-interceptors-global'

@Component({
  components: {
    WorkFileIdentificationStep,
    WorkFileTrackingStep,
    StepActions,
    DialogBox,
    ApiErrors
  },
  name: 'work-file-tracking'
})
export default class WorkFileTracking extends Form {
  //#region [Data]
  public stepDefinitions: StepDefinition[] = [
    new StepDefinition('connection', 1),
    new StepDefinition('tracking', 2)
  ] as StepDefinition[]
  //#endregion

  //#region [Method]
  public mounted() {
    this.onLangChanged(this.lang)
    this.currentStep = 1

    if (this.interceptorId !== null) {
      removeInterceptors(this.$api, this.interceptorId)
    }
    const interceptorId = addSmartMeterResponseInterceptors(this.$api, store)
    this.setInterceptorId(interceptorId)

    // Check maintenance
    this.$api.get('api/maintenanceManagement/file-tracking')
  }

  public resetForm() {
    this.currentStep = 1
    this.lastStepSeen = 1
    this.$store.dispatch('workFileTracking/reset')
    this.$nextTick(() => (this.currentStep = 1))
  }

  public getStepComponent(step: number): Step | null {
    return (this.$refs[`step${this.currentStep}`] as Step) || null
  }
  //#endregion
}


import { Component } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import GridAccessPointBox from '@/components/inputs/ProsumerDeclaration/meter-reading/GridAccessPointBox.vue'
import { helper } from '@ores/vue-library'
import { GridAccessPoint } from '@/models/prosumer-declaration'
import Vue from 'vue'
import Step from '@/components/steps/Step'

@Component({
  components: {
    GridAccessPointBox,
  },
  name: 'meter-reading-step',
})
export default class MeterReadingStep extends Step {
  //#region [Data]
  @State('gridAccessPoint', { namespace: 'prosumerDeclaration/identification' })
  public gridAccessPoint!: GridAccessPoint | null
  @State('meterReadingDate', { namespace: 'prosumerDeclaration/meterReading' })
  public readonly meterReadingDateState!: Date | null
  @State('confirmation', { namespace: 'prosumerDeclaration/meterReading' })
  public readonly confirmationState!: boolean
  @State('requestId', { namespace: 'prosumerDeclaration' }) public requestId!: string

  public meterReadingDate: Date | null = null
  public confirmation: boolean = false
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'prosumerDeclaration/meterReading' })
  protected readonly store!: (data: { date: Date | null, confirmation: boolean }) => void
  @Mutation('STORE_GRID_ACCESS_POINT', { namespace: 'prosumerDeclaration/identification' })
  protected readonly storeGridAccessPoint!: (data: GridAccessPoint) => void
  //#endregion

  //#region [Method]
  public async mounted(): Promise<void> {
    this.confirmation = helper.clone(this.confirmationState)
    if (this.meterReadingDateState) {
      this.meterReadingDate = helper.clone(this.meterReadingDateState)
    }
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (next && isValid) {
      this.$appInsights.trackEvent('ProsumerDeclaration_StepMeterReading_Validated', {
        Segment: 'ProsumerDeclaration',
        WebRef: this.requestId,
      })
    }

    this.store({ date: this.meterReadingDate, confirmation: this.confirmation })
  }

  //#endregion
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import { ModifiedConnection, PackagedElectricityMeter } from '@/models'
import PackageEditableBox from './PackageEditableBox.vue'
import AddConnectionEditableBox from './AddConnectionEditableBox.vue'
import PriceDisclaimer from './PriceDisclaimer.vue'

@Component({
  components: {
    PackageEditableBox,
    AddConnectionEditableBox,
    PriceDisclaimer
  },
  name: 'house-package-electricity'
})
export default class HousePackageElectricity extends Vue {
  @Prop({ type: Array, required: true }) public packagedElectricityMeters!: PackagedElectricityMeter[]
  @Prop({ type: Array, required: true }) public modifiedConnections!: ModifiedConnection[]

  @Prop({ type: Function, required: true }) public deleteModifiedConnection!: (identifier: number) => void
  @Prop({ type: Function, required: true }) public editModifiedConnection!: (identifier: number) => void
  @Prop({ type: Function, required: true }) public deleteElectricityBox!: (identifier: number) => void
  @Prop({ type: Function, required: true }) public editElectricityBox!: (identifier: number) => void

}

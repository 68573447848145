
import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'
import { WizardStepDefinition } from '@/models/wizard-step-definition'
import Vue2TouchEvents from 'vue2-touch-events'

@Component({
  name: 'wizard-display'
})
export default class WizardDisplay extends Vue {
  //#region [Property]
  @Prop({ type: Array, required: true }) public wizardStepDefinitions!: WizardStepDefinition[]
  @Prop({ type: Boolean, required: true }) public isPagingActivated!: boolean
  //#endregion

  //#region [Data]
  public currentStep: number = 0
  //#endregion

  //#region [Computed]
  public computePagingMargin(): string {
    return `${this.currentStep * 25}px`
  }
  //#endregion

  //#region [Methods]
  public setNextStep() {
    if (this.currentStep === this.wizardStepDefinitions.length - 1) {
      this.currentStep = 0
    } else {
      this.currentStep = this.currentStep + 1
    }
  }

  public setPreviousStep() {
    if (this.currentStep === 0) {
      this.currentStep = this.wizardStepDefinitions.length - 1
    } else {
      this.currentStep = this.currentStep - 1
    }
  }
  //#endregion
}


import { Component, Vue, Prop } from 'vue-property-decorator';
import { AddressPoint } from '@/models';
import { BuildingType } from '@/models/building-type';

@Component({
  name: 'resume-address-cmh-mobile'
})
export default class ResumeAddressCmhMobile extends Vue {
  @Prop({ type: Object, required: true }) public readonly address!: AddressPoint
  @Prop({ type: String, required: true }) public readonly buildingType!: BuildingType
}

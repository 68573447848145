
import { Vue, Component, Inject, Prop } from 'vue-property-decorator'
import { ContactAddress, Country } from '@/models'

@Component
export default class AddressForm extends Vue {
  //#region [Data]
  public countries: Country[] = []
  //#endregion

  //#region [Property]
  @Prop({ type: Object, required: true })
  public readonly value!: ContactAddress
  //#endregion

  //#region [Method]
  public mounted() {
    this.$addressRepository
      .get<Country[]>('/countries', {
        cache: {
          ignoreCache: false
        }
      })
      .then((response) => {
        const countries = response.data
        const belgiumIndex = countries.findIndex((x) => x.code === 'BE')
        const belgium: Country = countries.splice(belgiumIndex, 1)[0]
        this.countries = [belgium, ...countries]
      })
  }
  //#endregion
}

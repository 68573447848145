
import { Watch, Component } from 'vue-property-decorator'
import { ContactInfo } from '@/models'
import StepActions from '@/components/shared/StepActions.vue'
import SendConfirmation from '@/components/display/SendConfirmation.vue'
import Step from '@/components/steps/Step'
import AddressStep from '@/components/steps/synergrid-connection/AddressStep.vue'
import ConfigurationInfoStep from '@/components/steps/synergrid-connection/ConfigurationInfoStep.vue'
import AttachmentStep from '@/components/steps/synergrid-connection/AttachmentStep.vue'
import ContactInfoStep from '@/components/steps/synergrid-connection/ContactInfoStep.vue'
import VatInfoStep from '@/components/steps/synergrid-connection/VatInfoStep.vue'
import SummaryStep from '@/components/steps/synergrid-connection/SummaryStep.vue'
import DialogBox from '@/components/shared/DialogBox.vue'
import { State, Action, Mutation } from 'vuex-class'
import { Form } from './Form'
import { StepDefinition } from '@ores/vue-library'
import ApiErrors from '@/components/display/ApiErrors.vue'
import { removeInterceptors } from '@/plugins/http-interceptors-global'
import { addWorksResponseInterceptors } from '@/plugins/http-response-interceptors-works'
import store from '@/store/index'

@Component({
  components: {
    StepActions,
    AddressStep,
    ConfigurationInfoStep,
    ContactInfoStep,
    VatInfoStep,
    SummaryStep,
    SendConfirmation,
    AttachmentStep,
    DialogBox,
    ApiErrors
  },
  name: 'synergrid-connection'
})
export default class SynergridConnection extends Form {
  //#region [Data]
  public stepDefinitions: StepDefinition[] = [
    new StepDefinition('address', 1),
    new StepDefinition('request', 2, [new StepDefinition('', 3)]),
    new StepDefinition('contact', 4),
    new StepDefinition('vat', 5),
    new StepDefinition('summary', 6),
    new StepDefinition('confirm', 7)
  ] as StepDefinition[]

  @State('contactInfo', { namespace: 'synergridConnection/contactInfo' })
  public readonly contactInfoState!: ContactInfo | null
  //#endregion

  //#region [Method]
  @Action('validate', { namespace: 'synergridConnection/vatInfo' })
  public validateVatInfoSelection!: (contactInfo: ContactInfo) => Promise<boolean>

  public mounted() {
    this.currentStep = 1
    this.lastStepSeen = 1
    this.stepToGoAfterValidation = 6
    this.stepsToValidate = [4]

    this.onLangChanged(this.lang)

    if (this.interceptorId !== null) {
      removeInterceptors(this.$api, this.interceptorId)
    }
    const interceptorId = addWorksResponseInterceptors(this.$api, store)
    this.setInterceptorId(interceptorId)

    // Check maintenance
    this.$api.get('api/maintenanceManagement/synergrid-connection-request')
  }

  public getStepComponent(step: number): Step | null {
    switch (this.currentStep) {
      case 1:
        return this.$refs.step1 as Step
      case 2:
        return this.$refs.step2 as Step
      case 3:
        return this.$refs.step3 as Step
      case 4:
        return this.$refs.step4 as Step
      case 5:
        return this.$refs.step5 as Step
      case 6:
        return this.$refs.step6 as Step
      default:
        return null
    }
  }

  public resetForm() {
    this.currentStep = 0
    this.lastStepSeen = 1
    this.$store.dispatch('synergridConnection/reset')
    this.$nextTick(() => (this.currentStep = 1))
  }
  //#endregion

  //#region [Watch]
  @Watch('contactInfoState', { deep: true })
  public async onContactInfoChanged(contactInfo: ContactInfo | null) {
    if (contactInfo === null) {
      return
    }
    this.ensureLastStep(5, await this.validateVatInfoSelection(contactInfo))
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100);
  }
  //#endregion
}

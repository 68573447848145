
import { Vue, Prop, Component } from 'vue-property-decorator'
import { FileInfo, FileStatus } from '@ores/vue-library'

@Component
export default class WorksInputFile extends Vue {
  //#region [Property]
  @Prop({ type: String, required: true }) public label!: string
  @Prop({ type: String, required: false }) public exampleTitle?: string
  @Prop({ type: String, required: false }) public exampleSrc?: string
  @Prop({ type: Array, default: () => [] }) public filesInfo!: FileInfo[]
  @Prop({ type: String, required: false, default: '' }) public prefix!: string
  @Prop({ type: String, required: false, default: 'new-connection' }) public directory!: string
  @Prop({ type: String, default: null }) public accept!: string | null
  @Prop({ type: String, required: true }) public readonly requestId!: string
  @Prop({ type: Boolean, required: false, default: false }) public readonly required!: boolean;
  //#endregion

  //#region [Method]
  public async uploadFiles(formData: FormData): Promise<{ data: FileStatus[] }> {
    return await this.$api.post<FileStatus[]>(
      `/api/Attachments/work-request-attachment-upload?requestId=${this.requestId}&directory=${this.directory}`,
      formData
    )
  }

  public async deleteFiles(files: { data: string[] }): Promise<void> {
    return await this.$api.delete(`/api/Attachments/work-request-attachment?requestId=${this.requestId}&directory=${this.directory}`, files)
  }
  //#endregion
}

import { MutationTree } from 'vuex';
import { ContactInfoState } from './types';
import { ContactInfo } from '@/models';

export const mutations: MutationTree<ContactInfoState> = {
  STORE(state, data: ContactInfo) {
    switch (data.contactType) {
      case 'individual':
        data.contactFreelance = null
        data.contactCompany = null
        break;

      case 'freelance':
        data.contactIndividual = null
        data.contactCompany = null
        break;

      case 'company':
        data.contactFreelance = null
        data.contactIndividual = null
        break;

      default:
        break;
    }
    state.contactInfo = data
  },
  CLEAR(state) {
    state.contactInfo = null
  }
}

import { Contact, ContactAddress, ContactCompany, ContactFreelance, ContactIndividual, ContactInfo, ContactPerson } from '@/models'

function createContactIndividual(): ContactIndividual {
  return {
    civility: null,
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    mobile: null,
    birthDate: null,
    bankAccountBic: null,
    bankAccountIban: null,
    bankAccountHolderLastName: null,
    bankAccountHolderFistName: null,
  }
}

function createContactFreelance(): ContactFreelance {
  return {
    civility: null,
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    mobile: null,
    vatNumber: null,
    customerRef: null,
    birthDate: null,
    bankAccountBic: null,
    bankAccountHolderFistName: null,
    bankAccountHolderLastName: null,
    bankAccountIban: null
  }
}

function createContactCompany(): ContactCompany {
  return {
    civility: null,
    firstname: null,
    lastname: null,
    job: null,
    email: null,
    phone: null,
    companyType: null,
    companyName: null,
    vatNumber: null,
    customerRef: null,
    birthDate: null,
    address: createContactAddress()
  }
}

function createContactAddress(): ContactAddress {
  return {
    street: null,
    streetNumber: null,
    streetBox: null,
    zipCode: null,
    locality: null,
    country: null
  }
}

function createContactPerson(): ContactPerson {
  return {
    firstname: null,
    lastname: null,
    job: null,
    phone: null,
    mobile: null,
    email: null,
    address: {
      street: null,
      streetNumber: null,
      streetBox: null,
      zipCode: null,
      locality: null,
      country: null
    },
    sendOfferCopy: false
  }
}

function createContactInfo(): ContactInfo {
  return {
    contactType: null,
    contactIndividual: null,
    contactFreelance: null,
    contactCompany: null,
    otherContacts: [],
    useWorksAddress: true,
    otherAddress: null
  }
}

function createContactInfoFromEmail(email: string): ContactInfo {
  const contactInfo = createContactInfo();
  contactInfo!.contactIndividual = createContactIndividual()
  contactInfo!.contactFreelance = createContactFreelance()
  contactInfo!.contactCompany = createContactCompany()

  contactInfo!.contactIndividual!.email = email
  contactInfo!.contactFreelance!.email = email
  contactInfo!.contactCompany!.email = email

  return contactInfo
}

function cleanContactInfo(contactInfo: ContactInfo): void {
  if (contactInfo.contactType !== 'individual') {
    contactInfo.contactIndividual = null
  }

  if (contactInfo.contactType !== 'freelance') {
    contactInfo.contactFreelance = null
  }

  if (contactInfo.contactType !== 'company') {
    contactInfo.contactCompany = null
  }

  if (contactInfo.useWorksAddress) {
    contactInfo.otherAddress = null
  }
}

function getContactEmail(contactInfo: ContactInfo): string {
  switch (contactInfo.contactType) {
    case 'individual':
      return contactInfo.contactIndividual!.email!
    case 'freelance':
      return contactInfo.contactFreelance!.email!
    case 'company':
      return contactInfo.contactCompany!.email!
    default:
      return ''
  }
}

function getContact(contactInfo: ContactInfo): Contact {
  switch (contactInfo.contactType) {
    case 'individual':
      return {
        name: `${contactInfo.contactIndividual?.firstname} ${contactInfo.contactIndividual?.lastname}`,
        mobilePhoneNumber: contactInfo.contactIndividual?.mobile ?? '',
        email: contactInfo.contactIndividual?.email ?? '',
        housePhoneNumber: contactInfo.contactIndividual?.phone ?? ''
      }
    case 'freelance':
      return {
        name: `${contactInfo.contactFreelance?.firstname} ${contactInfo.contactFreelance?.lastname}`,
        mobilePhoneNumber: contactInfo.contactFreelance?.mobile ?? '',
        email: contactInfo.contactFreelance?.email ?? '',
        housePhoneNumber: contactInfo.contactFreelance?.phone ?? ''
      }
    case 'company':
      return {
        name: `${contactInfo.contactCompany?.firstname} ${contactInfo.contactFreelance?.lastname}`,
        mobilePhoneNumber: '',
        email: contactInfo.contactCompany?.email ?? '',
        housePhoneNumber: contactInfo.contactCompany?.phone ?? ''
      }
    default:
      return { name: '', mobilePhoneNumber: '', email: '', housePhoneNumber: '' }
  }
}

export const contactService = {
  createContactIndividual,
  createContactFreelance,
  createContactCompany,
  createContactAddress,
  createContactPerson,
  createContactInfo,
  cleanContactInfo,
  getContactEmail,
  getContact,
  createContactInfoFromEmail
}


import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { CompanyVatInfoSelection, CompanyVatInfoFirstStepSelection, CompanyVatInfoSecondStepSelection } from '@/models'
import CompanyFirstVatInfoSelector from '@/components/inputs/Vat/CompanyFirstVatInfoSelector.vue'
import CompanySecondVatInfoSelector from '@/components/inputs/Vat/CompanySecondVatInfoSelector.vue'

@Component({
  name: 'company-vat-info-selector',
  components: {
    CompanyFirstVatInfoSelector,
    CompanySecondVatInfoSelector
  }
})
export default class CompanyVatInfoSelector extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: false }) public readonly value!: CompanyVatInfoSelection | null
  //#endregion

  //#region [Data]
  public firstStepSelection: CompanyVatInfoFirstStepSelection | null = null
  public secondStepSelection: CompanyVatInfoSecondStepSelection | null = null
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(value: CompanyVatInfoSelection): CompanyVatInfoSelection {
    return value
  }
  //#endregion

  //#region [Watch]
  @Watch('value')
  public onValueChanged(newVal: CompanyVatInfoSelection) {
    this.firstStepSelection = newVal.primaryInfo
    this.secondStepSelection = newVal.secondaryInfo
  }
  //#endregion

  //#region [Method]
  public mounted() {
    if (this.value) {
      this.firstStepSelection = this.value.primaryInfo
      this.secondStepSelection = this.value.secondaryInfo
    }
  }

  public changeFirstStep(step: CompanyVatInfoFirstStepSelection) {
    this.firstStepSelection = step
    this.secondStepSelection = null

    this.$validatorObs.reset();
    this.change({ primaryInfo: step, secondaryInfo: this.secondStepSelection })
  }

  public changeSecondStep(step: CompanyVatInfoSecondStepSelection) {
    this.secondStepSelection = step

    this.change({ primaryInfo: this.firstStepSelection, secondaryInfo: this.secondStepSelection })
  }
  //#endregion
}

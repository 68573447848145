
import FindMeterLastDigitsModalDialog from '@/components/display/FindMeterLastDigitsModalDialog.vue'
import FindEanModalDialog from '@/components/display/FindEanModalDialog.vue'
import ZipCodeLocalitySelector from '@/components/inputs/Address/ZipCodeLocalitySelector.vue'
import ConnectionPointArea from '@/components/inputs/Address/ConnectionPointArea.vue'
import { CheckEanForProsumerDeclarationResponse, CheckEanSearch } from '@/models'
import CollapseInfo from '@/components/display/CollapseInfo.vue'
import { GridAccessPoint } from '@/models/prosumer-declaration'
import { Emit, Vue, Watch } from 'vue-property-decorator'
import { helper, chatService } from '@/services'
import { Mutation, State } from 'vuex-class'
import Component from 'vue-class-component'
import { AxiosResponse } from 'axios'

@Component({
  name: 'identification',
  components: {
    ZipCodeLocalitySelector,
    ConnectionPointArea,
    CollapseInfo,
    FindEanModalDialog,
    FindMeterLastDigitsModalDialog
  }
})
export default class Identification extends Vue {
  //#region [Data]
  @State('eanCode', { namespace: 'prosumerDeclaration/identification' })
  public eanCodeState!: string | null
  @State('meterLastFourNumbers', { namespace: 'prosumerDeclaration/identification' })
  public meterLastFourNumbersState!: string | null
  @State('requestId', { namespace: 'prosumerDeclaration' })
  public requestId!: string
  @State('gridAccessPoint', { namespace: 'prosumerDeclaration/identification' })
  public gridAccessPointState!: GridAccessPoint
  @State('lang')
  public lang!: string | null

  public eanCode: string | null = null
  public recaptchaToken: string | null = null
  public gridAccessPoint: GridAccessPoint | null = null
  public loading: boolean = false
  public hasError: boolean = false
  public isIdentified: boolean = false
  public recaptchaKey: number = 0
  public meterLastFourNumbers: string | null = null
  public whereFoundEanModalOpen: boolean = false
  public whereFindMeterLastDigitsOpen: boolean = false
  //#endregion

  //#region [Mutation]
  @Mutation('STORE_GRID_ACCESS_POINT', { namespace: 'prosumerDeclaration/identification' })
  public storeGridAccessPoint!: (index: GridAccessPoint | null) => void
  @Mutation('CLEAR_API_ERRORS', { namespace: 'apiErrors' })
  private clearApiErrors!: () => void
  //#endregion

  //#region [Computed]
  public get isIdentifiedAndCanContinue(): boolean {
    return this.isIdentified && !!this.gridAccessPoint
  }
  public get siteKey() {
    return process.env.VUE_APP_CAPTCHA_SITE_KEY
  }
  //#endregion

  //#region [Watch]
  @Watch('eanCode')
  @Emit('eanCode')
  public onEanCodeChange(value: string | null) {
    this.clearApiErrors()
    return value
  }

  @Watch('meterLastFourNumbers')
  @Emit('meterLastFourNumbers')
  public onMeterLastFourNumbers(meterLastFourNumbers: string | null) {
    return meterLastFourNumbers
  }

  @Watch('eanCodeState')
  public onEanCodeStateChange(value: string | null) {
    if (!value) {
      this.isIdentified = false
    }
  }

  @Watch('isIdentified')
  @Emit('isIdentified')
  public onIsIdentifiedChange(value: boolean): boolean | null {
    return value
  }

  @Watch('isIdentifiedAndCanContinue', { immediate: true })
  @Emit('canContinue')
  public isIdentifiedAndCanContinueChange(value: boolean): boolean | null {
    return value
  }

  @Watch('gridAccessPoint')
  @Emit('gridAccessPoint')
  public onGridAccessPointChange(value: GridAccessPoint):
    GridAccessPoint | null {
    return value
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.eanCode = helper.clone(this.eanCodeState)
    this.meterLastFourNumbers = helper.clone(this.meterLastFourNumbersState)
    this.gridAccessPoint = helper.clone(this.gridAccessPointState)
    this.isIdentified = !!this.gridAccessPointState
  }

  public async checkEanForProsumerDeclaration(): Promise<CheckEanForProsumerDeclarationResponse> {

    this.hasError = false;

    const response = await this.$api.get<CheckEanSearch, AxiosResponse<CheckEanForProsumerDeclarationResponse>>(
      `api/assets/check-ean-meter/prosumer-declaration?ean=${this.eanCode!.trim()}&meterLastFourNumbers=${this.meterLastFourNumbers}`,
      {
        headers: {
          'g-recaptcha-response': this.recaptchaToken
        },
        cache: {
          ignoreCache: false
        }
      }
    )
    if (response.status !== 200) {
      this.hasError = true;
      throw new Error("Grid access point not found");
    }
    return response.data
  }

  public async identify() {
    if (this.eanCode) {
      try {
        this.loading = true

        const checkEanForProsumerDeclarationResponse = await this.checkEanForProsumerDeclaration()

        this.gridAccessPoint = checkEanForProsumerDeclarationResponse.gridAccessPoint
        this.storeGridAccessPoint(this.gridAccessPoint)

        if (this.gridAccessPoint && checkEanForProsumerDeclarationResponse.token) {
          sessionStorage.setItem('token', checkEanForProsumerDeclarationResponse.token)
          this.isIdentified = true
        }

        this.loading = false

        if (!this.isIdentifiedAndCanContinue) {
          // Refresh the captcha for next identification to work
          this.recaptchaKey += 1
        }
      } catch (err) {
        this.loading = false
        this.isIdentified = false
        this.storeGridAccessPoint(null)
        this.recaptchaKey += 1
      }
    }
  }

  public openChat() {
    chatService.openChat()
  }

  //#endregion

  //#region [Emit]
  //#endregion
}

import { InstallationInformation } from '@/models/prosumer-declaration/prosumer-declaration'

export interface InstallationState {
  installation: InstallationInformation
}

export const InitialState: InstallationState = {
  installation: {
    technicalInformation: {
      productionSource: null,
      totalPower: null,
      maxOutputPower: null,
      powerInverters: [ { brand: null, model: null }]
    },
    battery: {
      hasBattery: null,
      batteries: []
    },
    relay: {
      hasRelay: null,
      firstDecouplingRelay: { brand: null, type: null },
      secondDecouplingRelay: { brand: null, type: null },
      nonReturnRelay: { brand: null, type: null, power: null }
    },
    accounting: {
      accountingType: null
    }
  }
}

import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { ChargingStationDeclarationState } from './types';

export const actions: ActionTree<ChargingStationDeclarationState, RootState> = {
  reset({ commit }) {
    commit('identification/CLEAR')
    commit('contactInfo/CLEAR')
    commit('information/CLEAR')
    commit('RESET')
  }
}

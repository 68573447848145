
import Step from '@/components/steps/Step'
import Component from 'vue-class-component'
import {
  AppointmentInfo,
  CallbackRequestCreationRequest,
  CheckEanSmartMeterResult,
  ContactInfo,
  CreateAppointmentRequest,
  EanConnectionContractResult,
  EnergyProductionInfoModel,
  GetAppointmentAvailabilitiesRequest,
  RestrictedPowerLevel,
  RestrictedWorkRequestType,
  WorksAppointmentTimeSlot,
  RestrictedWorkRequestService,
  RestrictedWorkRequestTopic
} from '@/models'
import Appointment from '@/components/inputs/Appointment/Appointment.vue'
import moment from 'moment'
import { AxiosResponse } from 'axios'
import { Mutation, State } from 'vuex-class'

@Component({
  name: 'AppointmentStep',
  components: {
    Appointment
  }
})
export default class AppointmentStep extends Step {
  //#region [Data]
  @State('noAppointment', { namespace: 'smartMeterConnection' })
  public skipAppointment!: boolean
  @State('requestId', { namespace: 'smartMeterConnection' })
  public requestId!: string
  @State('fileNumber', { namespace: 'smartMeterConnection/stakeholder' })
  public fileNumber!: string | null
  @State('searchStartDate', { namespace: 'smartMeterConnection/appointment' })
  public searchStartDateState!: Date
  @State('energyProductionInfo', { namespace: 'smartMeterConnection/energyProduction' })
  public energyProductionInfoState!: EnergyProductionInfoModel
  @State('connectionContract', { namespace: 'smartMeterConnection/identification' })
  public connectionContractState!: EanConnectionContractResult | null
  @State('checkEanResult', { namespace: 'smartMeterConnection/identification' })
  public checkEanResultState!: CheckEanSmartMeterResult
  @State('contactInfo', { namespace: 'smartMeterConnection/contactInfo' })
  public contactInfoState!: ContactInfo

  public appointment: AppointmentInfo = {
    remarks: null,
    selectedSlot: null,
    noSatisfyingDate: false
  }

  //#endregion

  //#region [Computed]
  private get searchAvailabilitiesParameters(): GetAppointmentAvailabilitiesRequest {
    return {
      workFileReference: this.fileNumber!,
      topic: RestrictedWorkRequestTopic.SmartMeter,
      service: this.energyProductionInfoState.hasEnergyProduction
        ? RestrictedWorkRequestService.ElectricityProductionCommissioning
        : RestrictedWorkRequestService.MeterReplacementBySmartMeter,
      periodStartDate: this.searchStartDateState,
      periodEndDate: this.currentSearchEndDate!,
      workRequestType: RestrictedWorkRequestType.Electricity,
      powerLevel: RestrictedPowerLevel.Low,
      timeSlot: WorksAppointmentTimeSlot.WholeDay,
      seeOtherDatesClicked: false
    }
  }

  public get currentSearchEndDate(): Date {
    return moment(this.searchStartDateState).add(7, 'days').toDate()
  }

  // Work file period end date (set in API when creating smart meter work file).
  // Limit for availabilities incremental search.
  private get searchEndDate(): Date {
    return moment(this.searchStartDateState).add(28, 'days').toDate()
  }

  private get workRequestCode(): string {
    const service = this.energyProductionInfoState.hasEnergyProduction ? 'PRO' : 'REM'
    return `EBSMT${service}`
  }

  //#endregion

  //#region [Mutations]
  @Mutation('STORE', { namespace: 'smartMeterConnection' })
  private storeNoAppointment!: (value: boolean) => void

  @Mutation('STORE_APPOINTMENT_INFO', { namespace: 'smartMeterConnection/appointment' })
  private storeAppointmentInfo!: (value: AppointmentInfo) => void
  //#endregion

  //#region [Method]
  public mounted() {
    if (!this.fileNumber) {
      // User must confirm that he sees the error messages
      return
    }
    if (this.skipAppointment) {
      this.$emit('next')
      return
    }
    this.setIsBack(false)
  }

  public onAppointmentInfoChange(value: AppointmentInfo) {
    this.appointment = value
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (!next) {
      // Leave this step using breadcrumb, with user confirmation input
      return true
    }
    if (!isValid) {
      return false
    }

    if (!this.fileNumber) {
      // After work file creation error
      location.href = this.$t('common.oresLink').toString()
      return false
    }

    if (!this.appointment.selectedSlot && !this.appointment.noSatisfyingDate) {
      return false
    }

    this.storeNoAppointment(this.appointment.noSatisfyingDate)

    this.$appInsights.trackEvent('WR_SMC_StepAppointmentStep_Validated', {
      Segment: 'WR_SMC',
      WebRef: this.requestId,
      ZipCode: this.checkEanResultState.consumptionAddress.zipCode,
      EnergyProductionInfo:
        this.energyProductionInfoState !== null && this.energyProductionInfoState.hasEnergyProduction !== null
          ? this.energyProductionInfoState.hasEnergyProduction.toString()
          : '0',
      ContactType: this.contactInfoState.contactType!.toString(),
      SimpleTariff: 'false',
      DualTariff: 'true'
    })

    if (this.appointment.noSatisfyingDate) {
      await this.sendCallbackRequest()
      return true
    }

    const data: CreateAppointmentRequest = {
      workFileReference: this.fileNumber!,
      topic: RestrictedWorkRequestTopic.SmartMeter,
      service: this.energyProductionInfoState.hasEnergyProduction
        ? RestrictedWorkRequestService.ElectricityProductionCommissioning
        : RestrictedWorkRequestService.MeterReplacementBySmartMeter,
      workFileStartDate: this.searchStartDateState,
      workFileEndDate: this.searchEndDate,
      workRequestType: RestrictedWorkRequestType.Electricity,
      powerLevel: RestrictedPowerLevel.Low,
      customerComment: this.appointment.remarks,
      appointmentStartTimestamp: this.appointment.selectedSlot!.startDate!,
      appointmentEndTimestamp: this.appointment.selectedSlot!.endDate!,
      grade: this.appointment.selectedSlot!.grade,
      yearlyReadingInformationReadingMonthNumber: this.connectionContractState?.yearlyReadingMonth
    }

    try {
      const token = sessionStorage.getItem('token')
      const response: AxiosResponse<void> = await this.$api.post(
        `api/worksrequest/work-file/${this.fileNumber}/appointment/create`,
        data,
        {
          headers: {
            Authorization: token
          }
        }
      )
      if (response.status >= 200 && response.status < 300) {
        this.$appInsights.trackEvent('WR_SMC_Appointment_Created', {
          Segment: 'WR_SMC',
          WebRef: this.requestId,
          ZipCode: this.checkEanResultState.consumptionAddress.zipCode,
          EnergyProductionInfo:
            this.energyProductionInfoState !== null && this.energyProductionInfoState.hasEnergyProduction !== null
              ? this.energyProductionInfoState.hasEnergyProduction.toString()
              : '0',
          ContactType: this.contactInfoState.contactType!.toString(),
          SimpleTariff: 'false',
          DualTariff: 'true',
          Remarks: this.appointment.remarks ?? ''
        })
        this.storeAppointmentInfo(this.appointment)
        return true
      }
      return false
    } catch (err) {
      this.storeNoAppointment(true)
      return false
    }
  }

  private async sendCallbackRequest() {
    const token = sessionStorage.getItem('token')
    const data: CallbackRequestCreationRequest = {
      workFileReference: this.fileNumber!,
      service: this.energyProductionInfoState.hasEnergyProduction
        ? RestrictedWorkRequestService.ElectricityProductionCommissioning
        : RestrictedWorkRequestService.MeterReplacementBySmartMeter,
      topic: RestrictedWorkRequestTopic.SmartMeter,
      powerLevel: RestrictedPowerLevel.Low,
      workRequestType: RestrictedWorkRequestType.Electricity,
      subject: 'Prise de rdv web indisponible',
      comment:
        'TL CREEE AUTOMATIQUEMENT : Le client n’a pas trouvé de date qui lui convient via le formulaire Web. Merci de le recontacter pour fixer un RDV'
    }
    await this.$api.post(`api/worksrequest/work-file/${this.fileNumber}/callback-request`, data, {
      headers: {
        Authorization: token
      }
    })

    this.$appInsights.trackEvent('WR_SMC_CallbackRequest_Created', {
      Segment: 'WR_SMC',
      WebRef: this.requestId,
      ZipCode: this.checkEanResultState.consumptionAddress.zipCode,
      EnergyProductionInfo:
        this.energyProductionInfoState !== null && this.energyProductionInfoState.hasEnergyProduction !== null
          ? this.energyProductionInfoState.hasEnergyProduction.toString()
          : '0',
      ContactType: this.contactInfoState.contactType!.toString(),
      CallbackRequestReason: 'customer_no_availability_found',
      WorkFileReference: this.fileNumber!,
      WorkRequestCode: this.workRequestCode
    })
  }

  //#endregion
}


import { CmhAddressPoint } from '@/models/cmh-address-point';
import { OfferingSelectionState } from '@/store/new-connection-cmh/offerings-selection/types';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';

export const actions: ActionTree<OfferingSelectionState, RootState> = {
  validate({ commit, state }, address: CmhAddressPoint): boolean {
    if (state?.selections === null ?? true) {
      return true
    }

    let change = false

    if (state.selections.some((x: string) => x === 'gas')) {
      if (!address.addressPoint?.subCity?.operationalOffices?.gasOffice
        || !address?.addressPoint?.subCity?.gridManagers?.gasManager?.isOres || false) {
        state.selections = state.selections.filter((x: string) => x !== 'gas')
        change = true
      }
    }

    if (state.selections.some((x: string) => x === 'electricty')) {
      if (!address.addressPoint?.subCity?.operationalOffices?.electricityOffice ||
        !address?.addressPoint?.subCity?.gridManagers?.electricityManager?.isOres ||
        false) {
          state.selections = state.selections.filter((x: string) => x !== 'electricty')
          change = true
      }
    }

    if (state.selections.some((x: string) => x === 'proximus')) {
      if (!address.addressPoint?.subCity?.connectMyHomeActivated
        || !address.addressPoint?.subCity?.connectMyHomeInformation?.proximusAvailable || false) {
          state.selections = state.selections.filter((x: string) => x !== 'proximus')
          change = true
        }
    }

    if (state.selections.some((x: string) => x === 'swde')) {
      if (!address.addressPoint?.subCity?.connectMyHomeActivated
        || !address.addressPoint?.subCity?.connectMyHomeInformation?.swdeAvailable || false) {
          state.selections = state.selections.filter((x: string) => x !== 'swde')
          change = true
        }
    }

    if (state.selections.some((x: string) => x === 'voo')) {
      if (!address.addressPoint?.subCity?.connectMyHomeActivated
        || !address.addressPoint?.subCity?.connectMyHomeInformation?.vooAvailable || false) {
          state.selections = state.selections.filter((x: string) => x !== 'voo')
          change = true
        }
    }

    if (change) {
      commit('STORE', state.selections)
    }

    return !change
  }
}


import { ModifiedConnection } from '@/models/configuration-info'
import { helper } from '@ores/vue-library'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {},
  name: 'cmh-electricity-modified-connection-form'
})
export default class CmhElectricityModifiedConnectionForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public modifiedConnection!: ModifiedConnection
  @Prop({ type: Object, required: true }) public defaultModifiedConnection!: ModifiedConnection
  //#endregion

  //#region [Data]

  public connection: ModifiedConnection = this.defaultModifiedConnection

  //#endregion

  //#region [Method]
  public mounted() {
    this.connection = helper.clone(this.modifiedConnection)
  }

  meterTypeChange(isSimple: boolean) {
    this.connection.type = isSimple ? 'simple' : 'dual'
  }

  public submit() {
    this.$emit('submit', this.connection)
  }
  //#endregion
}

import { ActionTree } from 'vuex'
import { EnergyProductionState } from './types';
import { RootState } from '@/store/types';
import { EnergySelection } from '@/models';

export const actions: ActionTree<EnergyProductionState, RootState> = {
  validate({ commit, state }, energySelection: EnergySelection): boolean {
    const energyProductionInfo = state.energyProductionInfo
    if (energyProductionInfo === null) {
      return !energySelection.electricity
    }

    if (!energySelection.electricity) {
      commit('STORE', null)
      return false
    }

    return true
  }
}

import { FileInfo } from '@ores/vue-library';

export interface FilesState {
  rapportFileInfo: FileInfo[]
  schemaFileInfo: FileInfo[]
  pictureFileInfo: FileInfo[]
}

export const InitialState: FilesState = {
  rapportFileInfo: [],
  schemaFileInfo: [],
  pictureFileInfo: []
}

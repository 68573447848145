import { GasMeter, ModifiedConnection, PackagedElectricityMeter } from '@/models'

function getTotalMeterCount<T extends GasMeter | PackagedElectricityMeter | ModifiedConnection>(meters: T[]): number {
  if (!meters?.length) {
    return 0
  }

  return meters.map((x: T) => x?.count ?? 0)
    .reduce((current: number | null, previous: number | null) => (current ?? 0) + (previous ?? 0))
}

function isCmhAvailableElec(meters: PackagedElectricityMeter[]): boolean {
  return isElectricityLowerThan56kva(meters) && !isFlatMetersGreaterThanFour(meters, []) && !isCommonMetersGreaterThanOne(meters)
}

function isCmhAvailableGas(meters: GasMeter[]): boolean {
  return isGasLowerThan150kw(meters) && meters.length <= 4
}

function isElectricityLowerThan56kva(meters: PackagedElectricityMeter[]): boolean {
  return !meters.some((meter) => !meter.customPower && meter.package?.maximumPower.kiloVoltAmpere! > 56) &&
    !meters.some((meter) => meter.customPower && meter.wantedPower && meter.wantedPower > 56)
}

function isElectricityCumulatedLowerThan56k(meters: PackagedElectricityMeter[]): boolean {
  let toTalPower = 0
  for (let meter of meters) {
    if (!meter.customPower) {
      toTalPower += meter.package?.maximumPower.kiloVoltAmpere! * meter.count!
    } else {
      toTalPower += meter.wantedPower! * meter.count!
    }
  }
  return toTalPower < 56
}

function isFlatMetersGreaterThanFour(electricityMeters: PackagedElectricityMeter[], gasMeters: GasMeter[]): boolean {

  let totalElectricalFlatMeterCount = 0
  let electricalFlatMeters = electricityMeters.filter(meter => meter.destination === 'residential')
  electricalFlatMeters.forEach(meter => {
    totalElectricalFlatMeterCount += meter.count!
  })
  let totalGasFlatMeterCount = 0
  let gasFlatMeters = gasMeters.filter(meter => meter.destination === 'residential')
  gasFlatMeters.forEach(meter => {
    totalGasFlatMeterCount += meter.count!
  })
  return totalElectricalFlatMeterCount > 4 || totalGasFlatMeterCount > 4
}

function isCommonMetersGreaterThanOne(electricityMeters: PackagedElectricityMeter[]): boolean {
  let totalElectricalCommonMeterCount = 0
  let electricalCommonMeters = electricityMeters.filter(meter => meter.destination === 'common')
  electricalCommonMeters.forEach(meter => {
    totalElectricalCommonMeterCount += meter.count!
  })
  return totalElectricalCommonMeterCount > 1
}

function isGasLowerThan150kw(meters: GasMeter[]): boolean {
  return !meters.some((meter) => meter.power > 150)
}

function isGasCumulatedLowerThan250kw(meters: GasMeter[]): boolean {
  let toTalPower = 0
  for (let meter of meters) {
    toTalPower += meter.power * meter.count!
  }
  return toTalPower < 250
}

export const cmhService = {
  getTotalMeterCount,
  isCmhAvailableElec,
  isCmhAvailableGas,
  isElectricityLowerThan56kva,
  isElectricityCumulatedLowerThan56k,
  isFlatMetersGreaterThanFour,
  isCommonMetersGreaterThanOne,
  isGasLowerThan150kw,
  isGasCumulatedLowerThan250kw
}


import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { idGenerator } from '@/services'
import AddressForm from '@/components/inputs/Contact/AddressForm.vue'
import { ContactPerson } from '@/models'

@Component({
  name: 'contacterson-form',
  components: {
    AddressForm,
  }
})
export default class ContactPersonForm extends Vue {

  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly value!: ContactPerson
  @Prop({ type: Boolean, required: false, default: true }) public readonly showSendOfferCopy!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly showCivility!: boolean
  //#endregion

  //#region [Data]
  public fieldId: string = idGenerator.getId()
  //#endregion

  //#region [Emit]
  @Emit('remove')
  public remove(): void {
    return undefined
  }
  //#endregion
}

import { GasMeter, ModifiedConnection, PackagedElectricityMeter } from '@/models/configuration-info';
import { MutationTree } from 'vuex';
import { CmhEnergyConfigurationState } from './types';
import { PackageDTO } from '@/models';

export interface CmhConfigurationStoreData {
  gasMeters: GasMeter[],
  packagedElectricityMeters: PackagedElectricityMeter[],
  modifiedConnections: ModifiedConnection[],
  isDefaultConfigurationOverriden: boolean
}

export const mutations: MutationTree<CmhEnergyConfigurationState> = {
  STORE(state, data: CmhEnergyConfigurationState) {
    state.gasMeters = data.gasMeters
    state.packagedElectricityMeters = data.packagedElectricityMeters
    state.modifiedConnections = data.modifiedConnections
    state.isDefaultConfigurationOverriden = data.isDefaultConfigurationOverriden
  },
  CLEAR(state) {
    state.gasMeters = []
    state.packagedElectricityMeters = []
    state.modifiedConnections = []
    state.isDefaultConfigurationOverriden = false
  },
  STORE_PACKAGE(state, data: PackageDTO[]) {
    if (data && data.length) {
      state.meterElectricityPackages = data
    }
  }
}

import Vue from 'vue'
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import { i18n } from '@/i18n';

export interface DialogsHelper {
  alert(text: string): void
  confirm(text: string): Promise<boolean>
}

declare module 'vue/types/vue' {

  interface Vue {
    $confirm: (text: string) => Promise<boolean>
  }

  interface VueConstructor {
    $confirm: (text: string) => Promise<boolean>
  }
}

export function DialogsPlugin(vueCtor: typeof Vue, store: Store<RootState>): void {
  const confirmDialog = (text: string): Promise<boolean> =>
    store.dispatch('dialogs/confirm', { text, title: i18n.t('dialogs.confirm') })

  vueCtor.prototype.$confirm = confirmDialog
}

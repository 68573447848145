
import { Form } from '@/views/forms/Form'
import { Component } from 'vue-property-decorator'
import { Identification, PersonalInfo } from '@/models'
import StepActions from '@/components/shared/StepActions.vue'
import IdentificationStep from '@/components/steps/prosumer-bonus/IdentificationStep.vue'
import PersonalInfoStep from '@/components/steps/prosumer-bonus/PersonalInfoStep.vue'
import SendConfirmation from '@/components/display/ProsumerBonus/SendConfirmation.vue'
import FormAlreadyCompleted from '@/components/display/ProsumerBonus/FormAlreadyCompleted.vue'
import DialogBox from '@/components/shared/DialogBox.vue'
import { State, Mutation } from 'vuex-class'

import Step from '@/components/steps/Step'
import { StepDefinition } from '@ores/vue-library'

import ApiErrors from '@/components/display/ApiErrors.vue'
import { removeInterceptors } from '@/plugins/http-interceptors-global'
import { addWorksResponseInterceptors } from '@/plugins/http-response-interceptors-works'
import store from '@/store/index'

@Component({
  components: {
    IdentificationStep,
    PersonalInfoStep,
    StepActions,
    SendConfirmation,
    FormAlreadyCompleted,
    DialogBox,
    ApiErrors
  },
  name: 'prosumer-bonus'
})
export default class ProsumerBonus extends Form {
  //#region [Data]
  public stepDefinitions: StepDefinition[] = [
    new StepDefinition('connection', 1),
    new StepDefinition('contact', 2),
    new StepDefinition('confirm', 3)
  ] as StepDefinition[]

  @State('identification', { namespace: 'prosumerBonus/identification' })
  public readonly identificationState!: Identification | null

  @State('personalInfo', { namespace: 'prosumerBonus/personalInfo' })
  public readonly personalInfoState!: PersonalInfo | null
  //#endregion

  //#region [Method]
  public mounted() {
    this.onLangChanged(this.lang)
    if (this.interceptorId !== null) {
      removeInterceptors(this.$api, this.interceptorId)
    }
    const interceptorId = addWorksResponseInterceptors(this.$api, store)
    this.setInterceptorId(interceptorId)

    // Check maintenance
    this.$api.get('api/maintenanceManagement/prime-prosumer')

    this.currentStep = 1
  }

  public resetForm() {
    this.currentStep = 1
    this.lastStepSeen = 1
    this.$store.dispatch('prosumerBonus/reset')
    this.$nextTick(() => (this.currentStep = 1))
  }

  public getStepComponent(step: number): Step | null {
    switch (this.currentStep) {
      case 1:
        return this.$refs.step1 as Step
      case 2:
        return this.$refs.step2 as Step
      default:
        return null
    }
  }

  public goToAlreadyCompletedStep() {
    this.currentStep = 4
    this.scrollTop()
  }
  //#endregion
}

import { ContactInfo } from '@/models';
import { CmhContactState } from '@/store/new-connection-cmh/cmh-contact/types';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<CmhContactState> = {
  STORE(state, data: ContactInfo) {
    state.contactInfo = data
  },
  CLEAR(state) {
    state.contactInfo = null
  }
}

import { GetterTree } from 'vuex'
import { VatInfoState } from './types';
import { RootState } from '@/store/types';

export interface Step2Getters {
  isMoreThanTenYearsSelected: boolean
  isLessThanTenYearsSelected: boolean
}

export const getters: GetterTree<VatInfoState, RootState> = {}

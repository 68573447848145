
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ZipCodeCheck from './ZipCodeCheck.vue'
import SubCitySelector from './SubCitySelector.vue'
import StreetSubCitySelector from './StreetSubCitySelector.vue'
import HouseNumberSelector from './HouseNumberSelector.vue'
import ConnectionPointArea from './ConnectionPointArea.vue'
import MapLocationPicker from './MapLocationPicker.vue'
import { AddressPoint, MapLocation, StreetSubCity, SubCity } from '@/models'
import { googleService } from '@/services'

@Component({
  components: {
    ZipCodeCheck,
    SubCitySelector,
    StreetSubCitySelector,
    HouseNumberSelector,
    ConnectionPointArea,
    MapLocationPicker
  }
})
export default class AddressSelector extends Vue {
  //#region [Property]
  @Prop(Object) public readonly value!: AddressPoint | null
  @Prop(Boolean) public readonly checkForGas?: boolean
  @Prop(Boolean) public readonly checkForElec?: boolean
  @Prop(Boolean) public readonly hideSuccessMessages?: boolean
  @Prop(Boolean) public readonly hideMap?: boolean
  @Prop(Boolean) public readonly multiline?: boolean
  @Prop(Boolean) public readonly showSynergridLink?: boolean
  @Prop(Boolean) public readonly showNonFinalHouseNumber?: boolean
  //#endregion

  //#region [Data]
  public temporaryZipCode: string | null = null
  public subCity: SubCity | null = null
  public streetSubCity: StreetSubCity | null = null
  public houseNumber: string | null = null
  public gasAllowed: boolean | null = null
  public elecAllowed: boolean | null = null
  public elecOrGasAllowed: boolean | null = null
  public addMapLocation: boolean = false
  public mapLocation: MapLocation | null = null
  public isNonFinalHouseNumber: boolean = false

  public isZipCodeValid: boolean = false
  public isSubCityValid: boolean = false
  public isStreetSubCityValid: boolean = false
  //#endregion

  //#region [Computed]
  get subCityAllowed() {
    return (this.checkForElec && this.elecAllowed) || (this.checkForGas && this.gasAllowed)
  }

  get subCityId(): number | null {
    return this.subCity ? this.subCity.georesSubcityId : null
  }

  get streetSubCityId(): number | null {
    return this.streetSubCity ? this.streetSubCity.georesStreetSubcityId : null
  }

  get connectionPoint(): AddressPoint | null {
    if (this.subCity === null) {
      return null
    }

    return {
      subCity: this.subCity,
      streetSubCity: this.streetSubCity,
      houseNumber: this.houseNumber,
      isMapLocation: this.addMapLocation,
      mapLocation: this.mapLocation,
      isNonFinalHouseNumber: this.isNonFinalHouseNumber
    }
  }

  get addressString(): string | null {
    return this.subCity ? `${this.subCity.zipCode} ${this.subCity.cityName}, Belgique` : null
  }

  //#endregion

  //#region [Watch]
  @Watch('gasAllowed')
  public onGasAllowedChanged(allowed: boolean | null): void {
    if (allowed === null) {
      return
    }
    this.$emit('gasAllowed', allowed)
  }

  @Watch('elecAllowed')
  public onElecAllowedChanged(allowed: boolean | null): void {
    if (allowed === null) {
      return
    }
    this.$emit('elecAllowed', allowed)
  }

  @Watch('elecOrGasAllowed')
  public onElecOrGasAllowedChanged(allowed: boolean | null): void {
    if (allowed === null) {
      return
    }
    this.$emit('elecOrGasAllowed', allowed)
  }

  @Watch('connectionPoint', { deep: true })
  public onConnectionPointChanged(val: AddressPoint | null, oldVal: AddressPoint | null): void {
    // Don't emit if no deeep value change
    if (val !== null && oldVal && JSON.stringify(val) !== JSON.stringify(oldVal)) {
      this.$emit('input', val)
    }
  }

  @Watch('value')
  public onValueChanged(newVal: AddressPoint | null): void {
    if (!newVal) {
      // reset all fields
      this.temporaryZipCode = null
      this.subCity = null
      this.streetSubCity = null
      this.houseNumber = null
      this.addMapLocation = false
    } else {
      this.temporaryZipCode = newVal.subCity.zipCode
      this.subCity = newVal.subCity
      this.streetSubCity = newVal.streetSubCity
      this.houseNumber = newVal.houseNumber
      this.addMapLocation = newVal.isMapLocation
      this.mapLocation = newVal.mapLocation
      this.isNonFinalHouseNumber = newVal.isNonFinalHouseNumber
    }
  }

  @Watch('addMapLocation')
  public onAddMapLocationChanged(value: boolean): void {
    if (!value) {
      this.mapLocation = null
    }
  }

  //#endregion

  //#region [Method]
  public canAllowGasCallback(canAllowGas: boolean): void {
    this.gasAllowed = canAllowGas
  }

  public canAllowElecCallback(canAllowElec: boolean): void {
    this.elecAllowed = canAllowElec
  }

  public canAllowElecOrGasCallback(canAllowElecOrGas: boolean): void {
    this.elecOrGasAllowed = canAllowElecOrGas
  }

  public emitZipCodeError(): void {
    this.$emit('zipCodeError')
  }

  public clearSubCityDependents() {
    this.addMapLocation = false
    this.streetSubCity = null
    this.houseNumber = null
  }

  public onZipCodeValidation(isValid: boolean): void {
    this.isZipCodeValid = isValid
    if (!isValid && this.subCity != null) {
      this.subCity = null
    }
  }

  public onSubCityValidation(isValid: boolean): void {
    this.isSubCityValid = isValid
    if (!isValid && this.streetSubCity != null) {
      this.streetSubCity = null
    }

    if (!isValid && (this.elecAllowed != null || this.gasAllowed != null)) {
      this.elecAllowed = null
      this.gasAllowed = null
    }
  }

  public onStreetSubCityValidation(isValid: boolean): void {
    this.isStreetSubCityValid = isValid
    if (!isValid && this.houseNumber != null) {
      this.houseNumber = null
    }
  }

  public mounted() {
    googleService.includeMapScript(this.$el)
  }

  //#endregion
}


import { Component, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { ApiErrorNotification } from '@/models/api-error-notification'

@Component
export default class ApiErrors extends Vue {
  //#region [Data]
  @State('apiErrors', { namespace: 'apiErrors' })
  public readonly apiErrors!: ApiErrorNotification | null
  //#endregion

  //#region [Computed]
  public get isSessionExpired(): boolean {
    return this.apiErrors?.isSessionExpired !== undefined
  }

  public get isAlert(): boolean {
    return this.apiErrors?.isSessionExpired !== undefined
  }
  //#endregion
}

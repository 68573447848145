
import { Component, Vue, Prop } from 'vue-property-decorator'
import GuaranteedMaximumPowerModal from '@/components/display/CmhNewConnection/GuaranteedMaximumPowerModal.vue'
import { vatService } from '@/services/index'
import { PackagedElectricityMeter } from '@/models'
import { BuildingType } from '@/models/building-type'
import { pricingService } from '@/services/pricing-service'

@Component({
  name: 'package-meter-summarized',
  components: {
    GuaranteedMaximumPowerModal
  }
})
export default class PackageMeterSummarized extends Vue {
  @Prop({ type: Object, required: true }) public meter!: PackagedElectricityMeter
  @Prop({ type: Boolean, required: true }) public isPriceToBeDetermined!: boolean
  @Prop({ type: String, required: true }) public applicableVAT!: string
  @Prop({ type: String, required: false }) buildingType!: BuildingType

  public guaranteedMaximumPowerModalOpen: boolean = false
  public isFree: boolean = false

  getVATLabel(): string {
    return vatService.getVatLabel(this.applicableVAT!)
  }

  getVATPriceForPackage(): string {
    return pricingService.formatNumberToLocaleStringFormat(vatService.getVATPriceForPackage(this.meter!, this.applicableVAT!, this.buildingType))
  }

  getMeterCount(): number {
    return this.meter.count ? this.meter.count : 0
  }
}

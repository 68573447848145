
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { AvailableServiceDefinition } from '@/models/available-service-definition'
@Component({
  name: 'available-service-display'
})
export default class AvailableServiceDisplay extends Vue {
  //#region [Property]
  @Prop({ type: Array, required: true }) public availableServiceDefinitions!: AvailableServiceDefinition[]
  //#endregion

  //#region [Computed]
  public setContainerBackground(isAvailable: boolean, key: number) {
    return {
      backgroundColor: isAvailable ? '#FFFFFF' : '#DFE2DF',
      marginLeft: key ? '16px' : '0px',
      border: isAvailable ? 'solid 1px #97bf0d' : 'solid 1px #DFE2DF'
    }
  }

  public setIconClass(isAvailable: boolean) {
    return isAvailable ? 'fa fa-check' : 'fa fa-times'
  }

  public setIconStyle(isAvailable: boolean) {
    return isAvailable ? { color: '#97BF0D' } : { color: '#57666F' }
  }
  //#endregion
}

import { AddressPoint } from '@/models'

export default {
  name: 'manager',
  validate: {
    validate: (value: AddressPoint | null, args: { [value: string]: any }) => {
      if (value == null) {
        return true
      }

      const grid = value.subCity.gridManagers;
      const checkElec = !args.value || args.value === 'electricity'
      const checkGas = !args.value || args.value === 'gas'

      if (checkElec && !!grid.electricityManager && grid.electricityManager.isOres) {
        return true
      }
      if (checkGas && !!grid.gasManager && grid.gasManager.isOres) {
        return true
      }

      return false
    }
  },
  params: [{ name: 'value' }],
}

import { MutationTree } from 'vuex';
import { VatInfoState } from './types';
import { VatInfo } from '@/models';

export const mutations: MutationTree<VatInfoState> = {
  STORE(state, data: VatInfo) {
    state.individualVatInfoSelection = data.individualVatInfoSelection
    state.freelanceVatInfoSelection = data.freelanceVatInfoSelection
    state.companyVatInfoSelection = data.companyVatInfoSelection
  },
  CLEAR(state) {
    state.individualVatInfoSelection = null
    state.freelanceVatInfoSelection = null
    state.companyVatInfoSelection = null
  }
}

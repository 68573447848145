import { MutationTree } from 'vuex';
import { AppointmentState } from './types';
import { AppointmentInfo, AppointmentSlot } from '@/models';

export const mutations: MutationTree<AppointmentState> = {
  STORE_SEARCH_START_DATE(state, data: Date) {
    state.searchStartDate = data
  },
  STORE_APPOINTMENT_INFO(state, data: AppointmentInfo) {
    state.appointment = data
  },
  CLEAR(state) {
    state.appointment = null
    state.searchStartDate = null
  }
}

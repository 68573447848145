
import { Vue, Component, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import FieldBox from '../../Shared/FieldBox'
@Component({
  name: 'electric-vehicle-question-form'
})
export default class ElectricVehicleQuestionForm extends FieldBox {

  //#region [Property]
  @Prop({ required: true }) public readonly value!: boolean | null
  //#endregion

  //#region [Data]
  public readonly tileYes: string = 'yes'
  public readonly tileNo: string = 'no'
  public isTitleTooltipOpen: boolean = false

  @State('requestId', { namespace: 'eMobility' })
  public requestId!: string
  //#endregion

  //#region [Computed]
  public get modelKnown(): string | null {
    if (this.value === null || this.value === undefined) {
      return null
    }
    return this.value ? this.tileYes : this.tileNo
  }

  public set modelKnown(val: string | null) {
    if (val === this.tileYes) {
      this.$emit('input', true)
    } else if (val === this.tileNo) {
      this.$emit('input', false)
    } else {
      this.$emit('input', null)
    }
  }
  //#endregion

  //#region [Method]
  public onTitleTooltipClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'CarModelKnow?'
    })
    this.isTitleTooltipOpen = !this.isTitleTooltipOpen
  }
  //#endregion
}

import { RootState } from '@/store/types'
import { Module } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { CmhEnergyConfigurationState, InitialState } from './types'

const namespaced: boolean = true

export const cmhEnergyConfiguration: Module<CmhEnergyConfigurationState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations
}

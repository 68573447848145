
import { Vue, Component, Inject, Watch } from 'vue-property-decorator'
import { helper, meterService, dateService, calendarService } from '@/services'
import Step from '@/components/steps/Step'
import ConstructionElectricityMeterForm from '@/components/inputs/Configuration/ConstructionElectricityMeterForm.vue'
import ConstructionPriceCalculator from '@/components/display/ConstructionPriceCalculator.vue'
import { State, Mutation, Getter } from 'vuex-class'
import { ConstructionConfigurationInfo, MeterElectricityType, MeterElectricityTension } from '@/models'

@Component({
  name: 'configuration-info-step',
  components: {
    ConstructionElectricityMeterForm,
    ConstructionPriceCalculator
  }
})
export default class ConfigurationInfoStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'constructionConnection' })
  public readonly requestId!: string

  @State('electricityTensions', { namespace: 'constructionConnection/configurationInfo' })
  public readonly electricityTensions!: MeterElectricityTension[]

  @State('electricityTarifications', { namespace: 'constructionConnection/configurationInfo' })
  public readonly electricityTarifications!: MeterElectricityType[]

  @State('configurationInfo', { namespace: 'constructionConnection/configurationInfo' })
  public configurationInfoState!: ConstructionConfigurationInfo | null

  public configurationInfo: ConstructionConfigurationInfo = {
    electricityMeter: meterService.getDefaultConstructionElectricityMeter('mono230V'),
    startDate: null,
    endDate: null
  }
  //#endregion

  //#region [Computed]
  protected get baseMinDate(): Date {
    return dateService.today()
  }

  public get minDate(): string {
    return dateService.stringifyDate(this.baseMinDate)
  }

  public get startDate(): string | null {
    if (this.configurationInfo.startDate === null) {
      return null
    }

    return dateService.stringifyDate(this.configurationInfo.startDate)
  }

  public get endDate(): string | null {
    if (this.configurationInfo.endDate === null) {
      return null
    }

    return dateService.stringifyDate(this.configurationInfo.endDate)
  }

  public get maxDate(): string | null {
    if (this.configurationInfo.startDate === null) {
      return null
    }

    return dateService.stringifyDate(calendarService.addYears(this.configurationInfo.startDate, 1))
  }

  public get selectedPeriod(): number | null {
    if (this.configurationInfo.startDate !== null && this.configurationInfo.endDate !== null) {
      return dateService.diffDate(this.configurationInfo.startDate, this.configurationInfo.endDate) + 1
    }

    return null
  }
  protected get minEndDate(): string {
    if (this.configurationInfo.startDate !== null) {
      return dateService.stringifyDate(calendarService.addDays(this.configurationInfo.startDate, 1))
    }

    const tmpDate = calendarService.addDays(this.baseMinDate, 1)
    return dateService.stringifyDate(tmpDate)
  }
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'constructionConnection/configurationInfo' })
  private store!: (index: ConstructionConfigurationInfo | null) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    if (this.configurationInfoState !== null) {
      this.configurationInfo = helper.clone(this.configurationInfoState)
    }
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid) {
      this.$appInsights.trackEvent('WR_CC_StepConfigurationInfo_Validated', {
        Segment: 'WR_CC',
        WebRef: this.requestId
      })
    }

    this.store(this.configurationInfo)
  }
  //#endregion
}

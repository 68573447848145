import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { InitialState, SmartMeterConnectionState } from './types'
import { identification } from './identification'
import { energyProduction } from './energy-production'
import { contactInfo } from './contact-info'
import { vatInfo } from './vat-info'
import { appointment } from './appointment'
import { stakeholder } from './stakeholder'
import { RootState } from '@/store/types'

const namespaced: boolean = true

export const smartMeterConnection: Module<SmartMeterConnectionState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations,
  modules: {
    identification,
    energyProduction,
    contactInfo,
    vatInfo,
    appointment,
    stakeholder
  }
}

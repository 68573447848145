import { SubCity } from '@/models'
import { MutationTree } from 'vuex';
import { IdentificationState } from './types';
import { GridAccessPoint } from '@/models/prosumer-declaration'

export const mutations: MutationTree<IdentificationState> = {
  STORE(state, data: { subCity: SubCity | null, eanCode: string | null, meterLastFourNumbers: string | null }) {
    state.subCity = data.subCity
    state.eanCode = data.eanCode
    state.meterLastFourNumbers = data.meterLastFourNumbers
  },
  STORE_GRID_ACCESS_POINT(state, data: GridAccessPoint) {
    state.gridAccessPoint = data
  },
  CLEAR(state) {
    state.subCity = null
    state.eanCode = null
    state.meterLastFourNumbers=null
    state.gridAccessPoint = null
  }
}

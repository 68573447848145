
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import { BatteryInformation } from '@/models/prosumer-declaration/prosumer-declaration'

@Component({
  name: 'battery-information-component'
})
export default class BatteryInformationComponent extends Vue {
  @Prop({ type: Object, required: true }) public readonly value!: BatteryInformation

  public batteryModalOpen: boolean = false

  public addBattery() {
    this.value.batteries.push({ brand: null, type: null, capacity: null })
  }

  public removeBattery(index: number) {
    this.value.batteries.splice(index, 1)
  }

  @Watch('value.hasBattery', { deep: true })
  public onHasBatteryChanged(newVal: boolean) {
    if (newVal && this.value.batteries.length === 0) {
      this.addBattery()
    }

    if (!newVal && this.value.batteries.length !== 0) {
      this.value.batteries = []
    }
  }
}

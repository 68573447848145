import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { SynergridConnectionState } from './types';

export const actions: ActionTree<SynergridConnectionState, RootState> = {
  reset({ commit }) {
    commit('vatInfo/CLEAR')
    commit('contactInfo/CLEAR')
    commit('attachment/CLEAR')
    commit('configurationInfo/CLEAR')
    commit('address/CLEAR')
    commit('RESET')
  }
}


import { Component, Vue } from 'vue-property-decorator'
import { State, Getter } from 'vuex-class'
import { ContactInfo } from '@/models'
import { pixelFacebookService } from '@ores/vue-library';

@Component({
  name: 'cmh-confirmation-step'
})
export default class CmhConfirmationStep extends Vue {
  @State('contactInfo', { namespace: 'newConnectionCmh/cmhContactInfo' })
  public contactInfo!: ContactInfo
  @State('emailAddress', { namespace: 'newConnectionCmh/mailRegistration' })
  public emailAddress!: string

  @Getter('isProximusAvailable', { namespace: 'newConnectionCmh/cmhAddress' })
  public isProximusAvailable!: boolean
  @Getter('isVooAvailable', { namespace: 'newConnectionCmh/cmhAddress' })
  public isVooAvailable!: boolean
  @Getter('isSwdeAvailable', { namespace: 'newConnectionCmh/cmhAddress' })
  public isSwdeAvailable!: boolean

  //#region [Method]
  public mounted() {
    pixelFacebookService.trackViewContent(
      {
      content_ids: ['CmhForm'],
      content_name: 'Confirmation Step',
      content_type: 'product'
      })
    pixelFacebookService.trackSubmitApplication()
  }

  //#endregion
}

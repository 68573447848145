import { idGenerator } from '@/services';
import { MutationTree } from 'vuex';
import { EMobilityConnectionState } from './types';

export const mutations: MutationTree<EMobilityConnectionState> = {
  STORE(state, requestId: string) {
    state.requestId = requestId
  },
  RESET(state) {
    state.requestId = idGenerator.getNewWebReference()
  }
}


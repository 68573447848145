import { ConsumptionAddress } from '.';
import { CarType } from '..';
import { ElectricityPowerSupplyType } from '../eletricity-specification';

export interface ElectricMobilityMeterInfos {
  isActive: boolean;
  isSmartMeter: boolean;
  isBudgetMeter: boolean;
  amperage: number;
  electricityPowerSupplyType: ElectricityPowerSupplyType
}

export interface ElectricMobilityGridAccessPointInfo {
  electricMobilityMeterInfos?: ElectricMobilityMeterInfos[];
  consumptionAddress: ConsumptionAddress;
}

export interface ConsumingDevice {
  id: number;
  device: string;
  kwh: number;
  day: boolean;
  night: boolean;
  icon: string | null;
}

export interface CheckConsumingDeviceResult {
  consumingDevices: ConsumingDevice[];
}

export interface BatteryRechargingCapacityRequest {
  electricityPowerSupplyType: ElectricityPowerSupplyType;
  power: number;
  batteryCapacity: number;
  carType: CarType;
  dayConsumption: number;
  nightConsumption: number;
}

export enum DayPeriod {
  day = 'day',
  night = 'night'
}

export interface BatteryRechargingCapacity {
  chargerPower: number;
  chargingDuration: number;
  period: DayPeriod
}

export interface ElectricMobilityCustomerEmailRequest {
  email: string;
  electricityPowerSupplyType: ElectricityPowerSupplyType;
  power: number;
  carType: CarType;
  hasSolarPanels: boolean;
  mainCapacity: BatteryRechargingCapacity | null;
  alternateCapacity: BatteryRechargingCapacity | null;
}

export interface ElectricVehicle {
  id: number
  brand: string
  model: string
  version: string | null
  isHybrid: boolean
  batteryTotalCapacity: number
  batteryUseableCapacity: number
}

export interface PowerIncreasePackages {
  powerIncreasePackages: ElectricMobilityPackageDto[]
}

export interface ElectricMobilityPackageDto {
  key: string
  maximumKva: number
  powerIncreasePriceVat21: number
  isCurrent: boolean
}

export interface GetPackagesRequest {
  amperage?: number,
  powerSupplyType?: string,
  ean?: string
}

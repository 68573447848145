
import { Vue, Component, Prop } from 'vue-property-decorator'
import ContactPersonForm from '@/components/inputs/Contact/ContactPersonForm.vue'
import { ContactPerson } from '@/models'
import { contactService } from '@/services'

@Component({
  name: 'contact-people-form',
  components: {
    ContactPersonForm
  }
})
export default class ContactPeopleForm extends Vue {
  //#region [Property]
  @Prop({ type: Array, required: true }) public readonly value!: ContactPerson[]
  @Prop({ type: Boolean, required: false, default: true }) public readonly showSendOfferCopy!: boolean
  @Prop({ required: false, default: null }) public readonly maxContactCount!: number | null
  @Prop({ type: Boolean, required: false, default: false }) public readonly showCivility!: boolean
  //#endregion

  //#region [Method]

  public updated() {
    const otherContactElements =
      document.getElementById('contactsContainer')
        ?.getElementsByClassName('lib__panel') ?? []
    if (otherContactElements.length) {
      otherContactElements[otherContactElements.length - 1].scrollIntoView()
    }
  }

  public add() {
    if (this.canAdd) {
      this.value.push(contactService.createContactPerson())
    }
  }

  public remove(index: number) {
    const contact = this.value[index]
    let name: string = [contact.firstname, contact.lastname].filter((n) => !!n).join(' ')

    if (!!name) {
      name = ` (${name})`
    }

    this.$confirm(this.$t('dialogs.deleteContact', [name]) as string).then((v) => {
      if (v) {
        this.value.splice(index, 1)
      }
    })
  }

  //#endregion

  get canAdd(): boolean {
    return this.maxContactCount === null || this.value.length < this.maxContactCount
  }
}


import { Component } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import EnergyProductionInfo from '@/components/inputs/EnergyProduction/EnergyProductionInfo.vue'
import { YesNoMaybeSelection } from '@ores/vue-library'
import { helper } from '@/services'
import { State, Mutation, Getter } from 'vuex-class'
import {
  CmhEnergyProductionConfigurationState
} from '@/store/new-connection-cmh/cmh-energy-production-configuration/types'
import { pixelFacebookService } from '@ores/vue-library'
import { EnergyProduction } from '@/models'

@Component({
  components: {
    EnergyProductionInfo
  },
  name: 'cmh-energy-production-configuration-step'
})
export default class CmhEnergyProductionConfigurationStep extends Step {
  @State('yesNoMaybeSelection', { namespace: 'newConnectionCmh/cmhEnergyProductionConfiguration' })
  public stateYesNoMaybeSelection!: YesNoMaybeSelection | null
  @State('kvaPower', { namespace: 'newConnectionCmh/cmhEnergyProductionConfiguration' })
  public stateKvaPower!: number | null
  @State('kwcPower', { namespace: 'newConnectionCmh/cmhEnergyProductionConfiguration' })
  public stateKwcPower!: number | null
  @State('requestId', { namespace: 'newConnectionCmh' })
  public requestId!: string

  public yesNoMaybeSelection: YesNoMaybeSelection | null = null
  public kvaPower: number | null = null
  public kwcPower: number | null = null

  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhEnergyProductionConfiguration' })
  public store!: (index: CmhEnergyProductionConfigurationState) => void

  @Getter('isElectricitySelected', { namespace: 'newConnectionCmh/offeringsSelection' })
  public isElectricitySelected!: boolean

  public async mounted() {
    pixelFacebookService.trackViewContent({
      content_ids: ['CmhForm'],
      content_name: 'Energy Production Step',
      content_type: 'product'
    })

    if (this.isElectricitySelected) {
      this.yesNoMaybeSelection = helper.clone(this.stateYesNoMaybeSelection)
      this.kvaPower = helper.clone(this.stateKvaPower)
      this.kwcPower = helper.clone(this.stateKwcPower)
    } else {
      await this.handleNoElectricity()
    }
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (isValid) {
      this.store({ yesNoMaybeSelection: this.yesNoMaybeSelection, kvaPower: this.kvaPower, kwcPower: this.kwcPower })

      await this.saveStep()

      this.$appInsights.trackEvent('WR_NCCMH_Step4_Validated', { Segment: 'WR_NCCMH', WebRef: this.requestId })
    }
  }

  public async saveStep() {
    const data: EnergyProduction = {
      hasEnergyProduction: this.yesNoMaybeSelection!,
      kvaPower: this.kvaPower,
      kwcPower: this.kwcPower
    }

    await this.$api.post<EnergyProduction>(`NewConnectionRequest/store/${this.requestId}/energy-production`, data)
  }

  private async handleNoElectricity(): Promise<void> {
    if (this.isBack) {
      await this.previous()
    } else {
      this.yesNoMaybeSelection = 0
      await this.next()
    }
  }
}

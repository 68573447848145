
import Component from 'vue-class-component'
import { Prop, Emit, Vue, Watch } from 'vue-property-decorator'
import { dateService } from '@/services'
import {
  AppointmentSlot,
  GetAppointmentAvailabilitiesRequest,
  GetAppointmentAvailabilitiesResponse,
  WorksAppointmentAvailability
} from '@/models'
import { AxiosResponse } from 'axios'

@Component({
  name: 'SearchAvailabilities'
})
export default class SearchAvailabilities extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public parameters!: GetAppointmentAvailabilitiesRequest
  @Prop({ type: String, required: true }) public waitingLabel!: string
  //#endregion

  //#region [Data]
  private loading: boolean = false
  private foundSlots: AppointmentSlot[] | null = null
  private waiting: boolean = false
  private waitingCounter: number = -1
  //#endregion

  //#region [Computed]
  private get waitingLabelWithCounter(): string {
    return this.waitingCounter !== -1 ? `${this.waitingLabel} ${this.waitingCounter}` : this.waitingLabel
  }

  //#endregion

  //#region [Watch]
  @Watch('parameters', { immediate: true, deep: true })
  private async onParametersChange(value: GetAppointmentAvailabilitiesRequest) {
    // Reset the counter in case the search was triggered again
    this.waitingCounter = -1
    // Set "optimisation de votre dossier" message (no counter yet) only when "see other dates" button was not clicked
    this.waiting = !value.seeOtherDatesClicked
    await this.searchForAvailabilities()
    await this.emitLoaded()
  }
  //#endregion

  //#region [Methods]

  // Search for availabilities/wait if needed.
  private async searchForAvailabilities() {
    this.emitLoading(true)
    // initial (and maybe sole) request
    let availabilitiesResponse = null
    try {
      availabilitiesResponse = await this.requestAppointmentAvailabilities()
      if (availabilitiesResponse!.remainingWaitTimeSeconds > 0) {
        // Wait, this will then remove wait indicator if set
        await this.wait(availabilitiesResponse!.remainingWaitTimeSeconds)
        // Perform request again
        availabilitiesResponse = await this.requestAppointmentAvailabilities()
      } else {
        this.waiting = false
      }

      if (
        !availabilitiesResponse ||
        availabilitiesResponse?.remainingWaitTimeSeconds > 0 ||
        !availabilitiesResponse?.schedulingServiceReady
      ) {
        // Unexpected error
        this.$store.dispatch('apiErrors/customManage', 'schedulingUnavailable')
        this.foundSlots = null
        this.emitLoading(false)
        return
      }

      // Map availabilities to slots
      this.foundSlots = this.getSlots(availabilitiesResponse?.availabilities || [])
      this.emitLoading(false)
    } catch (err) {
      this.$store.dispatch('apiErrors/customManage', 'schedulingUnavailable')
      this.foundSlots = null
      this.emitLoading(false)
    }
  }

  private getSlots(availabilities: WorksAppointmentAvailability[]): AppointmentSlot[] {
    return availabilities.map<AppointmentSlot>((a) => {
      return {
        startDate: new Date(a.startDate),
        endDate: new Date(a.endDate),
        timeslotLabel: '',
        wholeDayTimeslot: null,
        grade: a.grade,
        key: dateService.displayDateText(a.startDate),
        otherDate: false
      }
    })
  }

  private async requestAppointmentAvailabilities(): Promise<GetAppointmentAvailabilitiesResponse | null> {
    try {
      const token = sessionStorage.getItem('token')
      const response: AxiosResponse<GetAppointmentAvailabilitiesResponse> = await this.$api.post(
        `api/worksrequest/work-file/${this.parameters.workFileReference}/appointment/availabilities`,
        this.parameters,
        {
          headers: {
            Authorization: token
          }
        }
      )
      if (response.status !== 200) {
        if (response.status === 400) {
          const errorResponse = response as any
          if (errorResponse.data.errors && errorResponse.data.errors.length) {
            if (errorResponse.data.errors[0].key === 'FunctionalException') {
              this.$store.dispatch('apiErrors/customManage', 'schedulingUnavailable')
            } else {
              this.$store.dispatch('apiErrors/customManage', 'default')
            }
          } else {
            this.$store.dispatch('apiErrors/customManage', 'default')
          }
        }
        return null
      }
      return response.data
    } catch (err) {
      this.emitLoading(false)
      return null
    }
  }

  // Wait and emit waiting events
  private async wait(timeToWaitSeconds: number) {
    this.waitingCounter = timeToWaitSeconds
    return new Promise<void>((resolve) => {
      setInterval(() => {
        if (this.waitingCounter === 0) {
          this.waiting = false
          resolve()
        }
        this.waitingCounter--
      }, 1000)
    })
  }
  //#endregion

  //#region [Emit]
  @Emit('loaded')
  private async emitLoaded(): Promise<AppointmentSlot[] | null> {
    return this.foundSlots
  }

  @Emit('loading')
  private emitLoading(loading: boolean): boolean {
    this.loading = loading
    return loading
  }
  //#endregion
}

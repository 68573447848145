import { actions } from '@/store/new-connection-cmh/cmh-address/actions';
import { getters } from '@/store/new-connection-cmh/cmh-address/getters';
import { mutations } from '@/store/new-connection-cmh/cmh-address/mutations';
import { CmhAddressState, InitialState } from '@/store/new-connection-cmh/cmh-address/types';
import { RootState } from '@/store/types';
import { Module } from 'vuex';

const namespaced: boolean = true;

export const cmhAddress: Module<CmhAddressState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations
}

import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { ApiErrorsState } from './types';
import { AxiosResponse } from 'axios';
import { i18n } from '@/i18n';
import router from '@/router';

export const actions: ActionTree<ApiErrorsState, RootState> = {
  manageWorks({ commit }, error: AxiosResponse<any> | undefined) {
    if (!error) {
      commit('SET_API_ERRORS', { danger: [i18n.t('works.error.tryLater'), i18n.t('works.error.message')] })
      return
    }
    switch (error.status) {
      case 503:
        router.push({ path: '/maintenance' })
        break
      case 401:
        commit('SET_API_ERRORS', {
          danger: [i18n.t('common.error.identificationExpired'),
          i18n.t('common.error.refreshForm')]
        })
        break
      case 404:
        if (error.config.url?.startsWith('newconnectionrequest/')) {
          commit('SET_API_ERRORS', { isSessionExpired: [i18n.t('works.error.restartRequest')] })
          break
        }
        commit('SET_API_ERRORS', { danger: [i18n.t('works.error.tryLater'), i18n.t('works.error.message')] })
        break
      default:

        commit('SET_API_ERRORS', { danger: [i18n.t('works.error.tryLater'), i18n.t('works.error.message')] })
    }
  },
  manageSmartMeter({ commit }, error: AxiosResponse<any> | undefined) {
    if (!error) {
      commit('SET_API_ERRORS', { danger: [i18n.t('smartMeter.error.tryLater'), i18n.t('smartMeter.error.message')] })
      return
    }

    switch (error.status) {
      case 503:
        router.push({ path: '/maintenance' })
        break
      case 401:
        commit('SET_API_ERRORS', {
          danger: [i18n.t('common.error.identificationExpired'),
          i18n.t('common.error.refreshForm')]
        })
        break
      default:
        commit('SET_API_ERRORS', { danger: [i18n.t('smartMeter.error.tryLater'), i18n.t('smartMeter.error.message')] })
    }
  },
  manageAddressRepository({ commit }) {
    commit('SET_API_ERRORS', { danger: [i18n.t('addressRepository.error.tryLater'), i18n.t('addressRepository.error.message')] })
  },
  customManage({ commit }, error: string) {
    switch (error) {
      case 'contactCallCenter':
        commit('SET_API_ERRORS', {
          danger: [i18n.t(`custom.error.fileNotFound`),
          i18n.t(`custom.error.contactCallCenter`)]
        })
        break;
      case 'workFileNotCreatedError':
        commit('SET_API_ERRORS', {
          danger: [i18n.t(`smartMeter.workFileCreation.workFileNotCreatedError`)]
        })
        break;
      case 'verbalOfferNotCreatedError':
        commit('SET_API_ERRORS', {
          danger: [i18n.t(`smartMeter.workFileCreation.verbalOfferNotCreatedError`)]
        })
        break;
      case 'schedulingUnavailable':
        commit('SET_API_ERRORS', {
          danger: [i18n.t(`custom.error.schedulingUnavailable`)]
        })
        break;
      default:
        commit('SET_API_ERRORS', { danger: [i18n.t('custom.error.tryLater'), i18n.t('custom.error.message')] })
        break;
    }
  },
  manageEmobility({ commit }, error: AxiosResponse<any> | undefined) {
    if (!error) {
      commit('SET_API_ERRORS', { danger: [i18n.t('eMobility.error.tryLater'), i18n.t('eMobility.error.message')] })
      return
    }
    switch (error.status) {
      case 503:
        router.push({ path: '/maintenance' })
        break
      case 401:
        commit('SET_API_ERRORS', {
          danger: [i18n.t('common.error.identificationExpired'),
          i18n.t('common.error.refreshForm')]
        })
        break
      default:
        commit('SET_API_ERRORS', { danger: [i18n.t('eMobility.error.tryLater'), i18n.t('eMobility.error.message')] })
    }
  },
  manageChargingStationDeclaration({ commit }, error: AxiosResponse<any> | undefined) {
    if (!error) {
      commit('SET_API_ERRORS', { danger: [i18n.t('chargingStationDeclaration.error.tryLater'), i18n.t('chargingStationDeclaration.error.message')] })
      return
    }
    switch (error.status) {
      case 503:
        router.push({ path: '/maintenance' })
        break
      case 401:
        commit('SET_API_ERRORS', {
          danger: [i18n.t('common.error.identificationExpired'),
            i18n.t('common.error.refreshForm')]
        })
        break
      default:
        commit('SET_API_ERRORS', { danger: [i18n.t('chargingStationDeclaration.error.tryLater'), i18n.t('chargingStationDeclaration.error.message')] })
    }
  },
  manageProsumerDeclaration({ commit }, error: AxiosResponse<any> | undefined) {
    if (!error) {
      commit('SET_API_ERRORS', { danger: [i18n.t('prosumerDeclaration.error.tryLater'), i18n.t('prosumerDeclaration.error.message')] })
      return
    }
    switch (error.status) {
      case 503:
        router.push({ path: '/maintenance' })
        break
      case 401:
        commit('SET_API_ERRORS', {
          danger: [i18n.t('common.error.identificationExpired'),
            i18n.t('common.error.refreshForm')]
        })
        break
      default:
        commit('SET_API_ERRORS', { danger: [i18n.t('prosumerDeclaration.error.tryLater'), i18n.t('prosumerDeclaration.error.message')] })
    }
  },
  clear({ commit }) {
    commit('CLEAR_API_ERRORS')
  }
}


import { Component, Prop } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import MeterEditableBox from '@/components/inputs/Energy/MeterEditableBox.vue'
import {
  GasMeter,
  ContactInfo,
  IndividualVatInfoSelection,
  FreelanceVatInfoSelection,
  CompanyVatInfoSelection,
  PackagedElectricityMeter,
  ModifiedConnection,
  PriceDTO
} from '@/models'
import { State, Getter } from 'vuex-class'
import { CmhAddressPoint } from '@/models/cmh-address-point'
import { YesNoMaybeSelection, FileInfo } from '@ores/vue-library'
import { CmhEnergyProductionConfigurationState } from '@/store/new-connection-cmh/cmh-energy-production-configuration/types'
import { pixelFacebookService } from '@ores/vue-library'
import ResumeAddress from '@/components/display/Resume/ResumeAddress.vue'
import GasPriceCalculator from '@/components/display/GasPriceCalculator.vue'
import ElectricityPriceCalculator from '@/components/display/ElectricityPriceCalculator.vue'
import ResumeContactInfo from '@/components/display/Resume/ResumeContactInfo.vue'
import ResumeVatInfo from '@/components/display/Resume/ResumeVatInfo.vue'
import ResumeOfferingHeader from '@/components/display/Resume/ResumeOfferingHeader.vue'
import ResumeAddressCmhMobile from '@/components/display/Resume/ResumeAddressCmhMobile.vue'
import ResumeFilesCmh from '@/components/display/Resume/ResumeFilesCmh.vue'
import ResumeEnergyProduction from '@/components/display/Resume/ResumeEnergyProduction.vue'
import { pricingService } from '@/services/pricing-service'
import PackageEditableBox from '@/components/display/CmhNewConnection/PackageEditableBox.vue'
import PackageMeterSummarized from '@/components/display/CmhNewConnection/PackageMeterSummarized.vue'
import { vatService } from '@/services'
import AddConnectionSummarized from '@/components/display/CmhNewConnection/AddConnectionSummarized.vue'
import { BuildingType } from '@/models/building-type'
import PriceDisclaimer from '@/components/display/CmhNewConnection/PriceDisclaimer.vue'

@Component({
  components: {
    ResumeAddress,
    GasPriceCalculator,
    ElectricityPriceCalculator,
    ResumeContactInfo,
    ResumeVatInfo,
    MeterEditableBox,
    PackageEditableBox,
    PackageMeterSummarized,
    PriceDisclaimer,
    AddConnectionSummarized,
    ResumeAddressCmhMobile,
    ResumeFilesCmh,
    ResumeOfferingHeader,
    ResumeEnergyProduction
  },
  name: 'cmh-summary-step'
})
export default class CmhSummaryStep extends Step {
  //#region [Data]
  @Prop({ type: Boolean, required: false, default: false }) public readonly readOnly!: boolean

  @State('requestId', { namespace: 'newConnectionCmh' })
  public requestId!: string

  @State('gasMeters', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public stateGasMeters!: GasMeter[]
  @State('packagedElectricityMeters', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public statePackagedElectricityMeters!: PackagedElectricityMeter[]
  @State('modifiedConnections', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public stateModifiedConnections!: ModifiedConnection[]
  @State('addressPoint', { namespace: 'newConnectionCmh/cmhAddress' })
  public stateAddressPoint!: CmhAddressPoint
  @State('yesNoMaybeSelection', { namespace: 'newConnectionCmh/cmhEnergyProductionConfiguration' })
  public stateYesNoMaybeSelection!: YesNoMaybeSelection | null
  @State('cmhEnergyProductionConfiguration', { namespace: 'newConnectionCmh' })
  public cmhEnergyProductionConfiguration!: CmhEnergyProductionConfigurationState
  @State('contactInfo', { namespace: 'newConnectionCmh/cmhContact' })
  public stateContactInfo!: ContactInfo
  @State('individualVatInfoSelection', { namespace: 'newConnectionCmh/cmhVatInfo' })
  public stateIndividualVatInfoSelection!: IndividualVatInfoSelection | null
  @State('freelanceVatInfoSelection', { namespace: 'newConnectionCmh/cmhVatInfo' })
  public stateFreelanceVatInfoSelection!: FreelanceVatInfoSelection | null
  @State('companyVatInfoSelection', { namespace: 'newConnectionCmh/cmhVatInfo' })
  public stateCompanyVatInfoSelection!: CompanyVatInfoSelection | null
  @State('fileInfo', { namespace: 'newConnectionCmh/cmhFiles' })
  public stateFileInfo!: FileInfo[]

  @Getter('isElectricitySelected', { namespace: 'newConnectionCmh/offeringsSelection' })
  public isElectricitySelected!: boolean
  @Getter('isGasSelected', { namespace: 'newConnectionCmh/offeringsSelection' })
  public isGasSelected!: boolean
  @Getter('isSwdeSelected', { namespace: 'newConnectionCmh/offeringsSelection' })
  public isSwdeSelected!: boolean
  @Getter('isProximusSelected', { namespace: 'newConnectionCmh/offeringsSelection' })
  public isProximusSelected!: boolean
  @Getter('isVooSelected', { namespace: 'newConnectionCmh/offeringsSelection' })
  public isVooSelected!: boolean
  @Getter('isProximusAvailable', { namespace: 'newConnectionCmh/cmhAddress' })
  public isProximusAvailable!: boolean
  @Getter('isVooAvailable', { namespace: 'newConnectionCmh/cmhAddress' })
  public isVooAvailable!: boolean
  @Getter('isSwdeAvailable', { namespace: 'newConnectionCmh/cmhAddress' })
  public isSwdeAvailable!: boolean
  @Getter('hasDocumentsAttached', { namespace: 'newConnectionCmh/cmhFiles' })
  public hasDocumentsAttached!: boolean
  @Getter('isCmhAvailable', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public isCmhAvailable!: boolean

  public recaptchaToken: string | null = null
  public isSwdeInfoModalOpen: boolean = false

  public applicableVAT: string = ''

  public isHouseSelected: boolean = false
  public isBuildingSelected: boolean = false

  //#endregion

  //#region [Computed]
  public get siteKey() {
    return process.env.VUE_APP_CAPTCHA_SITE_KEY
  }
  public get hasTooManyMetersForCmh(): boolean {
    return !this.isCmhAvailable
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.isHouseSelected = this.stateAddressPoint.selection === BuildingType.house
    this.isBuildingSelected = this.stateAddressPoint.selection === BuildingType.building
    pixelFacebookService.trackViewContent({
      content_ids: ['CmhForm'],
      content_name: 'Summary Step',
      content_type: 'product'
    })
    this.getVATInfo()
  }

  public goToStep(step: number) {
    this.$emit('goToStep', step)
  }

  public getVATInfo() {
    this.applicableVAT = vatService.getVATInfo(this.stateIndividualVatInfoSelection!, this.stateFreelanceVatInfoSelection!, this.stateCompanyVatInfoSelection!)
  }

  getVATLabel(): string {
    return vatService.getVatLabel(this.applicableVAT!)
  }

  public getGasMeterTitle(item: GasMeter) {
    return `${item.count}x ${this.$t('new-connection-cmh.energy-configuration-step.meter')} ${this.$t(
      'new-connection-cmh.energy-configuration-step.naturalGas'
    )}`
  }

  public getGasMeterDescription(item: GasMeter) {
    return `${this.$t('new-connection-cmh.energy-configuration-step.kitchenHeaterHotWater')} - ${this.$t(
      'new-connection-cmh.energy-configuration-step.max'
    )} ${item.power}kW`
  }

  public editBox(identifier: number) {
    /* place holder */
  }

  public formatNumberToLocaleStringFormat(str: number): string {
    return str.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
  }

  public getPriceIsToBeDetermined(): boolean {
    return pricingService.getPriceIsToBeDetermined(this.statePackagedElectricityMeters)
  }

  public get fullPrice() {
    if (this.getPriceIsToBeDetermined() || this.isBuildingSelected) {
      return `${this.$t('new-connection-cmh.offering-selection-step.priceToBeEstimated')}`
    }
    let total = 0
    if (this.statePackagedElectricityMeters.length) {
      total += pricingService.getTotalPackagedElectricityMetersPrice(this.statePackagedElectricityMeters, this.applicableVAT, this.stateAddressPoint.selection)
    }
    if (this.stateModifiedConnections.length) {
      total += pricingService.getTotalModifiedConnectionsPrice(this.stateModifiedConnections, this.applicableVAT)
    }
    return `${pricingService.formatNumberToLocaleStringFormat(total)} €`
  }

  public displayPriceDisclaimer(price: PriceDTO | null): boolean {
    if (price) {
      return price.exVat > 0
    }
    return false
  }

  public async save(next: boolean, valid: boolean): Promise<boolean> {
    if (!next || !valid) {
      return true
    }

    let files: string[] = []
    if (this.stateFileInfo && this.stateFileInfo.length > 0) {
      files = [...files, ...this.stateFileInfo.map((file: FileInfo) => file.name)]
    }

    if (this.stateAddressPoint && this.stateAddressPoint?.addressPoint?.subCity && this.stateContactInfo) {
      const me = this
      function getStepOperatorSelection() {
        const withStepOperatorSelection =
          me.stateAddressPoint!.addressPoint!.subCity.connectMyHomeActivated &&
          me.stateAddressPoint.addressPoint!.subCity.connectMyHomeInformation &&
          me.isCmhAvailable

        if (!withStepOperatorSelection) {
          return null
        }

        return {
          operators: {
            proximus: me.isProximusAvailable ? me.isProximusSelected : null,
            voo: me.isVooAvailable ? me.isVooSelected : null,
            swde: me.isSwdeAvailable ? me.isSwdeSelected : null
          }
        }
      }

      const data = {
        requestId: this.requestId,
        stepAddress: {
          zipCode: this.stateAddressPoint.addressPoint.subCity.zipCode,
          subCityId: this.stateAddressPoint.addressPoint.subCity.georesSubcityId,
          streetSubCityId: this.stateAddressPoint.addressPoint.streetSubCity
            ? this.stateAddressPoint.addressPoint.streetSubCity.georesStreetSubcityId
            : null,
          houseNumber: this.stateAddressPoint.addressPoint.houseNumber,
          isMapLocation: this.stateAddressPoint.addressPoint.isMapLocation,
          mapLocation: this.stateAddressPoint.addressPoint.mapLocation,
          isNonFinalHouseNumber: this.stateAddressPoint.addressPoint.isNonFinalHouseNumber,
          buildingType: this.stateAddressPoint.selection
        },
        stepAttachments: {
          attachDocuments: files.length > 0,
          files
        },
        stepConfigurationInfo: {
          type: 2,
          packagedElectricityMeters: this.statePackagedElectricityMeters,
          modifiedConnections: this.stateModifiedConnections,
          gasMeters: this.stateGasMeters
        },
        stepContactInfo: this.stateContactInfo,
        stepEnergySelection: {
          electricity: this.isElectricitySelected,
          gas: this.isGasSelected
        },
        stepEnergyProductionInfo: {
          hasEnergyProduction: this.cmhEnergyProductionConfiguration.yesNoMaybeSelection,
          kVAPower: this.cmhEnergyProductionConfiguration.kvaPower,
          kwcPower: this.cmhEnergyProductionConfiguration.kwcPower
        },
        stepOperatorSelection: getStepOperatorSelection(),
        stepServicePlus: { servicePlusSelection: null },
        stepVatInfoSelection: {
          individualVatInfoSelection: this.stateIndividualVatInfoSelection,
          freelanceVatInfoSelection: this.stateFreelanceVatInfoSelection,
          companyVatInfoSelection: this.stateCompanyVatInfoSelection
        }
      }
      const response = await this.$api.post('api/worksrequest/new-connection-request', data, {
        headers: {
          'g-recaptcha-response': this.recaptchaToken
        }
      })

      if (response.status >= 200 && response.status < 300) {
        await this.$api.delete(`NewConnectionRequest/${this.requestId}`)
        const trackData: Record<string, string> = {
          Segment: 'WR_NCCMH',
          WebRef: this.requestId,
          postCode: this.stateAddressPoint.addressPoint.subCity.zipCode,
          withCmh: this.stateAddressPoint!.addressPoint.subCity.connectMyHomeActivated.toString(),
          buildingType: this.stateAddressPoint.selection,
          isElectricitySelected: this.isElectricitySelected.toString(),
          isGasSelected: this.isGasSelected.toString(),
          isProximusSelected: this.isProximusSelected.toString(),
          isVooSelected: this.isVooSelected.toString(),
          isSwdeSelected: this.isSwdeSelected.toString(),
          hasTooManyMetersForCmh: this.hasTooManyMetersForCmh.toString(),
          hasEnergyProduction: this.cmhEnergyProductionConfiguration.yesNoMaybeSelection?.toString() ?? '',
          flatNumber: this.stateAddressPoint.flatNumber?.toString() ?? '',
          commonElectricMeterNumber: this.stateAddressPoint.commonElectricMeterNumber?.toString() ?? '',
          hasDocumentsAttached: this.hasDocumentsAttached.toString(),
          meterFileInfoCount: this.stateFileInfo.length.toString(),
          buildingFileInfoCount: '0',
          otherFileInfoCount: '0',
          contactType: this.stateContactInfo.contactType ?? '',
          // electricityMeters: JSON.stringify(this.stateElectricityMeters),
          gasMeters: JSON.stringify(this.stateGasMeters)
        }

        this.$appInsights.trackEvent('WR_NCCMH_Step8_Validated', trackData)

        return true
      } else {
        return false
      }
    }
    return false
  }

  public openSwdeInfoModal() {
    this.isSwdeInfoModalOpen = true
  }
  //#endregion
}


import BatteryCapacityForm from '@/components/inputs/EMobility/Car/BatteryCapacityForm.vue'
import CarTypeSelector from '@/components/inputs/EMobility/Car/CarTypeSelector.vue'
import { CarSpecification, CarType } from '@/models'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  components: {
    CarTypeSelector,
    BatteryCapacityForm
  },
  name: 'car-specification-selector'
})
export default class CarSpecificationSelector extends Vue {
  //#region [Property]
  @Prop({ type: Object }) public readonly value!: CarSpecification | null
  @Prop({ type: Boolean, required: true }) public readonly required!: boolean
  //#endregion

  //#region [Data]
  @State('requestId', { namespace: 'eMobility' })
  public requestId!: string

  public isBatteryModalOpen: boolean = false
  //#endregion

  //#region [Computed]
  public get selection(): CarType | null {
    return this.value?.carType || null
  }

  public set selection(val: CarType | null) {
    this.$emit('input', Object.assign({}, this.value, { carType: val }))
  }

  public get batteryCapacity(): number | null {
    return this.value?.batteryCapacity || null
  }

  public set batteryCapacity(val: number | null) {
    this.$emit('input', Object.assign({}, this.value, { batteryCapacity: val }))
  }
  //#endregion

  //region [Method]
  public onBatteryModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'BatteryCapacity'
    })
    this.isBatteryModalOpen = !this.isBatteryModalOpen
  }

  public trackClick(): void {
    this.$appInsights.trackEvent('EMobility_linkClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Link: 'AutomobilePropre'
    })
  }

  //#endregion
}

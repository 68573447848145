import { CheckEanSmartMeterResult, EanConnectionContractResult, SubCity } from '@/models';
import { MutationTree } from 'vuex';
import { IdentificationState } from './types';

export const mutations: MutationTree<IdentificationState> = {
  STORE(state, data: { subCity: SubCity | null, eanCode: string | null }) {
    state.subCity = data.subCity
    state.eanCode = data.eanCode
  },
  STORE_EAN_RESULT(state, data: CheckEanSmartMeterResult) {
    state.checkEanResult = data
  },
  STORE_CONNECTION_CONTRACT(state, data: EanConnectionContractResult) {
    state.connectionContract = data
  },
  CLEAR(state) {
    state.subCity = null
    state.eanCode = null
    state.checkEanResult = null
    state.connectionContract = null
  }
}

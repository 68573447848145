import { AddressPoint } from '@/models';

export default {
  name: 'address',
  validate: (value: AddressPoint | null): boolean => {
    if (value == null) {
      return true;
    }

    if (value.subCity == null) {
      return false;
    }

    if (value.isMapLocation) {
      return value.mapLocation != null;
    }

    return value.streetSubCity != null && value.houseNumber != null;
  }
};

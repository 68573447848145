import { PowerIncreasePackages } from '@/models';
import { MutationTree } from 'vuex';
import { PowerIncreasePackagesState } from './types';

export const mutations: MutationTree<PowerIncreasePackagesState> = {
  STORE(state, data: PowerIncreasePackages) {
    state.powerIncreasePackages = data.powerIncreasePackages
  },
  CLEAR(state) {
    state.powerIncreasePackages = null
  }
}

import { FilesState } from './types'
import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'

export const getters: GetterTree<FilesState, RootState> = {
  hasDocumentsAttached(state: FilesState, gets, rs: RootState, rg) {
    return (state.rapportFileInfo?.length > 0 || state.schemaFileInfo?.length > 0 || state.pictureFileInfo?.length > 0)
  }
}



import { YesNoMaybeSelection } from '@ores/vue-library'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'resume-energy-production'
})
export default class ResumeEnergyProduction extends Vue {
  @Prop({ type: Number, required: false, default: 0 }) public kVA!: number
  @Prop({ type: Number, required: false, default: 0 }) public kWC!: number
  @Prop({ type: Number, required: true }) public yesNoMaybeSelection!: YesNoMaybeSelection
}

import { actions } from '@/store/new-connection-cmh/actions';
import { getters } from '@/store/new-connection-cmh/getters';
import { mutations } from '@/store/new-connection-cmh/mutations';
import { InitialState, NewConnectionCmhState } from '@/store/new-connection-cmh/types';
import { RootState } from '@/store/types';
import { Module } from 'vuex';
import { cmhAddress } from './cmh-address/index';
import { cmhContact } from './cmh-contact/index';
import { cmhEnergyConfiguration } from './cmh-energy-configuration';
import { cmhEnergyProductionConfiguration } from './cmh-energy-production-configuration/index';
import { cmhFiles } from './cmh-files';
import { cmhVatInfo } from './cmh-vat-info';
import { landingPage } from './landing-page';
import { mailRegistration } from './mail-registration';
import { offeringsSelection } from './offerings-selection';


const namespaced: boolean = true;

export const newConnectionCmh: Module<NewConnectionCmhState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations,
  modules: {
    landingPage,
    mailRegistration,
    cmhAddress,
    offeringsSelection,
    cmhEnergyProductionConfiguration,
    cmhEnergyConfiguration,
    cmhContact,
    cmhVatInfo,
    cmhFiles

  }
}

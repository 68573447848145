
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { ContactType } from '@/models'
import FieldBox from '@/components/inputs/Shared/FieldBox'

@Component({
  name: 'configuration-type-selector'
})
export default class ContactTypeSelector extends FieldBox {
  //#region [Property]
  @Prop({ type: String, required: false }) public readonly value!: ContactType | null
  //#endregion

  //#region [Computed]
  public get selection(): ContactType | null {
    return this.value
  }

  public set selection(val: ContactType | null) {
    this.change(val)
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(value: ContactType | null): ContactType | null {
    return value
  }
  //#endregion
}

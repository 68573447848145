
import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  PhaseSystemStatus,
  WorkRequest,
  WorkFile,
  WorkOfferType,
  WorkRequestType,
  PowerLevel,
  WorkRequestTopic,
  ClientType,
  WorkAppointment,
  Phase
} from '@/models'
import { dateService, timeService } from '@ores/vue-library'

@Component({
  name: 'work-request-summary'
})
export default class WorkRequestSummary extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly workRequest!: WorkRequest
  @Prop({ type: Object, required: true }) public readonly workFile!: WorkFile
  @Prop({ type: Boolean, required: false, default: false }) public readonly notAuthorizedWorkRequest!: boolean
  //#endregion

  //#region [Computed]
  public get showPrice(): boolean {
    return (
      this.workRequest.offerType !== WorkOfferType.WrittenOffer ||
      this.workRequest.phases.some(
        (p) => p.phaseNumber === 3 && (p.status === PhaseSystemStatus.Ongoing || p.status === PhaseSystemStatus.Closed)
      )
    )
  }

  public get level(): string {
    switch (this.workRequest.type) {
      case WorkRequestType.Electricity:
        return 'primary'
      case WorkRequestType.Gas:
        return 'secondary'
      case WorkRequestType.Water:
        return 'success'
      case WorkRequestType.Phone:
        return 'alert'
      case WorkRequestType.Television:
        return 'danger'
      default:
        return ''
    }
  }

  public get workRequestCancelled(): boolean {
    if (
      this.workRequest.offerType === WorkOfferType.VerbalOffer ||
      this.workRequest.offerType === WorkOfferType.WithoutOffer
    ) {
      return (
        (this.workRequest.phases.length === 1 && this.workRequest.phases[0].status === PhaseSystemStatus.Closed) ||
        (this.workRequest.phases.length === 2 && this.workRequest.phases[1].status === PhaseSystemStatus.Closed)
      )
    }

    return false
  }

  public get workType(): string {
    let translatedType = this.$t(`workFileTracking.tracking.workTypeDescription.${this.workRequest.code}`).toString()
    if (translatedType.indexOf('workFileTracking.tracking.workTypeDescription.') > -1) {
      // unsupported type => generic type
      translatedType = this.$t(`workFileTracking.tracking.workTypeDescription.generic`).toString()
    }
    return translatedType
  }

  public get appointment(): WorkAppointment | null {
    const phases = this.workRequest.phases.filter(
      (p) => p.status !== PhaseSystemStatus.Closed && p.appointment !== null
    )

    return phases.length ? phases[phases.length - 1].appointment : null
  }

  public get executionDate(): string | null {
    const appointment = this.appointment
    if (appointment?.executionDate != null) {
      return `${dateService.displayDateText(appointment.executionDate)} ${this.$t(
        'workFileTracking.tracking.timeSlot.' + appointment.executionTimeSlot
      )}`
    }
    return null
  }

  public get status(): string {
    const phase = this.workRequest.phases.find((p) => p.status === PhaseSystemStatus.Ongoing)
    if (phase) {
      const status = this.$t(
        `workFileTracking.tracking.${this.workRequest.offerType}.name.${phase.status}.${phase.phaseNumber}`
      ).toString()
      return `${status[0].toLowerCase()}${status.substring(1)}`
    }

    return this.workRequestCancelled
      ? (this.$t(`workFileTracking.tracking.${this.workRequest.offerType}.name.cancelled`) as string)
      : (this.$t(`workFileTracking.tracking.${this.workRequest.offerType}.name.closed.text`) as string)
  }

  public get price(): string {
    if (this.workRequest.offerType === WorkOfferType.VerbalOffer) {
      return `${this.workRequest.calculatedPrice}${this.$t('workFileTracking.tracking.euroVatInvoiceAfterWork')}`
    }

    if (this.workRequest.offerType === WorkOfferType.WrittenOffer) {
      return `${this.workRequest.calculatedPrice}${this.$t('workFileTracking.tracking.euroVat')}`
    }

    return this.$t('workFileTracking.tracking.free').toString()
  }

  public get outOfClick(): boolean {
    const hours = timeService.getBelgianTime().hours
    return hours < 7 || hours >= 23
  }

  public get canModifyCancelAppointmentBusinessCondition(): boolean {
    const appointment = this.appointment

    if (!appointment?.executionDate) {
      return false
    }

    if (appointment?.hasAssignedEmployee || dateService.diffDate(new Date(), appointment.executionDate) < 14) {
      return false
    }

    if (this.workRequest.type === WorkRequestType.Unknown) {
      return false
    }

    if (this.workRequest.powerLevel === PowerLevel.Unknown) {
      return false
    }

    return true
  }

  public get canModifyCancelAppointment(): boolean {
    return this.outOfClick || this.canModifyCancelAppointmentBusinessCondition
  }

  public get hasSmartMeterGovernmentMessage(): boolean {
    return (
      this.workFile.topic === WorkRequestTopic.SmartMeter &&
      this.workFile.clientType === ClientType.OwnerTenantUsufructuary
    )
  }
  //#endregion

  //#region [Method]
  public modifyDate() {
    this.$emit('modifyDate')
  }

  public cancelWork() {
    this.$emit('cancelWork')
  }
  //#endregion
}

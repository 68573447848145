import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { apiErrors } from './api-errors';
import { constructionConnection } from './construction-connection';
import { dialogs } from './dialogs';
import { eMobility } from './e-mobility';
import { mutations } from './mutations';
import { newConnectionCmh } from './new-connection-cmh/index';
import { otherEquipmentConnection } from './other-equipment-connection';
import { smartMeterConnection } from './smart-meter-connection';
import { synergridConnection } from './synergrid-connection';
import { temporaryConnection } from './temporary-connection';
import { prosumerBonus } from './prosumer-bonus';
import { workFileTracking } from './work-file-tracking';
import { chargingStationDeclaration } from './charging-station-declaration';
import { prosumerDeclaration } from './prosumer-declaration';
import { RootState } from './types';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    appVersion: '0.1.0',
    lang: 'fr',
    isBack: false
  },
  modules: {
    prosumerBonus,
    eMobility,
    newConnectionCmh,
    constructionConnection,
    temporaryConnection,
    synergridConnection,
    smartMeterConnection,
    otherEquipmentConnection,
    workFileTracking,
    chargingStationDeclaration,
    prosumerDeclaration,
    apiErrors,
    dialogs
  },
  mutations
}

export default new Vuex.Store<RootState>(store)


import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { routes } from '@/router'
import { localize } from 'vee-validate'

@Component({
  name: 'home'
})
export default class Home extends Vue {
  //#region [Data]
  @Prop({ type: String, required: true }) public readonly lang!: string
  //#endregion

  //#region [Watch]
  @Watch('lang')
  public onLangChanged(value: string) {
    this.$i18n.locale = value
    localize(value)
    this.$store.commit('SET_LANGUAGE', value)
  }
  //#endregion

  //#region [Method]
  public getUrl(lang: string, form: string): string {
    return routes.find((r) => r.name === form)!.paths[lang]
  }
  //#endregion
}


import { SubCity } from '@/models'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

interface Styles {
  style: 'green' | 'orange'
  logo: string
  messages: string[]
}

@Component
export default class ConnectionPointArea extends Vue {
  //#region [Property]
  @Prop(Object) public readonly subCity!: SubCity | null
  @Prop(Boolean) public readonly checkForGas?: boolean
  @Prop(Boolean) public readonly checkForElec?: boolean
  @Prop(Boolean) public readonly hideSuccessMessages?: boolean
  @Prop(Boolean) public readonly showSynergridLink?: boolean
  @Prop(Boolean) public readonly hideOresManagerMessage?: boolean
  //#endregion

  //#region [Data]
  public loading: boolean = false
  public areaInformation: SubCity | null = null
  //#endregion

  //#region [Computed]
  get energyManagerMessages(): Styles[] {
    // [ { style : « orange/green », logo : « icon-elec/icon-gaz/icon-both », messages : [ …, …] }
    if (!this.checkForElec && !this.checkForGas) {
      return []
    }
    const info = this.areaInformation
    if (!info) {
      return []
    }

    const allowGas = info.gridManagers.gasManager && info.gridManagers.gasManager.isOres
    const allowElec = info.gridManagers.electricityManager && info.gridManagers.electricityManager.isOres

    this.$emit('canAllowGas', allowGas)
    this.$emit('canAllowElec', allowElec)
    this.$emit('canAllowElecOrGas', allowGas || allowElec)

    // 1. operator?
    if (!info.gridOperators || !info.gridOperators.isInOresScope) {
      if (this.checkForElec && this.checkForGas) {
        return [
          {
            style: 'orange',
            logo: 'fas fa-thumbs-down',
            messages: ['oresNetwork.oresElecGasNotManager', 'oresNetwork.findManager']
          }
        ]
      } else if (this.checkForElec) {
        // check for elec only
        return [
          {
            style: 'orange',
            logo: 'fas fa-thumbs-down',
            messages: ['oresNetwork.oresElecNotManager', 'oresNetwork.findManager']
          }
        ]
      } else {
        // check for gas only
        return [
          {
            style: 'orange',
            logo: 'fas fa-thumbs-down',
            messages: ['oresNetwork.oresGasNotManager', 'oresNetwork.findManager']
          }
        ]
      }
    }

    const isGasManager = info.gridManagers.gasManager && info.gridManagers.gasManager.isOres
    const isElecManager = info.gridManagers.electricityManager && info.gridManagers.electricityManager.isOres

    // ORES is operator
    if (this.checkForElec) {
      // 2. electricity manager ?
      if (isElecManager) {
        if (this.checkForGas) {
          // 3. gas manager ?
          if (isGasManager) {
            if (!this.hideSuccessMessages) {
              return [{ style: 'green', logo: 'fas fa-thumbs-up', messages: ['oresNetwork.oresElecGasManager'] }]
            }
          } else {
            // ores not gas manager
            if (this.hideSuccessMessages) {
              return [
                {
                  style: 'orange',
                  logo: 'fas fa-thumbs-down',
                  messages: ['oresNetwork.oresGasNotManager', 'oresNetwork.findManager']
                }
              ]
            } else {
              return [
                { style: 'green', logo: 'fas fa-thumbs-up', messages: ['oresNetwork.oresElecManager'] },
                {
                  style: 'orange',
                  logo: 'fas fa-thumbs-down',
                  messages: ['oresNetwork.oresGasNotManager', 'oresNetwork.findManager']
                }
              ]
            }
          }
        } else {
          // don't check for gas
          if (!this.hideSuccessMessages) {
            return [{ style: 'green', logo: 'fas fa-thumbs-up', messages: ['oresNetwork.oresElecManager'] }]
          }
        }
      } else {
        // ores not elec manager
        if (this.checkForGas) {
          // 3. gas manager ?
          if (isGasManager) {
            if (this.hideSuccessMessages) {
              return [
                {
                  style: 'orange',
                  logo: 'fas fa-thumbs-down',
                  messages: ['oresNetwork.oresElecNotManager', 'oresNetwork.findManager']
                }
              ]
            } else {
              return [
                {
                  style: 'orange',
                  logo: 'fas fa-thumbs-down',
                  messages: ['oresNetwork.oresElecNotManager', 'oresNetwork.findManager']
                },
                { style: 'green', logo: 'fas fa-thumbs-up', messages: ['oresNetwork.oresGasManager'] }
              ]
            }
          } else {
            // not gas manager
            return [
              {
                style: 'orange',
                logo: 'fas fa-thumbs-down',
                messages: ['oresNetwork.oresElecGasNotManager', 'oresNetwork.findManager']
              }
            ]
          }
        } else {
          return [
            {
              style: 'orange',
              logo: 'fas fa-thumbs-down',
              messages: ['oresNetwork.oresElecNotManager', 'oresNetwork.findManager']
            }
          ]
        }
      }
    } else {
      // no check for elec
      if (this.checkForGas) {
        // 3. gas manager ?
        if (isGasManager) {
          if (!this.hideSuccessMessages) {
            return [{ style: 'green', logo: 'fas fa-thumbs-up', messages: ['oresNetwork.oresGasManager'] }]
          }
        } else {
          // ores not gas manager
          return [
            {
              style: 'orange',
              logo: 'fas fa-thumbs-down',
              messages: ['oresNetwork.oresGasNotManager', 'oresNetwork.findManager']
            }
          ]
        }
      }
    }

    return []
  }
  //#endregion

  //#region [Watch]
  @Watch('subCity')
  public onSubCityChanged(subCity: SubCity | null) {
    this.$nextTick(() => {
      this.fetchAreaInformation()
    })
  }
  //#endregion

  //#region [Method]
  public mounted(): void {
    if (this.subCity !== null) {
      this.fetchAreaInformation()
    }
  }

  public fetchAreaInformation(): void {
    if (this.subCity === null) {
      this.areaInformation = null
      return
    }

    this.areaInformation = this.subCity
  }
  //#endregion
}

import { MutationTree } from 'vuex';
import { PersonalInfoState } from './types';
import { PersonalInfo } from '@/models';

export const mutations: MutationTree<PersonalInfoState> = {
  STORE(state, data: PersonalInfo) {
    state.personalInfo = data
  },
  CLEAR(state) {
    state.personalInfo = null
  }
}

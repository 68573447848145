import { MutationTree } from 'vuex';
import { AttachmentState } from './types';

export const mutations: MutationTree<AttachmentState> = {
  STORE(state, data: AttachmentState) {
    state.attachDocuments = data.attachDocuments
    state.meterLocationFilesInfo = data.meterLocationFilesInfo
    state.buildingLocationFilesInfo = data.buildingLocationFilesInfo
    state.otherFilesInfo = data.otherFilesInfo
  },
  CLEAR(state) {
    state.attachDocuments = false
    state.meterLocationFilesInfo = []
    state.buildingLocationFilesInfo = []
    state.otherFilesInfo = []
  }
}

import { CarSpecification, ElectricVehicle } from '@/models';

export interface CarSpecificationState {
  carSpecification: CarSpecification | null
  electricVehicle: ElectricVehicle | null
}

export const InitialState: CarSpecificationState = {
  carSpecification: null,
  electricVehicle: null
}

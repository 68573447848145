
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import IndividualVatInfoSelector from '@/components/inputs/Vat/IndividualVatInfoSelector.vue'
import FreelanceVatInfoSelector from '@/components/inputs/Vat/FreelanceVatInfoSelector.vue'
import CompanyVatInfoSelector from '@/components/inputs/Vat/CompanyVatInfoSelector.vue'
import {
  IndividualVatInfoSelection,
  FreelanceVatInfoSelection,
  CompanyVatInfoSelection
} from '@/models'

@Component({
  name: 'vat-info-selector',
  components: {
    IndividualVatInfoSelector,
    FreelanceVatInfoSelector,
    CompanyVatInfoSelector
  }
})
export default class VatInfoSelector extends Vue {


  //#region [Property]
  @Prop({ type: String, required: true }) public readonly type!: string
  @Prop({ type: Boolean, default: false }) public readonly required!: boolean
  @PropSync('individual', {
    type: Number,
    required: false,
    default: null,
    validator: (v: IndividualVatInfoSelection | null) => v == null || +v in IndividualVatInfoSelection
  })
  public readonly individualVatInfoSelection!: IndividualVatInfoSelection | null
  @PropSync('freelance', {
    type: Number,
    required: false,
    default: null,
    validator: (v: FreelanceVatInfoSelection | null) => v == null || +v in FreelanceVatInfoSelection
  })
  public readonly freelanceVatInfoSelection!: FreelanceVatInfoSelection | null
  @PropSync('company', {
    type: Object,
    required: false,
    default: null
  })
  public readonly companyVatInfoSelection!: CompanyVatInfoSelection | null
  //#endregion

  //#region [Computed]
  public get isFreelance() {
    return this.type === 'freelance'
  }

  public get isIndividual() {
    return this.type === 'individual'
  }

  public get isCompany() {
    return this.type === 'company'
  }

  //#endregion
}

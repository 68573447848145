
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import { ProductionSource, TechnicalInformation } from '@/models/prosumer-declaration/prosumer-declaration'

@Component({
  name: 'technical-information-component'
})
export default class TechnicalInformationComponent extends Vue {
  @Prop({ type: Object, required: true }) public readonly value!: TechnicalInformation
  public productionSources: ProductionSource[] = []
  public productionSourcesValue: string |undefined=''
  public totalPowerModalOpen: boolean = false
  public maxOutputPowerModalOpen: boolean = false
  public powerInverterModalOpen: boolean = false

  public mounted() {
    this.fetchProductionSources()
    this.productionSourcesValue= this.value.productionSource?.value
  }

  public async fetchProductionSources() {
    const response = await this.$api.get<string[]>('ProsumerDeclaration/electricity-production-sources')
    this.productionSources = response.data.map((s) => {
      return { text: this.$tc(`prosumerDeclaration.productionSources.${s}`), value: s }
    })
  }

  public addInverter() {
    this.value.powerInverters.push({ brand: null, model: null })
  }

  public selectProductionsource(source:string) {
    this.value.productionSource = this.productionSources.find(s=>s.value === source)!
    console.log(source)
  }

  public removeInverter(index: number) {
    this.value.powerInverters.splice(index, 1)
  }

  public createDefaultSource(text: string | null): ProductionSource | null {
    if (!text) {
      return null
    }

    return {
      text,
      value: text
    }
  }

  public get maxOutputPowerNote(): string | null {
    if (this.value && this.value.maxOutputPower && this.value.maxOutputPower > 5) {
      return this.$tc('prosumerDeclaration.installation.maxOutputPowerNote')
    }
    return null
  }

  @Watch('value.productionSource', { deep: true })
  public onProductionSourceChanged(newVal: ProductionSource) {
    if (!newVal || newVal.value !== 'photovoltaic') {
      this.value.totalPower = null
    }
  }
}

import {
  ConstructionElectricityMeter,
  MeterElectricityTension,
  MeterElectricityConfiguration,
  TemporaryElectricityMeter,
  OtherEquipmentElectricityMeter
} from '@/models';
import { helper } from '@ores/vue-library';

const electricityConfiguration: MeterElectricityConfiguration = {
  mono230V: [16, 20, 25, 32, 40, 50],
  tri230V: [16, 20, 25, 32, 40, 42, 45, 48, 50, 51, 54, 57, 60, 63, 66, 69, 72, 75, 80, 85, 90, 95, 100],
  tri400V: [16, 20, 25, 32, 40, 42, 45, 48, 50, 51, 54, 57, 60, 63, 66, 69, 70, 72, 75, 80, 85, 90, 95,
    100, 110, 120, 125, 135, 145, 150, 160, 175, 190, 200, 215, 225, 240, 250],
  custom: []
}

const tempElectricityConfiguration: MeterElectricityConfiguration = {
  mono230V: [16, 32],
  tri230V: [32, 63, 125],
  tri400V: [32, 63, 125],
  custom: []
}

const otherEquipmentElectricityConfiguration = tempElectricityConfiguration

/**
 * Get the intensities (in ampere)
 * @param tension (in volts)
 * @param configuration (specific configuration)
 */
function getIntensities(tension: MeterElectricityTension, configuration?: MeterElectricityConfiguration): number[] {
  return helper.getByKey(configuration || electricityConfiguration, tension)
}

/**
 * Gets the powers (in kVA)
 * @param tension (in volts)
 * @param intensity (in ampere)
 * @param configuration (specific configuration)
 */
function getPower(
  tension: MeterElectricityTension,
  intensity: number
): number {
  let phase: number
  let volt: number

  switch (tension) {
    case 'mono230V':
      phase = 1
      volt = 230
      break
    case 'tri230V':
      phase = Math.sqrt(3)
      volt = 230
      break
    case 'tri400V':
      phase = Math.sqrt(3)
      volt = 400
      break
    case 'custom':
    default:
      return -1
  }

  return +(volt * phase * intensity / 1000).toFixed(1)
}


function getDefaultConstructionElectricityMeter(tension: MeterElectricityTension): ConstructionElectricityMeter {
  const ampere: number = getIntensities(tension)[0]
  const kva: number = getPower(tension, ampere)

  return {
    tension,
    power: {
      ampere,
      kva
    },
    use: null,
    type: null,
    wantEmergencyPower: false
  }
}

function getDefaultTemporaryElectricityMeter(tension: MeterElectricityTension): TemporaryElectricityMeter {
  const ampere: number = getIntensities(tension, tempElectricityConfiguration)[0]
  const kva: number = getPower(tension, ampere)

  if (tension === 'custom') {
    throw new Error(`The tension ${tension} isn't managed for temporary connection`)
  }

  return {
    tension,
    power: {
      ampere,
      kva
    },
    use: null
  }
}

function getDefaultOtherEquipmentElectricityMeter(tension: MeterElectricityTension): OtherEquipmentElectricityMeter {
  const ampere: number = getIntensities(tension, otherEquipmentElectricityConfiguration)[0]
  const kva: number = getPower(tension, ampere)

  if (tension === 'custom') {
    throw new Error(`The tension ${tension} isn't managed for temporary connection`)
  }

  return {
    count: 1,
    tension,
    power: {
      ampere,
      kva
    },
    type: null
  }
}

function validateConstructionElectricityMeter(meter: ConstructionElectricityMeter): boolean {
  if (meter.tension === 'custom') {
    return meter.power !== null && meter.power.kva > 0
  }

  return meter.type !== null && meter.power !== null && meter.power.ampere > 0 && meter.power.kva > 0
}

function validateTemporaryElectricityMeter(meter: TemporaryElectricityMeter): boolean {
  return meter.power !== null && meter.power.ampere > 0 && meter.power.kva > 0
}

export const meterService = {
  tempElectricityConfiguration,
  otherEquipmentElectricityConfiguration,
  electricityConfiguration,
  getIntensities,
  getPower,
  getDefaultConstructionElectricityMeter,
  getDefaultTemporaryElectricityMeter,
  getDefaultOtherEquipmentElectricityMeter,
  validateConstructionElectricityMeter,
  validateTemporaryElectricityMeter
}

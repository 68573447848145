
import { CmhAddressPoint } from '@/models/cmh-address-point';
import { CmhAddressState } from '@/store/new-connection-cmh/cmh-address/types';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';

export const actions: ActionTree<CmhAddressState, RootState> = {
  validate({ commit, state }, address: CmhAddressPoint): boolean {
    if (address.selection !== state.addressPoint?.selection) {
      commit('STORE', address.selection)
      return false;
    }
    return false;
  }
}

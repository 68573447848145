import { MutationTree } from 'vuex';
import { ConsumingDeviceState } from './types';

export const mutations: MutationTree<ConsumingDeviceState> = {
  STORE(state, data: ConsumingDeviceState) {
    state.consumingDevices = data.consumingDevices
  },
  STORE_DEVICE_SELECTION(state, data: ConsumingDeviceState) {
    state.consumingDeviceSelection = data.consumingDeviceSelection
  },
  CLEAR(state) {
    state.consumingDevices = null
    state.consumingDeviceSelection = []
  }
}

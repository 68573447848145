
import { ElectricityInstallation, MeterType } from '@/models'
import { Component, Prop } from 'vue-property-decorator'
import FieldBox from '../../Shared/FieldBox'
import { State } from 'vuex-class'

@Component({
  name: 'electricity-installation'
})
export default class ElectricityInstallationForm extends FieldBox {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly value!: ElectricityInstallation | null
  //#endregion

  //#region [Data]
  @State('requestId', { namespace: 'eMobility' })
  public requestId!: string

  public isElectricityPowerSupplyTypeModalOpen: boolean = false
  public isPowerModalOpen: boolean = false
  public isMonoModalOpen: boolean = false
  public isThreeModalOpen: boolean = false
  public isTetraModalOpen: boolean = false
  //#endregion

  //#region [Computed]
  public get isMechanicalMeter() {
    return this.value?.meterType === MeterType.mechanicalMeter
  }
  //#endregion

  //#region [Method]
  public onElectricityPowerSupplyTypeModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'PowerSupplyType'
    })
    this.isElectricityPowerSupplyTypeModalOpen = !this.isElectricityPowerSupplyTypeModalOpen
  }
  public onPowerModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'Power'
    })
    this.isPowerModalOpen = !this.isPowerModalOpen
  }
  public onMonoModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'MonophaseTile'
    })
    this.isMonoModalOpen = !this.isMonoModalOpen
  }
  public onThreeModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'ThreephaseTile'
    })
    this.isThreeModalOpen = !this.isThreeModalOpen
  }
  public onTetraModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'TetraphaseTile'
    })
    this.isTetraModalOpen = !this.isTetraModalOpen
  }
  //#endregion
}

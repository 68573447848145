
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import { idGenerator } from '@/services'
import { EnhanceRequestInfo } from '@/models'

@Component({
  name: 'synergrid-enhance-request-form',
})
export default class SynergridEnhanceRequestForm extends Vue {

  //#region [Property]
  @Prop({ type: Object, required: true })
  public readonly value!: EnhanceRequestInfo
  //#endregion

  //#region [Data]
  public fieldId: string = idGenerator.getId()
  //#endregion
}


import NoGridAccessPointInfo from '@/components/display/EMobility/NoGridAccessPointInfo.vue'
import ResumeGridAccessPointInfo from '@/components/display/EMobility/ResumeGridAccessPointInfo.vue'
import ElectricityInstallationForm from '@/components/inputs/EMobility/ElectricityInstallationForm/Installation.vue'
import { ConsumingDevice, ElectricityInstallation, ElectricMobilityGridAccessPointInfo } from '@/models'
import { helper } from '@/services'
import { AxiosResponse } from 'axios'
import { Component, Watch } from 'vue-property-decorator'
import { Getter, Mutation, State } from 'vuex-class'
import Step from '../Step'

@Component({
  name: 'electricity-specification-step',
  components: {
    ResumeGridAccessPointInfo,
    ElectricityInstallationForm,
    NoGridAccessPointInfo
  }
})
export default class GridAccessPointInfoOrInstallationStep extends Step {
  //#region [Data]
  @State('gridAccessPointInfo', { namespace: 'eMobility/identification' })
  public stateGridAccessPointInfo!: ElectricMobilityGridAccessPointInfo | null
  @State('electricityInstallation', { namespace: 'eMobility/electricityInstallation' })
  public stateElectricityInstallation!: ElectricityInstallation
  @State('consumingDevices', { namespace: 'eMobility/consumingDevices' })
  public stateConsumingDevices!: ConsumingDevice[]
  @State('requestId', { namespace: 'eMobility' })
  public requestId!: string

  public electricityInstallation: ElectricityInstallation = {
    meterType: null,
    electricityPowerSupplyType: null,
    power: null
  }

  public loading: boolean = false

  public isMeterTypeModalOpen: boolean = false
  //#endregion

  //#region [Getter]
  @Getter('getHasEan', { namespace: 'eMobility/identification' })
  public getHasEan!: string
  //#endregion

  //region [Mutation]
  @Mutation('STORE', { namespace: 'eMobility/electricityInstallation' })
  public store!: (value: ElectricityInstallation) => void
  @Mutation('STORE', { namespace: 'eMobility/consumingDevices' })
  public storeDevices!: (index: { consumingDevices: ConsumingDevice[] | null }) => void
  @Mutation('STORE_HAS_EAN', { namespace: 'eMobility/identification' })
  public storeHasEan!: (index: { hasEan: string | null }) => void
  //#endregion

  //region [Method]
  public mounted() {
    this.electricityInstallation = helper.clone(this.stateElectricityInstallation)
  }

  public async getConsumingDevice() {
    const response = await this.$api.get<ConsumingDevice, AxiosResponse<ConsumingDevice[]>>(
      `api/ElectricMobility/energy-consuming-devices`,
      {
        cache: {
          ignoreCache: false
        }
      }
    )

    return response.data
  }

  public onMeterTypeModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'MeterType'
    })
    this.isMeterTypeModalOpen = !this.isMeterTypeModalOpen
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (isValid && next) {
      if (!this.stateConsumingDevices) {
        this.loading = true
        const data = await this.getConsumingDevice()
        this.storeDevices({ consumingDevices: data })
        this.loading = false
      }

      this.$appInsights.trackEvent('EMobility_StepElectricInstallation_Validated', {
        Segment: 'EMobility',
        WebRef: this.requestId,
        IdentificationByEan: 'false'
      })
    }
    this.store(this.electricityInstallation)
  }
  //#endregion
}

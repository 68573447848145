import { MutationTree } from 'vuex'
import { InstallationState, InitialState } from './types'
import { BatteryInformation, RelayInformation, TechnicalInformation } from '@/models/prosumer-declaration/prosumer-declaration'

export const mutations: MutationTree<InstallationState> = {
  STORE_INSTALLATION(state, data: TechnicalInformation) {
    state.installation.technicalInformation = data
  },
  STORE_BATTERY(state, data: BatteryInformation) {
    state.installation.battery = data
  },
  STORE_RELAY(state, data: RelayInformation) {
    state.installation.relay = data
  },
  CLEAR(state) {
    state = Object.assign(state, InitialState)
  }
}

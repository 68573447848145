import { AxiosInstance, AxiosError, AxiosRequestConfig } from 'axios';
import { Store } from 'vuex';
import { RootState } from '@/store/types';

export function addRequestInterceptors(axios: AxiosInstance, store: Store<RootState>): void {
  axios.interceptors.request.use((request) => {
    request.headers['Accept-Language'] = store.state.lang
    return request
  })
}

export function removeInterceptors(axios: AxiosInstance, id: number): void {
  axios.interceptors.response.eject(id)
}

import { ConsumingDevice } from '@/models';


export interface ConsumingDeviceState {
  consumingDevices: ConsumingDevice[] | null
  consumingDeviceSelection: ConsumingDevice[] | null
}

export const InitialState: ConsumingDeviceState = {
  consumingDevices: null,
  consumingDeviceSelection: []
}


import Step from '@/components/steps/Step'
import Component from 'vue-class-component'
import WorkFileIdentificationForm from '@/components/inputs/WorkFileTracking/WorkFileIdentificationForm.vue'
import { GetWorkFileResponse, WorkFile, WorkFileIdentification } from '@/models'
import { Mutation, State } from 'vuex-class'
import { helper, refreshMtCaptcha } from '@/services'

@Component({
  name: 'work-file-identification-step',
  components: { WorkFileIdentificationForm }
})
export default class WorkFileIdentificationStep extends Step {
  //#region [Data]
  @State('identification', { namespace: 'workFileTracking/identification' })
  public identificationState!: WorkFileIdentification | null

  public identification: WorkFileIdentification | null = null
  public identificationForbidden: boolean = false
  public recaptcha: string | null = null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'workFileTracking/identification' })
  private store!: (index: WorkFileIdentification | null) => void

  @Mutation('STORE', { namespace: 'workFileTracking/workFile' })
  private storeFile!: (index: WorkFile | null) => void
  //#endregion
  public get siteKey() {
    return process.env.VUE_APP_CAPTCHA_SITE_KEY
  }
  //#region [Method]
  public mounted() {
    this.identification = helper.clone(this.identificationState)
    this.setIsBack(false)
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid && this.identification) {
      const data = {
        worksFileId: this.identification.worksFileId!.trim(),
        zipCode: this.identification.zipCode!.trim()
      }
      const response = await this.$api.get(`api/WorksRequest/get-work-file/${data.worksFileId}/${data.zipCode}`, {
        headers: {
          'g-recaptcha-response': this.recaptcha
        }
      })

      if (response.status === 403) {
        this.identificationForbidden = true
        refreshMtCaptcha()
        return false
      }

      if (response.status !== 200) {
        this.$store.dispatch('apiErrors/customManage', 'contactCallCenter')
        refreshMtCaptcha()
        return false
      }

      const workFileResponse: GetWorkFileResponse = response.data
      sessionStorage.setItem('token', workFileResponse.token)

      this.$appInsights.trackEvent('WorkFileTracking_StepIdentification_Validated', {
        Segment: 'WorkFileTracking',
        ZipCode: this.identification.zipCode!.trim(),
        WorksFileId: this.identification.worksFileId!.trim(),
        WorkRequestCode: workFileResponse.workFile.workRequests[0].code
      })

      this.store(this.identification)
      this.storeFile(workFileResponse.workFile)
      return true
    }

    return false
  }
  //#endregion
}

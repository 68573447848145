import { ElectricMobilityGridAccessPointInfo } from '@/models';
import { MutationTree } from 'vuex';
import { IdentificationState } from './types';

export const mutations: MutationTree<IdentificationState> = {
  STORE(state, data: { eanCode: string | null }) {
    state.eanCode = data.eanCode
  },
  STORE_HAS_EAN(state, data: { hasEan: string | null }) {
    state.hasEan = data.hasEan
  },
  STORE_GRID_ACCESS_POINT_INFO(state, data: ElectricMobilityGridAccessPointInfo) {
    state.gridAccessPointInfo = data
  },
  STORE_GRID_ACCESS_POINT_EAN_CODE(state, data: string) {
    state.gridAccessPointEanCode = data
  },
  STORE_GRID_ACCESS_POINT_NOK_REASON(state, data: string) {
    state.gridAccessPointNokReason = data
  },
  STORE_TOKEN(state, data: string | null) {
    state.token = data
  },
  CLEAR(state) {
    state.hasEan = null
    state.eanCode = null
    state.gridAccessPointInfo = null
    state.gridAccessPointEanCode = null
    state.token = null
  }
}

import { CmhAddressPoint } from '@/models/cmh-address-point'
import { CmhAddressState } from '@/store/new-connection-cmh/cmh-address/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<CmhAddressState> = {
  STORE(state, data: CmhAddressPoint) {
    state.addressPoint = data
  },
  CLEAR(state) {
    state.addressPoint = null
  }
}

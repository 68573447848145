import { GetterTree } from 'vuex'
import { IdentificationState } from './types';
import { RootState } from '@/store/types';
import { MeterType } from '@/models';

export const getters: GetterTree<IdentificationState, RootState> = {
  getHasEan: (state: IdentificationState) => state.hasEan === 'yes' ? true : false,
  getEanCode: (state: IdentificationState) => state.eanCode,
  getElectricityPowerSupplyType: (state: IdentificationState) => {
    if (state.gridAccessPointInfo?.electricMobilityMeterInfos &&
      state.gridAccessPointInfo.electricMobilityMeterInfos[0].electricityPowerSupplyType) {
      return state.gridAccessPointInfo.electricMobilityMeterInfos[0].electricityPowerSupplyType.toLocaleLowerCase() || ''
    } else {
      return null
    }
  },
  getMeterType: (state: IdentificationState) => {
    if (state.gridAccessPointInfo?.electricMobilityMeterInfos) {
      return state.gridAccessPointInfo.electricMobilityMeterInfos[0].isSmartMeter ?
        MeterType.smartMeter : MeterType.mechanicalMeter
    }
  },
  getAmperage: (state: IdentificationState) => {
    if (state.gridAccessPointInfo?.electricMobilityMeterInfos) {
      return state.gridAccessPointInfo.electricMobilityMeterInfos[0].amperage
    }
  }
}



import { Vue, Watch, Prop, Component, Inject } from 'vue-property-decorator'
import { idGenerator } from '@/services'
import { MapLocation } from '@/models'
import 'googlemaps'

interface Location {
  lat: number | null
  lng: number | null
}

@Component
export default class MapLocationPicker extends Vue {
  //#region [Property]
  @Prop(Object) public readonly value!: MapLocation | null
  @Prop(String) public readonly address?: string | undefined
  //#endregion

  //#region [Data]
  public fieldId: string = idGenerator.getId()
  public geocoder: google.maps.Geocoder = new google.maps.Geocoder()
  public geocodeStatus: google.maps.GeocoderStatus | null = null
  public mapOptions: google.maps.MapOptions = {
    center: undefined,
    streetViewControl: false,
    mapTypeControl: true,
    zoom: 14
  }
  public mapObject: google.maps.Map | null = null
  public infowindow: google.maps.InfoWindow = new google.maps.InfoWindow()
  public location: Location = {
    lat: null,
    lng: null
  }
  public marker: google.maps.Marker | null = null
  //#endregion

  //#region [Watch]
  @Watch('location', { deep: true })
  public onLocationChanged(val: MapLocation, oldVal: MapLocation) {
    if (val.lat !== null && val.lng !== null) {
      this.$emit('input', { lat: val.lat, lng: val.lng } as MapLocation)
    }
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.initialize()
  }

  public updateValue(value: MapLocation) {
    this.$emit('input', value)
  }

  public initialize() {
    this.geocoder.geocode(
      { address: this.address },
      (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        this.geocodeStatus = status

        if (status !== google.maps.GeocoderStatus.OK) {
          return
        }

        const location =
          this.value !== null ? new google.maps.LatLng(this.value.lat, this.value.lng) : results[0].geometry.location

        this.mapOptions.center = location

        this.mapObject = new google.maps.Map(document.getElementById(this.fieldId)!, this.mapOptions)

        this.marker = new google.maps.Marker({
          position: location,
          draggable: true,
          map: this.mapObject,
          title: ''
        })

        this.setLocation(location.lat(), location.lng())

        google.maps.event.addListener(this.marker!, 'dragend', (e) => {
          this.setLocation(e.latLng.lat(), e.latLng.lng())
        })

        google.maps.event.addListener(this.mapObject, 'click', (e) => {
          this.setLocation(e.latLng.lat(), e.latLng.lng())
          if (this.marker) {
            this.marker.setPosition(e.latLng)
          }
        })

        //// Manage map screen ratio dynamicly
        // $(window).resize(function () {
        //    var mapWidth = that.googleMap.width();
        //    var mapHeigth = mapWidth / that.MAP_HEIGHT_RATIO.width * that.MAP_HEIGHT_RATIO.height;
        //    that.googleMap.height(mapHeigth);
        // });
        // $(window).resize();
      }
    )
  }

  public setLocation(lat: number, lng: number) {
    this.location.lat = lat
    this.location.lng = lng
    this.infowindow.setContent('LAT : ' + lat + '<br>LNG : ' + lng)
    this.infowindow.open(this.mapObject || undefined, this.marker || undefined)
  }

  public getMessageFromGeocodeStatus(status: string) {
    let message = 'Google API Error: '
    switch (status) {
      case 'OK':
        message += 'Ok'
        break
      case 'ZERO_RESULTS':
        message += 'Zero results'
        break
      case 'OVER_QUERY_LIMIT':
        message += 'Over query limit'
        break
      case 'REQUEST_DENIED':
        message += 'Request denied'
        break
      case 'INVALID_REQUEST':
        message += 'Invalid request'
        break
      case 'UNKNOWN_ERROR':
      default:
        message += 'Unknown error'
        break
    }
    return message
  }
  //#endregion
}


import { Component, Vue, Prop } from 'vue-property-decorator'
import { vatService } from '@/services/index'
import { ModifiedConnection } from '@/models'

@Component({
  name: 'add-connection-summarized',
  components: {

  }
})
export default class AddConnectionSummarized extends Vue {
  @Prop({ type: Object, required: true }) public meter!: ModifiedConnection
  @Prop({ type: String, required: true }) public applicableVAT!: string

  getVATLabel(): string {
    return vatService.getVatLabel(this.applicableVAT!)
  }

  getVATPriceForAdditionnalConnection(): number {
    return vatService.getVATPriceForAdditionnalConnection(this.meter!, this.applicableVAT!)
  }

  getMeterCount(): number {
    return this.meter.count ? this.meter.count : 0
  }
}

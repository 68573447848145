
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ContactInfo } from '@/models'
import { contactService } from '@/services'

@Component({ name: 'send-confirmation' })
export default class SendConfirmation extends Vue {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly title!: string
  @Prop({ type: Object, required: true }) public readonly contactInfo!: ContactInfo
  @Prop({ type: Boolean, default: false }) public readonly noNextContact!: boolean
  //#endregion

  //#region [Data]
  public email: string = ''
  //#endregion

  //#region [Watch]
  public onContactInfoChanged(value: ContactInfo) {
    this.email = contactService.getContactEmail(value)
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.onContactInfoChanged(this.contactInfo)
  }
  //#endregion
}

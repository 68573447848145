import { WorkFileIdentification } from '@/models';
import { MutationTree } from 'vuex';
import { WorkFileIdentificationState } from './types';

export const mutations: MutationTree<WorkFileIdentificationState> = {
  STORE(state, data: WorkFileIdentification) {
    state.identification = data
  },
  CLEAR(state) {
    state.identification = null
  }
}

import {
  RestrictedPowerLevel, RestrictedWorkRequestService, RestrictedWorkRequestTopic, RestrictedWorkRequestType, TimeSlot
} from '.';

// The bookable timeslots (subset of availability timeslots).
export enum AppointmentTimeSlot {
  Afternoon = 'afternoon',
  Morning = 'morning',
  MorningAndAfternoon = 'morningAndAfternoon'
}
export interface AppointmentSlot {
  startDate: Date | null;
  endDate: Date | null;
  timeslotLabel: string;
  wholeDayTimeslot: boolean | null;
  grade: number | null;
  key: string; // useful for list component
  otherDate: boolean; // special slot
}

export interface AppointmentInfo {
  remarks: string | null;
  selectedSlot: AppointmentSlot | null;
  noSatisfyingDate: boolean;
}

import { FileInfo } from '@ores/vue-library';

export interface AttachmentState {
  attachDocuments: boolean
  locationFilesInfo: FileInfo[]
}

export const InitialState: AttachmentState = {
  attachDocuments: false,
  locationFilesInfo: []
}

import { FileInfo } from '@ores/vue-library';

export interface AttachmentState {
  attachDocuments: boolean
  meterLocationFilesInfo: FileInfo[]
  buildingLocationFilesInfo: FileInfo[]
  otherFilesInfo: FileInfo[]
}

export const InitialState: AttachmentState = {
  attachDocuments: false,
  meterLocationFilesInfo: [],
  buildingLocationFilesInfo: [],
  otherFilesInfo: []
}

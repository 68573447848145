
import Component from 'vue-class-component'
import { Emit, Vue, Prop } from 'vue-property-decorator'
import ApiErrors from '@/components/display/ApiErrors.vue'
import AppointmentModificationConfirmation from './AppointmentModificationConfirmation.vue'
import AppointmentModification from './AppointmentModification.vue'

@Component({ components: { ApiErrors, AppointmentModificationConfirmation, AppointmentModification } })
export default class AppointmentModificationModal extends Vue {
  //#region [Props]
  @Prop({ type: Boolean, required: true }) public readonly isModalOpen!: boolean
  //#endregion

  //#region [Data]
  private isSecondModalOpen: boolean = false
  //#endregion

  //#region [Computed]
  public get isFirstModalOpen(): boolean {
    return this.isModalOpen
  }

  public set isFirstModalOpen(val: boolean) {
    this.$emit('close', val)
  }

  //#endregion

  //#region [Methods]
  public async confirmModify() {
    this.isFirstModalOpen = false
    this.isSecondModalOpen = true
  }

  public onContinueWithError(): void {
    location.href = this.$t('common.oresLink').toString()
  }

  //#endregion

  //#region [Emit]
  @Emit('close')
  private close(): boolean {
    this.isSecondModalOpen = false
    return true
  }
  //#endregion
}

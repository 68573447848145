
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import {
  ElectricityMeter,
  GasMeter,
  ElectricityPriceRequest,
  ElectricityPriceResponse
} from '@/models'
import { AxiosResponse } from 'axios'

@Component({
  name: 'electricity-price-calculator'
})
export default class ElectricityPriceCalculator extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly meter!: ElectricityMeter
  @Prop({ type: Boolean, required: false, default: false }) public readonly item!: boolean
  //#endregion

  //#region [Data]
  public price: number | null = null
  //#endregion

  //#region [Computed]
  public get meterDescription(): string {
    const descr: Array<string | undefined> = []

    switch (this.meter.tension) {
      case 'mono230V':
        descr.push(this.$t('meter.voltage', [230]) as string)
        descr.push(this.$t('meter.phase.single') as string)
        break
      case 'tri230V':
        descr.push(this.$t('meter.voltage', [230]) as string)
        descr.push(this.$t('meter.phase.three') as string)
        break
      case 'tri400V':
        descr.push(this.$t('meter.voltage', [400]) as string)
        descr.push(this.$t('meter.phase.three') as string)
        break
    }

    if (this.meter.power) {
      if (this.meter.tension === 'custom') {
        descr.push(this.$t('meter.power', { ampere: '?', kva: this.meter.power.kva }) as string)
      } else {
        descr.push(this.$t('meter.power', this.meter.power) as string)
      }
    }

    return descr.join(' - ')
  }

  public get meterType(): string {
    return this.meter.type === null ? '' : this.$t('meter.type.' + this.meter.type) as string
  }

  public get priceRequest(): ElectricityPriceRequest | null {
    const value = this.meter
    if (value.tension === 'custom'
     || value.power == null
     || value.count == null
     || value.type == null) {
      return null
    }

    return {
      numberOfMeters: value.count,
      meterKvaPower: value.power.kva,
      meterAmpere: value.power.ampere,
      meterTension: value.tension,
      isBudgetMeter: value.type === 'budget',
      nightOnlyTariff: value.type === 'nightOnly'
    }
  }
  //#endregion

  //#region [Watch]
  @Watch('priceRequest', { deep: true })
  public onPriceRequestChanged(value: ElectricityPriceRequest | null) {
    this.changePrice(null)
    if (value == null) {
      return
    }

    this.$api
      .post<ElectricityPriceResponse>(
        'prices/work-requests/new-connection/new-or-existing-construction/definitive-connection/electricity',
         value
      )
      .then((response) => {
        this.changePrice(response.data.totalPrice)
      })
  }
  //#endregion

  //#region [Emit]
  @Emit('price')
  public changePrice(price: number | null): number | null {
    this.price = price
    return price
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.onPriceRequestChanged(this.priceRequest)
  }
  //#endregion
}

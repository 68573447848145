
import { Component, Vue, Prop } from 'vue-property-decorator'
import { pricingService } from '@/services/pricing-service'

@Component({
  name: 'price-summary'
})
export default class PriceSummary extends Vue {
  @Prop({ type: Number, required: true }) public price!: number
  @Prop({ type: Boolean, required: false, default: false }) public isPriceToBeDetermined!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public isVatIncluded!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public isBuildingSelected!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public isGasOnly!: boolean

  public formatNumberToLocaleStringFormat(str: number): string {
    return pricingService.formatNumberToLocaleStringFormat(str)
  }

  public getPriceToDisplay(): string {
    if (this.isGasOnly || this.price === 0 && !this.isPriceToBeDetermined && !this.isBuildingSelected) {
      return this.$t('new-connection-cmh.offering-selection-step.free').toString();
    }
    if (this.price > 0 && !this.isPriceToBeDetermined && !this.isBuildingSelected) {
      return this.formatNumberToLocaleStringFormat(this.price) + ' €';
    }
    return this.$t('new-connection-cmh.offering-selection-step.priceToBeEstimated').toString();
  }
}

import { MutationTree } from 'vuex'
import { MeterReadingState } from './types'

export const mutations: MutationTree<MeterReadingState> = {
  STORE(state, data: { date: Date | null, confirmation: boolean }) {
    state.meterReadingDate = data.date
    state.confirmation = data.confirmation
  },
  CLEAR(state) {
    state.meterReadingDate = null
    state.confirmation = false
  }
}

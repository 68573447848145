
import Vue from 'vue'
import { Watch, Emit, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { helper } from '@/services'
import { BuildingType } from '@/models/building-type'

@Component({
  name: 'building-type-selector'
})
export default class BuildingTypeSelector extends Vue {
  @Prop({ type: String, required: false, default: '' }) public stateSelection!: BuildingType

  public selection: BuildingType = helper.clone(this.stateSelection)

  public firstError(errors: { [key: string]: string[] }): string | null {
    for (const source of Object.values(errors)) {
      if (source.length > 0) {
        return source[0]
      }
    }

    return null
  }

  @Watch('selection', { deep: true })
  public onSelectionChanged(): void {
    this.onSelectionChangeHandler()
  }

  public onSelectionChangeHandler(): void {
    this.emitSelectionChanged()
  }

  @Emit('selectionChanged')
  public emitSelectionChanged(): BuildingType {
    return this.selection
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import { PackagedElectricityMeter } from '@/models'
import PackageEditableBox from './PackageEditableBox.vue'
import AddConnectionEditableBox from './AddConnectionEditableBox.vue'
import PriceDisclaimer from './PriceDisclaimer.vue'

@Component({
  components: {
    PackageEditableBox,
    AddConnectionEditableBox,
    PriceDisclaimer
  },
  name: 'building-package-electricity'
})
export default class BuildingPackageElectricity extends Vue {
  @Prop({ type: Array, required: true }) public meters!: PackagedElectricityMeter[]

  @Prop({ type: Function, required: true }) public onAddNewMeter!: (destination: string) => void
  @Prop({ type: Function, required: true }) public deleteElectricityBox!: (identifier: number) => void
  @Prop({ type: Function, required: true }) public editElectricityBox!: (identifier: number) => void

  addButtonDisabled(meterDestination: string): boolean {
    const meters = this.meters.filter(meter => meter.destination === meterDestination)
    return meters.length > 9;
  }

}


import { CarType } from '@/models'
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component({
  name: 'battery-capacity-form'
})
export default class BatteryCapacityForm extends Vue {
  //#region [Property]
  @Prop({ type: Number }) public readonly value!: number
  @Prop({ type: String, required: true }) public readonly carType!: CarType
  //#endregion

  //#region [Computed]
  public get batteryCapacity(): number | null {
    return this.value
  }
  public set batteryCapacity(value: number | null) {
    this.change(value)
  }
  public get getMaxCapacity() {
    return 200
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(value: number | null): number | null {
    return value
  }
  //#endregion
}

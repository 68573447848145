
import { Component } from 'vue-property-decorator'
import { helper, meterService } from '@/services'
import Step from '@/components/steps/Step'
import OtherEquipmentElectricityMeterForm from '@/components/inputs/Configuration/OtherEquipmentElectricityMeterForm.vue'
import { State, Mutation } from 'vuex-class'
import { OtherEquipmentConfigurationInfo, MeterElectricityType, MeterElectricityTension } from '@/models'

@Component({
  name: 'configuration-info-step',
  components: {
    OtherEquipmentElectricityMeterForm,
  }
})
export default class ConfigurationInfoStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'otherEquipmentConnection' })
  public requestId!: string
  @State('electricityTensions', { namespace: 'otherEquipmentConnection/configurationInfo' })
  public readonly electricityTensions!: MeterElectricityTension[]

  @State('configurationInfo', { namespace: 'otherEquipmentConnection/configurationInfo' })
  public configurationInfoState!: OtherEquipmentConfigurationInfo | null

  public electricityTarifications: MeterElectricityType[] = ['dual', 'simple', 'nightOnly']

  public configurationInfo: OtherEquipmentConfigurationInfo = {
    electricityMeter: meterService.getDefaultOtherEquipmentElectricityMeter('mono230V'),
    month: null,
    year: null,
    remark: null
  }
  //#endregion

  //#region [Computed]
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'otherEquipmentConnection/configurationInfo' })
  private store!: (index: OtherEquipmentConfigurationInfo | null) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    if (this.configurationInfoState !== null) {
      this.configurationInfo = helper.clone(this.configurationInfoState)
    }
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid) {
      this.$appInsights.trackEvent('WR_OEC_StepConfigurationInfo_Validated', {
        Segment: 'WR_OEC',
        WebRef: this.requestId
      })
    }

    this.store(this.configurationInfo)
  }
  //#endregion
}

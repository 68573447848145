import { MutationTree } from 'vuex';
import { MailRegistrationState } from './types';

export const mutations: MutationTree<MailRegistrationState> = {
  STORE(state, emailAddress: string) {
      state.emailAddress = emailAddress
    },
  CLEAR(state) {
    state.emailAddress = ''
  }
}

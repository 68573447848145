
import { Component, Prop } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import PriceAnnexe from '@/components/display/PriceAnnexe.vue'
import ResumeContactInfo from '@/components/display/Resume/ResumeContactInfo.vue'
import ResumeVatInfo from '@/components/display/Resume/ResumeVatInfo.vue'
import ResumeAddress from '@/components/display/Resume/ResumeAddress.vue'
import {
  AddressPoint,
  ContactInfo,
  IndividualVatInfoSelection,
  FreelanceVatInfoSelection,
  CompanyVatInfoSelection,
  OtherEquipmentConfigurationInfo,
  OtherEquipmentElectricityMeter
} from '@/models'
import { State } from 'vuex-class'
import { contactService, calendarService } from '@/services'
import { FileInfo } from '@ores/vue-library'

@Component({
  name: 'summary-step',
  components: {
    PriceAnnexe,
    ResumeContactInfo,
    ResumeVatInfo,
    ResumeAddress
  }
})
export default class SummaryStep extends Step {
  //#region [Property]
  @Prop({ type: Boolean, default: false }) public readonly readOnly!: boolean
  //#endregion

  //#region [Data]
  @State('requestId', { namespace: 'otherEquipmentConnection' })
  public requestId!: string
  @State('address', { namespace: 'otherEquipmentConnection/address' })
  public addressState!: AddressPoint | null
  @State('configurationInfo', { namespace: 'otherEquipmentConnection/configurationInfo' })
  public configurationInfoState!: OtherEquipmentConfigurationInfo | null

  @State('attachDocuments', { namespace: 'otherEquipmentConnection/attachment' })
  public attachDocumentsState!: boolean
  @State('meterLocationFilesInfo', { namespace: 'otherEquipmentConnection/attachment' })
  public meterLocationFilesInfoState!: FileInfo[]
  @State('buildingLocationFilesInfo', { namespace: 'otherEquipmentConnection/attachment' })
  public buildingLocationFilesInfoState!: FileInfo[]
  @State('otherFilesInfo', { namespace: 'otherEquipmentConnection/attachment' })
  public otherFilesInfoState!: FileInfo[]

  @State('contactInfo', { namespace: 'otherEquipmentConnection/contactInfo' })
  public contactInfoState!: ContactInfo | null
  @State('individualVatInfoSelection', { namespace: 'otherEquipmentConnection/vatInfo' })
  public individualVatInfoSelectionState!: IndividualVatInfoSelection | null
  @State('freelanceVatInfoSelection', { namespace: 'otherEquipmentConnection/vatInfo' })
  public freelanceVatInfoSelectionState!: FreelanceVatInfoSelection | null
  @State('companyVatInfoSelection', { namespace: 'otherEquipmentConnection/vatInfo' })
  public companyVatInfoSelectionState!: CompanyVatInfoSelection | null

  public recaptcha: string | null = null
  //#endregion

  //#region [Computed]
  public get siteKey() {
    return process.env.VUE_APP_CAPTCHA_SITE_KEY
  }
  public get meters(): OtherEquipmentElectricityMeter[] {
    return [this.configurationInfoState!.electricityMeter]
  }

  public get hasAttachedDocuments(): boolean {
    return this.attachDocumentsState !== null && this.attachDocumentsState
  }
  //#endregion

  //#region [Method]
  public goToStep(step: number) {
    this.$emit('goToStep', step)
  }

  public async save(next: boolean, valid: boolean): Promise<boolean> {
    if (!next || !valid) {
      return true
    }

    if (this.addressState && this.addressState.subCity) {
      contactService.cleanContactInfo(this.contactInfoState!)

      const files = []
      if (this.meterLocationFilesInfoState && this.meterLocationFilesInfoState.length > 0) {
        for (const file of this.meterLocationFilesInfoState) {
          files.push(file.name)
        }
      }
      if (this.buildingLocationFilesInfoState && this.buildingLocationFilesInfoState.length > 0) {
        for (const file of this.buildingLocationFilesInfoState) {
          files.push(file.name)
        }
      }
      if (this.otherFilesInfoState && this.otherFilesInfoState.length > 0) {
        for (const file of this.otherFilesInfoState) {
          files.push(file.name)
        }
      }

      const data = {
        requestId: this.requestId,
        stepAddress: {
          zipCode: this.addressState.subCity.zipCode,
          subCityId: this.addressState.subCity.georesSubcityId,
          streetSubCityId: this.addressState.streetSubCity
            ? this.addressState.streetSubCity.georesStreetSubcityId
            : null,
          houseNumber: this.addressState.houseNumber,
          isMapLocation: this.addressState.isMapLocation,
          mapLocation: this.addressState.mapLocation
        },
        stepConfigurationInfo: this.configurationInfoState,
        stepContactInfo: this.contactInfoState,
        stepVatInfoSelection: {
          individualVatInfoSelection: this.individualVatInfoSelectionState,
          freelanceVatInfoSelection: this.freelanceVatInfoSelectionState,
          companyVatInfoSelection: this.companyVatInfoSelectionState
        },
        stepAttachments: {
          attachDocuments: files.length > 0,
          files
        }
      }
      const response = await this.$api.post('api/worksrequest/other-equipment-connection-request', data, {
        headers: {
          'g-recaptcha-response': this.recaptcha
        }
      })
      if (response.status >= 200 && response.status < 300) {
        this.$appInsights.trackEvent('WR_OEC_StepSummary_Validated', {
          Segment: 'WR_OEC',
          WebRef: this.requestId,
          Remark: this.configurationInfoState!.remark !== null ? this.configurationInfoState!.remark : ''
        })

        return true
      }
    }
    return false
  }

  public getMonthLabel(month: number): string {
    return this.$t('month.' + calendarService.months[month - 1]) as string
  }
  //#endregion
}


import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'meter-editable-box'
})
export default class MeterEditableBox extends Vue {
  @Prop({ type: Number, required: true }) public identifier!: number
  @Prop({ type: String, required: true }) public title!: string
  @Prop({ type: String, required: false, default: '0' }) public excludingVatPrice!: string
  @Prop({ type: String, required: true }) public description!: string
  @Prop({ type: Function, required: true }) public onEdit!: (identifier: number) => void
  @Prop({ type: Function, required: true }) public onDelete!: (identifier: number) => void
  @Prop({ type: Boolean, required: false, default: false }) public readonly!: boolean
  @Prop({ type: Boolean, required: true }) public isPriceToBeDetermined!: boolean

  public isFree: boolean = false

  public mounted() {
    this.isFree = this.excludingVatPrice === '0'
  }
}

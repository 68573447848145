import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { DialogsState, initialState } from './types'
import { RootState } from '@/store/types'

const namespaced: boolean = true

export const dialogs: Module<DialogsState, RootState> = {
    namespaced,
    state: initialState,
    getters,
    actions,
    mutations
}

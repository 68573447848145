import { getters } from './getters';
import { mutations } from './mutations';
import { FilesState, InitialState } from './types';
import { RootState } from '@/store/types';
import { Module } from 'vuex';

const namespaced: boolean = true;

export const files: Module<FilesState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  mutations
}

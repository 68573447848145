
import { Emit, Vue, Watch } from 'vue-property-decorator'
import ZipCodeLocalitySelector from '@/components/inputs/Address/ZipCodeLocalitySelector.vue'
import ConnectionPointArea from '@/components/inputs/Address/ConnectionPointArea.vue'
import FindMeterLastDigitsModalDialog from '@/components/display/FindMeterLastDigitsModalDialog.vue'
import CollapseInfo from '@/components/display/CollapseInfo.vue'
import Component from 'vue-class-component'
import {
  ChargingStationDeclarationGridAccessPoint,
  CheckEanForChargingStationDeclarationResponse,
  CheckEanSearch,
  SubCity
} from '@/models'
import { Mutation, State } from 'vuex-class'
import { chatService, helper } from '@/services'
import { AxiosResponse } from 'axios'

@Component({
  name: 'identification',
  components: {
    ZipCodeLocalitySelector,
    ConnectionPointArea,
    CollapseInfo,
    FindMeterLastDigitsModalDialog
  }
})
export default class Identification extends Vue {
  //#region [Data]
  @State('eanCode', { namespace: 'chargingStationDeclaration/identification' })
  public eanCodeState!: string | null
  @State('meterLastFourNumbers', { namespace: 'chargingStationDeclaration/identification' })
  public meterLastFourNumbersState!: string | null
  @State('requestId', { namespace: 'chargingStationDeclaration' })
  public requestId!: string
  @State('gridAccessPoint', { namespace: 'chargingStationDeclaration/identification' })
  public gridAccessPointState!: ChargingStationDeclarationGridAccessPoint

  public subCity: SubCity | null = null
  public eanCode: string | null = null

  public recaptchaToken: string | null = null

  public gridAccessPoint: ChargingStationDeclarationGridAccessPoint | null = null
  public loading: boolean = false
  public hasError: boolean = false
  public isIdentified: boolean = false
  public recaptchaKey: number = 0

  public meterLastFourNumbers: string | null = null
  public whereFoundEanModalOpen: boolean = false
  public whereFindMeterLastDigitsOpen: boolean = false
  //#endregion
  //#region [Mutation]
  @Mutation('STORE', { namespace: 'chargingStationDeclaration/identification' })
  public store!: (index: { eanCode: string | null, meterLastFourNumbers: string | null }) => void

  //#endregion
  @Mutation('STORE_GRID_ACCESS_POINT', { namespace: 'chargingStationDeclaration/identification' })
  public storeGridAccessPoint!: (index: ChargingStationDeclarationGridAccessPoint | null) => void
  @Mutation('CLEAR_API_ERRORS', { namespace: 'apiErrors' })
  private clearApiErrors!: () => void

  //#region [Computed]
  public get isIdentifiedAndCanContinue(): boolean {
    return this.isIdentified && !!this.gridAccessPoint
  }

  public get siteKey() {
    return process.env.VUE_APP_CAPTCHA_SITE_KEY
  }

  //#endregion

  //#region [Watch]
  @Watch('eanCode')
  @Emit('eanCode')
  public onEanCodeChange(value: string | null) {
    this.clearApiErrors()
    if (value && value === this.eanCodeState) {
      // User input is the same as last identified EAN
      this.isIdentified = true
      return value
    }
    this.isIdentified = false
    return value
  }

  @Watch('eanCodeState')
  public onEanCodeStateChange(value: string | null) {
    if (!value) {
      this.isIdentified = false
    }
  }

  @Watch('isIdentified')
  @Emit('isIdentified')
  public onIsIdentifiedChange(value: boolean): boolean | null {
    return value
  }

  @Watch('isIdentifiedAndCanContinue', { immediate: true })
  @Emit('canContinue')
  public isIdentifiedAndCanContinueChange(value: boolean): boolean | null {
    return value
  }

  @Watch('gridAccessPoint')
  @Emit('gridAccessPoint')
  public onGridAccessPointChange(value: ChargingStationDeclarationGridAccessPoint):
    ChargingStationDeclarationGridAccessPoint | null {
    return value
  }

  //#endregion

  //#region [Method]
  public mounted() {
    this.eanCode = helper.clone(this.eanCodeState)
    this.meterLastFourNumbers = helper.clone(this.meterLastFourNumbersState)
    this.gridAccessPoint = helper.clone(this.gridAccessPointState)
    this.isIdentified = !!this.gridAccessPointState
  }

  public async checkEanForChargingStationDeclaration(): Promise<CheckEanForChargingStationDeclarationResponse> {
    this.hasError = false;

    const response = await this.$api.get<CheckEanSearch, AxiosResponse<CheckEanForChargingStationDeclarationResponse>>(
      `api/assets/check-ean-meter/charging-station-declaration?ean=${this.eanCode!.trim()}&meterLastFourNumbers=${this.meterLastFourNumbers}`,
      {
        headers: {
          'g-recaptcha-response': this.recaptchaToken
        },
        cache: {
          ignoreCache: false
        }
      }
    )

    if(response.status !== 200) {
      this.hasError = true
    }
    return response.data
  }

  public async identify() {
    if (this.eanCode) {
      try {
        this.loading = true

        const checkEanForChargingStationDeclarationResponse = await this.checkEanForChargingStationDeclaration()

        this.gridAccessPoint = checkEanForChargingStationDeclarationResponse.gridAccessPoint
        this.storeGridAccessPoint(this.gridAccessPoint)

        this.store({ eanCode: this.eanCode!.trim(), meterLastFourNumbers: this.meterLastFourNumbers })

        if (this.gridAccessPoint && checkEanForChargingStationDeclarationResponse.token) {
          sessionStorage.setItem('token', checkEanForChargingStationDeclarationResponse.token)
          this.isIdentified = true
        }

        this.loading = false

        if (!this.isIdentifiedAndCanContinue) {
          // Refresh the captcha for next identification to work
          this.recaptchaKey += 1
        }
      } catch (err) {
        this.loading = false
        this.isIdentified = false
        this.storeGridAccessPoint(null)
      }
    }
  }

  private openChat() {
    chatService.openChat()
  }

  //#endregion

  //#region [Emit]
  //#endregion
}

import { VatInfo } from '@/models'
import { MutationTree } from 'vuex'
import { CmhVatInfoState } from './types'

export const mutations: MutationTree<CmhVatInfoState> = {
  STORE(state, data: VatInfo) {
    state.individualVatInfoSelection = data.individualVatInfoSelection
    state.freelanceVatInfoSelection = data.freelanceVatInfoSelection
    state.companyVatInfoSelection = data.companyVatInfoSelection
  },
  CLEAR(state) {
    state.individualVatInfoSelection = null
    state.freelanceVatInfoSelection = null
    state.companyVatInfoSelection = null
  }
}


import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { ContactInfo, AddressPoint, ConsumptionAddress, ContactPerson } from '@/models'
import { contactService } from '@/services'

@Component
export default class ResumeContactInfo extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly contactInfo!: ContactInfo
  @Prop({ type: Object, required: true }) public readonly address!: AddressPoint | ConsumptionAddress
  @Prop({ type: Boolean, required: false, default: false }) public readonly isMobileLayoutApplied!: boolean
  //#endregion

  //#region [Method]
  //#endregion

  //#region [Computed]
  public get isConsumptionAddress(): boolean {
    return (this.address as ConsumptionAddress).cityName !== undefined
  }

  public get contactName(): string {
    switch (this.contactInfo.contactType) {
      case 'individual':
        if (this.contactInfo.contactIndividual) {
          let civility = null
          if (this.contactInfo.contactIndividual.civility === 1) {
            civility = this.$t('contact.civility.mr')
          } else if (this.contactInfo.contactIndividual.civility === 2) {
            civility = this.$t('contact.civility.mrs')
          }
          return `${civility} ${this.contactInfo.contactIndividual.firstname} ${this.contactInfo.contactIndividual.lastname}`
        }
        return ''
        break
      case 'freelance':
        if (this.contactInfo.contactFreelance) {
          let civility = null
          if (this.contactInfo.contactFreelance.civility === 1) {
            civility = this.$t('contact.civility.mr')
          } else if (this.contactInfo.contactFreelance.civility === 2) {
            civility = this.$t('contact.civility.mrs')
          }
          return `${civility} ${this.contactInfo.contactFreelance.firstname} ${this.contactInfo.contactFreelance.lastname}`
        }
        return ''
        break
      case 'company':
        if (this.contactInfo.contactCompany) {
          return `${this.contactInfo.contactCompany.firstname} ${this.contactInfo.contactCompany.lastname}`
        }
        return ''
        break
      default:
        return ''
        break
    }
  }

  public otherContactName(contactPerson: ContactPerson): string {
    if (contactPerson.civility) {
      let civility = null
      if (contactPerson.civility === 1) {
        civility = this.$t('contact.civility.mr')
      } else if (contactPerson.civility === 2) {
        civility = this.$t('contact.civility.mrs')
      }
      return `${civility} ${contactPerson.firstname} ${contactPerson.lastname}`
    }
    return ''
  }

  public get contactMail(): string {
    return contactService.getContactEmail(this.contactInfo)
  }

  public get contactPhones(): Array<{ mobile: boolean; nummer: string }> {
    switch (this.contactInfo.contactType) {
      case 'individual':
        if (this.contactInfo.contactIndividual) {
          return [
            {
              mobile: true,
              nummer: this.contactInfo.contactIndividual.mobile || ''
            },
            {
              mobile: false,
              nummer: this.contactInfo.contactIndividual.phone || ''
            }
          ].filter((n) => !!n.nummer)
        }
        break
      case 'freelance':
        if (this.contactInfo.contactFreelance) {
          return [
            {
              mobile: true,
              nummer: this.contactInfo.contactFreelance.mobile || ''
            },
            {
              mobile: false,
              nummer: this.contactInfo.contactFreelance.phone || ''
            }
          ].filter((n) => !!n.nummer)
        }
        break
      case 'company':
        if (this.contactInfo.contactCompany) {
          return [{ mobile: false, nummer: this.contactInfo.contactCompany.phone! }]
        }
        break
      default:
        return []
        break
    }
    return []
  }

  public get contactAddress(): string {
    if (this.contactInfo) {
      if (this.contactInfo.useWorksAddress) {
        if (this.contactInfo.contactType === 'company') {
          const address = this.contactInfo.contactCompany!.address
          return `${address.street} ${address.streetNumber} ${address.streetBox || ''} - ${address.zipCode} ${address.locality
            }`
        } else if (this.address) {
          if (!this.isConsumptionAddress) {
            const address = this.address as AddressPoint
            return address.isMapLocation
              ? `${address.mapLocation!.lat} ${address.mapLocation!.lng} ${address.subCity.zipCode} ${address.subCity.cityName
              }`
              : `${address.streetSubCity!.name} ${address.houseNumber} ${address.subCity.zipCode} ${address.subCity.cityName
              }`
          } else {
            const address = this.address as ConsumptionAddress
            return `${address.houseNumber} ${address.streetName} ${address.complement || ''} ${address.complementBox || ''
              } - ${address.zipCode} ${address.cityName}`
          }
        }
      } else if (this.contactInfo.otherAddress) {
        return `${this.contactInfo.otherAddress.street} ${this.contactInfo.otherAddress.streetNumber} ${this.contactInfo.otherAddress.streetBox || ''} - ${this.contactInfo.otherAddress.zipCode} ${this.contactInfo.otherAddress.locality} ${this.contactInfo.otherAddress.country}`
      }
    }
    return ''
  }
  //#endregion
}

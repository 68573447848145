
import { Prop, Component, Watch } from 'vue-property-decorator'
import {
  ContactInfo,
  EnergyProductionInfoModel,
  RestrictedPowerLevel,
  RestrictedWorkRequestService,
  RestrictedWorkRequestTopic,
  RestrictedWorkRequestType,
  WorkFileCancellationRequest
} from '@/models'
import StepActions from '@/components/shared/StepActions.vue'
import SendConfirmation from '@/components/steps/smart-meter-connection/SendConfirmation.vue'
import Step from '@/components/steps/Step'
import IdentificationStep from '@/components/steps/smart-meter-connection/IdentificationStep.vue'
import EnergyProductionStep from '@/components/steps/smart-meter-connection/EnergyProductionStep.vue'
import ContactInfoStep from '@/components/steps/smart-meter-connection/ContactInfoStep.vue'
import VatInfoStep from '@/components/steps/smart-meter-connection/VatInfoStep.vue'
import AppointmentDatePreselectionStep
  from '@/components/steps/smart-meter-connection/AppointmentDatePreselectionStep.vue'
import SummaryStep from '@/components/steps/smart-meter-connection/SummaryStep.vue'
import AppointmentStep from '@/components/steps/smart-meter-connection/AppointmentStep.vue'
import DialogBox from '@/components/shared/DialogBox.vue'
import { State, Mutation, Action } from 'vuex-class'
import { Form } from './Form'
import { StepDefinition, timeService } from '@ores/vue-library'
import ApiErrors from '@/components/display/ApiErrors.vue'
import { Route } from 'vue-router'
import { removeInterceptors } from '@/plugins/http-interceptors-global'
import { addSmartMeterResponseInterceptors } from '@/plugins/http-response-interceptors-smart-meter'
import store from '@/store/index'
import { contactService } from '@/services'

const initialSteps: StepDefinition[] = [
  new StepDefinition('identification', 1),
  new StepDefinition('request', 2),
  new StepDefinition('contact', 3, [new StepDefinition('vat', 4)]),
  new StepDefinition('summary', 5),
  new StepDefinition('appointment', 6, [new StepDefinition('', 7)]),
  new StepDefinition('confirm', 8)
]

@Component({
  components: {
    StepActions,
    IdentificationStep,
    EnergyProductionStep,
    ContactInfoStep,
    VatInfoStep,
    AppointmentDatePreselectionStep,
    SummaryStep,
    DialogBox,
    AppointmentStep,
    SendConfirmation,
    ApiErrors
  },
  name: 'smart-meter-connection'
})
export default class SmartMeterConnection extends Form {
  //#region [Property]
  @Prop({ type: Boolean, default: false })
  public readonly mockOutsideHour!: boolean
  //#endregion

  //#region [Data]
  public showStepAction: boolean = true
  public stepDefinitions: StepDefinition[] = initialSteps.map((s) => s)
  public isIdentified: boolean = false

  @State('contactInfo', { namespace: 'smartMeterConnection/contactInfo' })
  public contactInfoState!: ContactInfo | null

  @State('noAppointment', { namespace: 'smartMeterConnection' })
  public noAppointment!: boolean

  @State('fileNumber', { namespace: 'smartMeterConnection/stakeholder' })
  public fileNumber!: string | null

  @State('energyProductionInfo', { namespace: 'smartMeterConnection/energyProduction' })
  public energyProductionInfoState!: EnergyProductionInfoModel
  //#endregion

  //#region [Computed]
  public get hasPrevious(): boolean {
    return this.lastStepSeen < 8 && this.currentStep !== 7 && this.currentStep > 1
  }

  public get hasNext(): boolean {
    if (this.currentStep === 1) {
      return this.canContinue
    }
    return this.lastStepSeen < 8
  }

  private get nextLabel(): string | null {
    if (this.currentStep === 5) {
      return this.$t('smartMeter.buttons.summaryStep').toString()
    }
    if (this.currentStep === 6) {
      return this.$t('smartMeter.buttons.appointmentDatePreselectionStep').toString()
    }
    return null
  }

  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'smartMeterConnection' })
  public store!: (index: boolean | null) => void
  //#endregion

  //#region [Method]
  @Action('validate', { namespace: 'smartMeterConnection/vatInfo' })
  public validateVatInfoSelection!: (contactInfo: ContactInfo) => Promise<boolean>

  public mounted() {
    this.lastStepSeen = 1
    this.stepToGoAfterValidation = 5
    this.stepsToValidate = [3]

    this.setInitialStep()
    this.onLangChanged(this.lang)

    if (this.interceptorId !== null) {
      removeInterceptors(this.$api, this.interceptorId)
    }
    const interceptorId = addSmartMeterResponseInterceptors(this.$api, store)
    this.setInterceptorId(interceptorId)

    // Check maintenance
    this.$api.get('api/maintenanceManagement/smart-meter-request')
  }

  public getStepComponent(step: number): Step | null {
    return (this.$refs[`step${this.currentStep}`] as Step) || null
  }

  public resetForm() {
    this.setInitialStep()
    this.lastStepSeen = 1
    this.$store.dispatch('smartMeterConnection/reset')
    this.$nextTick(() => (this.currentStep = 1))
  }

  public setInitialStep() {
    const hours = this.mockOutsideHour ? 2 : timeService.getBelgianTime().hours

    this.stepDefinitions = initialSteps.map((s) => s)
    if (hours < 7 || hours >= 23) {
      this.currentStep = 0
    } else {
      this.currentStep = 1
    }
  }

  // This method is called whenever url changes.
  public async beforeRouteLeave(to: Route, from: Route, continueNavigation: any) {
    if (to.meta?.lang !== from.meta?.lang) {
      // url changes when user uses language switch,
      // allow navigation to target url.
      continueNavigation(true)
      return
    }
    if (this.shouldAskConfirmCloseWorkFile()) {
      // User leaves a specific step and this ask for user confirmation
      const answer = this.confirmCloseWorkFile()
      if (answer) {
        // User confirmed,
        // Leaving this step should close the work file and navigate to previous step
        await this.closeWorkFile()
        await this.previousStep(true)
        // Prevent url history navigation
        continueNavigation(false)
        return
      } else {
        // User didn't confirm, stay on page,
        // Prevent url history navigation
        continueNavigation(false)
      }
    } else {
      // No user confirmation required
      // Special case : step Identification and step for outside hours
      if (this.currentStep < 2) {
        continueNavigation(true)
        return
      } else {
        // Navigate to previous step
        this.previousStep(true)
        // Prevent url history navigation
        continueNavigation(false)
      }
    }
  }

  public async goToStepUsingBreadCrumb(step: number) {
    if (this.shouldAskConfirmCloseWorkFile()) {
      const answer = this.confirmCloseWorkFile()
      if (answer) {
        await this.closeWorkFile()
        this.goToStepWithValidation(step)
        return
      } else {
        return
      }
    }
    this.goToStepWithValidation(step)
  }

  public shouldAskConfirmCloseWorkFile(): boolean {
    return this.currentStep === 7 && !!this.fileNumber // null when file creation error occurred
  }

  public confirmCloseWorkFile(): boolean {
    return window.confirm(this.$t('appointment.leavePageMessage').toString())
  }

  public async closeWorkFile() {
    const token = sessionStorage.getItem('token')
    const data: WorkFileCancellationRequest = {
      workFileReference: this.fileNumber!,
      powerLevel: RestrictedPowerLevel.Low,
      workRequestType: RestrictedWorkRequestType.Electricity,
      service: this.energyProductionInfoState.hasEnergyProduction
        ? RestrictedWorkRequestService.ElectricityProductionCommissioning
        : RestrictedWorkRequestService.MeterReplacementBySmartMeter,
      topic: RestrictedWorkRequestTopic.SmartMeter,
      contactInfo: contactService.getContact(this.contactInfoState!),
      language: 'fr'
    }
    await this.$api.post(`api/WorksRequest/work-file/${this.fileNumber}/close`, data, {
      headers: {
        Authorization: token
      }
    })
  }

  public setLoading(value: boolean) {
    this.loading = value ? 'next' : null
  }

  //#endregion

  //#region [Watch]
  @Watch('contactInfoState', { deep: true })
  public async onContactInfoChanged(contactInfo: ContactInfo | null) {
    if (contactInfo === null) {
      return
    }

    this.ensureLastStep(4, await this.validateVatInfoSelection(contactInfo))
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('afterEnsured'))
    }, 100)
  }

  //#endregion
}

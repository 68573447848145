
import { Component, Vue } from 'vue-property-decorator'
import { State, Mutation } from 'vuex-class'
import { CmhAddressPoint } from '@/models/cmh-address-point'
import { AddressPoint, Contact, ContactInfo } from '@/models'
import { helper, contactService } from '@/services'
import ContactInfoForm from '@/components/inputs/Contact/ContactInfoForm.vue'
import Step from '@/components/steps/Step'
import { pixelFacebookService } from '@ores/vue-library'

@Component({
  components: {
    ContactInfoForm
  },
  name: 'cmh-contact-step'
})
export default class CmhContactStep extends Step {
  //#region [Data]
  @State('addressPoint', { namespace: 'newConnectionCmh/cmhAddress' })
  public stateAddressPoint!: CmhAddressPoint

  @State('emailAddress', { namespace: 'newConnectionCmh/mailRegistration' })
  public stateEmailAddress!: string

  @State('contactInfo', { namespace: 'newConnectionCmh/cmhContact' })
  public stateContactInfo!: ContactInfo

  @State('requestId', { namespace: 'newConnectionCmh' })
  public requestId!: string

  public address: AddressPoint | null = null
  public contactInfo: ContactInfo = contactService.createContactInfo()
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhContact' })
  public store!: (index: ContactInfo) => void
  //#endregion

  //#region [Method]
  public created(): void {
    pixelFacebookService.trackViewContent({
      content_ids: ['CmhForm'],
      content_name: 'Contact Step',
      content_type: 'product'
    })
    if (this.stateAddressPoint?.addressPoint ?? false) {
      this.address = helper.clone(this.stateAddressPoint.addressPoint)
    }
    if (this.stateContactInfo) {
      this.contactInfo = helper.clone(this.stateContactInfo)
    } else {
      this.contactInfo = contactService.createContactInfoFromEmail(this.stateEmailAddress)
    }
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (isValid) {

      this.saveStep()

      this.store(this.contactInfo)

      this.$appInsights.trackEvent('WR_NCCMH_Step5_Validated', { Segment: 'WR_NCCMH', WebRef: this.requestId })
    }
  }

  public async saveStep() {
    if (this.contactInfo.contactType === 'individual') {
      this.contactInfo.contactFreelance = null
      this.contactInfo.contactCompany = null
    } else if (this.contactInfo.contactType === 'freelance') {
      this.contactInfo.contactIndividual = null
      this.contactInfo.contactCompany = null
    } else {
      this.contactInfo.contactIndividual = null
      this.contactInfo.contactFreelance = null
    }
    this.$api.post<Contact>(`NewConnectionRequest/store/${this.requestId}/contact-info`, this.contactInfo)
  }
  //#endregion
}

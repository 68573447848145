
import { Component } from 'vue-property-decorator'
import { Form } from '@/views/forms/Form'
import CmhLandingPageStep from '@/components/steps/new-connection-cmh/CmhLandingPageStep.vue'
import DialogBox from '@/components/shared/DialogBox.vue'
import ApiErrors from '@/components/display/ApiErrors.vue'
import { googleService } from '@/services'
import { pixelFacebookService } from '@ores/vue-library'

@Component({
  components: {
    CmhLandingPageStep,
    DialogBox,
    ApiErrors
  },
  name: 'landing-page-cmh'
})
export default class LandingPageCmh extends Form {
  //#region [Methods]
  public mounted() {
    this.onLangChanged(this.lang)
    googleService.includeMapScript(this.$el)
    pixelFacebookService.trackViewContent({
      content_ids: ['CmhForm'],
      content_name: 'Landing Page - Cmh',
      content_type: 'product'
    })
  }
  //#endregion
}

/** 0: Building has less than ten years, 1: Building has more than ten years */
export enum IndividualVatInfoSelection {
  LessThanTenYears = 0,
  MoreThanTenYears = 1
}

/** 0: Monthly declaration, 1: Annual declaration */
export enum FreelanceVatInfoSelection {
  WithDeclaration = 0,
  WithoutDeclaration = 1,
  NotSubject = 2
}

// tslint:disable-next-line:max-line-length
/** 0: SubjectToVat, 1: TownWithoutSocialCharacter, 2: SocialHousing, 3: Embassy, 4: TownWithSocialCharacter, 5: HousingForDisabled, 6: RestHouse, 7: School */
export enum CompanyVatInfoFirstStepSelection {
  SubjectToVat = 0,
  TownWithoutSocialCharacter = 1,
  SocialHousing = 2,
  Embassy = 3,
  TownWithSocialCharacter = 4,
  HousingForDisabled = 5,
  RestHouse = 6,
  School = 7
}

export enum CompanyVatInfoSecondStepSelection {
  WithDeclaration = 0,
  WithoutDeclaration = 1,
  PublicBuilding = 2,
  PrivateBuildingMoreThanTenYears = 3,
  PrivateBuildingLessThanTenYears = 4,
  SocialPrivateBuilding = 5,
  PrivateBuildingForDisabled = 6
}

export interface CompanyVatInfoSelection {
  primaryInfo: CompanyVatInfoFirstStepSelection | null
  secondaryInfo: CompanyVatInfoSecondStepSelection | null
}

export interface VatInfo {
  individualVatInfoSelection: IndividualVatInfoSelection | null
  freelanceVatInfoSelection: FreelanceVatInfoSelection | null
  companyVatInfoSelection: CompanyVatInfoSelection | null
}


import { Vue, Component, Prop } from 'vue-property-decorator'
import { Phase, PhaseSystemStatus, WorkRequest, WorkOfferType, WorkFileAddress, CustomerAdvisor } from '@/models'
import { dateService } from '@ores/vue-library'

@Component({
  name: 'work-request-status'
})
export default class WorkRequestStatus extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly workRequest!: WorkRequest
  @Prop({ type: Object, required: true }) public readonly workAddress!: WorkFileAddress
  @Prop({ type: Object, required: false }) public readonly customerAdvisor!: CustomerAdvisor | null
  //#endregion

  //#region [Computed]
  public get workRequestCancelled(): boolean {
    if (
      this.workRequest.offerType === WorkOfferType.VerbalOffer ||
      this.workRequest.offerType === WorkOfferType.WithoutOffer
    ) {
      return (
        (this.workRequest.phases.length === 1 && this.workRequest.phases[0].status === PhaseSystemStatus.Closed) ||
        (this.workRequest.phases.length === 2 && this.workRequest.phases[1].status === PhaseSystemStatus.Closed)
      )
    }

    return false
  }
  //#endregion

  //#region [Method]
  public getName(phase: Phase): string {
    // Nom si phase annulé
    if (this.workRequestCancelled && phase.phaseNumber === this.workRequest.phases.length) {
      return this.$t(`workFileTracking.tracking.${this.workRequest.offerType}.name.cancelled`) as string
    }

    // Nom de la phase en rapport avec son statut
    return this.$t(
      `workFileTracking.tracking.${this.workRequest.offerType}.name.${phase.status}.${phase.phaseNumber}`
    ) as string
  }

  public getDescription(phase: Phase): string | null {
    // Description si phase annulé
    if (this.workRequestCancelled && phase.phaseNumber === this.workRequest.phases.length) {
      return this.$t(`workFileTracking.tracking.${this.workRequest.offerType}.description.cancelled`) as string
    }

    // Description si phase onGoing basé sur le code intervention
    if (phase.status === PhaseSystemStatus.Ongoing) {
      const executionDate = phase.appointment?.executionDate
      const executionTimeSlot = phase.appointment?.executionTimeSlot

      const displayExecutionDate = executionDate ? dateService.displayDateText(executionDate) : ''
      const displayExecutionTimeSlot = executionTimeSlot
        ? this.$t('workFileTracking.tracking.timeSlot.' + executionTimeSlot)
        : ''
      // Gestion des cas de messages avec paramètres...
      // Message pour verbal or without offer
      if (
        this.workRequest.offerType === WorkOfferType.VerbalOffer ||
        this.workRequest.offerType === WorkOfferType.WithoutOffer
      ) {
        switch (phase.shortInterventionCode) {
          // Paramètre : {0} => [DATE_V2], {1} => [PERIOD_JOURNEE], {2} => [ADRESSE_TRAVAUX]
          case 'EX':
            if (phase.appointment) {
              return this.$t(
                `workFileTracking.tracking.${this.workRequest.offerType}.description.${phase.status}.${phase.shortInterventionCode}.withAppointment`,
                [displayExecutionDate, displayExecutionTimeSlot]
              ) as string
            } else {
              return this.$t(
                `workFileTracking.tracking.${this.workRequest.offerType}.description.${phase.status}.${phase.shortInterventionCode}.withoutAppointment`,
                [displayExecutionDate, displayExecutionTimeSlot]
              ) as string
            }

          default:
            return this.$t(
              `workFileTracking.tracking.${this.workRequest.offerType}.description.${phase.status}.${phase.shortInterventionCode}`,
              [displayExecutionDate]
            ) as string
        }
        // Message pour written offer
      } else {
        const address = `${this.workAddress.houseNumber}
                         ${this.workAddress.streetName}${
          this.workAddress.complement ? ' ' + this.workAddress.complement : ''
        } -
                         ${this.workAddress.zipCode} ${this.workAddress.cityName}`
        const numcc =
          this.customerAdvisor !== null && this.customerAdvisor.publicNumber
            ? this.customerAdvisor.publicNumber
            : '078/15.78.01'
        const dateCloture45PT5JO = phase.endDatePtPhase
          ? dateService.displayDateText(dateService.addWorkDays(phase.endDatePtPhase, 5))
          : ''
        const dateExLess4JO = executionDate
          ? dateService.displayDateText(dateService.addWorkDays(executionDate, -4))
          : ''
        const dateEx5JO = executionDate ? dateService.displayDateText(dateService.addWorkDays(executionDate, 5)) : ''
        const dateEx15JO = executionDate ? dateService.displayDateText(dateService.addWorkDays(executionDate, 15)) : ''
        switch (phase.shortInterventionCode) {
          // Paramètre : {0} => [DATE_V2], {1} => [PERIOD_JOURNEE], {2} => [ADRESSE_TRAVAUX]
          case 'V2':
            return this.$t(
              `workFileTracking.tracking.${this.workRequest.offerType}.description.${phase.status}.${phase.shortInterventionCode}`,
              [displayExecutionDate, displayExecutionTimeSlot, address]
            ) as string

          // Paramètre : {0} => [Date clôture 45PT + 5 JO], {1} => [NUM_CC]
          case 'OD':
            return this.$t(
              `workFileTracking.tracking.${this.workRequest.offerType}.description.${phase.status}.${phase.shortInterventionCode}`,
              [dateCloture45PT5JO, numcc]
            ) as string

          // Paramètre : {0} => [DATE_EX], {1} => [PERIODE], {2} => [Num_CC],
          // {3} => [DATE_EX-4JO], {4} => [DATE_EX+5JO], {5} => [DATE_EX+15JO]
          case 'EX':
            if (phase.appointment) {
              return this.$t(
                `workFileTracking.tracking.${this.workRequest.offerType}.description.${phase.status}.${phase.shortInterventionCode}.withAppointment`,
                [displayExecutionDate, displayExecutionTimeSlot, numcc, dateExLess4JO, dateEx5JO, dateEx15JO]
              ) as string
            } else {
              return this.$t(
                `workFileTracking.tracking.${this.workRequest.offerType}.description.${phase.status}.${phase.shortInterventionCode}.withoutAppointment`,
                [displayExecutionDate, numcc, dateExLess4JO, dateEx5JO, dateEx15JO]
              ) as string
            }

          // Paramètre : {0} => [DATE_EX], {1} => [Num_CC], {2} => [DATE_EX+5JO], {3} => [DATE_EX+15JO]
          case 'TA':
          case 'CA':
            return this.$t(
              `workFileTracking.tracking.${this.workRequest.offerType}.description.${phase.status}.${phase.shortInterventionCode}`,
              [displayExecutionDate, numcc, dateEx5JO, dateEx15JO]
            ) as string

          // Paramètre : {0} => [NUM_CC]
          case 'S1':
          case 'ET':
          case 'PE':
          case 'CC':
          case 'OV':
          case 'V1':
          case 'ST':
          case 'OF':
          case 'RE':
          case 'PT':
          case 'AU':
          case 'CP':
          case 'TE':
          case 'TR':
          case 'TX':
            return this.$t(
              `workFileTracking.tracking.${this.workRequest.offerType}.description.${phase.status}.${phase.shortInterventionCode}`,
              [numcc]
            ) as string

          // Message par défaut (sans paramètre)
          default:
            return this.$t(
              `workFileTracking.tracking.${this.workRequest.offerType}.description.${phase.status}.${phase.shortInterventionCode}`
            ) as string
        }
      }
      // Description si phase Closed basé sur le numéro de la phase
    } else if (phase.status === PhaseSystemStatus.Closed) {
      return this.$t(
        `workFileTracking.tracking.${this.workRequest.offerType}.description.${phase.status}.${phase.phaseNumber}`
      ) as string
    }
    // Pas de description pour les phases en ToBeStarted
    return null
  }
  //#endregion
}

export enum MeterType {
  smartMeter = 'smart-meter',
  mechanicalMeter = 'mechanical-meter',
  unknown = 'unknown'
}

export enum ElectricityPowerSupplyType {
  monophase = 'monophase',
  triphase = 'triphase',
  tetraphase = 'tetraphase',
  unknown = 'unknown'
}

export interface ElectricityInstallation {
  meterType: MeterType | null;
  electricityPowerSupplyType: ElectricityPowerSupplyType | null;
  power: number | null;
}


import Component from 'vue-class-component'
import { Form } from '@/views/forms/Form'
import Step from '@/components/steps/Step'
import { State, Mutation } from 'vuex-class'
import { validation } from '@ores/vue-library'
import FaqDisplay from '@/components/display/FaqDisplay.vue'
import { pixelFacebookService } from '@ores/vue-library'
import { StepMailRegistration } from '@/models'

@Component({
  components: {
    FaqDisplay
  },
  name: 'cmh-email-registration-step'
})
export default class CmhEmailRegistrationStep extends Step {
  //#region [Data]
  public emailAddress: string = ''

  @State('requestId', { namespace: 'newConnectionCmh' })
  public requestId!: string
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'newConnectionCmh/mailRegistration' })
  private store!: (index: string) => void
  //#endregion

  //#region [Method]
  public mounted() {
    pixelFacebookService.trackViewContent({
      content_ids: ['CmhForm'],
      content_name: 'Email Registration Step',
      content_type: 'product'
    })
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (isValid) {
      this.store(this.emailAddress)

      const data: StepMailRegistration = {
        email: this.emailAddress,
        requestId: this.requestId,
        language: this.$i18n.locale
      }

      this.$api.post<StepMailRegistration>('NewConnectionRequest/store/mail-registration', data)

      this.$appInsights.trackEvent('WR_NCCMH_Step0_Validated', { Segment: 'WR_NCCMH', WebRef: this.requestId })
    }
  }
  //#endregion
}


import { Component, Prop, Emit } from 'vue-property-decorator'
import { CompanyVatInfoFirstStepSelection, CompanyVatInfoSecondStepSelection } from '@/models'
import FieldBox from '@/components/inputs/Shared/FieldBox'

@Component({
  name: 'company-second-vat-info-selector'
})
export default class CompanySecondVatInfoSelector extends FieldBox {
  //#region [Property]
  @Prop({ type: Number, required: false }) public readonly firstSelection!: CompanyVatInfoFirstStepSelection | null
  @Prop({ type: Number, required: false }) public readonly value!: CompanyVatInfoSecondStepSelection | null
  //#endregion

  //#region [Computed]
  public get selection(): CompanyVatInfoSecondStepSelection | null {
    return this.value
  }

  public set selection(val: CompanyVatInfoSecondStepSelection | null) {
    this.change(val)
  }

  public get showSecondStep0and1() {
    return this.firstSelection === 0
  }

  public get showSecondStep2and3() {
    return this.firstSelection === 1 || this.firstSelection === 6
  }

  public get showSecondStep4and5() {
    return this.firstSelection === 2 || this.firstSelection === 4
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(value: CompanyVatInfoSecondStepSelection | null): CompanyVatInfoSecondStepSelection | null {
    return value
  }
  //#endregion
}

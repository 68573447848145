
import { Component } from 'vue-property-decorator'
import StepActions from '@/components/shared/StepActions.vue'
import Step from '@/components/steps/Step'
import { Form } from './Form'
import { StepDefinition } from '@ores/vue-library'
import { removeInterceptors } from '@/plugins/http-interceptors-global'
import { addEmobilityResponseInterceptors } from '@/plugins/http-response-interceptors-e-mobility'
import store from '@/store/index'
import CarSpecificationStep from '@/components/steps/e-mobility/CarSpecificationStep.vue'
import CarSelectedStep from '@/components/steps/e-mobility/CarSelectedStep.vue'
import DialogBox from '@/components/shared/DialogBox.vue'
import ApiErrors from '@/components/display/ApiErrors.vue'
import IdentificationStep from '@/components/steps/e-mobility/IdentificationStep.vue'
import ConsumingDeviceStep from '@/components/steps/e-mobility/ConsumingDeviceStep.vue'
import { Getter, Mutation, State } from 'vuex-class'
import GridAccessPointInfoOrInstallationStep from '@/components/steps/e-mobility/GridAccessPointInfoOrInstallationStep.vue'
import { ElectricityInstallation, ElectricMobilityGridAccessPointInfo } from '@/models'
import ResultStep from '@/components/steps/e-mobility/ResultStep.vue'

@Component({
  components: {
    StepActions,
    CarSpecificationStep,
    CarSelectedStep,
    ConsumingDeviceStep,
    IdentificationStep,
    GridAccessPointInfoOrInstallationStep,
    ResultStep,
    DialogBox,
    ApiErrors
  },
  name: 'e-mobility'
})
export default class EMobility extends Form {
  //#region [Data]
  @State('electricityInstallation', { namespace: 'eMobility/electricityInstallation' })
  public stateElectricityInstallation!: ElectricityInstallation

  @State('gridAccessPointInfo', { namespace: 'eMobility/identification' })
  public stateGridAccessPointInfo!: ElectricMobilityGridAccessPointInfo | null

  public stepDefinitions: StepDefinition[] = [
    new StepDefinition('car', 1, [new StepDefinition('', 2)]),
    new StepDefinition('electricity-installation', 3, [new StepDefinition('', 4)]),
    new StepDefinition('home-device', 5),
    new StepDefinition('result', 6)
  ]
  //#endregion

  //#region [Computed]
  public get checkShowFaq() {
    return this.currentStep === 4
  }

  public get resetLabel() {
    return this.currentStep === 6 ? this.$t('common.resetRequest') : null
  }
  //#endregion

  //#region [Getter]
  @Getter('getHasEan', { namespace: 'eMobility/identification' })
  public getHasEan!: string
  //#endregion

  //#region [Mutation]
  @Mutation('STORE_HAS_EAN', { namespace: 'eMobility/identification' })
  public storeHasEan!: (index: { hasEan: string | null }) => void
  //#endregion

  //#region [Method]
  public beforeCreate() {
    document.title = this.$t('e-mobility.formTitle').toString()
  }
  public mounted() {
    this.currentStep = 1
    this.lastStepSeen = 1
    this.canNavigateAfterLastStep = false

    this.onLangChanged(this.lang)

    if (this.interceptorId !== null) {
      removeInterceptors(this.$api, this.interceptorId)
    }
    const interceptorId = addEmobilityResponseInterceptors(this.$api, store)
    this.setInterceptorId(interceptorId)

    // Check maintenance
    this.$api.get('api/maintenanceManagement/electric-mobility')
  }

  public checkNextStep() {
    let isOk = false
    if (
      this.stateElectricityInstallation.meterType &&
      this.stateElectricityInstallation.electricityPowerSupplyType &&
      this.stateElectricityInstallation.power
    ) {
      isOk = true
    }
    if ((this.stateGridAccessPointInfo || isOk) && (!this.getHasEan || this.stateGridAccessPointInfo)) {
      this.nextStep()
    } else {
      this.storeHasEan({ hasEan: 'no' })
    }
  }

  public getStepComponent(): Step | null {
    return (this.$refs[`step${this.currentStep}`] as Step) || null
  }

  public resetForm() {
    this.currentStep = 1
    this.lastStepSeen = 1
    this.$store.dispatch('eMobility/reset')
    this.$nextTick(() => (this.currentStep = 1))
  }
  //#endregion
}

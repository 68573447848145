import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
// Import every rule we want to be available
import { email, min, max, required, alpha_num, max_value, min_value, numeric, integer, regex } from 'vee-validate/dist/rules';
// Import messages for desired locale
import fr from './messages/messages.fr';
import nl from './messages/messages.nl';
import de from './messages/messages.de';
import validators from './validators';

import { validation } from '@ores/vue-library';

/*
 * Validator
 */
export default {
  configure(lang: string) {
    // Install the locale
    localize({
      fr: { messages: { ...validation.messages.fr.messages, ...fr.messages } },
      nl: { messages: { ...validation.messages.nl.messages, ...nl.messages } },
      de: { messages: { ...validation.messages.de.messages, ...de.messages } },
    });

    // Activate the locale
    localize(lang);

    // Activate the vee-validate rules we want to use
    extend('email', email);
    extend('min', min);
    extend('max', max);
    extend('required', required);
    extend('alpha_num', alpha_num);
    extend('max_value', max_value);
    extend('min_value', min_value);
    extend('numeric', numeric);
    extend('integer', integer);
    extend('regex', regex);

    // Custom validators
    for (const validator of Object.values(validators)) {
      extend(validator.name, validator.validate);
    }

    for (const validator of Object.values(validation.validators)) {
      extend(validator.name, validator.validate);
    }

    // Register the component globally
    Vue.component('ValidationProvider', ValidationProvider);
    Vue.component('ValidationObserver', ValidationObserver);
  },
};


import { Component, Watch } from 'vue-property-decorator'
import StepActions from '@/components/shared/StepActions.vue'
import { Form } from './Form'
import { StepDefinition } from '@ores/vue-library'
import ApiErrors from '@/components/display/ApiErrors.vue'
import IdentificationStep from '@/components/steps/prosumer-declaration/IdentificationStep.vue'
import ContactInfoStep from '@/components/steps/prosumer-declaration/ContactInfoStep.vue'
import InstallationStep from '@/components/steps/prosumer-declaration/InstallationStep.vue'
import BatteryStep from '@/components/steps/prosumer-declaration/BatteryStep.vue'
import RelayStep from '@/components/steps/prosumer-declaration/RelayStep.vue'
import AccountingStep from '@/components/steps/prosumer-declaration/AccountingStep.vue'
import SummaryStep from '@/components/steps/prosumer-declaration/SummaryStep.vue'
import SendConfirmation from '@/components/inputs/ProsumerDeclaration/SendConfirmation.vue'
import DialogBox from '@/components/shared/DialogBox.vue'
import { removeInterceptors } from '@/plugins/http-interceptors-global'
import store from '@/store'
import Step from '@/components/steps/Step'
import { ContactInfo } from '@/models'
import { State } from 'vuex-class'
import { contactService } from '@/services'
import { addProsumerDeclarationResponseInterceptors } from '@/plugins/http-response-interceptors-prosumer-declaration'
import MeterReadingStep from '@/components/steps/prosumer-declaration/MeterReadingStep.vue'
import FilesStep from '@/components/steps/prosumer-declaration/FilesStep.vue'

@Component({
  components: {
    FilesStep,
    MeterReadingStep,
    RelayStep,
    BatteryStep,
    InstallationStep,
    IdentificationStep,
    ContactInfoStep,
    AccountingStep,
    SummaryStep,
    StepActions,
    SendConfirmation,
    DialogBox,
    ApiErrors
  },
  name: 'prosumer-declaration'
})
export default class ProsumerDeclaration extends Form {
  //#region [Data]
  public stepDefinitions: StepDefinition[] = [
    new StepDefinition('identification', 1),
    new StepDefinition('contact', 2),
    new StepDefinition('installation', 3, [
      new StepDefinition('', 4),
      new StepDefinition('', 5),
    ]),
    new StepDefinition('meterReading', 6),
    new StepDefinition('accounting', 7),
    new StepDefinition('documents', 8),
    new StepDefinition('summary', 9),
    new StepDefinition('confirm', 10)
  ]
  public isIdentified: boolean = false

  @State('contactInfo', { namespace: 'prosumerDeclaration/contactInfo' })
  public contactInfoState!: ContactInfo | null

  @State('eanCode', { namespace: 'prosumerDeclaration/identification' })
  public eanCodeState!: string | null


  // #endregion

  //#region [Computed]
  public get nextLabel(): string | null {
    if (this.currentStep === 9) {
      return this.$t('common.confirm').toString()
    }

    return null
  }

  public get multipleSend(): boolean {
    return this.contactInfoState !== null && this.contactInfoState.otherContacts.length > 0
  }

  public get sendConfirmationEmail(): string {
    let email: string = ''
    if (this.contactInfoState) {
      email += contactService.getContactEmail(this.contactInfoState)
      for (const otherContact of this.contactInfoState.otherContacts) {
        email += ', ' + otherContact.email
      }
    }
    return email
  }
  //#endregion

  //#region [Mutation]
  //#endregion

  //#region [Method]
  public beforeCreate(): void {
    document.title = this.$t('prosumerDeclaration.title').toString()
  }

  public mounted() {
    this.currentStep = 1
    this.lastStepSeen = 1
    this.stepToGoAfterValidation = 9
    this.stepsToValidate = []

    this.onLangChanged(this.lang)

    if (this.interceptorId !== null) {
      removeInterceptors(this.$api, this.interceptorId)
    }
    const interceptorId = addProsumerDeclarationResponseInterceptors(this.$api, store)
    this.setInterceptorId(interceptorId)

    // Check maintenance
    this.$api.get('api/maintenanceManagement/prosumer-declaration')
  }

  public getStepComponent(step: number): Step | null {
    return (this.$refs[`step${this.currentStep}`] as Step) || null
  }

  public resetForm() {
    this.lastStepSeen = 1
    this.$store.dispatch('prosumerDeclaration/reset')
    this.$nextTick(() => (this.currentStep = 1))
  }

  //#endregion

  //#region [Watch]
  @Watch('eanCodeState')
  public async onEanCodeStateChanged(newValue: string | null, oldValue: string | null) {
    if (newValue !== oldValue) {
      this.ensureLastStep(6, false)
    }
  }
  //#endregion
}


import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'add-connection-editable-box',
  components: {}
})
export default class AddConnectionEditableBox extends Vue {
  @Prop({ type: Number, required: true }) public meterCount!: number
  @Prop({ type: String, required: false }) public selectedFunctionality!: string
  @Prop({ type: Number, required: true }) public identifier!: number
  @Prop({ type: Number, required: false, default: 0 }) public excludingVatPrice!: number

  @Prop({ type: Function, required: true }) public onEdit!: (identifier: number) => void
  @Prop({ type: Function, required: true }) public onDelete!: (identifier: number) => void

  public mounted() { }
}

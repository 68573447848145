import {
  MeterElectricityTension,
  MeterElectricityType,
  ConstructionConfigurationInfo,
} from '@/models'

export interface ConfigurationInfoState {
  configurationInfo: ConstructionConfigurationInfo | null
  electricityTensions: MeterElectricityTension[]
  electricityTarifications: MeterElectricityType[]
}

const electricityTensions: MeterElectricityTension[] = ['mono230V', 'tri230V', 'tri400V', 'custom']
const electricityTarifications: MeterElectricityType[] = ['simple', 'dual', 'nightOnly', 'budget']

export const InitialState: ConfigurationInfoState = {
  configurationInfo: null,
  electricityTensions,
  electricityTarifications
}


import Vue from 'vue'
import Step from '@/components/steps/Step'
import Component from 'vue-class-component'
import IdentificationForm from '@/components/inputs/ProsumerBonus/IdentificationForm.vue'
import { AddressPoint, Identification } from '@/models'
import { Mutation, State } from 'vuex-class'
import { Inject } from 'vue-property-decorator'
import { helper } from '@/services'

@Component({
  name: 'identification-step',
  components: { IdentificationForm }
})
export default class IdentificationStep extends Step {
  //#region [Data]
  @State('identification', { namespace: 'prosumerBonus/identification' })
  public identificationState!: Identification | null

  public identification: Identification | null = null
  public identificationFailed: boolean = false
  public recaptcha: string | null = null
  //#endregion

  public get siteKey() {
    return process.env.VUE_APP_CAPTCHA_SITE_KEY
  }

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'prosumerBonus/identification' })
  private store!: (index: Identification | null) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.identification = helper.clone(this.identificationState)
    this.setIsBack(false)
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid && this.identification) {
      const data = {
        worksFileId: this.identification.worksFileId!.trim(),
        eanCode: this.identification.eanCode!.trim()
      }
      const response = await this.$api.post(`prosumer-bonus/identification`, data, {
        headers: {
          'g-recaptcha-response': this.recaptcha
        }
      })

      if (response.status === 400 || response.status === 404) {
        this.identificationFailed = true
        return false
      }

      if (response.status === 403) {
        this.$emit('alreadyCompleted')
        return false
      }

      if (response.status !== 200) {
        this.$store.dispatch('apiErrors/manageWorks', response)
        return false
      }

      sessionStorage.setItem('token', response.data)

      this.$appInsights.trackEvent('Prosumer_Bonus_StepIdentification_Validated', {
        Segment: 'Prosumer_Bonus',
        EanCode: this.identification.eanCode!.trim(),
        WorksFileId: this.identification.worksFileId!.trim()
      })
    }

    this.store(this.identification)
    return true
  }
  //#endregion
}

import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { CmhEnergyConfigurationState } from './types';

export const actions: ActionTree<CmhEnergyConfigurationState, RootState> = {
  validate({ commit, state }, offeringSelections: string[]): boolean {
    if (state?.modifiedConnections === null && state?.gasMeters === null && state?.packagedElectricityMeters === null) {
      return true
    }

    let change = false

    if (!offeringSelections.some((x: string) => x === 'electricity')
      && state.packagedElectricityMeters.length) {
      state.modifiedConnections = []
      state.packagedElectricityMeters = []
      change = true
    }

    if (!offeringSelections.some((x: string) => x === 'gas')
      && state.gasMeters.length) {
      state.gasMeters = []
      change = true
    }

    if (offeringSelections.some((x: string) => x === 'electricity')
      && !state.packagedElectricityMeters.length) {
      state.modifiedConnections = []
      state.packagedElectricityMeters = [state.defaultPackagedElectricityMeter]
      change = true
    }


    if (offeringSelections.some((x: string) => x === 'gas')
      && !state.gasMeters.length) {
      state.gasMeters = [state.defaultGasMeter]
      change = true
    }


    if (change) {
      commit('STORE', { gasMeters: state.gasMeters, packagedElectricityMeters: state.packagedElectricityMeters, modifiedConnections: state.modifiedConnections, isDefaultConfigurationOverriden: state.isDefaultConfigurationOverriden })
    }

    return !change
  }
}

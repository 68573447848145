import { AppointmentInfo, AppointmentSlot } from '@/models';

export interface AppointmentState {
  appointment: AppointmentInfo | null;
  searchStartDate: Date | null;
}

export const InitialState: AppointmentState = {
  appointment: null,
  searchStartDate: null
}

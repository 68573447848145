import { idGenerator } from '@/services'

export interface NewConnectionCmhState {
  requestId: string
}

export const InitialState: NewConnectionCmhState = {
  requestId: idGenerator.getNewWebReference()
}


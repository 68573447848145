
import { Component, Prop, Vue } from 'vue-property-decorator'
import { GasMeter } from '@/models'
import PackageEditableBox from './PackageEditableBox.vue'

@Component({
  components: {
    PackageEditableBox
  },
  name: 'package-gas'
})
export default class PackageGas extends Vue {
  @Prop({ type: Array, required: true }) public meters!: GasMeter[]

  @Prop({ type: Function, required: true }) public editGasBox!: (identifier: number) => void
  @Prop({ type: Function, required: true }) public deleteGasBox!: (identifier: number) => void

}

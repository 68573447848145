import { BuildingType } from '@/models/building-type';
import { CmhAddressState } from '@/store/new-connection-cmh/cmh-address/types';
import { RootState } from '@/store/types';
import { GetterTree } from 'vuex';

export const getters: GetterTree<CmhAddressState, RootState> = {
  isConnectMyHomeAvailableForSelectedBuilding(state: CmhAddressState, gets, rs: RootState, rg) {
    if (state?.addressPoint?.flatNumber && state?.addressPoint?.selection === BuildingType.building) {
      return state.addressPoint.flatNumber <= 4
    }
    return true;
  },
  isProximusAvailable(state: CmhAddressState, gets, rs: RootState, rg) {
    return state.addressPoint?.addressPoint?.subCity.connectMyHomeActivated
      && state.addressPoint.addressPoint.subCity.connectMyHomeInformation?.proximusAvailable
      || false
  },
  isVooAvailable(state: CmhAddressState, gets, rs: RootState, rg) {
    return state.addressPoint?.addressPoint?.subCity.connectMyHomeActivated
      && state.addressPoint.addressPoint.subCity.connectMyHomeInformation?.vooAvailable
      || false
  },
  isSwdeAvailable(state: CmhAddressState, gets, rs: RootState, rg) {
    return state.addressPoint?.addressPoint?.subCity.connectMyHomeActivated
      && state.addressPoint.addressPoint.subCity.connectMyHomeInformation?.swdeAvailable
      || false
  },
  isGasAvailable(state: CmhAddressState, gets, rs: RootState, rg: any) {
    return state.addressPoint?.addressPoint?.subCity?.operationalOffices?.gasOffice
      && state?.addressPoint?.addressPoint?.subCity?.gridManagers?.gasManager?.isOres || false
  },
  isElectricityAvailable(state: CmhAddressState, gets, rs: RootState, rg: any) {
    return state.addressPoint?.addressPoint?.subCity?.operationalOffices?.electricityOffice
      && state?.addressPoint?.addressPoint?.subCity?.gridManagers?.electricityManager?.isOres || false
  }
}

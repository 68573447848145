
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { SynergridChangeTypeRequest } from '@/models'
import { idGenerator } from '@/services'
import FieldBox from '@/components/inputs/Shared/FieldBox'

@Component({
  name: 'synergrid-request-type-selector',
})
export default class SynergridRequestTypeSelector extends FieldBox {
  //#region [Property]
  @Prop({ type: Number, required: false }) public readonly value!: SynergridChangeTypeRequest | null
  //#endregion

  //#region [Computed]
  public get selection(): number | null {
    return this.value
  }

  public set selection(value: number | null) {
    this.change(value)
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(value: SynergridChangeTypeRequest | null): SynergridChangeTypeRequest | null {
    return value
  }
  //#endregion
}

import { Module } from 'vuex'
import { mutations } from './mutations'
import { InitialState, ChargingStationInformationState } from './types'
import { RootState } from '@/store/types';

const namespaced: boolean = true

export const information: Module<ChargingStationInformationState, RootState> = {
  namespaced,
  state: InitialState,
  mutations
}

import Vue, { AsyncComponent, ComponentOptions } from 'vue'
import Router, { NavigationGuard } from 'vue-router'
import ConstructionConnection from './views/forms/ConstructionConnection.vue'
import EMobility from './views/forms/EMobility.vue'
import LandingPageCmh from './views/forms/LandingPageCmh.vue'
import NewConnectionForm from './views/forms/NewConnectionForm.vue'
import LandingPageOres from './views/forms/LandingPageOres.vue'
import OtherEquipmentConnection from './views/forms/OtherEquipmentConnection.vue'
import SynergridConnection from './views/forms/SynergridConnection.vue'
import SmartMeterConnection from './views/forms/SmartMeterConnection.vue'
import WorkFileTracking from './views/forms/WorkFileTracking.vue'
import TemporaryConnection from './views/forms/TemporaryConnection.vue'
import ProsumerBonus from './views/forms/ProsumerBonus.vue'
import ProsumerDeclaration from './views/forms/ProsumerDeclaration.vue'
import ChargingStationDeclaration from './views/forms/ChargingStationDeclaration.vue'
import Maintenance from './views/Maintenance.vue'
import Home from './views/Home.vue'

Vue.use(Router)

interface I18nRoute {
  name: string,
  paths: { [key: string]: string } & { fr: string, nl: string, de: string },
  props?: { [key: string]: any },
  component: ComponentOptions<Vue> | typeof Vue | AsyncComponent
  beforeEnter?: NavigationGuard
}

const blockInProduction: NavigationGuard = (to, from, next) => {
  // Only allow when NOT in production
  if (Vue.prototype.$settings.environment !== 'production') {
    next()
  } else {
    window.location.href = 'https://www.ores.be/'
  }
}

export const routes: I18nRoute[] = [
  {
    name: 'home',
    paths: {
      fr: '/',
      nl: '/nl',
      de: '/de'
    },
    component: Home,
    beforeEnter: blockInProduction
  },
  {
    name: 'landing-page-cmh',
    paths: {
      fr: '/cmh-introduction/cmh',
      nl: '/cmh-invoering/cmh',
      de: '/cmh-einfuhrung/cmh'
    },
    component: LandingPageCmh
  },
  {
    name: 'landing-page-ores',
    paths: {
      fr: '/cmh-introduction/ores',
      nl: '/cmh-invoering/ores',
      de: '/cmh-einfuhrung/ores'
    },
    component: LandingPageOres
  },
  {
    name: 'new-connection-cmh',
    paths: {
      fr: '/nouveau-raccordement/definitif',
      nl: '/nieuwe-aansluiting/definitief',
      de: '/neuanschluss/definitiv'
    },
    component: NewConnectionForm
  },
  {
    name: 'construction-connection',
    paths: {
      fr: '/nouveau-raccordement/chantier',
      nl: '/nieuwe-aansluiting/werf',
      de: '/neuanschluss/baustelle'
    },
    component: ConstructionConnection
  },
  {
    name: 'other-equipment-connection',
    paths: {
      fr: '/nouveau-raccordement/autre-equipment',
      nl: '/nieuwe-aansluiting/andere-installatie',
      de: '/neuanschluss/sonstige-ausruestungen'
    },
    component: OtherEquipmentConnection
  },
  {
    name: 'temporary-connection',
    paths: {
      fr: '/nouveau-raccordement/temporaire',
      nl: '/nieuwe-aansluiting/tijdelijk',
      de: '/neuanschluss/voruebergehend'
    },
    component: TemporaryConnection
  },
  {
    name: 'synergrid-connection',
    paths: {
      fr: '/nouveau-raccordement/synergrid',
      nl: '/nieuwe-aansluiting/synergrid',
      de: '/neuanschluss/synergrid'
    },
    component: SynergridConnection
  },
  {
    name: 'prosumer-bonus',
    paths: {
      fr: '/prime-prosumer',
      nl: '/prosumerpremie',
      de: '/prosumentenpramie'
    },
    component: ProsumerBonus

  },
  {
    name: 'prosumer-declaration',
    paths: {
      fr: '/declaration-prosumer',
      nl: '/prosumers-aangifte',
      de: '/prosumenten-erklarung'
    },
    component: ProsumerDeclaration,
    beforeEnter: () => {
      // Redirection to the new prosumer declaration form
      if (process.env.VUE_APP_PROSUMER_DECLARATION_FORM_REDIRECTION) {
        window.location.href = process.env.VUE_APP_PROSUMER_DECLARATION_FORM_REDIRECTION
      }
    }
  },
  {
    name: 'charging-station-declaration',
    paths: {
      fr: '/declaration-borne-recharge',
      nl: '/verklaring-laadstation',
      de: '/anweisung-ladestation'
    },
    component: ChargingStationDeclaration,
    beforeEnter: () => {
      // Redirection to the new charging station declaration form
      if (process.env.VUE_APP_CHARGINGSTATION_DECLARATION_FORM_REDIRECTION) {
        window.location.href = process.env.VUE_APP_CHARGINGSTATION_DECLARATION_FORM_REDIRECTION
      }
    }
  },
  {
    name: 'smart-meter-connection',
    paths: {
      fr: '/nouveau-raccordement/compteur-intelligent',
      nl: '/nieuwe-aansluiting/slimme-meter',
      de: '/neuanschluss/intelligenter-zaehler'
    },
    component: SmartMeterConnection
  },
  {
    name: 'smart-meter-connection-mock',
    paths: {
      fr: '/mock/nouveau-raccordement/compteur-intelligent',
      nl: '/mock/nieuwe-aansluiting/slimme-meter',
      de: '/mock/neuanschluss/intelligenter-zaehler'
    },
    props: {
      mockOutsideHour: true
    },
    component: SmartMeterConnection
  },
  {
    name: 'work-file-tracking',
    paths: {
      fr: '/suivi-dossier',
      nl: '/dossier-opvolging',
      de: '/weiterverfolgung-akte'
    },
    component: WorkFileTracking
  },
  {
    name: 'e-mobility',
    paths: {
      fr: '/recharger-voiture',
      nl: '/auto-opladen',
      de: '/fahrzeug-aufladen'
    },
    component: EMobility
  },
  {
    name: 'maintenance',
    paths: {
      fr: '/maintenance',
      nl: '/onderhoud',
      de: '/wartung'
    },
    component: Maintenance
  },
  {
    name: 'old-prosumer-declaration',
    paths: {
      fr: '/prosumer-declaration',
      nl: '/prosumer-declaration',
      de: '/prosumer-declaration'
    },
    component: ProsumerDeclaration,
    beforeEnter: () => {
      // TODO delete in oct 23
      // we want an actual redirection hence not using next(/ ** /)
      window.location.href = '/declaration-prosumer'
    }
  }
]

const router = new Router({
  mode: 'history',
  routes: [
    ...routes.map((r) => {
      return Object.entries(r.paths).map((p) => {
        return {
          name: `${r.name}-${p[0]}`,
          path: p[1],
          meta: { lang: p[0] },
          component: r.component,
          props: { lang: p[0], ...(r.props || {}) },
          beforeEnter: r.beforeEnter
        }
      })
    }).flat()
  ]
})

export function routeAlternate(currentLang: string, targetLang: string) {
  const currentRoute = router.currentRoute.path
  const route = routes.find((r) => r.paths[currentLang] === currentRoute)
  if (route != null) {
    router.push({ path: route.paths[targetLang] })
  }
}

export default router

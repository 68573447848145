import { Vue, Component, Emit } from 'vue-property-decorator'

@Component
export default class InputWithValidation extends Vue {
  //#region [Emit]
  @Emit('validation')
  public notifyValidation(isValid: boolean): boolean {
    return isValid
  }
  //#endregion

  //#region [Method]
  public beforeDestroy() {
    this.notifyValidation(false)
  }
  //#endregion
}


import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElectricityPowerSupplyType, PackageDTO, PackagedElectricityMeter } from '@/models'
import PowerSelectBox from '@/components/inputs/Configuration/PowerSelectBox.vue'
import { helper } from '@/services'
import { nextTick } from 'vue'

@Component({
  components: {
    PowerSelectBox
  },
  name: 'cmh-electricity-package-popup-form'
})
export default class CmhElectricityPackagePopupForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public electricityMeter!: PackagedElectricityMeter
  @Prop({ type: Object, required: true }) public defaultPackagedElectricityMeter!: PackagedElectricityMeter
  @Prop({ type: Array, required: true }) public meterElectricityPackages!: PackageDTO[]
  @Prop({ type: Boolean, required: true }) public isHouseSelected!: boolean
  //#endregion

  //#region [Data]

  public meter: PackagedElectricityMeter = this.defaultPackagedElectricityMeter;

  //#endregion

  //#region [Method]
  public mounted() {
    if (this.meter !== null) {
      this.meter = helper.clone(this.electricityMeter)
    }
    if (this.isHouseSelected) {
      // This line forced a first validation. Necessary in case where isHouseSelected === true because no input field
      nextTick(() => this.$validatorObs.validate())
    }
  }

  meterConnectionTypeChange(connectionType: string) {
    switch (connectionType) {
      case 'monophase':
        this.meter.connectionType = ElectricityPowerSupplyType.monophase
        break
      case 'triphase':
        this.meter.connectionType = ElectricityPowerSupplyType.triphase
        break
      default:
        this.meter.connectionType = null
        break
    }
  }

  selectPackage(selectedPackage: PackageDTO) {
    if (selectedPackage.key === 'Confort') {
      this.meter.connectionType = ElectricityPowerSupplyType.triphase
    } else if (selectedPackage.key === 'Essentiel') {
      this.meter.connectionType = ElectricityPowerSupplyType.monophase
    } else {
      this.meter.connectionType = null
    }
    this.meter.customPower = false
    this.meter.wantedPower = null
    this.meter.package = selectedPackage
  }

  customPowerClick() {
    this.meter.customPower = true
    this.meter.package = {
      key: '69+',
      maximumPower: {
        kiloVoltAmpere: -1,
        voltAmpere: -1
      }
    }
  }

  public submit() {
    this.$emit('submit', this.meter)
  }
  //#endregion

  //#region [Watch]
  @Watch('electricityMeter')
  public onChange() {
    this.meter = helper.clone(this.electricityMeter)
  }

  //#endregion
}

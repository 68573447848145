
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { CompanyVatInfoFirstStepSelection } from '@/models'

@Component({
  name: 'company-first-vat-info-selector'
})
export default class CompanyFirstVatInfoSelector extends Vue {
  //#region [Property]
  @Prop({ type: Number, required: false }) public readonly value!: CompanyVatInfoFirstStepSelection | null
  //#endregion

  //#region [Computed]
  public get selection(): CompanyVatInfoFirstStepSelection | null {
    return this.value
  }

  public set selection(val: CompanyVatInfoFirstStepSelection | null) {
    this.change(val)
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(value: CompanyVatInfoFirstStepSelection | null): CompanyVatInfoFirstStepSelection | null {
    return value
  }
  //#endregion
}

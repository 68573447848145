import { GetterTree } from 'vuex'
import { IdentificationState } from './types';
import { RootState } from '@/store/types';

export interface Step1GetterTree {
  isElectricityAvailable: boolean
  isGasAvailable: boolean
  isElecticityManaged: boolean
  isGasManaged: boolean
}

export const getters: GetterTree<IdentificationState, RootState> = {
  isElectricityAvailable(state): boolean {
    return !!state.subCity && !!state.subCity.operationalOffices.electricityOffice
  },
  isGasAvailable(state): boolean {
    return !!state.subCity && !!state.subCity.operationalOffices.gasOffice
  },
  isElectricityManaged(state: IdentificationState, gets: Step1GetterTree): boolean {
    if (!state.subCity || !gets.isElectricityAvailable) {
      return false
    }

    const mgr = state.subCity.gridManagers.electricityManager
    return !!mgr && mgr.isOres
  },
  isGasManaged(state: IdentificationState, gets: Step1GetterTree): boolean {
    if (!state.subCity || !gets.isGasAvailable) {
      return false
    }

    const mgr = state.subCity.gridManagers.gasManager
    return !!mgr && mgr.isOres
  }
}

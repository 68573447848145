
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { GasMeter, MeterGasTechnicalCharacteristic } from '@/models'
import { helper } from '@/services'

@Component({
  name: 'cmh-gas-popup-form'
})
export default class CmhGasPopupForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public gasMeter!: GasMeter
  // @Prop({ type: Array, required: true }) public destinations!: MeterDestination[]
  @Prop({ type: Array, required: true }) public technicalCharacteristics!: MeterGasTechnicalCharacteristic[]
  //#endregion*

  //#region [Data]
  public meter: GasMeter = {
    count: 1,
    technicalCharacteristic: 'standard',
    destination: 'residential',
    power: 60,
    remarks: null
  }
  //#endregion

  //#region [Method]
  public mounted() {
    if (this.gasMeter != null) {
      this.meter = helper.clone(this.gasMeter)
    }
  }

  public validateMeter() {
    this.$emit('validateMeter', this.meter)
  }
  //#endregion

  //#region [Watch]
  @Watch('gasMeter')
  public gasMeterChange(value: GasMeter) {
    this.meter = helper.clone(this.gasMeter)
  }

  @Watch('meter.technicalCharacteristic')
  public onTechnicalCharacteristicChanged(newVal: MeterGasTechnicalCharacteristic) {
    if (newVal === 'standard') {
      this.meter.power = 60
    }
  }
  //#endregion
}

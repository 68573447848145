
import { Vue, Component, Prop, Emit, Watch, Inject } from 'vue-property-decorator'
import { EnergySelection, AddressPoint, EnergyProductionInfoModel } from '@/models'
import { YesNoMaybeSelection } from '@ores/vue-library'

@Component({
  name: 'energy-production-info'
})
export default class EnergyProductionInfo extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: false }) public readonly value!: EnergyProductionInfoModel | null
  @Prop({ type: Boolean, required: false, default: true }) public readonly askProductionDetails!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly hideDontKnowOption!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly downClassYes!: boolean
  //#endregion

  //#region [Computed]
  public get yesNoMaybeSelection(): YesNoMaybeSelection | null {
    return this.value ? this.value.hasEnergyProduction : null
  }
  public set yesNoMaybeSelection(val: YesNoMaybeSelection | null) {
    this.change(val, null, null)
  }

  public get kvaPower(): number | null {
    return this.value ? this.value.kVAPower : null
  }
  public set kvaPower(val: number | null) {
    val = val + '' === '' ? null : val
    this.change(this.yesNoMaybeSelection, val, this.kwcPower)
  }

  public get kwcPower(): number | null {
    return this.value ? this.value.kwcPower : null
  }
  public set kwcPower(val: number | null) {
    val = val + '' === '' ? null : val
    this.change(this.yesNoMaybeSelection, this.kvaPower, val)
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(
    data: YesNoMaybeSelection | null,
    kvaPower: number | null,
    kwcPower: number | null
  ): EnergyProductionInfoModel | null {
    if (data !== null) {
      return { hasEnergyProduction: data, kVAPower: kvaPower, kwcPower }
    } else {
      return null
    }
  }
  //#endregion
}

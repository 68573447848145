
import { CarType } from '@/models'
import { Component, Emit, Prop } from 'vue-property-decorator'
import FieldBox from '../../Shared/FieldBox'

@Component({
  name: 'car-type-selector'
})
export default class CarTypeSelector extends FieldBox {
  //#region [Property]
  @Prop({ type: String, required: false, default: '' }) public readonly value!: CarType
  //#endregion

  //#region [Computed]
  public get carType(): string | null {
    return this.value
  }
  public set carType(value: string | null) {
    this.change(value)
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(value: string | null): string | null {
    return value
  }
  //#endregion
}


import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import { RelayInformation } from '@/models/prosumer-declaration/prosumer-declaration'

@Component({
  name: 'relay-information-component'
})
export default class RelayInformationComponent extends Vue {
  @Prop({ type: Object, required: true }) public readonly value!: RelayInformation

  public decouplingRelayModalOpen: boolean = false
  public nonReturnRelayModalOpen: boolean = false

 @Watch('value.hasRelay', { deep: true })
  public onHasRelayChanged(newVal: boolean) {
      this.value.firstDecouplingRelay = { brand: null, type: null }
      this.value.secondDecouplingRelay = { brand: null, type: null }
      this.value.nonReturnRelay = { brand: null, type: null, power: null }

  }

}


import { Vue, Component, Inject, Watch } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import VatInfoSelector from '@/components/inputs/Vat/VatInfoSelector.vue'
import VatInfoBox from '@/components/inputs/Vat/VatInfoBox.vue'
import {
  IndividualVatInfoSelection,
  CompanyVatInfoSelection,
  FreelanceVatInfoSelection,
  ContactInfo,
  VatInfo
} from '@/models'
import { State, Mutation } from 'vuex-class'

@Component({
  name: 'vat-info-step',
  components: {
    VatInfoSelector,
    VatInfoBox
  }
})
export default class VatInfoStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'temporaryConnection' })
  public requestId!: string
  @State('contactInfo', { namespace: 'temporaryConnection/contactInfo' })
  public contactInfoState!: ContactInfo
  @State('freelanceVatInfoSelection', { namespace: 'temporaryConnection/vatInfo' })
  public freelanceVatInfoSelectionState!: FreelanceVatInfoSelection | null
  @State('companyVatInfoSelection', { namespace: 'temporaryConnection/vatInfo' })
  public companyVatInfoSelectionState!: CompanyVatInfoSelection | null

  public freelanceVatInfoSelection: FreelanceVatInfoSelection | null = null
  public companyVatInfoSelection: CompanyVatInfoSelection | null = null
  //#endregion

  //#region [Computed]
  public get individualVatInfoSelection(): IndividualVatInfoSelection | null {
    return this.contactInfoState.contactType === 'individual' ? IndividualVatInfoSelection.LessThanTenYears : null
  }
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'temporaryConnection/vatInfo' })
  private store!: (index: VatInfo | null) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    this.freelanceVatInfoSelection = this.freelanceVatInfoSelectionState
    this.companyVatInfoSelection = this.companyVatInfoSelectionState
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid) {
      this.$appInsights.trackEvent('WR_TC_StepVat_Validated', {
        Segment: 'WR_TC',
        WebRef: this.requestId
      })
    }

    this.store({
      individualVatInfoSelection: this.individualVatInfoSelection,
      freelanceVatInfoSelection: this.freelanceVatInfoSelection,
      companyVatInfoSelection: this.companyVatInfoSelection
    })
  }
  //#endregion
}

import { WorkFile } from '@/models';
import { MutationTree } from 'vuex';
import { WorkFileState } from './types';

export const mutations: MutationTree<WorkFileState> = {
  STORE(state, data: WorkFile) {
    state.workFile = data
  },
  CLEAR(state) {
    state.workFile = null
  }
}

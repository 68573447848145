import {
  ChargingStationDeclarationGridAccessPoint,
  SubCity
} from '@/models'
import { MutationTree } from 'vuex';
import { IdentificationState } from './types';

export const mutations: MutationTree<IdentificationState> = {
  STORE(state, data: { eanCode: string | null, meterLastFourNumbers: string | null }) {
    state.eanCode = data.eanCode
    state.meterLastFourNumbers = data.meterLastFourNumbers
  },
  STORE_GRID_ACCESS_POINT(state, data: ChargingStationDeclarationGridAccessPoint) {
    state.gridAccessPoint = data
  },
  CLEAR(state) {
    state.eanCode = null
    state.gridAccessPoint = null
  }
}

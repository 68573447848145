
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Emit } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import { chatService } from '@/services'

@Component({ name: 'step-actions' })
export default class StepActions extends Vue {
  //#region [Property]
  @Prop({ type: Boolean, required: false, default: true }) public readonly hasPrevious!: boolean
  @Prop({ type: Boolean, required: false, default: true }) public readonly hasNext!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly hasPrint!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly hasReset!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly hasSave!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly hasSend!: boolean
  @Prop({ type: String, required: false, default: null }) public readonly loading!: string
  @Prop({ type: Boolean, required: false, default: true }) public readonly canContinue!: boolean
  @Prop({ type: String, required: false, default: null }) public readonly nextLabel!: string
  @Prop({ type: Boolean, required: false, default: false }) public readonly showFaq!: boolean
  @Prop({ type: String, required: false, default: null }) public readonly faqLabel!: string
  @Prop({ type: Boolean, required: false, default: false }) public readonly showChatButton!: boolean
  @Prop({ type: String, required: false, default: null }) public readonly faqLink!: string
  @Prop({ type: String, required: false, default: null }) public readonly linkLabel!: string
  //#endregion

  //#region [Data]
  @State('requestId', { namespace: 'eMobility' })
  public requestId!: string
  //#endregion

  //#region [Computed]
  private get nextButtonLabel(): string {
    if (this.hasSend) {
      return this.$t('common.sendLabel').toString()
    }
    return this.nextLabel ? this.nextLabel : this.$t('common.nextButton').toString()
  }
  //#endregion

  //#region [Mutation]
  @Mutation('SET_ISBACK')
  private setIsBack!: (data: boolean) => void
  //#endregion

  //#region [Method]
  public print(): void {
    window.print()
  }

  public getState(key: string, loading: string, canContinue: boolean) {
    if (!loading) {
      return canContinue || key !== 'next' ? null : 'lib__btn--disabled'
    }

    return key === loading ? 'lib__btn--loading' : 'lib__btn--disabled'
  }

  public tryNext(): void {
    if (this.canContinue && !this.loading) {
      this.next()
    }
  }

  public tryPrevious(): void {
    if (!this.loading) {
      this.previous()
    }
  }

  public tryReset(): void {
    if (!this.loading) {
      this.reset()
    }
  }

  public openChat() {
    chatService.openChat()
  }

  public trackClick(): void {
    const url = window.location.href
    let segment = ''
    if (url.includes('recharger-voiture') || url.includes('auto-opladen') || url.includes('fahrzeug-aufladen')) {
      segment = 'EMobility'
    }
    if (segment !== '') {
      this.$appInsights.trackEvent(`${segment}_linkClick`, {
        Segment: segment,
        WebRef: this.requestId,
        Link: this.faqLink
      })
    }
  }
  //#endregion

  //#region [Emit]
  @Emit('next')
  public next() {
    this.setIsBack(false)
    return true
  }

  @Emit('previous')
  public previous() {
    this.setIsBack(true)
    return true
  }

  @Emit('reset')
  public reset() {
    return true
  }

  @Emit('save')
  public save() {
    return true
  }
  //#endregion
}

import { ElectricMobilityGridAccessPointInfo } from '@/models';

export interface IdentificationState {
  hasEan: string | null
  eanCode: string | null
  gridAccessPointInfo: ElectricMobilityGridAccessPointInfo | null
  gridAccessPointEanCode: string | null
  gridAccessPointNokReason: string | null
  token: string | null
}

export const InitialState: IdentificationState = {
  hasEan: null,
  eanCode: null,
  gridAccessPointInfo: null,
  gridAccessPointEanCode: null,
  gridAccessPointNokReason: null,
  token: null
}

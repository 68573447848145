
import { Component, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'

import Step from '@/components/steps/Step'
import ResumeContactInfo from '@/components/display/Resume/ResumeContactInfo.vue'
import { ChargingStationDeclarationGridAccessPoint, ContactInfo } from '@/models'
import {
  ChargingStationInformation,
  SendChargingStationDeclarationCommand
} from '@/models/charging-station-declaration'
import { dateService } from '@ores/vue-library'
import { AxiosRequestConfig } from 'axios'

@Component({
  name: 'summary-step',
  components: {
    ResumeContactInfo
  }
})
export default class SummaryStep extends Step {
  //#region [Property]
  @Prop({ type: Boolean, default: false }) public readonly readOnly!: boolean
  //#endregion

  //#region [Data]
  @State('gridAccessPoint', { namespace: 'chargingStationDeclaration/identification' })
  public gridAccessPointState!: ChargingStationDeclarationGridAccessPoint
  @State('contactInfo', { namespace: 'chargingStationDeclaration/contactInfo' })
  public contactInfoState!: ContactInfo
  @State('requestId', { namespace: 'chargingStationDeclaration' })
  public requestId!: string
  @State('stations', { namespace: 'chargingStationDeclaration/information' })
  public chargingStationsState!: ChargingStationInformation[]
  @State('lang')
  public language!: string
  //#endregion

  //#region [Methods]
  public displayDate(date: Date): string {
    return dateService.displayDate(date)
  }

  public async save(next: boolean, valid: boolean) {
    if (next && valid) {
      const data: SendChargingStationDeclarationCommand = {
        webReference: this.requestId,
        language: this.language,
        gridAccessPoint: this.gridAccessPointState,
        contactInfo: this.contactInfoState,
        stations: this.chargingStationsState.map((cs) => {
          return {
            power: cs.power !== -1 ? cs.power : null,
            otherPower: cs.power === -1,
            usage: cs.usage!,
            plug: cs.plug!,
            inServiceDate: cs.inServiceDate!,
            biDirectional: cs.biDirectional!,
            inService: cs.inService!,
            isSmart: cs.isSmart!,
            brand: cs.brand!.brand,
            model: cs.model!,

          }
        })
      }

      const config: AxiosRequestConfig = { headers: { 'Authorization': sessionStorage.getItem('token') } }
      await this.$api.post('/ChargingStationDeclaration', data, config)

      this.$appInsights.trackEvent('ChargingStationDeclaration_StepSummary_Validated', {
        Segment: 'ChargingStationDeclaration',
        WebRef: this.requestId
      })
    }
  }

  public goToStep(step: number) {
    this.$emit('goToStep', step)
  }

  //#endregion
}


import HasEanSelector from '@/components/inputs/EMobility/HasEanSelector/HasEanSelector.vue'
import { ElectricMobilityGridAccessPointInfo } from '@/models'
import { chatService, helper } from '@/services'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import Step from '../Step'

@Component({
  name: 'identification-step',
  components: {
    HasEanSelector
  }
})
export default class IdentificationStep extends Step {
  //#region [Data]
  @State('hasEan', { namespace: 'eMobility/identification' })
  public hasEanState!: string
  @State('requestId', { namespace: 'eMobility' })
  public requestId!: string
  @State('gridAccessPointInfo', { namespace: 'eMobility/identification' })
  public stateGridAccessPointInfo!: ElectricMobilityGridAccessPointInfo
  @State('gridAccessPointEanCode', { namespace: 'eMobility/identification' })
  public stateGridAccessPointEanCode!: string
  @State('token', { namespace: 'eMobility/identification' })
  public stateToken!: string | null
  @State('eanCode', { namespace: 'eMobility/identification' })
  public stateEanCode!: string | null

  public hasEanSelection: string | null = null

  public eanCode: string | null = null

  public recaptchaToken: string | null = null
  public gridAccessPointInfo: ElectricMobilityGridAccessPointInfo | null = null
  public loading: boolean = false
  public isIdentified: boolean = false
  public recaptchaKey: number = 0

  public isEanModalOpen: boolean = false

  //#endregion

  //#region [Computed]
  public get siteKey() {
    return process.env.VUE_APP_CAPTCHA_SITE_KEY
  }
  public get isIdentifiedAndCanContinue(): boolean {
    if (this.hasEanSelection === 'no') {
      return true
    }
    return this.isIdentified && !!this.gridAccessPointInfo
  }
  //#endregion

  //region [Mutation]
  @Mutation('STORE', { namespace: 'eMobility/identification' })
  public store!: (index: { eanCode: string | null }) => void

  @Mutation('STORE_HAS_EAN', { namespace: 'eMobility/identification' })
  public storeHasEan!: (index: { hasEan: string | null }) => void

  @Mutation('STORE_GRID_ACCESS_POINT_INFO', { namespace: 'eMobility/identification' })
  public storeGridAccessPointInfo!: (index: ElectricMobilityGridAccessPointInfo | null) => void

  @Mutation('STORE_GRID_ACCESS_POINT_EAN_CODE', { namespace: 'eMobility/identification' })
  public storeGridAccessPointEanCode!: (index: string | null) => void

  @Mutation('STORE_GRID_ACCESS_POINT_NOK_REASON', { namespace: 'eMobility/identification' })
  public storeGridAccessPointNokReason!: (index: string | null) => void

  @Mutation('STORE_TOKEN', { namespace: 'eMobility/identification' })
  public storeToken!: (index: string | null) => void

  @Mutation('CLEAR_API_ERRORS', { namespace: 'apiErrors' })
  private clearApiErrors!: () => void
  //#endregion

  //#region [Watch]
  @Watch('eanCode')
  public onEanCodeChanged(value: string | null) {
    this.clearApiErrors()
    this.isIdentified = !!value && value === this.stateEanCode
    return value
  }

  @Watch('stateEanCode')
  public onEanCodeStateChanged(value: string | null) {
    if (!value) {
      this.isIdentified = false
    }
  }

  @Watch('hasEanSelection')
  public onSelectionChanged(value: string | null) {
    this.storeHasEan({ hasEan: value })
  }
  //#endregion

  //region [Method]
  public mounted() {
    this.eanCode = helper.clone(this.stateEanCode)
    this.hasEanSelection = helper.clone(this.hasEanState)
    this.gridAccessPointInfo = helper.clone(this.stateGridAccessPointInfo)
    this.isIdentified = !!this.stateGridAccessPointInfo
  }

  public onEanModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'Ean'
    })
    this.isEanModalOpen = !this.isEanModalOpen
  }

  public openChat() {
    chatService.openChat()
  }

  public trackClick(): void {
    this.$appInsights.trackEvent('EMobility_linkClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Link: 'Formulaire code ean'
    })
  }

  public checkGridAccessPointInfoCorrect(value: ElectricMobilityGridAccessPointInfo | null): boolean {
    if (value && value.electricMobilityMeterInfos) {
      if (value.electricMobilityMeterInfos.length === 1) {
        const data = value.electricMobilityMeterInfos[0]
        if (data.amperage && data.electricityPowerSupplyType && data.isActive) {
          this.storeGridAccessPointNokReason(null)
          return true
        } else {
          // Manque certaines infos compteurs
          this.storeGridAccessPointNokReason('missingInformation')
        }
      } else {
        // plus d'un compteur elec
        this.storeGridAccessPointNokReason('moreThanOneMeter')
      }
    } else {
      // EAN Not Found
      this.storeGridAccessPointNokReason('eanNotFound')
    }
    return false
  }

  public async checkEMobilityEanCode(): Promise<ElectricMobilityGridAccessPointInfo | boolean | null> {
    if (this.eanCode) {
      if (this.eanCode !== this.stateGridAccessPointEanCode) {
        let token = this.stateToken
        if (!token) {
          const responseToken = await this.$api.get<string>(`electricMobility/token`, {
            headers: { 'g-recaptcha-response': this.recaptchaToken },
            cache: {
              ignoreCache: false
            }
          })

          this.storeToken(responseToken.data)
          token = responseToken.data
        }

        const response = await this.$api.get<ElectricMobilityGridAccessPointInfo>(
          `api/ElectricMobility/grid-access-point-info/${this.eanCode?.trim()}`,
          {
            headers: {
              Authorization: token
            },
            cache: {
              ignoreCache: false
            }
          }
        )
        if (response.status >= 200 && response.status < 300) {
          this.storeGridAccessPointEanCode(this.eanCode)
          return response.data
        }
        if (response.status === 404) {
          return null
        }
        if (response.status >= 500) {
          this.$store.dispatch('apiErrors/manageEmobility', 'default')
          return false
        }
      }
      return this.stateGridAccessPointInfo
    }
    return null
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (isValid && next && this.hasEanSelection === 'yes') {
      if (this.eanCode) {
        this.loading = true

        const gridAccessPointInfoResponse = await this.checkEMobilityEanCode()
        if (gridAccessPointInfoResponse === false) {
          return false
        }
        this.gridAccessPointInfo = gridAccessPointInfoResponse as ElectricMobilityGridAccessPointInfo | null

        if (this.checkGridAccessPointInfoCorrect(this.gridAccessPointInfo)) {
          this.storeGridAccessPointInfo(this.gridAccessPointInfo)
        } else {
          this.storeGridAccessPointInfo(null)
        }

        this.store({ eanCode: this.eanCode })

        this.loading = false
        this.isIdentified = true

        if (!this.isIdentifiedAndCanContinue) {
          // Refresh the captcha for next identification to work
          this.recaptchaKey += 1
        }
      }
    }

    if (next) {
      const GridAccessPointOk =
        !!(this.gridAccessPointInfo && this.checkGridAccessPointInfoCorrect(this.gridAccessPointInfo))

      this.$appInsights.trackEvent('EMobility_StepIdentification_Validated', {
        Segment: 'EMobility',
        WebRef: this.requestId,
        IdentificationByEan: this.hasEanSelection!,
        Ean: this.eanCode ?? '',
        GridAccessPointOk: GridAccessPointOk.toString()
      })

      if (GridAccessPointOk) {
        this.$appInsights.trackEvent('EMobility_StepElectricInstallation_Validated', {
          Segment: 'EMobility',
          WebRef: this.requestId,
          IdentificationByEan: 'true'
        })
      }
    }
    this.store({ eanCode: this.eanCode })
  }
  //#endregion
}

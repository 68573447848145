
import Step from '@/components/steps/Step'
import Component from 'vue-class-component'
import { CmhAddressPoint } from '@/models/cmh-address-point'
import { State, Mutation, Getter } from 'vuex-class'
import { helper } from '@/services'
import CmhOfferingsSelector from '@/components/inputs/CmhOfferings/CmhOfferingsSelector.vue'
import { OfferingSelection, SubCity } from '@/models'
import { pixelFacebookService } from '@ores/vue-library'

@Component({
  components: {
    CmhOfferingsSelector
  },
  name: 'cmh-offerings-selection-step'
})
export default class CmhOfferingsSelectionStep extends Step {
  //#region [Mutation]
  @Mutation('STORE', { namespace: 'newConnectionCmh/offeringsSelection' })
  public store!: (index: string[]) => void
  //#endregion

  //#region [Data]
  public selections: string[] = []
  public clonedAddressPoint!: CmhAddressPoint
  public isEnergySelectorErrorDisplayed: boolean = false

  @State('subCity', { namespace: 'newConnectionCmh/landingPage' })
  public stateSubCity!: SubCity
  @State('addressPoint', { namespace: 'newConnectionCmh/cmhAddress' })
  public stateAddressPoint!: CmhAddressPoint
  @State('selections', { namespace: 'newConnectionCmh/offeringsSelection' })
  public stateSelections!: string[]

  @Getter('isElectricityAvailable', { namespace: 'newConnectionCmh/cmhAddress' })
  public isElectricityAvailable!: boolean
  @Getter('isGasAvailable', { namespace: 'newConnectionCmh/cmhAddress' })
  public isGasAvailable!: boolean
  @Getter('isConnectMyHomeAvailableForSelectedBuilding', { namespace: 'newConnectionCmh/cmhAddress' })
  public isConnectMyHomeAvailableForSelectedBuilding!: boolean
  @Getter('isProximusAvailable', { namespace: 'newConnectionCmh/cmhAddress' })
  public isProximusAvailable!: boolean
  @Getter('isVooAvailable', { namespace: 'newConnectionCmh/cmhAddress' })
  public isVooAvailable!: boolean
  @Getter('isSwdeAvailable', { namespace: 'newConnectionCmh/cmhAddress' })
  public isSwdeAvailable!: boolean
  @State('requestId', { namespace: 'newConnectionCmh' })
  public requestId!: string
  //#endregion

  //#region [Method]
  public mounted() {
    pixelFacebookService.trackViewContent({
      content_ids: ['CmhForm'],
      content_name: 'Offering Selection Step',
      content_type: 'product'
    })
    this.clonedAddressPoint = helper.clone(this.stateAddressPoint)
    this.selections = helper.clone(this.stateSelections)
    this.handleSelectionsChange(this.selections)
  }

  public handleSelectionsChange(value: string[]) {
    this.selections = value
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (next && isValid) {
      if (!this.isConnectMyHomeAvailableForSelectedBuilding || !this.stateSubCity.connectMyHomeActivated) {
        this.selections = this.selections.filter((x: string) => x !== 'proximus' && x !== 'swde' && x !== 'voo')
      }
    }
    this.store(this.selections)

    this.saveStep()

    this.$appInsights.trackEvent('WR_NCCMH_Step2_Validated', { Segment: 'WR_NCCMH', WebRef: this.requestId })
  }

  public async saveStep() {
    const data: OfferingSelection = {
      electricity: this.selections.some((s) => s === 'electricity'),
      gas: this.selections.some((s) => s === 'gas'),
      swde: this.selections.some((s) => s === 'swde'),
      proximus: this.selections.some((s) => s === 'proximus' || s === 'proximusAndVoo'),
      voo: this.selections.some((s) => s === 'voo' || s === 'proximusAndVoo')
    }

    this.$api.post<OfferingSelection>(`NewConnectionRequest/store/${this.requestId}/offering-selection`, data)
  }

  //#endregion

  //#region [Computed]

  public get IsOfferingErrorDisplayed(): boolean {
    return (
      this.isEnergySelectorErrorDisplayed &&
      this.selections &&
      !this.selections.some((x: string) => x === 'electricity' || x === 'gas')
    )
  }

  public get isGdprDisplayed() {
    return (
      this.stateSubCity.connectMyHomeActivated &&
      this.selections.some((x: string) => x === 'proximus' || x === 'swde' || x === 'voo' || x === 'proximusAndVoo')
    )
  }

  //#endregion
}

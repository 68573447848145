import { FileInfo } from '@ores/vue-library';

export interface CmhFilesState {
  fileInfo: FileInfo[]
  showInputFile: boolean | null
}

export const InitialState: CmhFilesState = {
  fileInfo: [],
  showInputFile: null
}

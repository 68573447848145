
import { Prop, Watch, Component } from 'vue-property-decorator'
import { idGenerator } from '@/services'
import InputWithValidation from './InputWithValidation'
import { SubCity } from '@/models'

interface Data {
  fieldId: string
  loading: boolean
  subCities: SubCity[]
  selectedSubCity: SubCity | null
}

@Component
export default class SubCitySelector extends InputWithValidation {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly label!: string
  @Prop(Boolean) public readonly required?: boolean | null
  @Prop(Object) public readonly value!: SubCity | null
  @Prop(String) public readonly zipCode?: string | null
  @Prop(String) public readonly initialSubCityId?: string | null
  //#endregion

  //#region [Data]
  public fieldId: string = idGenerator.getId()
  public loading: boolean = false
  public subCities: SubCity[] = []
  public selectedSubCity: SubCity | null = this.value
  //#endregion

  //#region [Computed]
  public get selection(): number | null {
    return !this.selectedSubCity ? null : this.selectedSubCity.georesSubcityId
  }
  public set selection(value: number | null) {
    const subCity = this.subCities.filter((c) => c.georesSubcityId === value)

    if (subCity && subCity[0]) {
      this.notifyValidation(true)
      if (this.selectedSubCity == null || this.selectedSubCity.georesSubcityId !== value) {
        this.$emit('input', subCity[0])
      }
    } else {
      this.notifyValidation(false)
      this.$emit('input', null)
    }
  }

  public get sortedSubCities(): SubCity[] {
    return this.subCities.sort((a, b) => (a.cityName > b.cityName) ? 1 : ((b.cityName > a.cityName) ? -1 : 0))
  }
  //#endregion

  //#region [Watch]
  @Watch('zipCode')
  public onZipCodeChanged() {
    this.$nextTick(() => {
      this.fetchSubCities()
    })
  }

  @Watch('value')
  public onValueChanged(newValue: SubCity | null) {
    this.selectedSubCity = newValue
  }
  //#endregion

  //#region [Method]
  public async mounted() {
    if (this.zipCode !== null) {
      await this.fetchSubCities()
    }
  }
  public async fetchSubCities() {
    const zipCode = this.zipCode || ''
    if (zipCode === null || isNaN(+zipCode) || zipCode.length !== 4) {
      this.subCities = []
      this.selection = null
      return
    }
    this.loading = true

    const response = await this.$addressRepository.get<SubCity[]>(`subcities?zipCode=${zipCode}`, {
      cache: {
        ignoreCache: false
      }
    })
    let subCities = response.data.filter((sc) => !sc.isFictive)
    if (subCities == null || subCities.length === 0) {
      subCities = [{
        georesSubcityId: -1,
        zipCode,
        cityName: '',
        municipality: {
          georesMunicipalityId: -1,
          name: ''
        },
        isFictive: false,
        connectMyHomeActivated: false,
        connectMyHomeActivationDate: null,
        connectMyHomeInformation: null,
        gridOperators: null,
        gridManagers: {
          electricityManager: null,
          gasManager: null
        },
        operationalOffices: {
          electricityOffice: null,
          gasOffice: null
        }
      }];
    }
    this.subCities = subCities

    const selectedId = this.initialSubCityId || (this.selectedSubCity ? this.selectedSubCity.georesSubcityId : null)

    if (selectedId && subCities.filter((c) => c.georesSubcityId === selectedId).length >= 1) {
      this.selection = +selectedId
    } else {
      this.selection = subCities.length === 1 ? subCities[0].georesSubcityId : null
    }

    this.loading = false
  }
  //#endregion
}


import {
  BatteryRechargingCapacity,
  CarSpecification,
  CarType,
  CheckEanSearch,
  ConsumingDevice,
  DayPeriod,
  ElectricityInstallation,
  ElectricityPowerSupplyType,
  ElectricMobilityCustomerEmailRequest,
  ElectricMobilityGridAccessPointInfo,
  ElectricVehicle,
  GetPackagesRequest,
  PowerIncreasePackages
} from '@/models'
import { BatteryRechargingCapacityState } from '@/store/e-mobility/battery-recharging-capacity/types'
import { AxiosResponse } from 'axios'
import Component from 'vue-class-component'
import { Getter, Mutation, State } from 'vuex-class'
import Step from '../Step'
import PowerIncreasePackagesModal from '@/components/display/EMobility/PowerIncreasePackagesModal.vue'

@Component({
  name: 'result-step',
  components: {
    PowerIncreasePackagesModal
  }
})
export default class ResultStep extends Step {
  //#region [Data]
  @State('batteryResult', { namespace: 'eMobility/batteryRechargingCapacity' })
  public stateBatteryRechargingCapacity!: BatteryRechargingCapacityState
  @State('electricityInstallation', { namespace: 'eMobility/electricityInstallation' })
  public stateElectricityInstallation!: ElectricityInstallation
  @State('hasEan', { namespace: 'eMobility/identification' })
  public stateHasEan!: string
  @State('gridAccessPointInfo', { namespace: 'eMobility/identification' })
  public stateGridAccessPointInfo!: ElectricMobilityGridAccessPointInfo | null
  @State('token', { namespace: 'eMobility/identification' })
  public stateToken!: string | null
  @State('carSpecification', { namespace: 'eMobility/carSpecification' })
  public stateCarSpecification!: CarSpecification
  @State('requestId', { namespace: 'eMobility' })
  public requestId!: string
  @State('consumingDeviceSelection', { namespace: 'eMobility/consumingDevices' })
  public stateConsumingDeviceSelection!: ConsumingDevice[]
  @State('electricVehicle', { namespace: 'eMobility/carSpecification' })
  public stateElectricVehicle!: ElectricVehicle | null

  @Getter('getEanCode', { namespace: 'eMobility/identification' })
  public getEanCode!: string

  public finalBatteryRechargingCapacity: BatteryRechargingCapacity[] | null = null
  public email: string | null = null
  public emailSent: boolean = false

  public isHybridModalOpen: boolean = false
  public isHoursModalOpen: boolean = false
  public isPeriodModalOpen: boolean = false
  public isPriceModalOpen: boolean = false
  public isSendEmailModalOpen: boolean = false

  public recaptchaToken: string | null = null
  public loading: boolean = false
  public isIdentified: boolean = false
  public recaptchaKey: number = 0

  public packagesLoading: boolean = false
  //#endregion

  //#region [Mutation]
  @Mutation('STORE_TOKEN', { namespace: 'eMobility/identification' })
  public storeToken!: (index: string | null) => void

  @Mutation('STORE', { namespace: 'eMobility/powerIncreasePackages' })
  public storePackages!: (data: PowerIncreasePackages | null) => void
  //#endregion

  //region [Computed]
  public get siteKey() {
    return process.env.VUE_APP_CAPTCHA_SITE_KEY
  }
  public get getChargingDuration() {
    if (this.finalBatteryRechargingCapacity) {
      return this.finalBatteryRechargingCapacity[0].chargingDuration
    }
  }

  public get getHasSolarPanels() {
    if (this.stateConsumingDeviceSelection) {
      return this.stateConsumingDeviceSelection.some((d) => d.device === 'SolarPanel')
    }
    return false
  }

  public get getPeriod() {
    if (this.finalBatteryRechargingCapacity) {
      return this.finalBatteryRechargingCapacity[0].period === DayPeriod.day
        ? this.$t('e-mobility.result.day')
        : this.$t('e-mobility.result.night')
    }
  }

  public get isDayIcon() {
    if (this.finalBatteryRechargingCapacity) {
      return this.finalBatteryRechargingCapacity[0].period === DayPeriod.day
    }
  }

  public get isElectricalCar() {
    return this.stateCarSpecification.carType === CarType.electric
  }

  public get getChargerPower() {
    if (this.finalBatteryRechargingCapacity) {
      return this.finalBatteryRechargingCapacity[0].chargerPower
    }
  }

  public get getChargerPowerExtraInfo() {
    const data: ElectricityPowerSupplyType | null =
      this.stateHasEan === 'yes' && this.stateGridAccessPointInfo?.electricMobilityMeterInfos
        ? this.stateGridAccessPointInfo.electricMobilityMeterInfos[0].electricityPowerSupplyType
        : this.stateElectricityInstallation.electricityPowerSupplyType

    switch (data) {
      case ElectricityPowerSupplyType.monophase:
        return this.$t('e-mobility.result.phase.mono')
      case ElectricityPowerSupplyType.triphase:
        return this.$t('e-mobility.result.phase.three')
      case ElectricityPowerSupplyType.tetraphase:
        return this.$t('e-mobility.result.phase.tetra')
    }
  }

  public get getPowerInfo() {
    return this.stateHasEan === 'yes' && this.stateGridAccessPointInfo?.electricMobilityMeterInfos
      ? this.stateGridAccessPointInfo.electricMobilityMeterInfos[0].amperage
      : this.stateElectricityInstallation.power
  }

  public get isAnyAlternative() {
    return this.finalBatteryRechargingCapacity && this.finalBatteryRechargingCapacity.length > 1
  }

  public get hasMonophased() {
    const data: ElectricityPowerSupplyType | null =
      this.stateHasEan === 'yes' && this.stateGridAccessPointInfo?.electricMobilityMeterInfos
        ? this.stateGridAccessPointInfo.electricMobilityMeterInfos[0].electricityPowerSupplyType
        : this.stateElectricityInstallation.electricityPowerSupplyType

    return data === ElectricityPowerSupplyType.monophase
  }

  public get getElectricityPowerSupplyType() {
    return this.stateHasEan === 'yes' && this.stateGridAccessPointInfo?.electricMobilityMeterInfos
      ? this.stateGridAccessPointInfo.electricMobilityMeterInfos[0].electricityPowerSupplyType!
      : this.stateElectricityInstallation.electricityPowerSupplyType!
  }

  public get getAmperage() {
    return this.stateHasEan === 'yes' && this.stateGridAccessPointInfo?.electricMobilityMeterInfos
      ? this.stateGridAccessPointInfo.electricMobilityMeterInfos[0].amperage!
      : this.stateElectricityInstallation.power!
  }

  //#endregion

  //#region [Method]
  public mounted() {
    this.loadPackages()
    if (this.stateBatteryRechargingCapacity && this.stateBatteryRechargingCapacity.batteryResult) {
      const data = this.stateBatteryRechargingCapacity.batteryResult
      let temp = this.stateBatteryRechargingCapacity.batteryResult

      for (let i = 0; i < data.length; i++) {
        if (this.hasMonophased && data[i].chargerPower === 11) {
          temp = temp.filter((c) => c !== data[i])
        }
        if (i + 1 < data.length) {
          if (
            data[i].chargingDuration === data[i + 1].chargingDuration &&
            data[i].chargerPower === data[i + 1].chargerPower &&
            data[i].period === DayPeriod.day &&
            data[i + 1].period === DayPeriod.night
          ) {
            temp = temp.filter((c) => c !== data[i])
          }
        }
      }
      temp.sort((x, y) => y.chargingDuration - x.chargingDuration)
      temp = temp.splice(0, 2)

      this.finalBatteryRechargingCapacity = temp.length ? temp : null
    } else {
      this.finalBatteryRechargingCapacity = null
    }

    const firstResult = this.finalBatteryRechargingCapacity && this.finalBatteryRechargingCapacity.length > 0 ?
      this.finalBatteryRechargingCapacity[0] : null

    const secondResult = this.isAnyAlternative ? this.finalBatteryRechargingCapacity![1] : null

    this.$appInsights.trackEvent('EMobility_ResultStep', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      CarModelKnown: !!this.stateElectricVehicle ? 'true' : 'false',
      BatteryCapacity: this.stateCarSpecification!.batteryCapacity!.toString(),
      Ean: this.getEanCode,
      Result: this.isAnyAlternative
        ? 'TwoOrMoreResult'
        : this.finalBatteryRechargingCapacity
          ? 'OneResult'
          : 'InsufficientPower',
      Period1: firstResult?.period?.toString() ?? '',
      ChargingDuration1: firstResult?.chargingDuration?.toString() ?? '',
      ChargerPower1: firstResult?.chargerPower?.toString() ?? '',
      Period2: secondResult?.period?.toString() ?? '',
      ChargingDuration2: secondResult?.chargingDuration?.toString() ?? '',
      ChargerPower2: secondResult?.chargerPower?.toString() ?? ''
    })
  }

  public async loadPackages() {
    this.packagesLoading = true
    const data: GetPackagesRequest = {
      amperage: this.getAmperage,
      powerSupplyType: this.getElectricityPowerSupplyType
    }
    const result = await this.$api.post<PowerIncreasePackages>(`api/ElectricMobility/packages`, data)
    this.storePackages(result.data)
    this.packagesLoading = false
  }

  public async sendEmail(data: ElectricMobilityCustomerEmailRequest): Promise<void | null> {
    try {
      this.loading = true
      let token = this.stateToken

      if (!token) {
        const responseToken = await this.$api.get<CheckEanSearch, AxiosResponse<string>>(`electricMobility/token`, {
          headers: { 'g-recaptcha-response': this.recaptchaToken },
          cache: {
            ignoreCache: false
          }
        })
        token = responseToken.data
        this.storeToken(responseToken.data)
      }

      const response = await this.$api.post<ElectricMobilityCustomerEmailRequest, AxiosResponse<void>>(
        `api/ElectricMobility`,
        data,
        {
          headers: {
            Authorization: token
          },
          cache: {
            ignoreCache: false
          }
        }
      )

      this.emailSent = response.status === 204
    } catch (err) {
      return null
    } finally {
      this.isSendEmailModalOpen = this.emailSent
      this.loading = false
    }
  }

  public onHybridModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'HybridResult'
    })
    this.isHybridModalOpen = !this.isHybridModalOpen
  }

  public onHoursModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'HoursResult'
    })
    this.isHoursModalOpen = !this.isHoursModalOpen
  }

  public onPeriodModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'PeriodResult'
    })
    this.isPeriodModalOpen = !this.isPeriodModalOpen
  }

  public onPriceModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'PriceResult'
    })
    this.isPriceModalOpen = !this.isPriceModalOpen
  }

  public onEmailModalClick(): void {
    this.emailSent = false
    this.isSendEmailModalOpen = !this.isSendEmailModalOpen
  }

  public async onSendEmailClick(): Promise<void> {
    this.$appInsights.trackEvent('EMobility_EmailSent', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Result: this.isAnyAlternative
        ? 'TwoOrMoreResult'
        : this.finalBatteryRechargingCapacity
          ? 'OneResult'
          : 'InsufficientPower'
    })
    const isValid = await this.$validatorObs.validate()
    if (isValid) {
      this.emailSent = false
      if (this.email) {
        const mainCapacity = this.finalBatteryRechargingCapacity ? this.finalBatteryRechargingCapacity[0] : null
        const alternateCapacity =
          this.isAnyAlternative && this.finalBatteryRechargingCapacity ? this.finalBatteryRechargingCapacity[1] : null
        const data: ElectricMobilityCustomerEmailRequest | null = {
          email: this.email.trim(),
          hasSolarPanels: this.getHasSolarPanels,
          electricityPowerSupplyType: this.getElectricityPowerSupplyType,
          power: this.getPowerInfo!,
          carType: this.stateCarSpecification.carType!,
          mainCapacity,
          alternateCapacity
        }

        this.sendEmail(data)
      }
    }
  }

  public trackClick(): void {
    this.$appInsights.trackEvent('EMobility_linkClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Link: 'DemandeDevis',
      Result: this.isAnyAlternative
        ? 'TwoOrMoreResult'
        : this.finalBatteryRechargingCapacity
          ? 'OneResult'
          : 'InsufficientPower'
    })
  }

  public trackClickSmartCharger(): void {
    this.$appInsights.trackEvent('EMobility_linkClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Link: 'Decouvrir plus infos'
    })
  }

  public trackClickCalculationExplanation(): void {
    this.$appInsights.trackEvent('EMobility_linkClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Link: 'Comment est calcule ce restultat?'
    })
  }

  //#endregion
}

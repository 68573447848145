
import { Emit, Vue } from 'vue-property-decorator'
import ZipCodeLocalitySelector from '@/components/inputs/Address/ZipCodeLocalitySelector.vue'
import ConnectionPointArea from '@/components/inputs/Address/ConnectionPointArea.vue'
import CollapseInfo from '@/components/display/CollapseInfo.vue'
import Component from 'vue-class-component'
import {
  CheckEanForSmartMeterResponse,
  CheckEanSearch,
  CheckEanSmartMeterResult,
  EanConnectionContractResult,
  GetConnectionContractRequest,
  MetersError,
  SubCity
} from '@/models'
import { Mutation, State } from 'vuex-class'
import { Watch } from 'vue-property-decorator'
import { chatService, helper } from '@/services'
import { AxiosResponse } from 'axios'

@Component({
  name: 'identification',
  components: {
    ZipCodeLocalitySelector,
    ConnectionPointArea,
    CollapseInfo
  }
})
export default class Identification extends Vue {
  //#region [Data]
  @State('subCity', { namespace: 'smartMeterConnection/identification' })
  public subCityState!: SubCity | null
  @State('eanCode', { namespace: 'smartMeterConnection/identification' })
  public eanCodeState!: string | null
  @State('requestId', { namespace: 'smartMeterConnection' })
  public requestId!: string
  @State('checkEanResult', { namespace: 'smartMeterConnection/identification' })
  public eanResultState!: CheckEanSmartMeterResult
  @State('connectionContract', { namespace: 'smartMeterConnection/identification' })
  public connectionContractState!: EanConnectionContractResult | null

  public subCity: SubCity | null = null
  public eanCode: string | null = null

  public recaptchaToken: string | null = null

  public connectionContract: EanConnectionContractResult | null = null
  public eanResult: CheckEanSmartMeterResult | null = null
  public loading: boolean = false
  public isIdentified: boolean = false
  public recaptchaKey: number = 0

  public whereFoundEanModalOpen: boolean = false
  //#endregion

  //#region [Computed]
  public get isIdentifiedAndCanContinue(): boolean {
    return this.isIdentified && !!this.eanResult && this.eanResult.error === MetersError.NoError
  }
  public get siteKey() {
    return process.env.VUE_APP_CAPTCHA_SITE_KEY
  }
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'smartMeterConnection/identification' })
  public store!: (index: { subCity: SubCity | null; eanCode: string | null }) => void

  @Mutation('STORE_EAN_RESULT', { namespace: 'smartMeterConnection/identification' })
  public storeEanResult!: (index: CheckEanSmartMeterResult | null) => void

  @Mutation('STORE_CONNECTION_CONTRACT', { namespace: 'smartMeterConnection/identification' })
  public storeConnectionContract!: (index: EanConnectionContractResult | null) => void

  @Mutation('CLEAR_API_ERRORS', { namespace: 'apiErrors' })
  private clearApiErrors!: () => void
  //#endregion

  //#region [Watch]
  @Watch('eanCode')
  @Emit('eanCode')
  public onEanCodeChange(value: string | null) {
    this.clearApiErrors()
    if (value && value === this.eanCodeState) {
      // User input is the same as last identified EAN
      this.isIdentified = true
      return value
    }
    this.isIdentified = false
    return value
  }

  @Watch('eanCodeState')
  public onEanCodeStateChange(value: string | null) {
    if (!value) {
      this.isIdentified = false
    }
  }

  @Watch('isIdentified')
  @Emit('isIdentified')
  public onIsIdentifiedChange(value: boolean): boolean | null {
    return value
  }

  @Watch('isIdentifiedAndCanContinue', { immediate: true })
  @Emit('canContinue')
  public isIdentifiedAndCanContinueChange(value: boolean): boolean | null {
    return value
  }

  @Watch('eanResult')
  @Emit('eanResult')
  public onEanResultChange(value: CheckEanSmartMeterResult): CheckEanSmartMeterResult | null {
    return value
  }

  @Watch('connectionContract')
  @Emit('connectionContract')
  public onConnectionContractChange(value: EanConnectionContractResult): EanConnectionContractResult | null {
    return value
  }

  //#endregion

  //#region [Method]
  public mounted() {
    this.subCity = helper.clone(this.subCityState)
    this.eanCode = helper.clone(this.eanCodeState)
    this.eanResult = helper.clone(this.eanResultState)
    this.connectionContract = helper.clone(this.connectionContractState)
    this.isIdentified = !!this.eanResultState
  }

  public async checkEanForSmartMeter(): Promise<CheckEanForSmartMeterResponse> {
    const response = await this.$api.get<CheckEanSearch, AxiosResponse<CheckEanForSmartMeterResponse>>(
        `api/assets/check-ean/${this.eanCode?.trim()}/smart-meter`,
        {
          headers: {
            'g-recaptcha-response': this.recaptchaToken
          },
          cache: {
            ignoreCache: false
          }
        }
    )
    return response.data
  }

  public async getConnectionContract(token: string | null): Promise<EanConnectionContractResult | null> {
    const response = await this.$api.get<GetConnectionContractRequest, AxiosResponse<EanConnectionContractResult>>(
        `api/consumptionMeasurements/connection-contract/${this.eanCode!.trim()}`,
        {
          headers: {
            Authorization: token
          },
          cache: {
            ignoreCache: false
          }
        }
    )
    if (response.status >= 200 && response.status < 300) {
      return response.data
    }
    // Error loading connection contract
    return null
  }

  public async identify() {
    if (this.eanCode) {
      try {
        this.loading = true

        const checkEanForSmartMeterResponse = await this.checkEanForSmartMeter()

        this.eanResult = checkEanForSmartMeterResponse.eanInfo
        this.storeEanResult(this.eanResult)

        if (this.eanResult.error !== MetersError.NotFound) {
          if (checkEanForSmartMeterResponse.token) {
            sessionStorage.setItem('token', checkEanForSmartMeterResponse.token)

            const token = sessionStorage.getItem('token')
            this.connectionContract = await this.getConnectionContract(token)

            this.storeConnectionContract(this.connectionContract)
          }

          this.store({ subCity: this.subCity, eanCode: this.eanCode!.trim() })
        }

        this.loading = false
        this.isIdentified = true

        if (!this.isIdentifiedAndCanContinue) {
          // Refresh the captcha for next identification to work
          this.recaptchaKey += 1
        }
      } catch (err) {
        this.loading = false
        this.isIdentified = false
        this.storeEanResult(null)
        this.storeConnectionContract(null)
      }
    }
  }

  private openChat() {
    chatService.openChat()
  }

  //#endregion

  //#region [Emit]
  //#endregion
}

import { ModifiedConnection, PackagedElectricityMeter, PriceDTO } from '@/models'
import { BuildingType } from '@/models/building-type'
import { vatService } from '@/services'
import { i18n } from '@/i18n';

function getTotalPackagedElectricityMetersPrice(meters: PackagedElectricityMeter[], applicableVAT: string, buildingType: BuildingType): number {
  if (!meters?.length) {
    return 0
  }
  return meters.map((x: PackagedElectricityMeter) => vatService.getVATPriceForPackage(x!, applicableVAT, buildingType) ?? 0)
    .reduce((current: number | null, previous: number | null) => (current ?? 0) + (previous ?? 0))
}

function getTotalModifiedConnectionsPrice(meters: ModifiedConnection[], applicableVAT: string): number {
  if (!meters?.length) {
    return 0
  }
  return meters.map((x: ModifiedConnection) => vatService.getVATPriceForAdditionnalConnection(x!, applicableVAT) ?? 0)
    .reduce((current: number | null, previous: number | null) => (current ?? 0) + (previous ?? 0))
}

function getPriceIsToBeDetermined(meters: PackagedElectricityMeter[]): boolean {
  if (!meters?.length) {
    return false
  }
  if (meters.some((x: PackagedElectricityMeter) => x.customPower)) {
    return true
  } else {
    return false
  }
}

function getBuildingTypePrices(buildingType: BuildingType, meter: PackagedElectricityMeter): PriceDTO | undefined {
  if (buildingType === BuildingType.house) {
    return meter.package?.homePrice
  }
  if (buildingType === BuildingType.building) {
    return meter.package?.buildingPrice
  }
}

function formatNumberToLocaleStringFormat(str: number): string {
  return str.toLocaleString(i18n.locale, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
}

export const pricingService = {
  getTotalPackagedElectricityMetersPrice,
  getTotalModifiedConnectionsPrice,
  getPriceIsToBeDetermined,
  formatNumberToLocaleStringFormat
}


import { ElectricityPowerSupplyType, ElectricMobilityGridAccessPointInfo, MeterType } from '@/models'
import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation, State } from 'vuex-class'

@Component({
  name: 'resume-ean-result'
})
export default class ResumeGridAccessPointInfo extends Vue {
  @State('gridAccessPointInfo', { namespace: 'eMobility/identification' })
  public gridAccessPointInfo!: ElectricMobilityGridAccessPointInfo
  //#region [Getter]
  @Getter('getEanCode', { namespace: 'eMobility/identification' })
  public getEanCode!: string

  @Getter('getElectricityPowerSupplyType', { namespace: 'eMobility/identification' })
  public getElectricityPowerSupplyType!: string

  @Getter('getMeterType', { namespace: 'eMobility/identification' })
  public getMeterType!: string

  @Getter('getAmperage', { namespace: 'eMobility/identification' })
  public getAmperage!: string
  //#endregion

  //#region [Mutation]
  @Mutation('STORE_HAS_EAN', { namespace: 'eMobility/identification' })
  public storeHasEan!: (index: { hasEan: string | null }) => void
  //#endregion

  //#region [Computed]
  public get getGridAccessPointInfoAddress() {
    const address = this.gridAccessPointInfo?.consumptionAddress
    if (address) {
      let finalAddress = this.capitalize(address.streetName) + ' ' + address.houseNumber
      if (address.complement) {
        finalAddress += ' ' + address.complement
      }
      if (address.complementBox) {
        finalAddress += ' ' + address.complementBox
      }
      if (address.floorNumber) {
        finalAddress += this.$t('e-mobility.ean-result.stage') + ' ' + address.floorNumber
      }
      finalAddress += '<br/>' + address.zipCode + ' ' + this.capitalize(address.cityName)
      return finalAddress
    }
  }

  public get getElectricityPowerSupplyTypeReturn() {
    switch (this.getElectricityPowerSupplyType) {
      case ElectricityPowerSupplyType.monophase:
        return this.$t('e-mobility.ean-result.phase.mono')
      case ElectricityPowerSupplyType.triphase:
        return this.$t('e-mobility.ean-result.phase.three')
      case ElectricityPowerSupplyType.tetraphase:
        return this.$t('e-mobility.ean-result.phase.tetra')
      default:
        return this.$t('e-mobility.ean-result.unknown')
    }
  }

  public get getMeterTypeReturn() {
    switch (this.getMeterType) {
      case MeterType.mechanicalMeter:
        return this.$t('e-mobility.ean-result.meterTypes.mechanicalMeter')
      case MeterType.smartMeter:
        return this.$t('e-mobility.ean-result.meterTypes.smartMeter')
      default:
        return this.$t('e-mobility.ean-result.unknown')
    }
  }
  //#endregion

  //#region [Method]
  public onManuallySelected() {
    this.$appInsights.trackEvent('EMobility_EanWrongInfo', {
      Segment: 'EMobility',
      Ean: this.getEanCode
    })
    this.storeHasEan({ hasEan: 'no' })
  }

  public capitalize = (s: string) => {
    const temp = s.toLocaleLowerCase()
    if (temp[0]) {
      return temp[0].toUpperCase() + temp.slice(1)
    } else {
      return null
    }
  }
  //endregion
}


import { Vue, Component, Prop, Emit, Watch, Inject } from 'vue-property-decorator'
import { WorkFileIdentification } from '@/models'

@Component
export default class WorkFileIdentificationForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: false }) public readonly value!: WorkFileIdentification | null
  //#endregion

  //#region [Computed]
  public get worksFileId(): string | null {
    return this.value ? this.value.worksFileId : null
  }
  public set worksFileId(val: string | null) {
    this.change(val, this.zipCode)
  }

  public get zipCode(): string | null {
    return this.value ? this.value.zipCode : null
  }
  public set zipCode(val: string | null) {
    this.change(this.worksFileId, val)
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(worksFileId: string | null, zipCode: string | null): WorkFileIdentification | null {
    if (worksFileId !== null || zipCode !== null) {
      return { worksFileId, zipCode }
    } else {
      return null
    }
  }
  //#endregion
}


import { Component, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'

import Step from '@/components/steps/Step'
import ResumeContactInfo from '@/components/display/Resume/ResumeContactInfo.vue'
import { GridAccessPoint, InstallationInformation, SendProsumerDeclarationRequest } from '@/models/prosumer-declaration'
import { ContactInfo } from '@/models'
import { dateService, FileInfo } from '@ores/vue-library'
import { AxiosRequestConfig } from 'axios'

@Component({
  name: 'summary-step',
  components: {
    ResumeContactInfo
  }
})
export default class SummaryStep extends Step {
  //#region [Property]
  @Prop({ type: Boolean, default: false }) public readonly readOnly!: boolean
  //#endregion

  //#region [Data]
  @State('requestId', { namespace: 'prosumerDeclaration' })
  public requestId!: string
  @State('lang')
  public language!: string
  @State('gridAccessPoint', { namespace: 'prosumerDeclaration/identification' })
  public gridAccessPointState!: GridAccessPoint
  @State('contactInfo', { namespace: 'prosumerDeclaration/contactInfo' })
  public contactInfoState!: ContactInfo
  @State('installation', { namespace: 'prosumerDeclaration/installation' })
  public installationState!: InstallationInformation
  @State('meterReadingDate', { namespace: 'prosumerDeclaration/meterReading' })
  public readonly meterReadingDateState!: Date | null
  @State('rapportFileInfo', { namespace: 'prosumerDeclaration/files' })
  public rapportFileInfoState!: FileInfo[]
  @State('schemaFileInfo', { namespace: 'prosumerDeclaration/files' })
  public schemaFileInfoState!: FileInfo[]
  @State('pictureFileInfo', { namespace: 'prosumerDeclaration/files' })
  public pictureFileInfoState!: FileInfo[]
  //#endregion

  //#region [Methods]
  public displayDate(date: Date): string {
    return dateService.displayDate(date)
  }

  public async save(next: boolean, valid: boolean) {
    if (next) {
      const data = this.getSendProsumerDeclarationCommand()

      const config: AxiosRequestConfig = { headers: { 'Authorization': sessionStorage.getItem('token') } }
      await this.$api.post('/ProsumerDeclaration', data, config)

      this.$appInsights.trackEvent('ProsumerDeclaration_StepSummary_Validated', {
        Segment: 'ProsumerDeclaration',
        WebRef: this.requestId
      })
    }
  }

  public goToStep(step: number) {
    this.$emit('goToStep', step)
  }

  private getSendProsumerDeclarationCommand(): SendProsumerDeclarationRequest {
    const data: SendProsumerDeclarationRequest = {
      webReference: this.requestId,
      language: this.language,
      gridAccessPoint: this.gridAccessPointState,
      contactInfo: this.contactInfoState,
      installation: this.installationState,
      meterReadingDate: this.meterReadingDateState ?? new Date(0)
    }

    const filteredInv = data.installation.technicalInformation.powerInverters
      .filter(inv => !!inv.brand && !!inv.model)
    data.installation.technicalInformation.powerInverters = filteredInv

    return data
  }

  //#endregion
}

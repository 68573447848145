
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import { idGenerator } from '@/services'
import { NewRequestInfo } from '@/models'

@Component({
  name: 'synergrid-new-request-form',
})
export default class SynergridNewRequestForm extends Vue {

  //#region [Property]
  @Prop({ type: Object, required: true })
  public readonly value!: NewRequestInfo
  //#endregion

  //#region [Data]
  public fieldId: string = idGenerator.getId()
  public kvaCustom: number | null = null
  //#endregion
}

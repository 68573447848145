import { Module } from 'vuex'
import { actions } from './actions'
import { mutations } from './mutations'
import { InitialState, ProsumerDeclarationState } from './types'
import { identification } from './identification'
import { contactInfo } from './contact-info'
import { installation } from './installation'
import { meterReading } from './meter-reading'
import { files } from './files'
import { RootState } from '@/store/types'

const namespaced: boolean = true

export const prosumerDeclaration: Module<ProsumerDeclarationState, RootState> = {
  namespaced,
  state: InitialState,
  actions,
  mutations,
  modules: {
    identification,
    contactInfo,
    installation,
    meterReading,
    files
  }
}

import { CmhFilesState } from '@/store/new-connection-cmh/cmh-files/types'
import { RootState } from '@/store/types'
import { GetterTree } from 'vuex'

export const getters: GetterTree<CmhFilesState, RootState> = {
  hasDocumentsAttached(state: CmhFilesState, gets, rs: RootState, rg) {
    return (state.fileInfo?.length > 0)
  }
}


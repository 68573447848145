import { GetterTree } from 'vuex'
import { IdentificationState } from './types';
import { RootState } from '@/store/types';

export interface Step1GetterTree {
  isElectricityAvailable: boolean
  isGasAvailable: boolean
  isElecticityManaged: boolean
  isGasManaged: boolean
}

export const getters: GetterTree<IdentificationState, RootState> = {

}


import { Component, Vue, Prop } from 'vue-property-decorator'
import GuaranteedMaximumPowerModal from '@/components/display/CmhNewConnection/GuaranteedMaximumPowerModal.vue'
import { BuildingType } from '@/models/building-type'
import { pricingService } from '@/services/pricing-service'

@Component({
  name: 'package-editable-box',
  components: {
    GuaranteedMaximumPowerModal
  }
})
export default class PackageEditableBox extends Vue {
  @Prop({ type: Boolean, required: true }) public isElectricMeter!: boolean
  @Prop({ type: Boolean, required: true }) public isGasMeter!: boolean
  @Prop({ type: String, required: true }) public packageKey!: string
  @Prop({ type: Number, required: true }) public meterCount!: number
  @Prop({ type: Number, required: true }) public maximumPowerGuaranteed!: number
  @Prop({ type: String, required: false }) public selectedFunctionality!: string
  @Prop({ type: Number, required: true }) public identifier!: number
  @Prop({ type: Number, required: false, default: 0 }) public excludingVatPrice!: number
  @Prop({ type: Boolean, required: true }) public isPriceToBeDetermined!: boolean
  @Prop({ type: Boolean, required: false, default: false }) isCustomPower!: boolean
  @Prop({ type: Number, required: false }) wantedPower!: number
  @Prop({ type: String, required: false }) buildingType!: BuildingType

  @Prop({ type: Function, required: true }) public onEdit!: (identifier: number) => void
  @Prop({ type: Function, required: true }) public onDelete!: (identifier: number) => void

  public guaranteedMaximumPowerModalOpen: boolean = false

  //#region [Computed]
  public get getPrice(): string {
    return pricingService.formatNumberToLocaleStringFormat(this.excludingVatPrice)
  }
  //#endregion
}


import Component from 'vue-class-component'
import { Emit, Vue, Prop, Watch } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'
import ApiErrors from '@/components/display/ApiErrors.vue'
import { appointmentService, dateService, calendarService } from '@/services'
import {
  AppointmentSlot,
  GetAppointmentAvailabilitiesRequest,
  GetAppointmentAvailabilitiesResponse,
  WorksAppointmentAvailability,
  WorksAppointmentTimeSlot
} from '@/models'
import { AxiosResponse } from 'axios'
import AppointmentTimeSlots from '@/components/inputs/Appointment/AppointmentTimeSlots.vue'

@Component({ components: { ApiErrors, AppointmentTimeSlots } })
export default class AppointmentTimeslotSelection extends Vue {
  //#region [Props]
  @Prop({ type: Object, required: true }) public parameters!: GetAppointmentAvailabilitiesRequest

  //#endregion

  //#region [Data]
  public dateService = dateService
  public calendarService = calendarService

  private loadingSlots: boolean = false
  private currentSlots: AppointmentSlot[] | null = null
  private selectedSlot: AppointmentSlot | null = null

  //#endregion

  //#region [Computed]
  private get requestData(): GetAppointmentAvailabilitiesRequest {
    return {
      workFileReference: this.parameters.workFileReference,
      topic: this.parameters.topic,
      service: this.parameters.service,
      workRequestType: this.parameters.workRequestType,
      powerLevel: this.parameters.powerLevel,
      periodStartDate: this.parameters.periodStartDate,
      periodEndDate: this.parameters.periodEndDate,
      timeSlot: WorksAppointmentTimeSlot.MorningAndAfternoon,
      seeOtherDatesClicked: false
    }
  }

  private get hasSlots(): boolean {
    return !!this.currentSlots && this.currentSlots.length > 0
  }

  private get noSlotFound(): boolean {
    return !!this.currentSlots && this.currentSlots.length === 0
  }
  //#endregion

  //#region [Mutations]
  @Mutation('CLEAR_API_ERRORS', { namespace: 'apiErrors' })
  private clearApiErrors!: () => void

  //#endregion

  //#region [Watch]
  @Watch('date', { immediate: true })
  public onDateChange(value: Date) {
    this.fetchSlots(value)
  }

  //#endregion

  //#region [Methods]
  private async fetchSlots(date: Date) {
    this.loadingSlots = true
    this.clearApiErrors()

    const token = sessionStorage.getItem('token')
    const response: AxiosResponse<GetAppointmentAvailabilitiesResponse> = await this.$api.post(
      `api/worksrequest/work-file/${this.parameters.workFileReference}/appointment/availabilities`,
      this.requestData,
      {
        headers: {
          Authorization: token
        }
      }
    )

    if (response.status !== 200) {
      if (response.status === 400) {
          const errorResponse = response as any
          if (errorResponse.data.errors && errorResponse.data.errors.length) {
            if (errorResponse.data.errors[0].key === 'FunctionalException') {
              this.$store.dispatch('apiErrors/customManage', 'schedulingUnavailable')
            } else {
              this.$store.dispatch('apiErrors/customManage', 'default')
            }
          } else {
            this.$store.dispatch('apiErrors/customManage', 'default')
          }
        }
      this.loadingSlots = false
      return
    }
    this.loadingSlots = false

    // Map availabilities to slots
    this.currentSlots = this.getSlots(response.data.availabilities || [])
    if (this.currentSlots.length) {
      // Add the whole day option
      const slot = {
        startDate: new Date(
          this.parameters.periodStartDate.getFullYear(),
          this.parameters.periodStartDate.getMonth(),
          this.parameters.periodStartDate.getDate(),
          7,
          0,
          0
        ),
        endDate: new Date(
          this.parameters.periodEndDate.getFullYear(),
          this.parameters.periodEndDate.getMonth(),
          this.parameters.periodEndDate.getDate(),
          16,
          45,
          0
        ),
        timeslotLabel: '',
        wholeDayTimeslot: true,
        grade: null,
        key: 'wholeDay',
        otherDate: false
      }
      slot.timeslotLabel = appointmentService.timeslotLabel(slot, true)
      this.currentSlots.push(slot)
    }
  }

  private getSlots(availabilities: WorksAppointmentAvailability[]): AppointmentSlot[] {
    return availabilities.map<AppointmentSlot>((a) => {
      const slot = {
        startDate: new Date(a.startDate),
        endDate: new Date(a.endDate),
        timeslotLabel: '',
        wholeDayTimeslot: false,
        grade: a.grade,
        key: a.startDate.toString(),
        otherDate: false
      }
      slot.timeslotLabel = appointmentService.timeslotLabel(slot, false)
      return slot
    })
  }

  private onSlotSelect(value: AppointmentSlot) {
    this.selectedSlot = value
  }

  //#endregion

  //#region [Emit]
  @Emit('input')
  private selectTimeSlot(): AppointmentSlot {
    return this.selectedSlot!
  }

  @Emit('remove')
  private removeDate(): Date {
    return this.parameters.periodStartDate
  }

  @Emit('cancel')
  private cancel(): boolean {
    return true
  }
  //#endregion
}


import { Component, Prop } from 'vue-property-decorator'
import AddressForm from '@/components/inputs/Contact/AddressForm.vue'
import { ContactCompany } from '@/models'
import ContactBaseForm from './ContactBaseForm'

@Component({
  components: {
    AddressForm
  }
})
export default class ContactCompanyForm extends ContactBaseForm {
  //#region [Property]
  @Prop({ type: Object, required: true })
  public readonly value!: ContactCompany
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isSmartMeterForm!: boolean
  @Prop({ type: Boolean, required: false, default: true })
  public readonly showBirthdate!: boolean
  //#endregion

  //#region [Computed]
  public get companyTypes(): Array<{ text: string; value: string; disable?: boolean }> {
    const list: Array<{ text: string; value: string; disable?: boolean }> = []
    for (let i = 0; i < 12; i++) {
      list.push({ value: i.toString(), text: this.$t(`contact.companyType.${i}`) as string })
    }
    return list
  }
  protected get emailAddress(): string | null {
    return this.value.email
  }
  protected set emailAddress(value: string | null) {
    this.value.email = value?.trim() ?? null
  }
  //#endregion
}

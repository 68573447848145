import { MutationTree } from 'vuex';
import { ConfigurationInfoState } from './types';
import { SynergridConfigurationInfo } from '@/models';

export const mutations: MutationTree<ConfigurationInfoState> = {
  STORE(state, data: SynergridConfigurationInfo) {
    state.configurationInfo = data
  },
  CLEAR(state) {
    state.configurationInfo = null
  }
}


import { ContactInfo } from '@/models';
import { CmhVatInfoState } from '@/store/new-connection-cmh/cmh-vat-info/types';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';

export const actions: ActionTree<CmhVatInfoState, RootState> = {
  validate({ commit, state }, contactInfo: ContactInfo) {
    let change = false

    if (state.individualVatInfoSelection != null && contactInfo.contactType !== 'individual') {
      change = true
      state.individualVatInfoSelection = null
    }

    if (state.freelanceVatInfoSelection != null && contactInfo.contactType !== 'freelance') {
      change = true
      state.freelanceVatInfoSelection = null
    }

    if (state.companyVatInfoSelection != null && contactInfo.contactType !== 'company') {
      change = true
      state.companyVatInfoSelection = null
    }

    if (change) {
      commit('STORE', {
        individualVatInfoSelection: state.individualVatInfoSelection,
        freelanceVatInfoSelection: state.freelanceVatInfoSelection,
        companyVatInfoSelection: state.companyVatInfoSelection
      })
    }
    return !change
  }
}

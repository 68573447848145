
import Component from 'vue-class-component'
import { Emit, Vue } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'
import {
  AppointmentModificationProgress,
  GetWorkFileResponse,
  WorkFile,
  WorkFileIdentification
} from '@/models/work-file-tracking'
import ApiErrors from '@/components/display/ApiErrors.vue'
import Appointment from '@/components/inputs/Appointment/Appointment.vue'
import SendConfirmation from '@/components/inputs/Appointment/SendConfirmation.vue'
import { AxiosResponse } from 'axios'
import {
  AppointmentCancellationRequest,
  AppointmentInfo,
  CallbackRequestCreationRequest,
  GetAppointmentAvailabilitiesRequest,
  UpdateAppointmentRequest,
  WorksAppointmentTimeSlot
} from '@/models'
import { appointmentService } from '@/services/appointment-service'
import moment from 'moment'

@Component({ components: { ApiErrors, Appointment, SendConfirmation } })
export default class AppointmentModification extends Vue {
  //#region [Props]
  //#endregion

  //#region [Data]
  @State('workFile', { namespace: 'workFileTracking/workFile' })
  public workFile!: WorkFile
  @State('requestId', { namespace: 'workFileTracking' })
  public requestId!: string
  @State('identification', { namespace: 'workFileTracking/identification' })
  public identificationState!: WorkFileIdentification | null

  private progressIndication: AppointmentModificationProgress = {
    deletingAppointment: false,
    appointing: false,
    sendConfirmation: false
  }

  private appointment: AppointmentInfo | null = null
  private loading: boolean = false

  //#endregion

  //#region [Mutations]
  @Mutation('STORE', { namespace: 'workFileTracking/workFile' })
  private storeFile!: (index: WorkFile | null) => void

  //#endregion

  //#region [Computed]
  public get searchAvailabilitiesParameters(): GetAppointmentAvailabilitiesRequest {
    return {
      workFileReference: this.workFile!.reference!,
      topic: appointmentService.appointmentWorkRequestTopic(this.workFile),
      service: appointmentService.appointmentWorkRequestService(this.workFile),
      workRequestType: appointmentService.appointmentWorkRequestType(this.workFile),
      powerLevel: appointmentService.appointmentPowerLevel(this.workFile),
      periodStartDate: this.currentSearchStartDate,
      periodEndDate: this.currentSearchEndDate,
      timeSlot: WorksAppointmentTimeSlot.WholeDay,
      seeOtherDatesClicked: false
    }
  }

  public get appointmentCancellationParameters(): AppointmentCancellationRequest {
    return {
      workFileReference: this.workFile!.reference!,
      topic: appointmentService.appointmentWorkRequestTopic(this.workFile),
      service: appointmentService.appointmentWorkRequestService(this.workFile),
      workRequestType: appointmentService.appointmentWorkRequestType(this.workFile),
      powerLevel: appointmentService.appointmentPowerLevel(this.workFile)
    }
  }

  public get currentSearchStartDate(): Date {
    return appointmentService.workFileTrackingAppointmentSearchSuitableStartDate(new Date(), this.workFile)
  }

  public get currentSearchEndDate(): Date {
    return moment(this.currentSearchStartDate).add(7, 'days').toDate()
  }

  public get searchEndDate() {
    return new Date(this.workFile.desiredEndDate!)
  }

  public get contactEmail(): string | null {
    return this.workFile.contactInfo?.email
  }

  //#endregion

  //#region [Watch]
  //#endregion

  //#region [Methods]
  public async mounted() {
    // 1. Delete appointment
    const result = await this.deleteAppointment()
    if (result) {
      // No error
      this.progressIndication.appointing = true
    }
  }

  public async deleteAppointment() {
    // set progress indicator
    this.progressIndication.deletingAppointment = true
    this.$appInsights.trackEvent('WorkFileTracking_AppointmentModification_Cancelled', {
      Segment: 'WorkFileTracking',
      ZipCode: this.identificationState!.zipCode!.trim(),
      WorksFileId: this.identificationState!.worksFileId!.trim(),
      WorkRequestCode: this.workFile.workRequests[0].code
    })

    try {
      const token = sessionStorage.getItem('token')
      const response: AxiosResponse<void> = await this.$api.post(
        `api/worksrequest/work-file/${this.workFile.reference}/appointment/cancel`,
        this.appointmentCancellationParameters,
        {
          headers: {
            Authorization: token
          }
        }
      )
      this.progressIndication.deletingAppointment = false

      return response.status === 204
    } catch (err) {
      this.progressIndication.deletingAppointment = false
      return false
    }
  }

  public onAppointmentInfoChange(value: AppointmentInfo) {
    this.appointment = value
  }

  public async saveAppointment() {
    if (!this.appointment) {
      return
    }

    this.loading = true

    if (this.appointment.noSatisfyingDate) {
      await this.sendCallbackRequest()
      this.progressIndication.appointing = false
      this.progressIndication.sendConfirmation = true
      this.loading = false
      return
    }

    this.$appInsights.trackEvent('WorkFileTracking_AppointmentModification_Updated', {
      Segment: 'WorkFileTracking',
      ZipCode: this.identificationState!.zipCode!.trim(),
      WorksFileId: this.identificationState!.worksFileId!.trim(),
      WorkRequestCode: this.workFile.workRequests[0].code
    })

    const data: UpdateAppointmentRequest = {
      workFileReference: this.workFile.reference!,
      topic: appointmentService.appointmentWorkRequestTopic(this.workFile),
      service: appointmentService.appointmentWorkRequestService(this.workFile),
      workRequestType: appointmentService.appointmentWorkRequestType(this.workFile),
      powerLevel: appointmentService.appointmentPowerLevel(this.workFile),
      workFileStartDate: this.workFile.desiredStartDate!,
      workFileEndDate: this.workFile.desiredEndDate!,
      customerComment: this.appointment.remarks,
      appointmentStartTimestamp: this.appointment.selectedSlot!.startDate!,
      appointmentEndTimestamp: this.appointment.selectedSlot!.endDate!,
      grade: this.appointment.selectedSlot!.grade,
      yearlyReadingInformationReadingMonthNumber: null,
      language: 'fr', // lang will be determined in API but cannot be null
      contactInfo: this.workFile.contactInfo ?? {
        name: null,
        mobilePhoneNumber: null,
        housePhoneNumber: null,
        email: null
      },
      reference: this.requestId,
      workRequestCode: this.workFile.workRequests[0].code
    }

    const token = sessionStorage.getItem('token')
    const response: AxiosResponse<void> = await this.$api.post(
      `api/worksrequest/work-file/${this.workFile.reference}/appointment/update`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    )
    if (response.status >= 200 && response.status < 300) {
      this.progressIndication.appointing = false
      this.progressIndication.sendConfirmation = true
    }
    this.loading = false
  }

  public async sendCallbackRequest() {
    this.loading = true
    const token = sessionStorage.getItem('token')
    const data: CallbackRequestCreationRequest = {
      workFileReference: this.workFile.reference!,
      service: appointmentService.appointmentWorkRequestService(this.workFile),
      topic: appointmentService.appointmentWorkRequestTopic(this.workFile),
      powerLevel: appointmentService.appointmentPowerLevel(this.workFile),
      workRequestType: appointmentService.appointmentWorkRequestType(this.workFile),
      subject: 'Prise de rdv web indisponible',
      comment:
        'TL CREEE AUTOMATIQUEMENT : Le client n\'a pas trouvé de date qui lui convient via le formulaire Web. Merci de le recontacter pour fixer un RDV'
    }
    await this.$api.post(`api/worksrequest/work-file/${this.workFile.reference}/callback-request`, data, {
      headers: {
        Authorization: token
      }
    })
    this.$appInsights.trackEvent('WorkFileTracking_CallbackRequest_Created', {
      Segment: 'WorkFileTracking',
      WebRef: this.requestId,
      CallbackRequestReason: 'customer_no_availability_found',
      WorkRequestCode: this.workFile.workRequests[0].code,
      WorkFileReference: this.workFile.reference
    })

    this.loading = false
  }

  public async onSendConfirmationClose() {
    if (!this.appointment || this.appointment.noSatisfyingDate) {
      location.href = this.$t('common.oresLink').toString()
      return
    }
    // Refresh the work file and go back to work file view step
    this.loading = true
    const token = sessionStorage.getItem('token')
    const response = await this.$api.get(`api/WorksRequest/get-work-file/${this.workFile.reference}`, {
      headers: {
        Authorization: token
      }
    })

    this.loading = false

    if (response.status !== 200) {
      this.$store.dispatch('apiErrors/customManage', 'contactCallCenter')
      return false
    }

    const workFileResponse: GetWorkFileResponse = response.data
    this.storeFile(workFileResponse.workFile)

    this.close()
  }

  //#endregion

  //#region [Emit]
  @Emit('close')
  private close(): boolean {
    return true
  }
  //#endregion
}

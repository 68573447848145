import { RootState } from '@/store/types'
import { cmhService } from '@/services'
import { GetterTree } from 'vuex'
import { CmhEnergyConfigurationState } from './types'

export const getters: GetterTree<CmhEnergyConfigurationState, RootState> = {
  isCmhAvailable(state: CmhEnergyConfigurationState): boolean {
    return cmhService.isCmhAvailableElec(state.packagedElectricityMeters)
      && cmhService.isCmhAvailableGas(state.gasMeters)
  }
}


import { Component, Watch } from 'vue-property-decorator'
import { State, Mutation, Getter } from 'vuex-class'
import Step from '@/components/steps/Step'
import CmhElectricityPopupForm from '@/components/inputs/Configuration/CmhElectricityPopupForm.vue'
import { helper, cmhService } from '@/services'
import CmhGasPopupForm from '@/components/inputs/Configuration/CmhGasPopupForm.vue'
import { CmhAddressPoint } from '@/models/cmh-address-point'
import PriceSummary from '@/components/display/PriceSummary.vue'
import { CmhConfigurationStoreData } from '@/store/new-connection-cmh/cmh-energy-configuration/mutations'
import { BuildingType } from '@/models/building-type'
import { pixelFacebookService } from '@ores/vue-library'
import { pricingService } from '@/services/pricing-service'


import {
  MeterDestination,
  GasMeter,
  MeterGasTechnicalCharacteristic,
  ConfigurationInfo,
  PackagedElectricityMeter,
  PackageDTO,
  ModifiedConnection
} from '@/models'
import CmhElectricityPackagePopupForm from '@/components/inputs/Configuration/CmhElectricityPackagePopupForm.vue'
import CmhElectricityModifiedConnectionForm from '@/components/inputs/Configuration/CmhElectricityModifiedConnectionForm.vue'
import HousePackageElectricity from '@/components/display/CmhNewConnection/HousePackageElectricity.vue'
import BuildingPackageElectricity from '@/components/display/CmhNewConnection/BuildingPackageElectricity.vue'
import PackageGas from '@/components/display/CmhNewConnection/PackageGas.vue'
import SimulatorAdvertising from '@/components/display/CmhNewConnection/SimulatorAdvertising.vue'

@Component({
  components: {
    HousePackageElectricity,
    BuildingPackageElectricity,
    PackageGas,
    SimulatorAdvertising,
    CmhElectricityPopupForm,
    CmhElectricityPackagePopupForm,
    CmhElectricityModifiedConnectionForm,
    PriceSummary,
    CmhGasPopupForm
  },
  name: 'cmh-energy-configuration-step'
})
export default class CmhEnergyConfigurationStep extends Step {
  //#region [Data]
  @State('packageFromSimulator', { namespace: 'newConnectionCmh/landingPage' })
  public statePackageFromSimulator!: string | null
  @State('desiredPowerFromSimulator', { namespace: 'newConnectionCmh/landingPage' })
  public stateDesiredPowerFromSimulator!: number | null

  @State('isDefaultConfigurationOverriden', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public isDefaultConfigurationOverriden!: boolean

  @State('packagedElectricityMeters', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public statePackagedElectricityMeters!: PackagedElectricityMeter[]
  @State('modifiedConnections', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public stateModifiedConnections!: ModifiedConnection[]
  @State('gasMeters', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public stateGasMeters!: GasMeter[]

  @State('addressPoint', { namespace: 'newConnectionCmh/cmhAddress' })
  public stateAddressPoint!: CmhAddressPoint
  @State('destinations', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public destinations!: MeterDestination[]
  @State('technicalCharacteristics', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public technicalCharacteristics!: MeterGasTechnicalCharacteristic[]

  @State('defaultPackagedElectricityMeter', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public defaultPackagedElectricityMeter!: PackagedElectricityMeter
  @State('defaultModifiedConnection', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public defaultModifiedConnection!: ModifiedConnection
  @State('defaultGasMeter', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public defaultGasMeter!: GasMeter

  @State('requestId', { namespace: 'newConnectionCmh' })
  public requestId!: string

  @State('meterElectricityPackages', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public meterElectricityPackages!: PackageDTO[]

  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  public store!: (index: CmhConfigurationStoreData) => void

  @Mutation('CLEAR_SIMULATOR_VALUES', { namespace: 'newConnectionCmh/landingPage' })
  public clearSimulatorValues!: () => void

  @Getter('isElectricitySelected', { namespace: 'newConnectionCmh/offeringsSelection' })
  public isElectricitySelected!: boolean
  @Getter('isGasSelected', { namespace: 'newConnectionCmh/offeringsSelection' })
  public isGasSelected!: boolean

  public packagedElectricityMeters: PackagedElectricityMeter[] = []
  public modifiedConnections: ModifiedConnection[] = []
  public gasMeters: GasMeter[] = []

  public selectedPackagedElectricityMeter: PackagedElectricityMeter | null = null
  public selectedModifiedConnection: ModifiedConnection | null = null
  public selectedPackagedElectricityMeterIdentifier: number = 0
  public selectedModifiedConnectionMeterIdentifier: number = 0
  public selectedGasMeter: GasMeter | null = null

  public electricityMeterAdding: boolean = false
  public modifiedConnectionsAdding: boolean = false
  public gasMeterAdding: boolean = false

  public isElectricityEditModalOpen: boolean = false
  public isGasEditModalOpen: boolean = false
  public isModifiedConnectionOpen: boolean = false

  public isPriceToBeDetermined = false
  public isHouseSelected: boolean = false
  public isBuildingSelected: boolean = false
  //#endregion

  //#region [Method]
  public onAddNewConnection() {
    this.addModifiedConnection()
    this.isModifiedConnectionOpen = true
  }

  public onAddNewMeter(destination: MeterDestination) {
    const newMeter = helper.clone(this.defaultPackagedElectricityMeter)
    newMeter.destination = destination!
    this.packagedElectricityMeters.push(newMeter)
    this.electricityMeterAdding = true
    this.editElectricityBox(this.packagedElectricityMeters.length - 1)
    this.isElectricityEditModalOpen = true
  }

  public addNewElectricityMeter() {
    this.packagedElectricityMeters.push(helper.clone(this.defaultPackagedElectricityMeter))
    this.electricityMeterAdding = true
    this.editElectricityBox(this.packagedElectricityMeters.length - 1)
  }

  public addModifiedConnection() {
    const defaultModifiedConnection = helper.clone(this.defaultModifiedConnection)
    this.modifiedConnections.push(defaultModifiedConnection)
    this.modifiedConnectionsAdding = true
    this.selectedModifiedConnection = this.modifiedConnections[this.modifiedConnections.length - 1]
    this.selectedModifiedConnectionMeterIdentifier = this.modifiedConnections.length - 1
  }

  public editElectricityBox(identifier: number): void {
    this.selectedPackagedElectricityMeter = this.packagedElectricityMeters[identifier]
    this.selectedPackagedElectricityMeterIdentifier = identifier
    this.isElectricityEditModalOpen = true
  }

  public editModifiedConnection(identifier: number): void {
    this.selectedModifiedConnection = this.modifiedConnections[identifier]
    this.selectedModifiedConnectionMeterIdentifier = identifier
    this.isModifiedConnectionOpen = true
  }

  public deleteElectricityBox(identifier: number): void {
    this.packagedElectricityMeters.splice(identifier, 1)
  }

  public deleteModifiedConnection(identifier: number): void {
    this.modifiedConnections.splice(identifier, 1)
  }

  public onElectricityModalClose(): void {
    if (this.electricityMeterAdding) {
      this.packagedElectricityMeters.splice(this.packagedElectricityMeters.length - 1, 1)
      this.electricityMeterAdding = false
    }
  }

  public onModifeidConnectionModalClose(): void {
    if (this.modifiedConnectionsAdding) {
      this.modifiedConnections.splice(this.modifiedConnections.length - 1, 1)
      this.modifiedConnectionsAdding = false
    }
  }

  public submitElecMeter(packagedElectricityMeter: PackagedElectricityMeter) {
    this.electricityMeterAdding = false
    this.selectedPackagedElectricityMeter!.count = packagedElectricityMeter.count
    this.selectedPackagedElectricityMeter!.customPower = packagedElectricityMeter.customPower
    this.selectedPackagedElectricityMeter!.package = packagedElectricityMeter.package
    this.selectedPackagedElectricityMeter!.remarks = packagedElectricityMeter.remarks
    this.selectedPackagedElectricityMeter!.connectionType = packagedElectricityMeter.connectionType
    this.selectedPackagedElectricityMeter!.wantedPower = packagedElectricityMeter.wantedPower
    this.isElectricityEditModalOpen = false
    this.getElectricityMeterPrice()
  }

  public submitModifiedConnection(modifiedConnection: ModifiedConnection) {
    this.modifiedConnectionsAdding = false
    this.selectedModifiedConnection!.price = modifiedConnection.price
    this.selectedModifiedConnection!.count = modifiedConnection.count
    this.selectedModifiedConnection!.remarks = modifiedConnection.remarks
    this.selectedModifiedConnection!.type = modifiedConnection.type
    this.getModifiedConnectionPrice()
    this.isModifiedConnectionOpen = false
  }

  public getElectricityMeterPrice(): void {
    this.packagedElectricityMeters[this.selectedPackagedElectricityMeterIdentifier].package!.homePrice = this.selectedPackagedElectricityMeter?.package?.homePrice!
    this.packagedElectricityMeters[this.selectedPackagedElectricityMeterIdentifier].package!.buildingPrice = this.selectedPackagedElectricityMeter?.package?.buildingPrice!
  }

  public getModifiedConnectionPrice(): void {
    this.modifiedConnections[this.selectedModifiedConnectionMeterIdentifier].price = this.selectedModifiedConnection?.price!
  }

  public getElectricityTotalPrice(): number {
    if (this.packagedElectricityMeters.length) {
      if (this.packagedElectricityMeters.some((x: PackagedElectricityMeter) => x.customPower)) {
        this.isPriceToBeDetermined = true
        return 0
      } else {
        this.isPriceToBeDetermined = false
        let total = 0
        total += pricingService.getTotalPackagedElectricityMetersPrice(this.packagedElectricityMeters, 'exVat', this.stateAddressPoint.selection)
        if (this.modifiedConnections.length) {
          total += this.modifiedConnections.map((modifiedConnection) => modifiedConnection?.price!.exVat).reduce((current, next) => current + next)
        }
        return total
      }
    } else {
      return 0
    }
  }

  public addNewGasMeter() {
    this.gasMeters.push(helper.clone(this.defaultGasMeter))
    this.gasMeterAdding = true
    this.editGasBox(this.gasMeters.length - 1)
  }

  public editGasBox(identifier: number): void {
    this.selectedGasMeter = this.gasMeters[identifier]
    this.isGasEditModalOpen = true
  }

  public validateGasMeter(meter: GasMeter) {
    this.gasMeterAdding = false
    this.selectedGasMeter!.count = meter.count
    this.selectedGasMeter!.technicalCharacteristic = meter.technicalCharacteristic
    this.selectedGasMeter!.destination = meter.destination
    this.selectedGasMeter!.remarks = meter.remarks
    this.selectedGasMeter!.power = meter.power

    this.isGasEditModalOpen = false
  }

  public deleteGasBox(identifier: number): void {
    this.gasMeters.splice(identifier, 1)
  }

  public onGasModalClose(): void {
    if (this.gasMeterAdding) {
      this.gasMeters.splice(this.gasMeters.length - 1, 1)
      this.gasMeterAdding = false
    }
  }

  addButtonDisabled(meterDestination: string): boolean {
    const meters = this.packagedElectricityMeters.filter(meter => meter.destination === meterDestination)
    return meters.length > 9;
  }

  public getGasMeterTitle(item: GasMeter) {
    return `${item.count}x ${this.$t('new-connection-cmh.energy-configuration-step.meter')} ${this.$t(
      'new-connection-cmh.energy-configuration-step.naturalGas'
    )}`
  }

  public getGasMeterDescription(item: GasMeter) {
    return `${this.$t('new-connection-cmh.energy-configuration-step.kitchenHeaterHotWater')} - ${this.$t(
      'new-connection-cmh.energy-configuration-step.max'
    )} ${item.power}kW`
  }

  public async mounted() {
    pixelFacebookService.trackViewContent({
      content_ids: ['CmhForm'],
      content_name: 'Energy Configuration Step',
      content_type: 'product'
    })
    this.isHouseSelected = this.stateAddressPoint.selection === BuildingType.house
    this.isBuildingSelected = this.stateAddressPoint.selection === BuildingType.building
    if (this.isDefaultConfigurationOverriden) {
      if (this.isElectricitySelected) {
        if (!this.statePackagedElectricityMeters.length) {
          this.setDefaultElectricityMeter()
        } else {
          this.packagedElectricityMeters = helper.clone(this.statePackagedElectricityMeters)
          if (this.isHouseSelected) {
            this.modifiedConnections = helper.clone(this.stateModifiedConnections)
          }
        }
      }
      if (this.isGasSelected) {
        if (!this.stateGasMeters.length) {
          this.setDefaultGasMeter()
        } else {
          this.gasMeters = helper.clone(this.stateGasMeters)
        }

      }
    } else if (this.stateAddressPoint) {
      if (this.isElectricitySelected) {
        this.setDefaultElectricityMeter()
      }
      if (this.isGasSelected) {
        this.setDefaultGasMeter()
      }
    }
    if (this.statePackageFromSimulator) {
      let selectedMeter = helper.clone(this.defaultPackagedElectricityMeter)
      if (!this.stateDesiredPowerFromSimulator) {
        const packageSimulator = this.meterElectricityPackages.find(obj => obj.key === this.statePackageFromSimulator);
        if (packageSimulator !== undefined) {
          selectedMeter.package = helper.clone(packageSimulator)!;
        }
      } else {
        selectedMeter.package = {
          key: '69+',
          maximumPower: {
            kiloVoltAmpere: -1,
            voltAmpere: -1
          },
        }
        selectedMeter.customPower = true
        selectedMeter.wantedPower = this.stateDesiredPowerFromSimulator
      }
      this.packagedElectricityMeters = []
      this.packagedElectricityMeters.push(selectedMeter)
      this.clearSimulatorValues()
    }
  }

  setDefaultGasMeter(): void {
    if (this.isBuildingSelected) {
      this.setDefaultGasMeterForBuilding()
    }
    if (this.isHouseSelected) {
      this.setDefaultGasMeterForHouse()
    }
  }

  setDefaultElectricityMeter(): void {
    if (this.isHouseSelected) {
      this.setDefaultElectricityMeterForHouse()
    }

    if (this.isBuildingSelected) {
      this.setDefaultElectricityMeterForBuilding()
    }
  }

  public setDefaultGasMeterForBuilding(): void {
    let gasMeter = helper.clone(this.defaultGasMeter)
    gasMeter.count = this.stateAddressPoint.flatNumber ? +this.stateAddressPoint.flatNumber : 0
    this.gasMeters.push(gasMeter)
  }

  public setDefaultGasMeterForHouse(): void {
    this.gasMeters.push(helper.clone(this.defaultGasMeter))
  }

  public setDefaultElectricityMeterForHouse(): void {
    this.packagedElectricityMeters.push(helper.clone(this.defaultPackagedElectricityMeter))
  }

  public setDefaultElectricityMeterForBuilding(): void {
    let commonMeters = helper.clone(this.defaultPackagedElectricityMeter)
    commonMeters.destination = 'common'
    commonMeters.count = this.stateAddressPoint.commonElectricMeterNumber ? +this.stateAddressPoint.commonElectricMeterNumber : 0
    this.packagedElectricityMeters.push(commonMeters)
    let flatMeters = helper.clone(this.defaultPackagedElectricityMeter)
    flatMeters.destination = 'residential'
    flatMeters.count = this.stateAddressPoint.flatNumber ? +this.stateAddressPoint.flatNumber : 0
    this.packagedElectricityMeters.push(flatMeters)
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (isValid) {
      this.store({ gasMeters: this.gasMeters, packagedElectricityMeters: this.packagedElectricityMeters, modifiedConnections: this.modifiedConnections, isDefaultConfigurationOverriden: true })

      await this.saveStep()

      this.$appInsights.trackEvent('WR_NCCMH_Step3_Validated', { Segment: 'WR_NCCMH', WebRef: this.requestId })
      return !next ? true : this.isMeterConfigurationValid
    }
  }

  public async saveStep() {
    const data = {
      type: 2,
      gasMeters: this.gasMeters,
      packagedElectricityMeters: this.packagedElectricityMeters,
      modifiedConnections: this.modifiedConnections
    }

    this.$api.post<ConfigurationInfo>(`NewConnectionRequest/store/${this.requestId}/meter-configuration`, data)
  }
  //#endregion

  //#region [Watch]
  @Watch('packagedElectricityMeters')
  public onPriceChange() {
    this.getElectricityTotalPrice()
  }
  //#endregion

  //#region [Computed]
  public get isMeterConfigurationValid() {
    if (this.isElectricitySelected && !this.isGasSelected) {
      return this.packagedElectricityMeters.length > 0
    } else if (this.isGasSelected && !this.isElectricitySelected) {
      return this.gasMeters.length > 0
    } else if (this.isElectricitySelected && this.isGasSelected) {
      return this.gasMeters.length > 0 && this.packagedElectricityMeters.length > 0
    }
  }

  public get isElectricityErrorDisplayed(): boolean {
    return this.isElectricitySelected && !this.packagedElectricityMeters.length
  }

  public get isElectricityLowerThan56kvaDisplayed(): boolean {
    return cmhService.isElectricityLowerThan56kva(this.packagedElectricityMeters)
  }

  public get isElectricityCumulatedLowerThan56kvaDisplayed(): boolean {
    return cmhService.isElectricityCumulatedLowerThan56k(this.packagedElectricityMeters)
  }

  public get isFlatMetersGreaterThanFour(): boolean {
    return cmhService.isFlatMetersGreaterThanFour(this.packagedElectricityMeters, this.gasMeters)
  }

  public get isCommonMetersGreaterThanOne(): boolean {
    return cmhService.isCommonMetersGreaterThanOne(this.packagedElectricityMeters)
  }

  public get isGasErrorDisplayed(): boolean {
    return this.isGasSelected && !this.gasMeters.length
  }

  public get isGasLowerThan150kwDisplayed(): boolean {
    return cmhService.isGasLowerThan150kw(this.gasMeters)
  }

  public get isGasCumulatedLowerThan250kwDisplayed(): boolean {
    return cmhService.isGasCumulatedLowerThan250kw(this.gasMeters)
  }

  //#endregion
}

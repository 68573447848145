import { MutationTree } from 'vuex'
import { SmartMeterConnectionState } from './types'
import { idGenerator } from '@/services'

export const mutations: MutationTree<SmartMeterConnectionState> = {
  STORE(state, noAppointment: boolean) {
    state.noAppointment = noAppointment
  },
  STORE_RECAPTCHA(state, recaptcha: string | null) {
    state.recaptcha = recaptcha
  },
  RESET(state) {
    state.requestId = idGenerator.getNewWebReference()
    state.noAppointment = false
  }
}

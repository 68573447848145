import { MutationTree } from 'vuex';
import { StakeholderState } from './types';
import { FileRequestToken } from '@/models';

export const mutations: MutationTree<StakeholderState> = {
  STORE_FILENUMBER(state, data: FileRequestToken) {
    state.fileNumber = data.fileNumber
    state.hash = data.hash
  },
  STORE_WORKS(state, data: string | null) {
    state.workRequesterId = data
  },
  CLEAR(state) {
    state.workRequesterId = null
    state.fileNumber = null
    state.hash = null
  },
  CLEAR_FILENUMBER(state) {
    state.fileNumber = null
    state.hash = null
  }
}


import { Component } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import ContactInfoForm from '@/components/inputs/Contact/ContactInfoForm.vue'
import { State, Mutation } from 'vuex-class'
import {
  ContactInfo,
  WorkRequesterViewModel,
  CheckEanSmartMeterResult,
  EnergyProductionInfoModel
} from '@/models'
import { helper, contactService } from '@/services'
import { AxiosResponse } from 'axios'

@Component({
  name: 'contact-info-step',
  components: {
    ContactInfoForm
  }
})
export default class ContactInfoStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'smartMeterConnection' })
  public requestId!: string
  @State('workRequesterId', { namespace: 'smartMeterConnection/stakeholder' })
  public workRequesterId!: string | null
  @State('eanCode', { namespace: 'smartMeterConnection/identification' })
  public eanCode!: string
  @State('checkEanResult', { namespace: 'smartMeterConnection/identification' })
  public eanResult!: CheckEanSmartMeterResult
  @State('contactInfo', { namespace: 'smartMeterConnection/contactInfo' })
  public contactInfoState!: ContactInfo | null
  @State('energyProductionInfo', { namespace: 'smartMeterConnection/energyProduction' })
  public energyProductionInfoState!: EnergyProductionInfoModel | null

  public contactInfo: ContactInfo = contactService.createContactInfo()
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'smartMeterConnection/contactInfo' })
  private store!: (index: ContactInfo | null) => void

  @Mutation('STORE_WORKS', { namespace: 'smartMeterConnection/stakeholder' })
  private storeWorks!: (index: string | null) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    if (this.contactInfoState) {
      this.contactInfo = helper.clone(this.contactInfoState)
    }

    this.canContinue(true)
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean> {
    if (isValid && this.contactInfo && this.contactInfo.contactType) {
      this.$appInsights.trackEvent('WR_SMC_StepContact_Validated', {
        Segment: 'WR_SMC',
        WebRef: this.requestId,
        ContactType: this.contactInfo.contactType!.toString(),
        EnergyProductionInfo:
            this.energyProductionInfoState !== null && this.energyProductionInfoState.hasEnergyProduction !== null
                ? this.energyProductionInfoState.hasEnergyProduction.toString()
                : '0',
        SimpleTariff: 'false',
        DualTariff: 'true'
      })
    }

    if (isValid && next) {
      const address = this.contactInfo.useWorksAddress ? this.eanResult.consumptionAddress : null
      const data: WorkRequesterViewModel = {
        eanCode: this.eanCode,
        contactInfo: this.contactInfo,
        address
      }

      const token = sessionStorage.getItem('token')

      const workRequesterId: string | null = await this.$api
        .post<WorkRequesterViewModel, AxiosResponse<string>>('api/stakeholder/work-requester', data, {
          headers: {
            Authorization: token
          }
        })
        .then((resp) => {
          this.storeWorks(resp.data ? resp.data.toString() : null)
          return resp.data
        })
        .catch(() => null)
    }
    this.store(this.contactInfo)
    return true
  }
  //#endregion
}

import Vue, { VueConstructor } from 'vue'
import { VueAppInsights } from './application-insights'
import { ApiAxios } from './customer-works'
import { AddressRepositoryAxios } from './address-repository'
import { DialogsPlugin } from './dialogs'
import { ValidatorObserver } from './validator-observer'
import { RootState } from '@/store/types'
import { Store } from 'vuex'
import { CustomerFormsAxios } from './customer-forms'

interface Settings {
  addressApiUrl: string
  apiUrl: string
  appInsightsKey: string
  mainSiteUrl: string
  customerFormsApiUrl: string
}

declare module 'vue/types/vue' {
  interface Vue {
    $settings: Settings,
    $testMode: boolean
  }

  interface VueConstructor {
    $settings: Settings,
    $testMode: boolean
  }
}

export function InstallApp(vueCtor: VueConstructor<Vue>, options: { store: Store<RootState>, settings: Settings }) {
  vueCtor.prototype.$settings = options.settings;
  vueCtor.$settings = options.settings;
  const testMode = process.env.VUE_APP_TEST_MODE === 'YES';
  vueCtor.prototype.$testMode = testMode;
  vueCtor.$testMode = testMode;

  vueCtor.use(CustomerFormsAxios, options.store)
  vueCtor.use(AddressRepositoryAxios, options.store)
  vueCtor.use(ApiAxios, options.store)
  vueCtor.use(VueAppInsights, {
    id: vueCtor.$settings.appInsightsKey
  })
  vueCtor.use(DialogsPlugin, options.store)
  vueCtor.use(ValidatorObserver)
}

import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { InitialState, ContactInfoState } from './types'
import { RootState } from '@/store/types';

const namespaced: boolean = true

export const contactInfo: Module<ContactInfoState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations
}

import { FileInfo } from '@ores/vue-library';

export interface AttachmentState {
  attachDocuments: boolean
  buildingLocationFilesInfo: FileInfo[]
}

export const InitialState: AttachmentState = {
  attachDocuments: false,
  buildingLocationFilesInfo: []
}


import { Prop, Component, Watch, Emit } from 'vue-property-decorator'
import InputWithValidation from './InputWithValidation'
import { SubCityStreet, StreetSubCity } from '@/models'
import { AxiosResponse } from 'axios'
import store from '@/store'

@Component
export default class StreetSubCitySelector extends InputWithValidation {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly label!: string
  @Prop({ type: Number, required: false }) public readonly subCityId?: number | null
  @Prop(Boolean) public readonly required!: boolean | null
  @Prop(Boolean) public readonly disabled!: boolean | null
  @Prop(Object) public readonly value!: SubCityStreet | null
  @Prop(Boolean) public readonly hideLabel?: boolean
  //#endregion

  //#region [Data]
  public loading: boolean = false
  public streets: SubCityStreet[] = []
  public selectedStreetSubCity: SubCityStreet | null = this.value
  //#endregion

  //#region [Computed]
  public get selection(): number | null {
    return !this.selectedStreetSubCity ? null : this.selectedStreetSubCity.georesStreetSubcityId
  }
  public set selection(value: number | null) {
    const street = this.streets.filter((s) => s.georesStreetSubcityId === value)
    if (!!value && !!street && street.length) {
      this.notifyValidation(true)
      this.onSelectedStreetSubCityChanged(street[0])
    } else {
      this.notifyValidation(!this.required)
      this.onSelectedStreetSubCityChanged(null)
    }
  }

  public get sortedStreetSubCities(): SubCityStreet[] {
    return this.streets.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  }
  //#endregion

  //#region [Watch]
  @Watch('subCityId')
  public onSubCityIdChanged() {
    this.$nextTick(() => {
      this.fetchStreets()
    })
  }

  @Watch('required')
  public onRequiredChanged(required: boolean) {
    if (!required && this.selection == null) {
      this.notifyValidation(true)
    } else if (required && this.selection == null) {
      this.notifyValidation(false)
    }
  }

  @Watch('value')
  public onValueChanged(newValue: StreetSubCity | null) {
    this.selectedStreetSubCity = newValue
  }
  //#endregion

  //#region [Method]
  public mounted() {
    if (this.subCityId !== null) {
      this.fetchStreets()
    }
  }
  public fetchStreets() {
    const subCityId = this.subCityId
    if (subCityId === null) {
      this.streets = []
      this.selection = null
      return
    }
    this.loading = true
    this.$addressRepository
      .get(`streets?subcityId=${subCityId}`, {
        cache: {
          ignoreCache: false
        }
      })
      .then(
        (response: AxiosResponse<SubCityStreet[]>) => {
          if (response.status >= 200 && response.status < 300) {
            const streets = response.data

            this.streets.splice(0)
            const emptyStreet: StreetSubCity = {
              georesStreetId: null,
              georesStreetSubcityId: null,
              name: ''
            }
            this.streets.push(emptyStreet)
            for (const street of streets) {
              this.streets.push(street)
            }

            if (this.selectedStreetSubCity !== null) {
              const street = this.streets.find((s) => s.georesStreetSubcityId === this.selection)
              if (street) {
                this.selection = street.georesStreetSubcityId
                this.loading = false
                return
              }
            }
            this.selection = streets.length === 1 && !this.disabled ? streets[0].georesStreetSubcityId : null
          }
          else if(response.status === 404){
            store.dispatch('apiErrors/clear')
          }
          else {
            store.dispatch('apiErrors/manageAddressRepository')
          }
        },
        (error) => {
          this.notifyValidation(false)
        }
      )
      .then(() => {
        this.loading = false
      })
  }

  public onChange() {
    this.$emit('manualChange')
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public onSelectedStreetSubCityChanged(streetSubCity: StreetSubCity | null): StreetSubCity | null {
    return streetSubCity
  }
  //#endregion
}

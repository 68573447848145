import {
  MeterDestination, MeterElectricityTension, MeterElectricityType,
  MeterGasTechnicalCharacteristic
} from './api';

import { ElectricityPowerSupplyType, PackageDTO, PriceDTO } from '@/models'

/** 0 (I don't know), 1 (Standard), 2 (Custom) */
export enum ConfigurationType {
  DontKnow = 0,
  Standard = 1,
  Custom = 2
}

export interface Power {
  ampere: number,
  kva: number
}

export interface GasMeter {
  count: number
  technicalCharacteristic: MeterGasTechnicalCharacteristic
  destination: MeterDestination
  remarks: string | null
  power: number
}

export interface ElectricityMeter {
  tension: MeterElectricityTension
  count: number | null
  power: Power | null
  destination: MeterDestination | null
  type: MeterElectricityType | null
  remarks: string | null
  wantEmergencyPower: boolean
  price?: number | null
}

export interface PackagedElectricityMeter {
  count: number | null
  connectionType: ElectricityPowerSupplyType | null
  remarks: string | null
  package: PackageDTO | null
  customPower: boolean
  wantedPower: number | null
  destination: MeterDestination | null
}

export interface ModifiedConnection {
  count: number | null
  type: MeterElectricityType | null
  price: PriceDTO | null
  remarks: string | null
}

export interface ConstructionElectricityMeter {
  tension: MeterElectricityTension
  power: Power | null
  type: MeterElectricityType | null
  use: string | null
  wantEmergencyPower: boolean
}

export interface TemporaryElectricityMeter {
  tension: MeterElectricityTension & ('mono230V' | 'tri230V' | 'tri400V')
  power: Power | null
  use: string | null
}

export interface ConfigurationInfo {
  type: ConfigurationType | null
  electricityMeters: ElectricityMeter[],
  gasMeters: GasMeter[]
}

export interface ConstructionConfigurationInfo {
  electricityMeter: ConstructionElectricityMeter,
  startDate: Date | null
  endDate: Date | null
}

export interface TemporaryConfigurationInfo {
  electricityMeters: TemporaryElectricityMeter[],
  startDate: Date | null
  endDate: Date | null
}

export enum SynergridChangeTypeRequest {
  New = 0,
  Move = 1,
  Enhance = 2,
  Remove = 3,
  ModifyRef = 4
}

export interface SynergridConfigurationInfo {
  changeType: SynergridChangeTypeRequest | null,
  newRequestInfo: NewRequestInfo | null,
  moveRequestInfo: MoveRequestInfo | null,
  enhanceRequestInfo: EnhanceRequestInfo | null,
  removeRequestInfo: RemoveRequestInfo | null,
  modifyRefRequestInfo: ModifyRefRequestInfo | null,

  month: number | null,
  year: number | null,
  remark: string | null
}

export interface NewRequestInfo {
  synergridRef: string | null,
  group: string | null,
  power: number | null
}

export interface MoveRequestInfo {
  ean: string | null,
}

export interface EnhanceRequestInfo {
  group: string | null,
  power: number | null
}

export interface RemoveRequestInfo {
  ean: string | null
}

export interface ModifyRefRequestInfo {
  oldRef: string | null,
  oldGroup: string | null,
  newRef: string | null,
  newGroup: string | null
}

export interface OtherEquipmentConfigurationInfo {
  electricityMeter: OtherEquipmentElectricityMeter,
  month: number | null,
  year: number | null,
  remark: string | null
}

export interface OtherEquipmentElectricityMeter {
  count: number | null
  tension: MeterElectricityTension & ('mono230V' | 'tri230V' | 'tri400V')
  power: Power | null
  type: MeterElectricityType | null
}

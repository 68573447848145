import { TimeInfo, timeService } from '@ores/vue-library';
import moment from 'moment';
import {
  AppointmentSlot, RestrictedWorkRequestService, RestrictedWorkRequestTopic,
  PowerLevel, RestrictedPowerLevel, RestrictedWorkRequestType, WorkFile, WorkRequestService, WorkRequestTopic,
  WorkRequestType
} from '@/models';
import { i18n } from '@/i18n';

// Appointment service
const startHour = 7;
const startMinute = 30;
const endHour = 23;
const endMinute = 30;

// Timeslot boundaries
const morningTimeSlotStartHour = 8;
const morningTimeSlotEndHour = 12;
const afternoonTimeSlotStartHour = 13;
const afternoonTimeSlotEndHour = 16;

function isOpen(now: TimeInfo): boolean {
  return !(
    now.hours < startHour ||
    (now.hours === startHour && now.minutes < startMinute) ||
    now.hours > endHour ||
    (now.hours === endHour && now.minutes >= endMinute)
  )
}

function appointmentSearchSuitableStartDate(now: Date, meterReadingMonth: number | null): Date {
  // Use the first day of meter reading month
  // Should be at least equal to today + 21 business days

  // We mean to compute a date the correct day (hour is ignored in API).
  // Format the date at 2h Belgian time so that UTC time day is the same.
  const firstAllowedDateInFutureMoment = moment(
    new Date(now.getFullYear(), now.getMonth() + 1, now.getDate(), 2, 0, 0)
  );

  if (meterReadingMonth === null) {
    return firstAllowedDateInFutureMoment.toDate();
  }
  let meterReadingMonthDay = 1;
  if (meterReadingMonth === 0 || meterReadingMonth === 4 || meterReadingMonth === 10) {
    meterReadingMonthDay = 2
  }

  let meterReadingMonthMoment = moment(new Date(now.getFullYear(), meterReadingMonth, meterReadingMonthDay, 2, 0, 0));
  if (meterReadingMonthMoment < moment(now)) {
    meterReadingMonthMoment = moment(new Date(now.getFullYear() + 1, meterReadingMonth, meterReadingMonthDay, 2, 0, 0));
  }
  let returnedMoment = meterReadingMonthMoment > firstAllowedDateInFutureMoment
    ? meterReadingMonthMoment : firstAllowedDateInFutureMoment;
  // Shift if Saturday or Sunday
  if (returnedMoment.day() === 0) {
    returnedMoment = returnedMoment.add(1, 'days')
  } else if (returnedMoment.day() === 6) {
    returnedMoment = returnedMoment.add(2, 'days')
  }
  return returnedMoment.toDate();
}

function workFileTrackingAppointmentSearchSuitableStartDate(now: Date, workFile: WorkFile): Date {
  // We mean to compute a date the correct day (hour is ignored in API).
  // Format the date at 2h Belgian time so that UTC time day is the same.
  const firstAllowedDateInFutureMoment = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 2, 0, 0)
  ).add(5, 'day');
  const returnedMoment = moment(workFile.desiredStartDate!) > firstAllowedDateInFutureMoment
    ? moment(workFile.desiredStartDate!) : firstAllowedDateInFutureMoment;
  return returnedMoment.toDate();
}

function timeslotLabel(appointmentSlot: AppointmentSlot, wholeDay: boolean): string {
  const startTime = timeService.displayTime(appointmentSlot.startDate!)
  const endTime = timeService.displayTime(appointmentSlot.endDate!)
  if (wholeDay) {
    return i18n.t('smartMeter.appointmentTimeslot.wholeDay', [startTime, endTime]).toString()
  }
  if (appointmentSlot.endDate!.getHours() < afternoonTimeSlotStartHour) {
    return i18n.t('smartMeter.appointmentTimeslot.morning', [startTime, endTime]).toString()
  }
  return i18n.t('smartMeter.appointmentTimeslot.afternoon', [startTime, endTime]).toString()
}

function appointmentPowerLevel(workFile: WorkFile): RestrictedPowerLevel {
  const powerLevel: PowerLevel = workFile.powerLevel
  switch (powerLevel) {
    case PowerLevel.Low:
      return RestrictedPowerLevel.Low
    case PowerLevel.High:
      return RestrictedPowerLevel.High
    default:
      throw new Error('Work file "unknown" power level, cannot reschedule an appointment')
  }
}

function appointmentWorkRequestType(workFile: WorkFile): RestrictedWorkRequestType {
  const requestType: WorkRequestType = workFile.type
  switch (requestType) {
    case WorkRequestType.Electricity:
      return RestrictedWorkRequestType.Electricity
    case WorkRequestType.Gas:
      return RestrictedWorkRequestType.Gas
    case WorkRequestType.Phone:
      return RestrictedWorkRequestType.Phone
    case WorkRequestType.Television:
      return RestrictedWorkRequestType.Television
    case WorkRequestType.Water:
      return RestrictedWorkRequestType.Water
    default:
      throw new Error('Work file "unknown" request type, cannot reschedule an appointment')
  }
}

function appointmentWorkRequestTopic(workFile: WorkFile): RestrictedWorkRequestTopic {
  const topic: WorkRequestTopic = workFile.topic
  switch (topic) {
    case WorkRequestTopic.BudgetMeter:
      return RestrictedWorkRequestTopic.BudgetMeter
    case WorkRequestTopic.Connection:
      return RestrictedWorkRequestTopic.Connection
    case WorkRequestTopic.ConnectionWithoutMeter:
      return RestrictedWorkRequestTopic.ConnectionWithoutMeter
    case WorkRequestTopic.ChargingStationConnection:
      return RestrictedWorkRequestTopic.ChargingStationConnection
    case WorkRequestTopic.MeterWorks:
      return RestrictedWorkRequestTopic.MeterWorks
    case WorkRequestTopic.DualFlowMeter:
      return RestrictedWorkRequestTopic.DualFlowMeter
    case WorkRequestTopic.DualRegisterMeter:
      return RestrictedWorkRequestTopic.DualRegisterMeter
    case WorkRequestTopic.SmartMeter:
      return RestrictedWorkRequestTopic.SmartMeter
    case WorkRequestTopic.Unsupported:
      throw new Error('Work file "unsupported" topic, cannot reschedule an appointment')
  }
}

function appointmentWorkRequestService(workFile: WorkFile): RestrictedWorkRequestService {
  const service: WorkRequestService = workFile.service
  switch (service) {
    case WorkRequestService.MeterTakeDown:
      return RestrictedWorkRequestService.MeterTakeDown
    case WorkRequestService.MeterActivationWithDayNightTariff:
      return RestrictedWorkRequestService.MeterActivationWithDayNightTariff
    case WorkRequestService.MeterReplacement:
      return RestrictedWorkRequestService.MeterReplacement
    case WorkRequestService.MeterReplacementBySmartMeter:
      return RestrictedWorkRequestService.MeterReplacementBySmartMeter
    case WorkRequestService.MeterCommissioning:
      return RestrictedWorkRequestService.MeterCommissioning
    case WorkRequestService.MeterActivationWithTotalHoursTariffOnDualRegisters:
      return RestrictedWorkRequestService.MeterActivationWithTotalHoursTariffOnDualRegisters
    case WorkRequestService.MeterStrengthening:
      return RestrictedWorkRequestService.MeterStrengthening
    case WorkRequestService.GasMeterWeakening:
      return RestrictedWorkRequestService.GasMeterWeakening
    case WorkRequestService.MeterRemoval:
      return RestrictedWorkRequestService.MeterRemoval
    case WorkRequestService.MeterRestoring:
      return RestrictedWorkRequestService.MeterRestoring
    case WorkRequestService.MeterDecommissioning:
      return RestrictedWorkRequestService.MeterDecommissioning
    case WorkRequestService.ElectricityProductionCommissioning:
      return RestrictedWorkRequestService.ElectricityProductionCommissioning
    case WorkRequestService.Unsupported:
      throw new Error('Work request "unsupported" service, cannot reschedule an appointment')
  }
}

export const appointmentService = {
  startHour,
  startMinute,
  endHour,
  endMinute,
  isOpen,
  appointmentSearchSuitableStartDate,
  workFileTrackingAppointmentSearchSuitableStartDate,
  timeslotLabel,
  appointmentPowerLevel,
  appointmentWorkRequestType,
  appointmentWorkRequestTopic,
  appointmentWorkRequestService
};

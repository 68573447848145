import { actions } from '@/store/new-connection-cmh/cmh-files/actions';
import { getters } from '@/store/new-connection-cmh/cmh-files/getters';
import { mutations } from '@/store/new-connection-cmh/cmh-files/mutations';
import { CmhFilesState, InitialState } from '@/store/new-connection-cmh/cmh-files/types';
import { RootState } from '@/store/types';
import { Module } from 'vuex';

const namespaced: boolean = true;

export const cmhFiles: Module<CmhFilesState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations
}


import { Vue, Component, Prop } from 'vue-property-decorator'
import { PersonalInfo } from '@/models'

@Component
export default class PersonalInfoForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly value!: PersonalInfo
  //#endregion

  //#region [Computed]
  public get bicVisible(): boolean {
    return (
      this.value.bankAccountIban !== null &&
      this.value.bankAccountIban.length > 1 &&
      !this.value.bankAccountIban.toLowerCase().startsWith('be')
    )
  }
  //#endregion
}

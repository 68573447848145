
import { Component, Vue } from 'vue-property-decorator'
import { Getter, State } from 'vuex-class'
@Component({
  name: 'no-ean-result'
})
export default class NoGridAccessPointInfo extends Vue {
  //#region [Data]
  @Getter('getEanCode', { namespace: 'eMobility/identification' })
  public getEanCode!: string

  @State('gridAccessPointNokReason', { namespace: 'eMobility/identification' })
  public stateGridAccessPointNokReason!: string
  //#endregion
}


import { Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { helper } from '@/services'
import MoreInformation from '@/components/display/MoreInformation.vue'

@Component({
  components: {
    MoreInformation
  },
  name: 'cmh-offerings-selector'
})
export default class CmhOfferingsSelector extends Vue {
  @Prop({ type: Boolean, required: true }) public isElectricityAvailable!: boolean
  @Prop({ type: Boolean, required: true }) public isGasAvailable!: boolean
  @Prop({ type: Boolean, required: true }) public isConnectMyHomeAvailableForSelectedBuilding!: boolean
  @Prop({ type: Boolean, required: true }) public isVooAvailable!: boolean
  @Prop({ type: Boolean, required: true }) public isProximusAvailable!: boolean
  @Prop({ type: Boolean, required: true }) public isSwdeAvailable!: boolean
  @Prop({ type: Boolean, required: true }) public isCmhAvailable!: boolean
  @Prop({ type: Array, required: false, default: [] }) public selections!: string[]

  public selectorSelections: string[] = []

  public isSwdeInfoModalOpen: boolean = false

  public mounted() {
    if (this.selections) {
      this.selectorSelections = helper.clone(this.selections)
    }
  }

  public pxsAndVooOptionClick() {
    if (!this.selectorSelections.includes('proximusAndVoo')) {
      this.selectorSelections.push('proximusAndVoo')
    }

    this.selectorSelections = this.selectorSelections.filter((s) => s !== 'voo' && s !== 'proximus')
  }

  public pxsOptionClick() {
    if (!this.selectorSelections.includes('proximus')) {
      this.selectorSelections.push('proximus')
    }
    this.selectorSelections = this.selectorSelections.filter((s) => s !== 'proximusAndVoo' && s !== 'voo')
  }

  public vooOptionClick() {
    if (!this.selectorSelections.includes('voo')) {
      this.selectorSelections.push('voo')
    }
    this.selectorSelections = this.selectorSelections.filter((s) => s !== 'proximusAndVoo' && s !== 'proximus')
  }

  public tvPhoneInternetClick() {
    if (this.isTvPhoneInternetSelected) {
      this.selectorSelections = this.selectorSelections.filter((s) => s !== 'proximusAndVoo' && s !== 'voo' && s !== 'proximus')
    } else {
      if (this.isVooAvailable && this.isProximusAvailable) {
        this.selectorSelections.push('proximusAndVoo')
      }
      if (this.isVooAvailable && !this.isProximusAvailable) {
        this.selectorSelections.push('voo')
      }
      if (!this.isVooAvailable && this.isProximusAvailable) {
        this.selectorSelections.push('proximus')
      }
      this.emitSelections()
    }
  }

  public get isGasOrElecSelected() {
    return this.selectorSelections.some((x: string) => x === 'gas' || x === 'electricity')
  }

  public get isTvPhoneInternetSelected() {
    return this.selectorSelections.some((x: string) => x === 'tvPhoneInternet')
  }

  public get isOneOtherOfferingSelected() {
    return this.selectorSelections.some((x: string) => x === 'proximus' || x === 'voo' || x === 'swde')
  }

  public openSwdeInfoModal() {
    this.isSwdeInfoModalOpen = true
  }

  @Watch('selectorSelections')
  public onSelectionsChange() {
    if (!this.isGasOrElecSelected && this.isOneOtherOfferingSelected) {
      this.selectorSelections = []
    }
    this.emitSelections()
  }

  @Emit('onSelectionsChange')
  public emitSelections() {
    return this.selectorSelections
  }
}

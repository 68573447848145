import { ElectricityInstallation } from '@/models';

export interface ElectricityInstallationState {
  electricityInstallation: ElectricityInstallation | null
}

export const InitialState: ElectricityInstallationState = {
  electricityInstallation: {
    meterType: null,
    electricityPowerSupplyType: null,
    power: null
  }
}

import { CompanyVatInfoSelection, FreelanceVatInfoSelection, IndividualVatInfoSelection } from '@/models'

export interface CmhVatInfoState {
  individualVatInfoSelection: IndividualVatInfoSelection | null
  freelanceVatInfoSelection: FreelanceVatInfoSelection | null
  companyVatInfoSelection: CompanyVatInfoSelection | null
}

export const InitialState: CmhVatInfoState = {
  individualVatInfoSelection: null,
  freelanceVatInfoSelection: null,
  companyVatInfoSelection: null
}

import { GetterTree } from 'vuex'
import { AddressState } from './types';
import { RootState } from '@/store/types';

export interface Step1GetterTree {
  isElectricityAvailable: boolean
  isGasAvailable: boolean
  isElecticityManaged: boolean
  isGasManaged: boolean
  isProximusAvailable: boolean
  isVooAvailable: boolean
  isSwdeAvailable: boolean
}

export const getters: GetterTree<AddressState, RootState> = {
  isElectricityAvailable(state, g, rs, rg): boolean {
    return !!state.address && !!state.address.subCity.operationalOffices.electricityOffice
  },
  isGasAvailable(state, g, rs, rg): boolean {
    return !!state.address && !!state.address.subCity.operationalOffices.gasOffice
  },
  isElectricityManaged(state: AddressState, gets: Step1GetterTree, rs: RootState, rg: any): boolean {
    if (!state.address || !gets.isElectricityAvailable) {
      return false
    }

    const mgr = state.address.subCity.gridManagers.electricityManager
    return !!mgr && mgr.isOres
  },
  isGasManaged(state: AddressState, gets: Step1GetterTree, rs: RootState, rg: any): boolean {
    if (!state.address || !gets.isGasAvailable) {
      return false
    }

    const mgr = state.address.subCity.gridManagers.gasManager
    return !!mgr && mgr.isOres
  },
  isProximusAvailable(state: AddressState, gets, rs: RootState, rg): boolean {
    if (state.address && state.address.subCity) {
      const info = state.address.subCity
      return info.connectMyHomeActivated && info.connectMyHomeInformation!.proximusAvailable
    } else {
      return false
    }
  },
  isVooAvailable(state: AddressState, gets, rs: RootState, rg): boolean {
    if (state.address && state.address.subCity) {
      const info = state.address.subCity
      return info.connectMyHomeActivated && info.connectMyHomeInformation!.vooAvailable
    } else {
      return false
    }
  },
  isSwdeAvailable(state: AddressState, gets, rs: RootState, rg): boolean {
    if (state.address && state.address.subCity) {
      const info = state.address.subCity
      return info.connectMyHomeActivated && info.connectMyHomeInformation!.swdeAvailable
    } else {
      return false
    }
  }
}

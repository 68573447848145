
import {
  BatteryRechargingCapacity,
  BatteryRechargingCapacityRequest,
  CarSpecification,
  ConsumingDevice,
  ElectricityInstallation,
  ElectricMobilityGridAccessPointInfo
} from '@/models'
import Component from 'vue-class-component'
import { Mutation, State } from 'vuex-class'
import Step from '../Step'
import { firstError } from '@/services'
import { AxiosResponse } from 'axios'
import { Watch } from 'vue-property-decorator'

@Component({
  name: 'consuming-device-step'
})
export default class ConsumingDeviceStep extends Step {
  //#region [Data]
  @State('carSpecification', { namespace: 'eMobility/carSpecification' })
  public stateCarSpecification!: CarSpecification
  @State('electricityInstallation', { namespace: 'eMobility/electricityInstallation' })
  public stateElectricityInstallation!: ElectricityInstallation
  @State('hasEan', { namespace: 'eMobility/identification' })
  public stateHasEan!: string
  @State('gridAccessPointInfo', { namespace: 'eMobility/identification' })
  public stateGridAccessPointInfo!: ElectricMobilityGridAccessPointInfo | null

  @State('consumingDevices', { namespace: 'eMobility/consumingDevices' })
  public stateConsumingDevices!: ConsumingDevice[]
  @State('consumingDeviceSelection', { namespace: 'eMobility/consumingDevices' })
  public stateConsumingDeviceSelection!: ConsumingDevice[] | null

  @State('requestId', { namespace: 'eMobility' })
  public requestId!: string

  public deviceSelection: string[] = []

  public isConsumingModalOpen: boolean = false
  public isConsumingDeviceModalOpen: boolean = false
  public consumingDeviceTooltip: string | null = null
  public consumingDeviceTooltipText: string | null = null
  public consumingDeviceTooltipTitle: string | null = null
  //#endregion

  //region [Mutation]
  @Mutation('STORE_DEVICE_SELECTION', { namespace: 'eMobility/consumingDevices' })
  public storeConsumingDeviceSelection!: (index: { consumingDeviceSelection: ConsumingDevice[] | null }) => void
  @Mutation('STORE', { namespace: 'eMobility/batteryRechargingCapacity' })
  public storeBatteryRechargingCapacity!: (index: { batteryResult: BatteryRechargingCapacity[] | null }) => void
  //#endregion

  //#region [Method]
  public async mounted() {
    const data = this.stateConsumingDeviceSelection
    this.applyAllTileText()
    if (data) {
      this.deviceSelection = data.map((c) => c.device)
    } else {
      this.deviceSelection = ['none']
    }
  }

  public onConsumingDeviceModalClick(device: string) {
    this.consumingDeviceTooltipText = this.$t(`e-mobility.consuming-device.device.${device}Tooltip`).toString()
    this.consumingDeviceTooltipTitle = this.$t(`e-mobility.consuming-device.device.${device}`).toString()
    this.consumingDeviceTooltip = device
    this.isConsumingDeviceModalOpen = true

    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: `ConsumingDevice - ${device}`
    })
  }

  public consumingTileHasTooltip(device: string): boolean {
    return device !== 'SolarPanel'
  }

  public applyAllTileText() {
    const tiles = document.querySelectorAll('.lib__tile')
    let maxHeight = 0
    for (const tile of tiles) {
      const element = tile.querySelector('.lib__tile__text')
      if (element) {
        const height = Number(getComputedStyle(element).height.split('px')[0])
        if (height > maxHeight) {
          maxHeight = height
        }
      }
    }

    for (const tile of tiles) {
      const element = tile.querySelector<HTMLElement>('.lib__tile__text')
      if (element) {
        element.style.height = maxHeight + 'px'
      }
    }
  }

  public onConsumingModalClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'ConsumingDevice'
    })
    this.isConsumingModalOpen = !this.isConsumingModalOpen
  }

  public getDeviceName(name: string) {
    return this.$t(`e-mobility.consuming-device.device.${name}`)
  }

  public checkErrors(errors: { [key: string]: string[] }) {
    return firstError(errors)
  }

  public filterData(isNoneToRemove: boolean) {
    if (isNoneToRemove) {
      this.deviceSelection = this.deviceSelection.filter((c) => c !== 'none')
    } else {
      this.deviceSelection = this.deviceSelection.filter((c) => c === 'none')
    }
  }

  public getBindedSelectedDevice() {
    const data: ConsumingDevice[] = []
    let hasNone = false
    for (const element of this.deviceSelection) {
      if (element !== 'none') {
        const bindedDevice = this.stateConsumingDevices.find((c) => c.device === element)
        if (bindedDevice) {
          data.push(bindedDevice)
        }
      } else {
        hasNone = true
      }
    }

    if (data.length) {
      this.storeConsumingDeviceSelection({ consumingDeviceSelection: data })
    } else if (hasNone) {
      this.storeConsumingDeviceSelection({ consumingDeviceSelection: null })
    } else {
      this.storeConsumingDeviceSelection({ consumingDeviceSelection: [] })
    }
  }

  public async getBatteryRechargingCapacity(
    data: BatteryRechargingCapacityRequest
  ): Promise<BatteryRechargingCapacity[] | null> {
    const response = await this.$api.post<BatteryRechargingCapacity, AxiosResponse<BatteryRechargingCapacity[]>>(
      `api/ElectricMobility/battery-recharging-capacity`,
      data
    )
    return response.data
  }

  public getConsumption(): { day: number; night: number } {
    let day = 0
    let night = 0
    if (this.stateConsumingDeviceSelection) {
      for (const device of this.stateConsumingDeviceSelection) {
        if (device.day) {
          day += device.kwh
        }
        if (device.night) {
          night += device.kwh
        }
      }
    }
    return { day, night }
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    this.getBindedSelectedDevice()
    if (isValid && next) {
      const data: BatteryRechargingCapacityRequest = {
        electricityPowerSupplyType:
          this.stateHasEan === 'yes'
            ? this.stateGridAccessPointInfo?.electricMobilityMeterInfos![0].electricityPowerSupplyType!
            : this.stateElectricityInstallation.electricityPowerSupplyType!,
        power:
          this.stateHasEan === 'yes'
            ? this.stateGridAccessPointInfo?.electricMobilityMeterInfos![0].amperage!
            : this.stateElectricityInstallation.power!,
        batteryCapacity: this.stateCarSpecification.batteryCapacity!,
        carType: this.stateCarSpecification.carType!,
        dayConsumption: this.getConsumption().day,
        nightConsumption: this.getConsumption().night
      }

      const batteryRechargingCapacity = await this.getBatteryRechargingCapacity(data)

      this.storeBatteryRechargingCapacity({ batteryResult: batteryRechargingCapacity })
      this.$appInsights.trackEvent('EMobility_StepConsumingDevice_Validated', {
        Segment: 'EMobility',
        WebRef: this.requestId,
        ElectricityPowerSupplyType:
          this.stateHasEan === 'yes'
            ? this.stateGridAccessPointInfo?.electricMobilityMeterInfos![0].electricityPowerSupplyType!
            : this.stateElectricityInstallation.electricityPowerSupplyType!,
        Power:
          this.stateHasEan === 'yes'
            ? this.stateGridAccessPointInfo!.electricMobilityMeterInfos![0].amperage!.toString()
            : this.stateElectricityInstallation.power!.toString(),
        BatteryCapacity: this.stateCarSpecification.batteryCapacity!.toString(),
        CarType: this.stateCarSpecification.carType!,
        DayConsumption: this.getConsumption().day.toString(),
        NightConsumption: this.getConsumption().night.toString(),
        ConsumingDevices: this.deviceSelection.join(',')
      })
    }
  }
  //#endregion

  //#region [Watch]
  @Watch('$i18n.locale')
  public onLocaleChanged() {
    this.applyAllTileText()
  }
  //#endregion
}


import Step from '@/components/steps/Step'
import Component from 'vue-class-component'
import { Prop, Emit } from 'vue-property-decorator'
import { calendarService, dateService, timeService, appointmentService } from '@/services'
import { AppointmentSlot } from '@/models'

@Component({
  name: 'AppointmentSlots'
})
export default class AppointmentTimeSlots extends Step {
  //#region [Property]
  @Prop({ type: Array, required: false }) public currentSlots!: AppointmentSlot[] | null
  @Prop({ type: Object }) public value!: AppointmentSlot | null
  @Prop({ type: Boolean, default: false }) public required!: boolean | null
  @Prop({ type: Boolean, default: false }) public loadingSlots!: boolean
  //#endregion

  //#region [Data]
  public dateService = dateService
  public calendarService = calendarService
  public timeService = timeService
  public appointmentService = appointmentService

  //#endregion

  //#region [Computed]
  public get selection(): string | null {
    return this.value ? this.value.key : null
  }
  public set selection(value: string | null) {
    this.selectSlot(value)
  }

  private get hasSlots(): boolean {
    return !!this.currentSlots && this.currentSlots.length > 0
  }

  private get hasNoSlot(): boolean {
    return !!this.currentSlots && this.currentSlots.length === 0
  }

  private get slots(): AppointmentSlot[] {
    return this.currentSlots || []
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public selectSlot(key: string | null): AppointmentSlot | null {
    return key ? this.currentSlots!.find((s) => s.key === key)! : null
  }

  @Emit('delete')
  public deleteDate() {
    return true
  }
  //#endregion

  //#region [Method]
  public isSelected(slot: AppointmentSlot): boolean {
    return !!this.value && this.value.key === slot.key
  }
  //#endregion
}

import { MutationTree } from 'vuex';
import { ChargingStationInformationState } from './types';
import { ChargingStationInformation } from '@/models/charging-station-declaration'

export const mutations: MutationTree<ChargingStationInformationState> = {
  STORE(state, data: ChargingStationInformation[]) {
    state.stations = data
  },
  CLEAR(state) {
    state.stations = null
  }
}

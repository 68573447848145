import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { InitialState, ProsumerBonusState } from './types'
import { identification } from './identification'
import { personalInfo } from './personal-info'
import { RootState } from '@/store/types';

const namespaced: boolean = true

export const prosumerBonus: Module<ProsumerBonusState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations,
  modules: {
    identification,
    personalInfo
  }
}

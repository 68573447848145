
import { Component } from 'vue-property-decorator'
import StepActions from '@/components/shared/StepActions.vue'
import { Form } from './Form'
import { StepDefinition } from '@ores/vue-library'
import ApiErrors from '@/components/display/ApiErrors.vue'
import IdentificationStep from '@/components/steps/charging-station-declaration/IdentificationStep.vue'
import ContactInfoStep from '@/components/steps/charging-station-declaration/ContactInfoStep.vue'
import ChargingStationInformationStep
  from '@/components/steps/charging-station-declaration/ChargingStationInformationStep.vue'
import SummaryStep from '@/components/steps/charging-station-declaration/SummaryStep.vue'
import SendConfirmation from '@/components/inputs/ChargingStationDeclaration/SendConfirmation.vue'
import DialogBox from '@/components/shared/DialogBox.vue'
import { removeInterceptors } from '@/plugins/http-interceptors-global'
import store from '@/store'
import Step from '@/components/steps/Step'
import {
  addChargingStationDeclarationResponseInterceptors
} from '@/plugins/http-response-interceptors-charging-station-declaration'
import { ChargingStationDeclarationGridAccessPoint, ContactInfo } from '@/models'
import { State } from 'vuex-class'
import { contactService } from '@/services'

@Component({
  components: {
    ChargingStationInformationStep,
    IdentificationStep,
    ContactInfoStep,
    SummaryStep,
    StepActions,
    SendConfirmation,
    DialogBox,
    ApiErrors
  },
  name: 'charging-station-declaration'
})
export default class ChargingStationDeclaration extends Form {
  //#region [Data]
  public stepDefinitions: StepDefinition[] = [
    new StepDefinition('identification', 1),
    new StepDefinition('contact', 2),
    new StepDefinition('station', 3),
    new StepDefinition('summary', 4),
    new StepDefinition('confirm', 5)
  ]
  public isIdentified: boolean = false

  @State('gridAccessPoint', { namespace: 'chargingStationDeclaration/identification' })
  public gridAccessPointState!: ChargingStationDeclarationGridAccessPoint
  @State('contactInfo', { namespace: 'chargingStationDeclaration/contactInfo' })
  public contactInfoState!: ContactInfo | null
  // #endregion

  public get multipleSend(): boolean {
    return this.contactInfoState !== null && this.contactInfoState.otherContacts.length > 0
  }

  public get sendConfirmationEmail(): string {
    let email: string = ''
    if (this.contactInfoState) {
      email += contactService.getContactEmail(this.contactInfoState)
      for (const otherContact of this.contactInfoState.otherContacts) {
        email += ', ' + otherContact.email
      }
    }
    return email
  }

  //#region [Computed]
  private get nextLabel(): string | null {
    if (this.currentStep === 4) {
      return this.$t('common.confirm').toString()
    }

    return null
  }

  //#endregion

  //#region [Mutation]
  //#endregion

  //#region [Method]
  public beforeCreate(): void {
    document.title = this.$t('chargingStationDeclaration.title').toString()
  }

  public mounted() {
    this.currentStep = 1
    this.lastStepSeen = 1
    this.stepToGoAfterValidation = 4
    this.stepsToValidate = []

    this.onLangChanged(this.lang)

    if (this.interceptorId !== null) {
      removeInterceptors(this.$api, this.interceptorId)
    }
    const interceptorId = addChargingStationDeclarationResponseInterceptors(this.$api, store)
    this.setInterceptorId(interceptorId)

    // Check maintenance
    this.$api.get('api/maintenanceManagement/charging-station-declaration')
  }

  public getStepComponent(step: number): Step | null {
    return (this.$refs[`step${this.currentStep}`] as Step) || null
  }

  public resetForm() {
    this.lastStepSeen = 1
    this.$store.dispatch('chargingStationDeclaration/reset')
    this.$nextTick(() => (this.currentStep = 1))
  }

  //#endregion

  //#region [Watch]
  //#endregion
}


import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { meterService } from '@/services'
import ElectricityPriceCalculator from '@/components/display/ElectricityPriceCalculator.vue'
import PowerSelectBox from '@/components/inputs/Configuration/PowerSelectBox.vue'
import {
  MeterElectricityType,
  MeterElectricityTension,
  ConstructionElectricityMeter
} from '@/models'

@Component({
  name: 'construction-electricity-meter-form',
  components: {
    ElectricityPriceCalculator,
    PowerSelectBox,
  }
})
export default class ConstructionElectricityMeterForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true })
  public readonly value!: ConstructionElectricityMeter

  @Prop({ type: Boolean, default: false })
  public readonly hasEnergyProduction!: boolean

  @Prop({ type: Array, required: true })
  public readonly tensions!: MeterElectricityTension[]

  @Prop({ type: Array, required: true })
  public readonly tarifications!: MeterElectricityType[]
  //#endregion

  //#region [Data]
  public kvaCustom: number | null = null
  //#endregion

  //#region [Computed]
  public get powers(): number[] {
    return meterService.getIntensities(this.value.tension)
  }

  public get isCustom(): boolean {
    return this.value.tension === 'custom'
  }

  public get ampere(): number | null {
    return this.value.power ? this.value.power.ampere : null
  }

  public set ampere(value: number | null) {
    if (!value) {
      this.value.power = null
    } else if (this.value.tension !== 'custom') {
      this.value.power = {
        ampere: value,
        kva: meterService.getPower(this.value.tension, value)
      }
    }
  }

  public get kva(): number | null {
    return this.value.power ? this.value.power.kva : null
  }

  public set kva(value: number | null) {
    if (!value) {
      this.value.power = null
    } else if (this.value.tension === 'custom') {
      this.value.power = {
        ampere: 0,
        kva: value
      }
    }
  }

  public get tarificationsOptions(): Array<{ text: string; value: string }> {
    const postfix = this.hasEnergyProduction ? this.$t('meter.type.double')! : ''
    const getPostfix = (type: MeterElectricityType) => (type === 'simple' || type === 'dual' ? postfix : '')

    return this.tarifications.map((t) => {
      return { text: (this.$t('meter.type.' + t) as string) + getPostfix(t), value: t }
    })
  }
  //#endregion

  //#region [Watch]
  @Watch('value.tension')
  public onTensionChange(value: MeterElectricityTension) {
    if (value === 'custom') {
      this.value.type = null
      if (this.ampere) {
        this.value.power = null
      }
    } else {
      const ampere = this.ampere
      const intensities = meterService.getIntensities(value)

      if (!ampere || intensities.indexOf(ampere) === -1) {
        this.ampere = intensities[0]
      } else {
        this.ampere = ampere
      }
    }
  }
  //#endregion
}


import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Emit, Watch } from 'vue-property-decorator'
import { State, Mutation, Action } from 'vuex-class'

const namespace = 'dialogs'

@Component({ name: 'dialog-box' })
export default class DialogBox extends Vue {
  //#region [Property]
  @State('active', { namespace })
  public readonly active!: boolean
  @State('text', { namespace })
  public readonly text!: string
  @State('title', { namespace })
  public readonly title!: string
  @State('callback', { namespace })
  public readonly callback!: ((isConfirmed: boolean) => void) | null
  @Action('close', { namespace })
  public close!: () => void
  //#endregion

  //#region [Watch]
  @Watch('active')
  public onActiveChanged(val: boolean) {
    if (val) {
      document.body.classList.add('lib__app__dialogs--open')
    } else {
      document.body.classList.remove('lib__app__dialogs--open')
    }
  }
  //#endregion

  //#region [Method]
  public confirm(value: boolean) {
    if (this.callback == null) {
      // tslint:disable-next-line:quotemark
      throw new Error("Callback function can't be null for confirmation dialog.")
    }

    this.callback(value)
    this.close()
  }
  //#endregion
}


import Step from '@/components/steps/Step'
import Identification from '@/components/inputs/ProsumerDeclaration/Identification.vue'
import IdentificationConfirmation from '@/components/inputs/ProsumerDeclaration/IdentificationConfirmation.vue'
import Component from 'vue-class-component'
import { State } from 'vuex-class'
import { GridAccessPoint } from '@/models/prosumer-declaration'

@Component({
  name: 'identification-step',
  components: {
    Identification,
    IdentificationConfirmation
  }
})
export default class IdentificationStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'prosumerDeclaration' })
  public requestId!: string

  public eanCode: string | null = null
  public gridAccessPoint: GridAccessPoint | null = null
  public isIdentified: boolean | null = null
  //#endregion

  //#region [Computed]
  //#endregion

  //#region [Mutation]
  //#endregion

  //#region [Watch]
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean> {

    if (isValid) {
      this.$appInsights.trackEvent('ProsumerDeclaration_StepIdentification_Validated', {
        Segment: 'ProsumerDeclaration',
        WebRef: this.requestId,
        ZipCode: this.gridAccessPoint?.consumptionAddress.zipCode ?? ''
      })
    }
    return next && isValid
  }

  //#endregion
}

import { actions } from '@/store/new-connection-cmh/cmh-vat-info/actions';
import { getters } from '@/store/new-connection-cmh/cmh-vat-info/getters';
import { mutations } from '@/store/new-connection-cmh/cmh-vat-info/mutations';
import { CmhVatInfoState, InitialState } from '@/store/new-connection-cmh/cmh-vat-info/types';
import { RootState } from '@/store/types';
import { Module } from 'vuex';

const namespaced: boolean = true;

export const cmhVatInfo: Module<CmhVatInfoState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations
}

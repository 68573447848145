
import Step from '@/components/steps/Step'
import Component from 'vue-class-component'
import { SubCity, StreetSubCity, MapLocation, NewConnectionRequestAddress } from '@/models'
import { State, Mutation } from 'vuex-class'
import StreetSubCitySelector from '@/components/inputs/Address/StreetSubCitySelector.vue'
import AvailableServiceDisplay from '@/components/display/AvailableServiceDisplay.vue'
import { AvailableServiceDefinition } from '@/models/available-service-definition'
import { CmhAddressPoint } from '@/models/cmh-address-point'
import BuildingTypeSelector from '@/components/inputs/Address/BuildingTypeSelector.vue'
import { helper } from '@/services'
import ConnectionPointArea from '@/components/inputs/Address/ConnectionPointArea.vue'
import MapLocationPicker from '@/components/inputs/Address/MapLocationPicker.vue'
import { BuildingType } from '@/models/building-type'
import CmhProvidersExternalLinks from '@/components/display/CmhProvidersExternalLinks.vue'
import ZipCodeLocalitySelector from '@/components/inputs/Address/ZipCodeLocalitySelector.vue'
import { Watch } from 'vue-property-decorator'
import { pixelFacebookService } from '@ores/vue-library'

@Component({
  components: {
    CmhProvidersExternalLinks,
    StreetSubCitySelector,
    BuildingTypeSelector,
    AvailableServiceDisplay,
    ConnectionPointArea,
    MapLocationPicker,
    ZipCodeLocalitySelector
  },
  name: 'cmh-address-step'
})
export default class CmhAddressStep extends Step {
  //#region [Data]
  @State('subCity', { namespace: 'newConnectionCmh/landingPage' })
  public stateSubCity!: SubCity

  @State('packageFromSimulator', { namespace: 'newConnectionCmh/landingPage' })
  public statePackageFromSimulator!: string | null

  @State('addressPoint', { namespace: 'newConnectionCmh/cmhAddress' })
  public stateAddressPoint!: CmhAddressPoint

  @State('requestId', { namespace: 'newConnectionCmh' })
  public requestId!: string

  public subCity: SubCity | null = null
  public streetSubCity: StreetSubCity | null = null
  public mapLocation: MapLocation | null = null
  public houseNumber: string | null = null
  public selection: BuildingType = BuildingType.unknown
  public flatNumber: number | null = null
  public commonElectricMeterNumber: number | null = null

  public addMapLocation: boolean = false
  public isNonFinalHouseNumber: boolean = false
  public isStreetSubCityValid: boolean = false
  public isBuildingInfoModalOpen: boolean = false
  public isNonFinalNumberModalOpen: boolean = false

  public hasOres: boolean = false

  public availableServiceDefinitions: AvailableServiceDefinition[] = []
  //#endregion

  //region [Mutation]
  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhAddress' })
  public store!: (index: CmhAddressPoint) => void

  @Mutation('STORE', { namespace: 'newConnectionCmh/landingPage' })
  private subCityStore!: (index: SubCity | null) => void

  @Mutation('CLEAR', { namespace: 'newConnectionCmh/cmhEnergyConfiguration' })
  private clearEnergyConfiguration!: () => void
  //endregion

  //#region [Method]
  public mounted(): void {
    pixelFacebookService.trackViewContent({
      content_ids: ['CmhForm'],
      content_name: 'Address Step',
      content_type: 'product'
    })
    this.subCity = helper.clone(this.stateSubCity)
    this.setAvailableServiceDefinitions(this.subCity)
    if (this.stateAddressPoint?.addressPoint ?? false) {
      if (this.stateAddressPoint?.addressPoint?.streetSubCity ?? false) {
        this.streetSubCity = helper.clone(this.stateAddressPoint?.addressPoint?.streetSubCity) || null
      }
      if (this.stateAddressPoint?.addressPoint?.mapLocation ?? false) {
        this.mapLocation = helper.clone(this.stateAddressPoint?.addressPoint?.mapLocation) || null
      }
      if (this.stateAddressPoint?.addressPoint?.isMapLocation ?? false) {
        this.addMapLocation = helper.clone(this.stateAddressPoint?.addressPoint?.isMapLocation) || false
      }
      if (this.stateAddressPoint?.selection !== BuildingType.unknown) {
        this.selection = helper.clone(this.stateAddressPoint.selection)
      }
    }
    this.houseNumber = this.stateAddressPoint?.addressPoint?.houseNumber ?? null
    this.flatNumber = this.stateAddressPoint?.flatNumber ?? null
    this.commonElectricMeterNumber = this.stateAddressPoint?.commonElectricMeterNumber ?? null
    if (this.statePackageFromSimulator !== null) {
      this.onSelectionChange(BuildingType.house);
    }
  }

  public setAvailableServiceDefinitions(subCity: SubCity): void {
    this.availableServiceDefinitions = [
      {
        imageFileName: this.currentWindowWidth > 576 ? 'ores-logo-2x.png' : 'ores-logo.png',
        isAvailable: subCity?.gridManagers?.electricityManager?.isOres || false,
        imageStyle: {}
      },
      {
        imageFileName: this.currentWindowWidth > 576 ? 'swde-logo-2x.png' : 'swde-logo.png',
        isAvailable: subCity?.connectMyHomeInformation?.swdeAvailable || false,
        imageStyle: {}
      },
      {
        imageFileName: this.currentWindowWidth > 576 ? 'proximus-logo-2x.png' : 'proximus-logo.png',
        isAvailable: subCity?.connectMyHomeInformation?.proximusAvailable || false,
        imageStyle: { marginTop: '8px' }
      },
      {
        imageFileName: this.currentWindowWidth > 576 ? 'voo-logo-2x.png' : 'voo-logo.png',
        isAvailable: subCity?.connectMyHomeInformation?.vooAvailable || false,
        imageStyle: { marginTop: '15px' }
      }
    ]
  }

  public onStreetSubCityValidation(isValid: boolean): void {
    this.isStreetSubCityValid = isValid
    if (!isValid && this.houseNumber != null) {
      this.houseNumber = null
    }
  }

  public onSelectionChange(value: BuildingType): void {
    this.selection = value
    this.clearEnergyConfiguration()
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (isValid) {
      if (
        this.hasOres &&
        (this.isHouseSelected || (this.isBuildingSelected && this.flatNumber && this.commonElectricMeterNumber))
      ) {
        this.store({
          addressPoint: {
            subCity: this.subCityOrState,
            streetSubCity: this.streetSubCity,
            houseNumber: this.houseNumber,
            isMapLocation: this.addMapLocation,
            isNonFinalHouseNumber: this.isNonFinalHouseNumber,
            mapLocation: this.mapLocation
          },
          selection: this.selection,
          flatNumber: this.flatNumber,
          commonElectricMeterNumber: this.commonElectricMeterNumber
        })

        await this.saveStep()

        this.$appInsights.trackEvent('WR_NCCMH_Step1_Validated', {
          Segment: 'WR_NCCMH',
          WebRef: this.requestId,
          Address: this.fullAddressString,
          IsMapLocation: this.addMapLocation.toString(),
          IsNonFinalHouseNumber: this.isNonFinalHouseNumber.toString(),
          PostCode: this.subCityOrState.zipCode,
          IsCmhAvailable: this.isAvailable.toString(),
          IsCmhFullyAvailable: this.isFullyAvailable.toString()
        })
        return true
      } else {
        return false
      }
    }
  }

  public async saveStep() {
    const data: NewConnectionRequestAddress = {
      zipCode: this.subCityOrState ? this.subCityOrState.zipCode : null,
      subCityId: this.subCityOrState ? this.subCityOrState.georesSubcityId : null,
      streetSubCityId: this.streetSubCity ? this.streetSubCity.georesStreetSubcityId : null,
      houseNumber: this.houseNumber ?? null,
      isNonFinalHouseNumber: this.isNonFinalHouseNumber,
      isMapLocation: this.addMapLocation,
      latitude: this.mapLocation?.lat ?? null,
      longitude: this.mapLocation?.lng ?? null,
      buildingType: this.selection,
      flatNumber: this.flatNumber,
      commonElectricMeterNumber: this.commonElectricMeterNumber
    }

    await this.$api.post<NewConnectionRequestAddress>(`NewConnectionRequest/store/${this.requestId}/address`, data)
  }

  public openBuildingInfoModal(): void {
    this.isBuildingInfoModalOpen = true
  }

  public openNonFinalNumberModal(): void {
    this.isNonFinalNumberModalOpen = true
  }

  @Watch('subCity')
  public onSubCityChanged() {
    this.subCityStore(this.subCity)
    if (this.subCity) {
      this.setAvailableServiceDefinitions(this.subCity)
    }
  }
  //#endregion

  //#region [Computed]
  public get isBuildingSelected(): boolean {
    return this.selection.valueOf() === BuildingType.building.valueOf()
  }

  public get hasBuildingCmhUnavailable(): boolean {
    return (
      this.isBuildingSelected && !!this.flatNumber && (this.flatNumber > 4 || (this.commonElectricMeterNumber ?? 0) > 1)
    )
  }

  public get isHouseSelected(): boolean {
    return this.selection.valueOf() === BuildingType.house.valueOf()
  }

  public getCmhLogoStyle(): { filter: 'invert(100%) brightness(100%) contrast(0%)' | '' } {
    return this.hasBuildingCmhUnavailable ? { filter: 'invert(100%) brightness(100%) contrast(0%)' } : { filter: '' }
  }

  public get isAvailable(): boolean {
    if (this.subCity) {
      return this.subCity.connectMyHomeActivated && !!this.subCity.connectMyHomeInformation
    }

    return true
  }

  public get isFullyAvailable(): boolean {
    if (this.subCity?.connectMyHomeInformation !== null) {
      return (
        this.isAvailable &&
        !!this.subCity?.connectMyHomeInformation?.proximusAvailable &&
        !!this.subCity?.connectMyHomeInformation?.vooAvailable &&
        !!this.subCity?.connectMyHomeInformation?.swdeAvailable
      )
    }
    return false
  }

  public get currentWindowWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  }

  public get areLinksDisplayed() {
    return (
      (this.isHouseSelected && !this.isFullyAvailable) ||
      (this.hasBuildingCmhUnavailable && this.flatNumber && this.commonElectricMeterNumber) ||
      (!this.hasBuildingCmhUnavailable && !this.isFullyAvailable && this.flatNumber && this.commonElectricMeterNumber)
    )
  }

  public get areAvailableServiceDisplayed() {
    return (
      this.isHouseSelected || (this.flatNumber && this.commonElectricMeterNumber && !this.hasBuildingCmhUnavailable)
    )
  }

  public get isSelectionStepDisplayed() {
    return (
      (this.houseNumber && !!this.streetSubCity && this.isStreetSubCityValid) ||
      (this.addMapLocation && this.mapLocation && this.isStreetSubCityValid)
    )
  }

  public get addressString(): string | null {
    return this.subCity ? `${this.subCity.zipCode} ${this.subCity.cityName}, Belgique` : null
  }

  public get fullAddressString(): string {
    if (this.addMapLocation) {
      return `${this.subCityOrState.zipCode} ${this.subCityOrState.cityName}, http://maps.google.com/maps?q='${this.mapLocation!.lat
        };${this.mapLocation!.lng}`
    }
    return `${this.houseNumber} ${this.streetSubCity!.name}, ${this.subCityOrState.zipCode} ${this.subCityOrState.cityName
      }`
  }

  public get subCityOrState(): SubCity {
    return this.subCity ?? this.stateSubCity
  }
  //#endregion
}

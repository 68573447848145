
import { Vue, Component, Prop } from 'vue-property-decorator'
import { WorkFile } from '@/models'
import { dateService } from '@ores/vue-library'

@Component({
  name: 'work-file-address-and-contact'
})
export default class WorkFileAddressAndContact extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly workFile!: WorkFile
  //#endregion

  //#region [Computed]
  public get reference() {
    return this.workFile.reference.replace(/^0+/, '')
  }

  public get startDate() {
    return dateService.displayDateText(this.workFile.startDate!)
  }

  public get workAddress() {
    const address = this.workFile.workExecutionAddress
    return `${address.houseNumber}
            ${address.streetName}${address.complement ? ' ' + address.complement : ''} -
            ${address.zipCode} ${address.cityName}`
  }

  public get advisor() {
    if (this.workFile.customerAdvisor) {
      return `${this.workFile.customerAdvisor.name} - ${this.workFile.customerAdvisor.publicNumber ? this.workFile.customerAdvisor.publicNumber : '078/15.78.01'}`
    }
    return `078/15.78.01 - <a href="mailto:contact@ores.be">contact@ores.be</a>`
  }
  //#endregion
}

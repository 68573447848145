import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { SmartMeterConnectionState } from './types';

export const actions: ActionTree<SmartMeterConnectionState, RootState> = {
  reset({ commit }) {
    commit('appointment/CLEAR')
    commit('contactInfo/CLEAR')
    commit('energyProduction/CLEAR')
    commit('identification/CLEAR')
    commit('meterTypeOption/CLEAR')
    commit('stakeholder/CLEAR')
    commit('vatInfo/CLEAR')
    commit('RESET')
  }
}

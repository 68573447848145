import { Civility } from '@ores/vue-library'

export enum CompanyType {
  Asbl = 0,
  Sa = 1,
  Sarl = 2,
  Sc = 3,
  Sca = 4,
  Scris = 5,
  Scrl = 6,
  Scs = 7,
  Snc = 8,
  Sprl = 9,
  Sprlu = 10,
  Other = 11
}

export type ContactType = 'individual' | 'freelance' | 'company'

export interface ContactIndividual extends IbanInfo {
  civility: Civility | null
  firstname: string | null
  lastname: string | null
  email: string | null
  phone: string | null
  mobile: string | null
  birthDate: Date | null
}

export interface ContactFreelance extends IbanInfo {
  civility: Civility | null
  firstname: string | null
  lastname: string | null
  email: string | null
  phone: string | null
  mobile: string | null
  vatNumber: string | null
  customerRef: string | null
  birthDate: Date | null
}

export interface ContactCompany {
  civility: Civility | null
  firstname: string | null
  lastname: string | null
  job: string | null
  email: string | null
  phone: string | null
  companyType: CompanyType | null,
  companyName: string | null
  vatNumber: string | null
  customerRef: string | null
  birthDate: Date | null
  address: ContactAddress
}

export interface ContactPerson {
  civility?: Civility | null
  firstname: string | null
  lastname: string | null
  job: string | null
  phone: string | null
  mobile: string | null
  email: string | null
  address: ContactAddress
  sendOfferCopy: boolean
}

export interface ContactAddress {
  street: string | null
  streetNumber: string | null
  streetBox: string | null
  zipCode: string | null
  locality: string | null
  country: string | null
}

export interface ContactInfo {
  contactType: ContactType | null
  contactIndividual: ContactIndividual | null
  contactFreelance: ContactFreelance | null
  contactCompany: ContactCompany | null
  otherContacts: ContactPerson[]
  useWorksAddress: boolean
  otherAddress: ContactAddress | null
}

export interface IbanInfo {
  bankAccountIban: string | null
  bankAccountBic: string | null
  bankAccountHolderLastName: string | null
  bankAccountHolderFistName: string | null
}

import Vue from 'vue'
import axios, { AxiosInstance, AxiosError } from 'axios'
import { setupCache, setup } from 'axios-cache-adapter'
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import { addRequestInterceptors } from './http-interceptors-global';
import { addAddressRepositoryResponseInterceptors } from './http-response-interceptors-address-repository';

declare module 'vue/types/vue' {

  interface Vue {
    $addressRepository: AxiosInstance
  }

  interface VueConstructor {
    $addressRepository: AxiosInstance
  }
}

export function AddressRepositoryAxios(vueCtor: typeof Vue, store: Store<RootState>): void {
  // No cache by default
  const addressRepository = setup({
    baseURL: vueCtor.$settings.addressApiUrl,
    cache: {
      maxAge: 60 * 60 * 1000,
      ignoreCache: true,
      exclude: {
        query: false
      }
    }
  })

  addRequestInterceptors(addressRepository, store)
  addAddressRepositoryResponseInterceptors(addressRepository, store)

  vueCtor.prototype.$addressRepository = addressRepository
}

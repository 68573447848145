import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { ProsumerBonusState } from './types';

export const actions: ActionTree<ProsumerBonusState, RootState> = {
  reset({ commit }) {
    commit('personalInfo/CLEAR')
    commit('identification/CLEAR')
    commit('RESET')
  }
}

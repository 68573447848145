import Vue from 'vue'
import { AxiosInstance } from 'axios'
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import { addRequestInterceptors } from './http-interceptors-global';
import { setup } from 'axios-cache-adapter';

declare module 'vue/types/vue' {

  interface Vue {
    $api: AxiosInstance,
    $inMemoryEventsHub: Vue // Used as Message Bus
  }

  interface VueConstructor {
    $api: AxiosInstance
  }
}

export function ApiAxios(vueCtor: typeof Vue, store: Store<RootState>): void {
  const api = setup({
    baseURL: vueCtor.$settings.apiUrl,
    cache: {
      maxAge: 15 * 60 * 1000,
      ignoreCache: true,
      exclude: {
        query: false
      }
    }
  })

  addRequestInterceptors(api, store)

  vueCtor.prototype.$api = api
}


import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { helper } from '@/services'
import {
  AppointmentInfo,
  AppointmentSlot,
  GetAppointmentAvailabilitiesRequest,
  WorksAppointmentTimeSlot
} from '@/models'
import AppointmentSlots from '@/components/inputs/Appointment/AppointmentSlots.vue'
import AppointmentTimeslotSelection from '@/components/inputs/Appointment/AppointmentTimeslotSelection.vue'
import SearchAvailabilities from '@/components/inputs/Appointment/SearchAvailabilities.vue'
import moment from 'moment'
import { Mutation } from 'vuex-class'
import { dateService } from '@ores/vue-library'

@Component({
  name: 'Appointment',
  components: {
    AppointmentSlots,
    AppointmentTimeslotSelection,
    SearchAvailabilities
  }
})
export default class Appointment extends Vue {
  //#region [Props]
  // Parameters for availabilities initial search
  @Prop({ type: Object, required: true })
  public searchAvailabilitiesParameters!: GetAppointmentAvailabilitiesRequest
  // Limit for availabilities incremental search
  @Prop({ type: Date, required: true }) public searchEndDate!: Date
  //#endregion

  //#region [Data]
  public appointment: AppointmentInfo = {
    remarks: null,
    selectedSlot: null,
    noSatisfyingDate: false
  }

  public clickedDateSlot: AppointmentSlot | null = null
  public currentSlots: AppointmentSlot[] | null = null
  public currentSearchStartDate: Date | null = null
  public currentSearchEndDate: Date | null = null
  public seeMoreDates: boolean = true
  public seeMoreDatesLoading: boolean = false
  public displayTimelotSelection: boolean = false

  private hasSlotsSearchError: boolean = false
  // Current search parameters
  private currentSearchAvailabilitiesParameters: GetAppointmentAvailabilitiesRequest | null = null

  private slotsLoading: boolean = false
  //#endregion

  //#region [Computed]

  private get searchTimeslotsParameters(): GetAppointmentAvailabilitiesRequest {
    return {
      workFileReference: this.searchAvailabilitiesParameters.workFileReference!,
      topic: this.searchAvailabilitiesParameters.topic,
      service: this.searchAvailabilitiesParameters.service,
      workRequestType: this.searchAvailabilitiesParameters.workRequestType,
      powerLevel: this.searchAvailabilitiesParameters.powerLevel,
      periodStartDate: this.clickedDateSlot?.startDate!,
      periodEndDate: this.clickedDateSlot?.endDate!,
      timeSlot: WorksAppointmentTimeSlot.MorningAndAfternoon,
      seeOtherDatesClicked: false
    }
  }

  private get hasSlots(): boolean {
    return !!this.currentSlots && this.currentSlots.length > 0
  }

  private get otherSlot(): AppointmentSlot {
    return {
      startDate: null,
      endDate: null,
      timeslotLabel: '',
      wholeDayTimeslot: null,
      key: 'other-date',
      grade: null,
      otherDate: true
    }
  }

  private get isDateSlotSelected(): boolean {
    return !!this.appointment.selectedSlot && this.appointment.selectedSlot.key !== 'other-date'
  }

  private get title(): string {
    return this.hasSlotsSearchError ? '' : this.$t('appointment.title').toString()
  }

  //#endregion

  //#region [Mutations]
  @Mutation('CLEAR_API_ERRORS', { namespace: 'apiErrors' })
  private clearApiErrors!: () => void
  //#endregion

  //#region [Watch]
  @Watch('slots', { immediate: true, deep: true })
  public onSlotsChange(value: AppointmentSlot[] | null) {
    if (!value) {
      return
    }
    this.currentSlots = helper.clone(value)
  }

  @Watch('appointment', { deep: true })
  public onAppointmentChanged(value: AppointmentInfo) {
    if (value) {
      this.$emit('input', value)
    }
  }
  //#endregion

  //#region [Method]

  public async mounted() {
    // Set the current search dates
    this.currentSearchStartDate = this.searchAvailabilitiesParameters.periodStartDate
    this.currentSearchEndDate = moment(this.currentSearchStartDate).add(7, 'days').toDate()
    // Trigger the search for availabilities
    this.currentSearchAvailabilitiesParameters = this.getComputedSearchAvailabilitiesParameters(false)
  }

  public onSelectedSlotChanged(value: AppointmentSlot) {
    if (value.otherDate) {
      this.appointment.selectedSlot = this.otherSlot
      this.appointment.noSatisfyingDate = true
    } else {
      this.clickedDateSlot = value
      this.displayTimelotSelection = true
    }
  }

  public onTimeSlotSelect(value: AppointmentSlot) {
    this.displayTimelotSelection = false
    if (!value) {
      return
    }
    this.appointment.selectedSlot = {
      startDate: value.startDate,
      endDate: value.endDate,
      timeslotLabel: value.timeslotLabel,
      wholeDayTimeslot: false,
      grade: value.grade,
      key: dateService.displayDateText(value.startDate!),
      otherDate: false
    }
    this.appointment.noSatisfyingDate = value.otherDate
  }

  public onTimeSlotCancel() {
    this.displayTimelotSelection = false
    // No timeslot chosen
    // Remove selection and clear validation errors
    this.removeSelectedSlotAndClearValidationErrors()
  }

  public onDelete() {
    // Remove selection and clear validation errors
    this.removeSelectedSlotAndClearValidationErrors()
  }

  public onDateRemove(date: Date) {
    this.displayTimelotSelection = false
    // No availability anymore, remove this date
    const slotToRemove = this.currentSlots!.find((s) => s.startDate === date)
    this.currentSlots!.splice(this.currentSlots!.indexOf(slotToRemove!), 1)
    // Remove selection and clear errors
    this.removeSelectedSlotAndClearValidationErrors()
  }

  public removeSelectedSlotAndClearValidationErrors() {
    this.clearValidationErrors()
    this.appointment.selectedSlot = null
  }

  public clearValidationErrors() {
    const validator: any = this.$refs.validator
    validator.reset()
  }

  private seeOtherDates() {
    this.seeMoreDatesLoading = true
    this.clearValidationErrors()
    this.clearApiErrors()

    // Increment the search dates
    this.currentSearchStartDate = moment(this.currentSearchStartDate!).add(7, 'days').toDate()
    this.currentSearchEndDate = moment(this.currentSearchStartDate!).add(7, 'days').toDate()
    const endOfSearchPeriodReached = this.currentSearchEndDate! >= this.searchEndDate
    this.seeMoreDates = !endOfSearchPeriodReached

    // Trigger the search for availabilities
    this.currentSearchAvailabilitiesParameters = this.getComputedSearchAvailabilitiesParameters(true)
  }

  private onSlotsLoaded(value: AppointmentSlot[] | null) {
    this.seeMoreDatesLoading = false
    this.hasSlotsSearchError = !value
    if (value) {
      if (!this.currentSlots) {
        this.currentSlots = value
      } else {
        this.currentSlots!.push(...value!)
      }
    }
  }

  private onSlotsLoading(value: boolean) {
    this.slotsLoading = value
  }

  private getComputedSearchAvailabilitiesParameters(
    seeOtherDatesClicked: boolean
  ): GetAppointmentAvailabilitiesRequest {
    return {
      workFileReference: this.searchAvailabilitiesParameters.workFileReference,
      topic: this.searchAvailabilitiesParameters.topic,
      service: this.searchAvailabilitiesParameters.service,
      periodStartDate: this.currentSearchStartDate!,
      periodEndDate: this.currentSearchEndDate!,
      timeSlot: this.searchAvailabilitiesParameters.timeSlot,
      workRequestType: this.searchAvailabilitiesParameters.workRequestType,
      powerLevel: this.searchAvailabilitiesParameters.powerLevel,
      seeOtherDatesClicked
    }
  }

  //#endregion

  //#region [Emit]
  //#endregion
}

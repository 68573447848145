
import { Vue, Component, Prop } from 'vue-property-decorator'
import { WorkFile, WorkFileCancellationRequest, WorkFileIdentification } from '@/models'
import { appointmentService } from '@/services/appointment-service'
import { State } from 'vuex-class'

@Component
export default class CancelWorkModal extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly workFile!: WorkFile
  @Prop({ type: Boolean, required: true }) public readonly isModalOpen!: boolean
  //#endregion

  //#region [Data]
  @State('identification', { namespace: 'workFileTracking/identification' })
  public identificationState!: WorkFileIdentification | null

  public isSecondModalOpen: boolean = false
  public isConfirmationModalOpen: boolean = false
  //#endregion

  //#region [Computed]
  public get isFirstModalOpen(): boolean {
    return this.isModalOpen
  }

  public set isFirstModalOpen(val: boolean) {
    this.$emit('close', val)
  }
  //#endregion

  //#region [Method]
  public cancelWork() {
    this.isSecondModalOpen = true
    this.isFirstModalOpen = false
  }

  public keepAppointment() {
    this.isSecondModalOpen = false
    this.isFirstModalOpen = false
  }

  public async cancelWorkConfirmation() {
    this.$appInsights.trackEvent('WorkFileTracking_CancelWork_Cancelled', {
      Segment: 'WorkFileTracking',
      ZipCode: this.identificationState!.zipCode!.trim(),
      WorksFileId: this.identificationState!.worksFileId!.trim(),
      WorkRequestCode: this.workFile.workRequests[0].code
    })

    const token = sessionStorage.getItem('token')
    const data: WorkFileCancellationRequest = {
      workFileReference: this.workFile.reference,
      powerLevel: appointmentService.appointmentPowerLevel(this.workFile),
      workRequestType: appointmentService.appointmentWorkRequestType(this.workFile),
      service: appointmentService.appointmentWorkRequestService(this.workFile),
      topic: appointmentService.appointmentWorkRequestTopic(this.workFile),
      language: 'fr', // lang will be determined in API but cannot be null
      contactInfo: this.workFile.contactInfo ?? {
        name: null,
        mobilePhoneNumber: null,
        housePhoneNumber: null,
        email: null
      }
    }
    const response = await this.$api
      .post(`api/WorksRequest/work-file/${this.workFile.reference}/close`, data, {
        headers: {
          Authorization: token
        }
      })
      .then(() => {
        this.isSecondModalOpen = false
        this.isFirstModalOpen = false
        this.isConfirmationModalOpen = true
      })
      .catch(() => {
        this.isSecondModalOpen = false
        this.isFirstModalOpen = false
      })
  }

  public onConfirmationModalClose() {
    location.href = this.$t('common.oresLink').toString()
  }
  //#endregion
}


import { ElectricVehicle } from '@/models'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  name: 'electric-vehicle-selected-form'
})
export default class ElectricVehicleSelectedForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly value!: ElectricVehicle | null
  //#endregion

  //#region [Data]
  public isBatteryCapacityModalOpen: boolean = false

  @State('requestId', { namespace: 'eMobility' })
  public requestId!: string
  //#endregion

  //#region [Computed]
  public get brand(): string {
    return this.value?.brand ?? ''
  }

  public get model(): string {
    return this.value?.model ?? ''
  }

  public get batteryCapacity(): string {
    return this.value?.batteryTotalCapacity?.toString() ?? ''
  }
  //#endregion

  //#region [Method]
  public onBatteryCapacityTooltipClick(): void {
    this.$appInsights.trackEvent('EMobility_TooltipClick', {
      Segment: 'EMobility',
      WebRef: this.requestId,
      Tooltip: 'Capacite de batterie'
    })
    this.isBatteryCapacityModalOpen = !this.isBatteryCapacityModalOpen
  }
  //#endregion
}

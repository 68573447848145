
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'readonly-mobile-box'
})
export default class ReadonlyMobileBox extends Vue {
  @Prop({ type: String, required: true }) public title!: string
  @Prop({ type: String, required: true }) public description!: string
}

import { CheckEanSmartMeterResult, EanConnectionContractResult, SubCity } from '@/models';

export interface IdentificationState {
  subCity: SubCity | null,
  eanCode: string | null,
  checkEanResult: CheckEanSmartMeterResult | null
  connectionContract: EanConnectionContractResult | null
}

export const InitialState: IdentificationState = {
  subCity: null,
  eanCode: null,
  checkEanResult: null,
  connectionContract: null
}

import { YesNoMaybeSelection } from '@ores/vue-library'

export enum YesNoMaybe {
  no,
  yes,
  mayBe
}

export function YesNoMaybeConverter(yesNoMaybe: YesNoMaybe): YesNoMaybeSelection | null {
  if (yesNoMaybe.toString() === YesNoMaybe[YesNoMaybe.mayBe]) {
    return -1
  }
  if (yesNoMaybe.toString() === YesNoMaybe[YesNoMaybe.no]) {
    return 0
  }
  if (yesNoMaybe.toString() === YesNoMaybe[YesNoMaybe.yes]) {
    return 1
  }
  return null
}

import CarModelSelector from '@/components/inputs/EMobility/Car/CarModelSelector.vue'
import CarSpecificationSelector from '@/components/inputs/EMobility/Car/CarSpecificationSelector.vue'
import ElectricVehicleQuestionForm from '@/components/inputs/EMobility/Car/ElectricVehicleQuestionForm.vue'
import Step from '@/components/steps/Step'
import { CarSpecification, ElectricVehicle, CarType } from '@/models'
import { Component } from 'vue-property-decorator'
import { Mutation, State } from 'vuex-class'

@Component({
  components: {
    CarModelSelector,
    CarSpecificationSelector,
    ElectricVehicleQuestionForm
  },
  name: 'car-specification-step'
})
export default class CarSpecificationStep extends Step {
  //#region [Data]
  @State('carSpecification', { namespace: 'eMobility/carSpecification' })
  public stateCarSpecification!: CarSpecification | null
  @State('electricVehicle', { namespace: 'eMobility/carSpecification' })
  public stateElectricVehicle!: ElectricVehicle | null

  @State('requestId', { namespace: 'eMobility' })
  public requestId!: string

  public carSpecification: CarSpecification | null = null
  public electricVehicle: ElectricVehicle | null = null
  public isModelKnown: boolean | null = null
  //#endregion

  //region [Mutation]
  @Mutation('STORE', { namespace: 'eMobility/carSpecification' })
  public store!: (value: CarSpecification) => void
  @Mutation('STORE_VEHICULE', { namespace: 'eMobility/carSpecification' })
  public storeVehicule!: (value: ElectricVehicle) => void
  @Mutation('CLEAR', { namespace: 'eMobility/carSpecification' })
  public clear!: () => void
  //#endregion

  //#region [Computed]
  public get showCarModelSelector(): boolean {
    return this.isModelKnown === true
  }

  public get showCarSpecificationsSelector(): boolean {
    return this.isModelKnown === false
  }
  //#endregion

  //region [Method]
  public mounted(): void {
    this.$appInsights.trackEvent('EMobility_EMobility_StartForm', {
      Segment: 'EMobility',
      WebRef: this.requestId
    })

    this.setIsBack(false)
    if (!!this.stateElectricVehicle) {
      this.isModelKnown = true
      this.electricVehicle = this.stateElectricVehicle
    }
    if (!!this.stateCarSpecification) {
      if (this.isModelKnown === null) {
        this.isModelKnown = false
      }
      this.carSpecification = this.stateCarSpecification
    }
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    if (isValid) {
      let carSpec = this.carSpecification
      if (!!this.electricVehicle) {
        carSpec = {
          batteryCapacity: this.electricVehicle.batteryTotalCapacity,
          carType: this.electricVehicle.isHybrid ? CarType.hybrid : CarType.electric
        }
        this.storeVehicule(this.electricVehicle)
      }

      if (!!carSpec) {
        this.store(carSpec)
      } else {
        throw Error('Car specification is null')
      }

      if (next) {
        this.$appInsights.trackEvent('EMobility_StepCarSpecification_Validated', {
          Segment: 'EMobility',
          WebRef: this.requestId,
          IsModelKnown: this.isModelKnown?.toString() ?? '',
          CarType: carSpec.carType!.toString(),
          BatteryCapacity: carSpec.batteryCapacity!.toString(),
          CarBrand: this.electricVehicle?.brand.toString() ?? '',
          CarModel: this.electricVehicle?.model.toString() ?? '',
          CarVersion: this.electricVehicle?.version?.toString() ?? ''
        })
      }
    }
  }

  public onCarModelNotFound() {
    this.isModelKnown = false
    this.onModelKnownChanged(false)
  }

  public onModelKnownChanged(val: boolean | null) {
    this.isModelKnown = val
    this.carSpecification = null
    this.electricVehicle = null
    this.clear()
  }
  //#endregion
}

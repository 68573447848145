
import { Vue, Component, Prop, Emit, Inject, Watch } from 'vue-property-decorator'
import { idGenerator, helper } from '@/services'
import { RemoveRequestInfo } from '@/models'

@Component({
  name: 'synergrid-remove-request-form',
  components: {
  }
})
export default class SynergridRemoveRequestForm extends Vue {

  //#region [Property]
  @Prop({ type: Object, required: true })
  public readonly value!: RemoveRequestInfo
  //#endregion

  //#region [Data]
  public fieldId: string = idGenerator.getId()
  //#endregion
}


import { Vue, Component, Prop } from 'vue-property-decorator'
import { IndividualVatInfoSelection, CompanyVatInfoSelection, FreelanceVatInfoSelection } from '@/models'

@Component({
  name: 'vat-info-box'
})
export default class VatInfoBox extends Vue {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly type!: string
  @Prop({ type: Boolean, default: false }) public readonly required!: boolean
  @Prop({
    type: Number,
    required: false,
    default: null,
    validator: (v: IndividualVatInfoSelection | null) => {
      return v == null || +v in IndividualVatInfoSelection
    }
  })
  public readonly individual!: IndividualVatInfoSelection | null
  @Prop({
    type: Number,
    required: false,
    default: null,
    validator: (v: FreelanceVatInfoSelection | null) => v == null || +v in FreelanceVatInfoSelection
  })
  public readonly freelance!: FreelanceVatInfoSelection | null
  @Prop({
    type: Object,
    required: false,
    default: null
  })
  public readonly company!: CompanyVatInfoSelection | null
  //#endregion

  //#region [Computed]
  get showVatSixPercentMessage() {
    if (this.type === 'individual') {
      return this.individual === 1
    } else if (this.type === 'freelance') {
      return false
    } else if (this.type === 'company') {
      if (this.company && this.company.primaryInfo && this.company.secondaryInfo === null) {
        return this.company.primaryInfo === 5 || this.company.primaryInfo === 7
      } else if (this.company && this.company.secondaryInfo) {
        return this.company.secondaryInfo === 5
      }
    }
    return false
  }

  get showVatLess10YearsMessage() {
    if (this.type === 'individual') {
      return this.individual === 0
    }
    return false
  }

  get showBasicMessage() {
    if (this.type === 'freelance') {
      return this.freelance !== null
    } else if (this.type === 'company') {
      if (this.company != null && this.company.primaryInfo != null && this.company.secondaryInfo === null) {
        return this.company.primaryInfo === 6
      } else if (this.company != null && this.company.secondaryInfo != null) {
        return (
          this.company.secondaryInfo === 0 ||
          this.company.secondaryInfo === 1 ||
          this.company.secondaryInfo === 2 ||
          this.company.secondaryInfo === 3 ||
          this.company.secondaryInfo === 4
        )
      }
    }
    return false
  }

  get showNeedExemptionFormMessage() {
    if (this.type === 'individual') {
      return false
    } else if (this.type === 'freelance') {
      return false
    } else if (this.type === 'company') {
      if (this.company && this.company.primaryInfo && this.company.secondaryInfo === null) {
        return this.company.primaryInfo === 3
      }
    }
    return false
  }

  //#endregion
}

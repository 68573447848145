
import Component from 'vue-class-component'
import { Emit, Prop, Vue } from 'vue-property-decorator'
import { ChargingStationInformation, ChargingStationSpecification } from '@/models/charging-station-declaration'

@Component({
  name: 'charging-station-information'
})
export default class ChargingStationInformationComponent extends Vue {
  @Prop({ type: Object, required: true }) public readonly value!: ChargingStationInformation
  @Prop({ type: Number, required: true }) public readonly index!: number
  @Prop({ type: Boolean, required: true }) public readonly canDelete!: number

  public brands: ChargingStationSpecification[] = []
  public powers: Array<{ text: string; value: number }> = []
  public plugs: Array<{ text: string; value: number }> = [
    { text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 }]

  public usageModalOpen: boolean = false
  public bidirectionalModalOpen: boolean = false
  public smartModalOpen: boolean = false
  public powerModalOpen: boolean = false
  public plugModalOpen: boolean = false

  public get models(): string[] {
    return this.value.brand ? this.value.brand.models : []
  }

  public mounted() {
    this.fetchBrandsAndModels()
    this.fetchPowers()
  }

  public async fetchBrandsAndModels() {
    const response = await this.$api.get<ChargingStationSpecification[]>('ChargingStationDeclaration/specifications')
    this.brands = response.data
  }

  public async fetchPowers() {
    const response = await this.$api.get<number[]>('ChargingStationDeclaration/powers')
    this.powers = response.data.map((d) => {
      return {
        text: `${d.toString()} kW`,
        value: d
      }
    })
    this.powers.push({
      text: this.$tc('common.other'),
      value: -1
    })
  }

  public createDefaultBrand(text: string | null): ChargingStationSpecification | null {
    if (!text) {
      return null
    }

    return {
      brand: text,
      models: []
    }
  }

  //#region [Emit]
  @Emit('remove')
  public remove(): void {
    return undefined
  }

  //#endregion
}

export interface StakeholderState {
  workRequesterId: string | null;
  fileNumber: string | null;
  hash: string | null;
}

export const InitialState: StakeholderState = {
  workRequesterId: null,
  fileNumber: null,
  hash: null
}

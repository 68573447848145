import {
  ChargingStationDeclarationGridAccessPoint
} from '@/models'

export interface IdentificationState {
  eanCode: string | null,
  meterLastFourNumbers: string | null,
  gridAccessPoint: ChargingStationDeclarationGridAccessPoint | null
}

export const InitialState: IdentificationState = {
  eanCode: null,
  meterLastFourNumbers: null,
  gridAccessPoint: null
}

import { Identification } from '@/models';
import { MutationTree } from 'vuex';
import { IdentificationState } from './types';

export const mutations: MutationTree<IdentificationState> = {
  STORE(state, data: Identification) {
    state.identification = data
  },
  CLEAR(state) {
    state.identification = null
  }
}

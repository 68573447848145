
import { Component, Vue } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import WorksInputFile from '@/components/inputs/Files/WorksInputFile.vue'
import { State, Mutation } from 'vuex-class'
import { FileInfo } from '@ores/vue-library'
import MoreInformation from '@/components/display/MoreInformation.vue'
import { CmhFilesState } from '@/store/new-connection-cmh/cmh-files/types'
import { helper } from '@/services'
import { pixelFacebookService } from '@ores/vue-library'
import { extend } from 'vee-validate'

@Component({
  components: {
    MoreInformation,
    WorksInputFile
  },
  name: 'cmh-files-step'
})
export default class CmhFilesStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'newConnectionCmh' })
  public requestId!: string

  @State('fileInfo', { namespace: 'newConnectionCmh/cmhFiles' })
  public stateFileInfo!: FileInfo[]

  @State('showInputFile', { namespace: 'newConnectionCmh/cmhFiles' })
  public stateShowInputFile!: boolean | null

  public fileInfo: FileInfo[] = []

  public showInputFile: boolean | null = null;
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'newConnectionCmh/cmhFiles' })
  public store!: (index: CmhFilesState) => void
  //#endregion

  //#region [Computed]
  public get fileInError(): boolean {
    return (
      this.fileInfo.some((v) => v.error !== null)
    )
  }
  //#endregion

  //#region [Method]
  public mounted(): void {
    extend('no_errored_file', {
      // If no errors in files or file input is not showed
      validate: (value) => { return !value || !this.showInputFile! }
    })
    pixelFacebookService.trackViewContent({
      content_ids: ['CmhForm'],
      content_name: 'Files Step',
      content_type: 'product'
    })
    if (this.stateFileInfo) {
      this.fileInfo = helper.clone(this.stateFileInfo)
    }
    this.showInputFile = helper.clone(this.stateShowInputFile)
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean | void> {
    this.store({
      fileInfo: this.fileInfo.filter((a) => a.error === null),
      showInputFile: this.showInputFile
    })
    this.$appInsights.trackEvent('WR_NCCMH_Step7_Validated', { Segment: 'WR_NCCMH', WebRef: this.requestId })
  }
  //#endregion
}

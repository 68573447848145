
import { Component } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import { State, Mutation } from 'vuex-class'
import { FileInfo } from '@ores/vue-library'
import { AttachmentState } from '@/store/synergrid-connection/attachment/types'
import WorksInputFile from '@/components/inputs/Files/WorksInputFile.vue'

@Component({
  name: 'attachment-step',
  components: {
    WorksInputFile
  }
})
export default class AttachmentStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'synergridConnection' })
  public readonly requestId!: string

  @State('attachDocuments', { namespace: 'synergridConnection/attachment' })
  public attachDocumentsState!: boolean
  @State('locationFilesInfo', { namespace: 'synergridConnection/attachment' })
  public locationFilesInfoState!: FileInfo[]

  public attachDocuments: boolean = false
  public locationFilesInfo: FileInfo[] = []
  //#endregion

  //#region [Computed]
  public get fileInError(): boolean {
    return this.locationFilesInfo.some((v) => v.error !== null)
  }

  public get yesChecked(): boolean {
    return this.attachDocuments !== null && !!this.attachDocuments
  }

  public set yesChecked(newVal: boolean) {
    this.attachDocuments = newVal
  }

  public get noChecked(): boolean {
    return this.attachDocuments !== null && !this.attachDocuments
  }

  public set noChecked(newVal: boolean) {
    this.attachDocuments = !newVal
  }
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'synergridConnection/attachment' })
  private store!: (index: AttachmentState) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    this.attachDocuments = this.attachDocumentsState
    this.locationFilesInfo = this.locationFilesInfoState
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean> {
    if (isValid) {
      this.$appInsights.trackEvent('WR_SC_StepAttachment_Validated', {
        Segment: 'WR_SC',
        WebRef: this.requestId
      })
    }

    this.store({
      locationFilesInfo: this.locationFilesInfo.filter((a) => a.error === null),
      attachDocuments: this.attachDocuments
    })
    return Promise.resolve(true)
  }
  //#endregion
}


import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { helper, meterService, dateService } from '@/services'
import {
  TemporaryConfigurationInfo,
  TemporaryElectricityPriceRequest,
  TemporaryElectricityPriceResponse
} from '@/models'

@Component({
  name: 'temporary-price-calculator',
})
export default class TemporaryPriceCalculator extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public configurationInfo!: TemporaryConfigurationInfo
  //#endregion

  //#region [Data]
  public prices: TemporaryElectricityPriceResponse | null = null
  //#endregion

  //#region [Computed]
  public get selectedPeriod(): number | null {
    if (this.configurationInfo.startDate !== null && this.configurationInfo.endDate !== null) {
      return dateService.diffDate(this.configurationInfo.startDate, this.configurationInfo.endDate) + 1
    }

    return null
  }

  public get priceRequest(): TemporaryElectricityPriceRequest | null {
    const value = this.configurationInfo

    if (value.startDate === null || value.endDate === null || value.startDate > value.endDate) {
      return null
    }

    const meters = value.electricityMeters
    if (meters.length === 0 || meters.every((meter) => !meterService.validateTemporaryElectricityMeter(meter))) {
      return null
    }

    return {
      startDate: value.startDate,
      endDate: value.endDate,
      meters: meters.map((meter) => {
        return {
          powerOutletCount: 1,
          tension: meter.tension,
          ampere: meter.power!.ampere
        }
      })
    }
  }
  //#endregion

  //#region [Watch]
  @Watch('priceRequest', { deep: true })
  public onPriceRequestChanged(value: TemporaryElectricityPriceRequest | null) {
    this.prices = null
    if (value == null) {
      return
    }

    this.$api
      .post<TemporaryElectricityPriceResponse>(
        'prices/work-requests/new-connection/other-construction/temporary-connection',
        value
      )
      .then((response) => (this.prices = response.data))
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.onPriceRequestChanged(this.priceRequest)
  }
  //#endregion
}

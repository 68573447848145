
import { Vue, Component, Inject, Watch, Prop } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import ContactInfoForm from '@/components/inputs/Contact/ContactInfoForm.vue'
import { State, Mutation } from 'vuex-class'
import { ContactInfo, AddressPoint } from '@/models'
import { helper, contactService } from '@/services'

@Component({
  name: 'contact-info-step',
  components: {
    ContactInfoForm
  }
})
export default class ContactInfoStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'constructionConnection' })
  public requestId!: string
  @State('address', { namespace: 'constructionConnection/address' })
  public addressState!: AddressPoint | null

  @State('contactInfo', { namespace: 'constructionConnection/contactInfo' })
  public contactInfoState!: ContactInfo | null

  public contactInfo: ContactInfo = contactService.createContactInfo()
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'constructionConnection/contactInfo' })
  private store!: (index: ContactInfo | null) => void
  //#endregion

  //#region [Watch]
  public onStateChanged(state: ContactInfo | null) {
    if (state !== null) {
      this.contactInfo = helper.clone(state)
    }
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    this.onStateChanged(this.contactInfoState)
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid) {
      this.$appInsights.trackEvent('WR_CC_StepContact_Validated', {
        Segment: 'WR_CC',
        WebRef: this.requestId
      })
    }

    this.store(this.contactInfo)
  }
  //#endregion
}

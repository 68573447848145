export interface MtCaptchaStatus {
  element: Element;
  domID: string;
  verifiedToken: string | null;
  isVerified: boolean;
  isVisible: boolean;
  statusCode: number;
  statusDesc: string;
}

export type MtStateCallback = (state: MtCaptchaStatus) => void;
export type MtTokenCallback = (token: string | null) => void;

export interface MtCaptchaConfig {
  sitekey?: string; // Get tie site key from Sites page of MTCaptcha admin site
  widgetSize?: 'mini';
  miniFormHeight?: string;
  miniFormWidth?: string;
  theme?:
  | 'basic'
  | 'overcast'
  | 'neowhite'
  | 'goldbezel'
  | 'blackmoon'
  | 'darkruby'
  | 'touchoforange'
  | 'caribbean'
  | 'woodyallen'
  | 'chrome'
  | 'highcontrast';
  render?: 'explicit';
  renderQueue: string[];
  lang?: string;
  'jsloaded-callback'?: () => void;
  'rendered-callback'?: MtStateCallback;
  'verified-callback'?: MtStateCallback;
  'verifyexpired-callback'?: MtStateCallback;
  'error-callback'?: MtStateCallback;
  customStyle: any;
  waitForDom: number;
}

export interface MtCaptcha {
  enableTestMode(domId?: string): void;
  getConfiguration(domId?: string): MtCaptchaConfig;
  getStatus(domId?: string): MtCaptchaStatus;
  getVerifiedToken(domId?: string): string | null;
  renderUI(domId?: string): void;
  resetUI(domId?: string): void;
  showMandatory(domId?: string): void;
}

declare global {
  interface Window {
    mtcaptchaConfig?: MtCaptchaConfig;
    mtcaptcha?: MtCaptcha;
  }
}

export function refreshMtCaptcha(domId?: string | undefined) {
  if (window.mtcaptcha) {
    window.mtcaptcha.resetUI(domId);
  }
}


import { Component, Prop } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import ContactInfoForm from '@/components/inputs/Contact/ContactInfoForm.vue'
import { Mutation, State } from 'vuex-class'
import { ContactInfo } from '@/models'
import { contactService, helper } from '@/services'
import { GridAccessPoint } from '@/models/prosumer-declaration'

@Component({
  name: 'contact-info-step',
  components: {
    ContactInfoForm
  }
})
export default class ContactInfoStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'prosumerDeclaration' })
  public requestId!: string
  @State('gridAccessPoint', { namespace: 'prosumerDeclaration/identification' })
  public gridAccessPointState!: GridAccessPoint | null

  @State('contactInfo', { namespace: 'prosumerDeclaration/contactInfo' })
  public contactInfoState!: ContactInfo | null

  public contactInfo: ContactInfo = contactService.createContactInfo()
  //#endregion

  //#region [Property]
  @Prop({ type: Boolean, required: false, default: false }) public readonly showCivility!: boolean
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'prosumerDeclaration/contactInfo' })
  private store!: (index: ContactInfo | null) => void
  //#endregion

  //#region [Watch]
  public onStateChanged(state: ContactInfo | null) {
    if (state !== null) {
      this.contactInfo = helper.clone(state)
    }
  }

  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    this.onStateChanged(this.contactInfoState)
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid) {
      this.$appInsights.trackEvent('ProsumerDeclaration_StepContact_Validated', {
        Segment: 'ProsumerDeclaration',
        WebRef: this.requestId
      })
    }

    this.store(this.contactInfo)
  }

  //#endregion
}

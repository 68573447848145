import { idGenerator } from '@/services';
import { NewConnectionCmhState } from '@/store/new-connection-cmh/types';
import { MutationTree } from 'vuex';

export const mutations: MutationTree<NewConnectionCmhState> = {
  STORE(state, requestId: string) {
    state.requestId = requestId
  },
  RESET(state) {
    state.requestId = idGenerator.getNewWebReference()
  }
}


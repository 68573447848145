
import Vue from 'vue'
import Step from '@/components/steps/Step'
import AddressSelector from '@/components/inputs/Address/AddressSelector.vue'
import Component from 'vue-class-component'
import { AddressPoint } from '@/models'
import { Mutation, State } from 'vuex-class'
import { Inject } from 'vue-property-decorator'
import { helper } from '@/services'

@Component({
  name: 'address-step',
  components: { AddressSelector }
})
export default class AddressStep extends Step {
  //#region [Data]
  @State('address', { namespace: 'synergridConnection/address' })
  public addressState!: AddressPoint | null
  @State('requestId', { namespace: 'synergridConnection' })
  public requestId!: string

  public address: AddressPoint | null = null
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'synergridConnection/address' })
  private store!: (index: AddressPoint | null) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    this.address = helper.clone(this.addressState)
  }

  public async save(next: boolean, isValid: boolean) {
    if (isValid) {
      this.$appInsights.trackEvent('WR_SC_StepAddress_Validated', {
        Segment: 'WR_SC',
        WebRef: this.requestId
      })
    }
    this.store(this.address)
  }
  //#endregion
}


import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { contactService } from '@/services'
import { ContactInfo, ContactType, AddressPoint, ConsumptionAddress } from '@/models'
import ContactTypeSelector from '@/components/inputs/Contact/ContactTypeSelector.vue'
import ContactIndividualForm from '@/components/inputs/Contact/ContactIndividualForm.vue'
import ContactFreelanceForm from '@/components/inputs/Contact/ContactFreelanceForm.vue'
import ContactCompanyForm from '@/components/inputs/Contact/ContactCompanyForm.vue'
import ContactPeopleForm from '@/components/inputs/Contact/ContactPeopleForm.vue'
import AddressForm from '@/components/inputs/Contact/AddressForm.vue'

@Component({
  name: 'contact-info-form',
  components: {
    ContactTypeSelector,
    ContactIndividualForm,
    ContactFreelanceForm,
    ContactCompanyForm,
    ContactPeopleForm,
    AddressForm
  }
})
export default class ContactForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true })
  public readonly value!: ContactInfo
  @Prop({ type: Object, required: true })
  public address!: AddressPoint | ConsumptionAddress
  @Prop({ type: Boolean, required: false, default: false })
  public hideOtherContact!: boolean
  @Prop({ type: Boolean, required: false, default: true })
  public readonly showSendOfferCopy!: boolean
  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly tooltips!: { [key: string]: string | undefined }
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isSmartMeterForm!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isProsumerDeclarationForm!: boolean
  @Prop({ type: Boolean, required: false, default: true })
  public readonly showBirthdate!: boolean
  @Prop({ type: Boolean, required: false, default: false })
  public readonly ibanRequired!: boolean
  @Prop({ type: Boolean, required: false, default: true })
  public readonly billingInfo!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly withIbanHolderName!: boolean
  @Prop({ required: false, default: null }) public readonly maxOtherContactCount!: number | null
  @Prop({ type: Boolean, required: false, default: false }) public readonly showCivility!: boolean
  //#endregion

  //#region [Computed]
  public get isFilled(): boolean {
    if (!this.value.useWorksAddress && this.value.otherAddress == null) {
      return false
    }

    return this.showAddress
  }

  public get isConsumptionAddress(): boolean {
    return (this.address as ConsumptionAddress).cityName !== undefined
  }

  public get consumptionAddress(): ConsumptionAddress{
    return (this.address as ConsumptionAddress)
  }

  public get addressPoint(): AddressPoint{
    return (this.address as AddressPoint)
  }

  public get showAddress(): boolean {
    switch (this.value.contactType) {
      case null:
        return false
      case 'individual':
        return this.value.contactIndividual != null
      case 'freelance':
        return this.value.contactFreelance != null
      case 'company':
        const company = this.value.contactCompany
        return (
          company !== null &&
          !!company.address.street &&
          !!company.address.streetNumber &&
          !!company.address.zipCode &&
          !!company.address.locality &&
          !!company.address.country
        )
      default:
        return false
    }
  }

  public get useWorksAddress(): number {
    return this.value.useWorksAddress ? 1 : 0
  }

  public set useWorksAddress(value: number) {
    this.value.useWorksAddress = !!value
  }

  //#endregion

  //#region [Watch]
  @Watch('value.contactType')
  public onContactTypeChanged(value: ContactType) {
    switch (value) {
      case 'individual':
        if (this.value.contactIndividual == null) {
          this.value.contactIndividual = contactService.createContactIndividual()
        }
        break
      case 'freelance':
        if (this.value.contactFreelance == null) {
          this.value.contactFreelance = contactService.createContactFreelance()
        }
        break
      case 'company':
        if (this.value.contactCompany == null) {
          this.value.contactCompany = contactService.createContactCompany()
        }
        break
      default:
        break
    }
  }

  @Watch('value.useWorksAddress')
  public onUseWorksAddressChanged(value: boolean) {
    if (value && this.value.otherAddress !== null) {
      this.value.otherAddress = null
    } else if (!value && this.value.otherAddress === null) {
      this.value.otherAddress = contactService.createContactAddress()
    }
  }

  //#endregion

  //#region [Method]
  public setUseWorksAddress(value: boolean) {
    this.value.useWorksAddress = value
  }

  //#endregion
}

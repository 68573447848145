import { MutationTree } from 'vuex';
import { ApiErrorsState } from './types';
import { ApiErrorNotification } from '@/models/api-error-notification';

export const mutations: MutationTree<ApiErrorsState> = {
  SET_API_ERRORS(state: ApiErrorsState, errorObj: ApiErrorNotification) {
    state.apiErrors = errorObj;
    document.getElementsByClassName('lib__header')[0].scrollIntoView();
  },
  CLEAR_API_ERRORS(state: ApiErrorsState) {
    state.apiErrors = null;
  },
  SET_INTERCEPTOR_ID(state: ApiErrorsState, data: number) {
    state.interceptorId = data;
  },
}

export interface WorkFileIdentification {
  worksFileId: string | null;
  zipCode: string | null;
}

export interface GetWorkFileResponse {
  workFile: WorkFile;
  token: string;
}

export interface WorkFile {
  reference: string;
  type: WorkRequestType;
  powerLevel: PowerLevel;
  topic: WorkRequestTopic;
  service: WorkRequestService;
  startDate: Date | null;
  endDate: Date | null;
  desiredStartDate: Date | null;
  desiredEndDate: Date | null;
  workRequesterId: string;
  contactInfo: Contact;
  workExecutionAddress: WorkFileAddress;
  customerAdvisor: CustomerAdvisor;
  workRequests: WorkRequest[];
  clientType: ClientType;
  canModifyAppointment: boolean;
  canCancelWorkFile: boolean;
}

export enum WorkRequestTopic {
  BudgetMeter = 'budgetMeter',
  Connection = 'connection',
  ConnectionWithoutMeter = 'connectionWithoutMeter',
  ChargingStationConnection = 'chargingStationConnection',
  MeterWorks = 'meterWorks',
  DualFlowMeter = 'dualFlowMeter',
  DualRegisterMeter = 'dualRegisterMeter',
  SmartMeter = 'smartMeter',
  Unsupported = 'unsupported'
}

export enum WorkRequestService {
  MeterTakeDown = 'meterTakeDown',
  MeterActivationWithDayNightTariff = 'meterActivationWithDayNightTariff',
  MeterCommissioning = 'meterCommissioning',
  GasMeterWeakening = 'gasMeterWeakening',
  MeterActivationWithTotalHoursTariffOnDualRegisters = 'meterActivationWithTotalHoursTariffOnDualRegisters',
  MeterStrengthening = 'meterStrengthening',
  MeterRemoval = 'meterRemoval',
  MeterRestoring = 'meterRestoring',
  MeterDecommissioning = 'meterDecommissioning',
  ElectricityProductionCommissioning = 'electricityProductionCommissioning',
  MeterReplacement = 'meterReplacement',
  MeterReplacementBySmartMeter = 'meterReplacementBySmartMeter',
  Unsupported = 'unsupported'
}

export interface Contact {
  name: string | null;
  mobilePhoneNumber: string | null;
  housePhoneNumber: string | null;
  email: string | null;
}

export enum WorkFileCreationChannel {
  Phone = 'phone',
  Web = 'web'
}

export interface WorkRequesterContactInfo {
  name: string | null;
  mobilePhoneNumber: string | null;
  housePhoneNumber: string | null;
  email: string | null;
}
export interface VatCriteria {
  buildingType: BuildingType;
  clientType: ClientType;
}

export enum BuildingType {
  FinalDestruction = 'finalDestruction',
  PrivateHousingAdapterForDisabledPeople = 'privateHousingAdapterForDisabledPeople',
  PrivateHousingLessThan10Years = 'privateHousingLessThan10Years',
  PrivateHousingMoreThan10Years = 'privateHousingMoreThan10Years',
  PrivateHousingRentedUnderSocialPolicyFramework = 'privateHousingRentedUnderSocialPolicyFramework',
  PublicBuilding = 'publicBuilding',
  WithMonthlyOrQuarterlyDeclaration = 'withMonthlyOrQuarterlyDeclaration',
  WithoutMonthlyOrQuarterlyDeclaration = 'withoutMonthlyOrQuarterlyDeclaration',
  Other = 'other'
}

export enum ClientType {
  AmbassyArmyEsaOTAN = 'ambassyArmyEsaOTAN',
  OwnerTenantUsufructuary = 'ownerTenantUsufructuary',
  PublicBodyWithoutSocialPurpose = 'publicBodyWithoutSocialPurpose',
  PublicBodyWithSocialPurpose = 'publicBodyWithSocialPurpose',
  RegionalAndSocialHousingCompany = 'regionalAndSocialHousingCompany',
  ResthouseOrBoardingSchool = 'resthouseOrBoardingSchool',
  SubjectToVat = 'subjectToVat',
  Other = 'other'
}

export interface WorkFileAddress {
  cityName: string;
  streetName: string;
  zipCode: string;
  houseNumber: string;
  complement: string;
}

export interface CustomerAdvisor {
  id: string;
  name: string;
  internalNumber: string;
  publicNumber: string;
}

export interface WorkOrder {
  id: string;
  code: string;
  description: string;
  calculatedPrice: number | null;
  eanCodes: string[];
  status: WorkOrderStatus;
  plannedStartDate: Date;
  plannedEndDate: Date | null;
  workRequestCode: string;
  operations: Operation[];
}

export enum WorkOrderStatus {
  Closed = 'closed',
  Open = 'open',
  Started = 'started',
  TechnicallyClosed = 'technicallyClosed',
  Unknown = 'unknown'
}

export interface Operation {
  code: string;
  description: string;
  executionDate: Date | null;
  executionTimeSlot: TimeSlot | null;
  hasAssignedEmployee: boolean;
}

export enum TimeSlot {
  Afternoon = 'afternoon',
  Day = 'day',
  FirstHour = 'firstHour',
  Morning = 'morning',
  Unknown = 'unknown'
}

export interface WorkRequest {
  code: string;
  type: WorkRequestType;
  offerType: WorkOfferType;
  powerLevel: PowerLevel;
  phases: Phase[];
  description: string;
  calculatedPrice: number | null;
  calculatedPriceWithoutPrime: number | null;
  prime: number | null;
}

export interface Phase {
  interventionCode: string | null;
  shortInterventionCode: string | null;
  phaseNumber: number;
  endDatePtPhase: Date | null;
  status: PhaseSystemStatus;
  appointment: WorkAppointment | null;
}

export interface WorkAppointment {
  executionDate: Date | null;
  executionTimeSlot: TimeSlot | null;
  hasAssignedEmployee: boolean;
}

export enum WorkRequestType {
  Electricity = 'electricity',
  Gas = 'gas',
  Phone = 'phone',
  Television = 'television',
  Water = 'water',
  Unknown = 'unknown'
}

export enum PowerLevel {
  Low = 'low',
  High = 'high',
  Unknown = 'unknown'
}

export enum PhaseSystemStatus {
  Closed = 'closed',
  Ongoing = 'ongoing',
  ToBeStarted = 'toBeStarted',
  Unknown = 'unknown'
}

export enum WorkOfferType {
  VerbalOffer = 'verbalOffer',
  WrittenOffer = 'writtenOffer',
  WithoutOffer = 'withoutOffer'
}

export interface UserStatus {
  code: string;
  label: string;
}

export interface AppointmentModificationProgress {
  deletingAppointment: boolean;
  appointing: boolean;
  sendConfirmation: boolean;
}

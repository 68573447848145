import { CompanyVatInfoSelection, FreelanceVatInfoSelection, IndividualVatInfoSelection } from '@/models';

export interface VatInfoState {
  individualVatInfoSelection: IndividualVatInfoSelection | null
  freelanceVatInfoSelection: FreelanceVatInfoSelection | null
  companyVatInfoSelection: CompanyVatInfoSelection | null
}

export const InitialState: VatInfoState = {
  individualVatInfoSelection: null,
  freelanceVatInfoSelection: null,
  companyVatInfoSelection: null
}


import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { IndividualVatInfoSelection } from '@/models'
import FieldBox from '@/components/inputs/Shared/FieldBox'

@Component({
  name: 'individual-vat-info-selector'
})
export default class IndividualVatInfoSelector extends FieldBox {
  //#region [Property]
  @Prop({ type: Number, required: false }) public readonly value!: IndividualVatInfoSelection | null
  //#endregion

  //#region [Computed]
  public get selection(): IndividualVatInfoSelection | null {
    return this.value
  }

  public set selection(val: IndividualVatInfoSelection | null) {
    this.change(val)
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(value: IndividualVatInfoSelection | null): IndividualVatInfoSelection | null {
    return value
  }
  //#endregion
}

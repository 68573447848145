
import { Vue, Component, Prop, Emit, Inject, Watch } from 'vue-property-decorator'
import { idGenerator, helper, meterService } from '@/services'
import ElectricityPriceCalculator from '@/components/display/ElectricityPriceCalculator.vue'
import PowerSelectBox from '@/components/inputs/Configuration/PowerSelectBox.vue'
import {
  ElectricityMeter,
  Power,
  MeterElectricityType,
  MeterElectricityTension,
  MeterDestination,
  TemporaryElectricityMeter
} from '@/models'

@Component({
  name: 'temporary-electricity-meter-form',
  components: {
    ElectricityPriceCalculator,
    PowerSelectBox,
  }
})
export default class TemporaryElectricityMeterForm extends Vue {

  //#region [Property]
  @Prop({ type: Object, required: true })
  public readonly value!: TemporaryElectricityMeter

  @Prop({ type: Boolean, default: false })
  public readonly hasEnergyProduction!: boolean

  @Prop({ type: Array, required: true })
  public readonly tensions!: MeterElectricityTension[]
  //#endregion

  //#region [Data]
  public fieldId: string = idGenerator.getId()
  public kvaCustom: number | null = null
  //#endregion

  //#region [Computed]
  public get powers(): number[] {
    return meterService.getIntensities(this.value.tension, meterService.tempElectricityConfiguration)
  }

  public get ampere(): number | null {
    return this.value.power ? this.value.power.ampere : null
  }

  public set ampere(value: number | null) {
    if (!value) {
      this.value.power = null
    } else {
      this.value.power = {
        ampere: value,
        kva: meterService.getPower(this.value.tension, value)
      }
    }
  }

  public get kva(): number | null {
    if (this.value.power) {
      return this.value.power.kva
    }

    return null
  }
  //#endregion

  //#region [Watch]
  @Watch('value.tension')
  public onTensionChange(value: MeterElectricityTension) {
    const ampere = this.ampere
    const intensities = meterService.getIntensities(value, meterService.tempElectricityConfiguration)

    if (!ampere || intensities.indexOf(ampere) === -1) {
      this.ampere = intensities[0]
    } else {
      this.ampere = ampere
    }
  }
  //#endregion

  //#region [Emit]
  @Emit('remove')
  public remove(): void {
    return undefined
  }
  //#endregion
}

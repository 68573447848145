// Availabilities

import { Contact, ContactInfo } from '..';

export interface GetAppointmentAvailabilitiesRequest {
  workFileReference: string,
  topic: RestrictedWorkRequestTopic,
  service: RestrictedWorkRequestService,
  periodStartDate: Date,
  periodEndDate: Date
  timeSlot: TimeSlot,
  workRequestType: RestrictedWorkRequestType,
  powerLevel: RestrictedPowerLevel,
  seeOtherDatesClicked: boolean // used only by frontend
}

export interface GetAppointmentAvailabilitiesResponse {
  availabilities: Availability[],
  remainingWaitTimeSeconds: number,
  schedulingServiceReady: boolean
}

enum TimeSlot {
  Afternoon = 'afternoon',
  FirstHour = 'firstHour',
  Morning = 'morning',
  MorningAndAfternoon = 'morningAndAfternoon',
  TwoHoursSlot = 'twoHoursSlot',
  WholeDay = 'wholeDay'
}

interface Availability {
  startDate: Date,
  endDate: Date,
  grade: number
}

// Appointment

export interface CreateAppointmentRequest {
  workFileReference: string,
  topic: RestrictedWorkRequestTopic,
  service: RestrictedWorkRequestService,
  workFileStartDate: Date,
  workFileEndDate: Date,
  workRequestType: RestrictedWorkRequestType,
  powerLevel: RestrictedPowerLevel,
  customerComment: string | null,
  appointmentStartTimestamp: Date,
  appointmentEndTimestamp: Date,
  grade: number | null,
  yearlyReadingInformationReadingMonthNumber: number | null | undefined
}

export interface AppointmentCancellationRequest {
  workFileReference: string,
  topic: RestrictedWorkRequestTopic,
  service: RestrictedWorkRequestService,
  workRequestType: RestrictedWorkRequestType,
  powerLevel: RestrictedPowerLevel
}

export interface UpdateAppointmentRequest {
  workFileReference: string,
  topic: RestrictedWorkRequestTopic,
  service: RestrictedWorkRequestService,
  workFileStartDate: Date,
  workFileEndDate: Date,
  workRequestType: RestrictedWorkRequestType,
  powerLevel: RestrictedPowerLevel,
  customerComment: string | null,
  appointmentStartTimestamp: Date,
  appointmentEndTimestamp: Date,
  grade: number | null,
  yearlyReadingInformationReadingMonthNumber: number | null | undefined,
  contactInfo: Contact
  language: string,
  reference: string,
  workRequestCode: string
}

// Callback Request
export interface CallbackRequestCreationRequest {
  workFileReference: string,
  service: RestrictedWorkRequestService,
  topic: RestrictedWorkRequestTopic,
  subject: string,
  comment: string,
  workRequestType: RestrictedWorkRequestType,
  powerLevel: RestrictedPowerLevel
}

// Cancel work file
export interface WorkFileCancellationRequest {
  workFileReference: string,
  topic: RestrictedWorkRequestTopic,
  service: RestrictedWorkRequestService,
  workRequestType: RestrictedWorkRequestType,
  powerLevel: RestrictedPowerLevel,
  contactInfo: Contact,
  language: string,
}

// Common

export enum RestrictedWorkRequestService {
  ElectricityProductionCommissioning = 'electricityProductionCommissioning',
  GasMeterWeakening = 'gasMeterWeakening',
  MeterActivationWithDayNightTariff = 'meterActivationWithDayNightTariff',
  MeterActivationWithTotalHoursTariffOnDualRegisters = 'meterActivationWithTotalHoursTariffOnDualRegisters',
  MeterCommissioning = 'meterCommissioning',
  MeterDecommissioning = 'meterDecommissioning',
  MeterRemoval = 'meterRemoval',
  MeterReplacement = 'meterReplacement',
  MeterReplacementBySmartMeter = 'meterReplacementBySmartMeter',
  MeterRestoring = 'meterRestoring',
  MeterStrengthening = 'meterStrengthening',
  MeterTakeDown = 'meterTakeDown'
}

export enum RestrictedWorkRequestTopic {
  BudgetMeter = 'budgetMeter',
  ChargingStationConnection = 'chargingStationConnection',
  Connection = 'connection',
  ConnectionWithoutMeter = 'connectionWithoutMeterConnectionWithoutMeter',
  DualFlowMeter = 'dualFlowMeter',
  DualRegisterMeter = 'dualRegisterMeter',
  MeterWorks = 'meterWorks',
  SmartMeter = 'smartMeter'
}

export enum RestrictedWorkRequestType {
  Electricity = 'electricity',
  Gas = 'gas',
  Phone = 'phone',
  Television = 'television',
  Water = 'water'
}

export enum RestrictedPowerLevel {
  High = 'high',
  Low = 'low'
}

export {
  TimeSlot as WorksAppointmentTimeSlot,
  Availability as WorksAppointmentAvailability,
}


import CollapseInfo from '@/components/display/CollapseInfo.vue'
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import { GridAccessPoint } from '@/models/prosumer-declaration'
import { chatService } from '@/services'

@Component({
  components: {
    CollapseInfo
  }
})
export default class IdentificationConfirmation extends Vue {
  //#region [Props]
  @Prop({ type: String, required: true }) public readonly eanCode!: string
  @Prop({ type: Object, required: true }) public readonly gridAccessPoint!: GridAccessPoint
  //#endregion

  //#region [Data]
  //#endregion

  //#region [Computed]
  private get addressFromConsumption() {
    const address = this.gridAccessPoint.consumptionAddress
    let str = address.streetName
    if (address.houseNumber) {
      str += ' ' + address.houseNumber
    }
    if (address.complement) {
      str += ' ' + address.complement
    }
    if (address.complementBox) {
      str += ' ' + address.complementBox
    }
    if (address.floorNumber) {
      str += ` (${this.$t(`identification.smartMeter.floor`).toString()}: ${address.floorNumber})`
    }
    str += ', ' + address.zipCode
    str += ' ' + address.cityName
    return str
  }

  //#endregion

  //#region [Method]
  private openChat() {
    chatService.openChat()
  }

  //#endregion
}

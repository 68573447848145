
import { Component, Prop } from 'vue-property-decorator'
import { ContactFreelance } from '@/models'
import ContactBaseForm from './ContactBaseForm'
import ContactIbanInfos from '@/components/inputs/Contact/ContactIbanInfos.vue'

@Component({
  components: { ContactIbanInfos }
})
export default class ContactFreelanceForm extends ContactBaseForm {
  //#region [Property]
  @Prop({ type: Object, required: true })
  public readonly value!: ContactFreelance
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isSmartMeterForm!: boolean
  @Prop({ type: Boolean, required: false, default: true })
  public readonly showBirthdate!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly ibanRequired!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly withIbanHolderName!: boolean
  //#endregion

  //#region [Data]
  //#endregion

  //#region [Method]
  //#endregion

  protected get emailAddress(): string | null {
    return this.value.email
  }
  protected set emailAddress(value: string | null) {
    this.value.email = value?.trim() ?? null
  }
}

import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ContactBaseForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly tooltips!: { [key: string]: string | undefined }
  //#endregion

  //#region [Computed]
  public get emailTooltip(): string | undefined {
    return this.tooltips.email || undefined
  }

  public get mobileTooltip(): string | undefined {
    return this.tooltips.mobile || undefined
  }

  public get phoneTooltip(): string | undefined {
    return this.tooltips.phone || undefined
  }
  //#endregion
}

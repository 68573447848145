
import { Vue, Component, Watch } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import { State, Mutation } from 'vuex-class'
import { FileInfo } from '@ores/vue-library'
import { AttachmentState } from '@/store/other-equipment-connection/attachment/types'
import WorksInputFile from '@/components/inputs/Files/WorksInputFile.vue'

@Component({
  name: 'attachment-step',
  components: {
    WorksInputFile
  }
})
export default class AttachmentStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'otherEquipmentConnection' })
  public requestId!: string
  @State('attachDocuments', { namespace: 'otherEquipmentConnection/attachment' })
  public attachDocumentsState!: boolean
  @State('meterLocationFilesInfo', { namespace: 'otherEquipmentConnection/attachment' })
  public meterLocationFilesInfoState!: FileInfo[]
  @State('buildingLocationFilesInfo', { namespace: 'otherEquipmentConnection/attachment' })
  public buildingLocationFilesInfoState!: FileInfo[]
  @State('otherFilesInfo', { namespace: 'otherEquipmentConnection/attachment' })
  public otherFilesInfoState!: FileInfo[]

  public attachDocuments: boolean = false
  public meterLocationFilesInfo: FileInfo[] = []
  public buildingLocationFilesInfo: FileInfo[] = []
  public otherFilesInfo: FileInfo[] = []
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'otherEquipmentConnection/attachment' })
  private store!: (data: AttachmentState | null) => void
  //#endregion

  //#region [Computed]
  public get fileInError(): boolean {
    return (
      this.meterLocationFilesInfoState.some((v) => v.error !== null) ||
      this.buildingLocationFilesInfoState.some((v) => v.error !== null) ||
      this.otherFilesInfoState.some((v) => v.error !== null)
    )
  }

  get yesChecked(): boolean {
    return this.attachDocuments !== null && !!this.attachDocuments
  }

  set yesChecked(newVal: boolean) {
    this.attachDocuments = newVal
  }

  get noChecked(): boolean {
    return this.attachDocuments !== null && !this.attachDocuments
  }

  set noChecked(newVal: boolean) {
    this.attachDocuments = !newVal
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    this.attachDocuments = this.attachDocumentsState
    this.meterLocationFilesInfo = this.meterLocationFilesInfoState
    this.buildingLocationFilesInfo = this.buildingLocationFilesInfoState
    this.otherFilesInfo = this.otherFilesInfoState
  }

  public async save(next: boolean, isValid: boolean): Promise<boolean> {
    if (isValid) {
      this.$appInsights.trackEvent('WR_OEC_StepAttachment_Validated', {
        Segment: 'WR_OEC',
        WebRef: this.requestId
      })
    }

    this.store({
      attachDocuments: this.attachDocuments,
      meterLocationFilesInfo: this.meterLocationFilesInfo.filter((a) => a.error === null),
      buildingLocationFilesInfo: this.buildingLocationFilesInfo.filter((a) => a.error === null),
      otherFilesInfo: this.otherFilesInfo.filter((a) => a.error === null)
    })

    return Promise.resolve(true)
  }
  //#endregion
}

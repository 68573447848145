
import { Vue, Component, Inject, Watch, Prop } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import PersonalInfoForm from '@/components/inputs/ProsumerBonus/PersonalInfoForm.vue'
import { State, Mutation } from 'vuex-class'
import { ContactInfo, AddressPoint, PersonalInfo, Identification } from '@/models'
import { helper, contactService } from '@/services'

@Component({
  name: 'personal-info-step',
  components: {
    PersonalInfoForm
  }
})
export default class PersonalInfoStep extends Step {
  //#region [Data]
  @State('personalInfo', { namespace: 'prosumerBonus/personalInfo' })
  public personalInfoState!: PersonalInfo | null

  @State('identification', { namespace: 'prosumerBonus/identification' })
  public indentificationState!: Identification | null

  public personalInfo: PersonalInfo = { bankAccountIban: '', bankAccountBic: '', email: '', phone: '' }
  //#endregion

  //#region [Mutation]
  @Mutation('STORE', { namespace: 'prosumerBonus/personalInfo' })
  private store!: (index: PersonalInfo | null) => void
  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    if (this.personalInfoState !== null) {
      this.personalInfo = helper.clone(this.personalInfoState)
    }
  }

  public async save(next: boolean, isValid: boolean) {
    if (next && isValid && this.personalInfo) {
      const data = {
        worksFileId: this.indentificationState!.worksFileId!.trim(),
        eanCode: this.indentificationState!.eanCode!.trim(),
        bankAccountIban: this.personalInfo.bankAccountIban,
        bankAccountBic: this.personalInfo.bankAccountIban!.toLowerCase().startsWith('be')
          ? null
          : this.personalInfo.bankAccountBic,
        contact: {
          email: this.personalInfo.email,
          mobilePhoneNumber: this.personalInfo.phone
        }
      }

      const token = sessionStorage.getItem('token')

      const response = await this.$api.post(
        `prosumer-bonus`,
        data,
        {
          headers: {
            Authorization: token
          }
        })

      if (response.status !== 200) {
        return false
      }

      this.$appInsights.trackEvent('Prosumer_Bonus_StepPersonalInfo_Validated', {
        Segment: 'Prosumer_Bonus'
      })
    }

    this.store(this.personalInfo)
  }
  //#endregion
}

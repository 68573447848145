
import { Vue, Watch, Component, Inject, Prop, Emit } from 'vue-property-decorator'
import { idGenerator } from '@/services'
import InputWithValidation from './InputWithValidation'
import axios from 'axios'
import { mixins } from 'vue-class-component'
import { extend } from 'vee-validate'

@Component
export default class ZipCodeCheck extends InputWithValidation {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly label!: string
  @Prop({ type: String, required: false }) public readonly name!: string | null
  @Prop({ type: Boolean, default: false }) public readonly required!: boolean
  @Prop(String) public readonly value!: string | null
  //#endregion

  //#region [Data]
  public fieldId: string = this.name ? this.name : idGenerator.getId()
  public loading: boolean = false
  public zipCode: string | null = this.value
  public isValid: boolean | null = null
  //#endregion

  //#region [Watch]
  @Watch('value')
  public onValueChanged(newValue: string) {
    this.zipCode = newValue
  }

  @Watch('zipCode')
  public onZipCodeChanged(newZipCode: string | null) {
    if (newZipCode === null || isNaN(+newZipCode) || newZipCode.length !== 4) {
      this.isValid = null
      this.notifyValidation(false)
      return
    }

    if (newZipCode !== this.value) {
      this.$emit('input', newZipCode)
    }
  }
  //#endregion

  //#region [Method]
  public mounted() {
    extend('zip-code-check', {
      validate: (value: string) => {
        if (!value || value.length !== 4) {
          return Promise.resolve({ valid: true })
        }

        this.loading = true
        return this.$addressRepository
          .get(`/zip-codes/${value}/validation`, {
            cache: {
              ignoreCache: false
            }
          })
          .then(
            (response) => {
              this.notifyValidation(response.data.isValid)
              this.loading = false
              if (!response.data.isValid) {
                this.$emit('error')
              }
              return { valid: response.data.isValid }
            },
            (error) => {
              this.notifyValidation(false)
              this.$emit('error')
              this.loading = false
              return { valid: false }
            }
          )
      },
      message: this.$t('address.zipCodeNotExist') as string
    })
  }

  public validateZipCode() {
    const zipCode = this.zipCode || ''

    this.loading = true
  }

  public onInput() {
    this.$emit('manualChange')
  }
  //#endregion
}


import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'more-information'
})
export default class MoreInformation extends Vue {
  @Prop({ type: String, required: true }) public information!: string
  @Prop({ type: String, required: true }) public textColor!: string
  @Prop({ type: String, required: true }) public borderColor!: string
}

import './hooks'
import { initClickHandlers } from '@ores/css-library'
import OresPlugin from '@ores/vue-library'
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
import App from './App.vue'
import { i18n } from './i18n'
import { InstallApp } from './plugins'
import router from './router'
import store from './store/index'
import validation from './validation'

async function getEnv(): Promise<{ [key: string]: string }> {
  const conf = process.env.VUE_APP_CONFIG
  const env: { [key: string]: string } = await fetch('/env.json')
    .then((response) => response.json());

  // tslint:disable-next-line: no-console
  if (conf) {
    const envSpec = await fetch(`/env.${conf}.json`).then((response) => response.json());
    return { ...env, ...envSpec };
  }

  return env;
}

getEnv()
  .then((settings) => {
    // configure validator
    validation.configure('fr')
    Vue.use(InstallApp, { store, settings })
    Vue.use(OresPlugin, { i18n })

    // Use the Touchs Event for mobile devices
    // Npm Package : https://www.npmjs.com/package/vue2-touch-events
    // Repository : https://github.com/jerrybendy/vue-touch-events
    Vue.use(Vue2TouchEvents)

    new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App)
    }).$mount('#app')
  })

initClickHandlers();

// Message bus for in-memory events
Vue.prototype.$inMemoryEventsHub = new Vue();

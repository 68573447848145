import { idGenerator } from '@/services'

export interface SmartMeterConnectionState {
  requestId: string
  noAppointment: boolean
  recaptcha: string | null
}

export const InitialState: SmartMeterConnectionState = {
  requestId: idGenerator.getNewWebReference(),
  noAppointment: false,
  recaptcha: null
}


import { Vue, Inject, Prop, Emit, Component } from 'vue-property-decorator'
import { meterService } from '@/services'
import { MeterElectricityTension } from '@/models'
import { FieldMixin } from '@ores/vue-library';
import { mixins } from 'vue-class-component';

@Component
export default class PowerSelectBox extends mixins(FieldMixin) {
  //#region [Property]
  @Prop({ type: [String, Number], default: null }) public readonly value!: string | null
  @Prop({ type: String, default: null }) public readonly label!: string | null
  @Prop({ type: Array, required: true })
  public readonly options!: Array<{ text: string; value: string; disabled?: boolean }>
  @Prop({ type: String, required: true }) public tension!: MeterElectricityTension
  @Prop({ type: Boolean, default: false }) public readonly required!: boolean
  //#endregion

  //#region [Computed]
  public get text(): string | null {
    return this.value
  }
  public set text(value: string | null) {
    this.change(value)
  }
  //#endregion

  //#region [Method]
  public getKva(tension: MeterElectricityTension, intensity: string) {
    return meterService.getPower(tension, +intensity)
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(value: string | null): string | null {
    return value
  }
  //#endregion
}

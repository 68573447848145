
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import {
  IndividualVatInfoSelection,
  FreelanceVatInfoSelection,
  CompanyVatInfoSelection,
  CompanyVatInfoFirstStepSelection,
  CompanyVatInfoSecondStepSelection,
  ContactInfo
} from '@/models'

@Component
export default class ResumeVatInfo extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true }) public readonly contactInfo!: ContactInfo
  @Prop({ type: Number, required: false, default: null })
  public readonly individualVatInfoSelection!: IndividualVatInfoSelection | null
  @Prop({ type: Number, required: false, default: null })
  public readonly freelanceVatInfoSelection!: FreelanceVatInfoSelection | null
  @Prop({ type: Object, required: false, default: null })
  public readonly companyVatInfoSelection!: CompanyVatInfoSelection | null
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isMobileLayoutApplied!: boolean
  //#endregion

  //#region [Method]
  //#endregion

  //#region [Computed]
  public get buildingHasMoreThanTenYears(): boolean {
    return (
      this.individualVatInfoSelection !== null &&
      this.individualVatInfoSelection === IndividualVatInfoSelection.MoreThanTenYears
    )
  }

  public get freelanceWithDeclaration(): string {
    switch (this.freelanceVatInfoSelection) {
      case FreelanceVatInfoSelection.WithDeclaration:
        return this.$t('vatInfo.withDeclarationDescription') as string
      case FreelanceVatInfoSelection.WithoutDeclaration:
        return this.$t('vatInfo.withoutDeclarationDescription') as string
      case FreelanceVatInfoSelection.NotSubject:
        return this.$t('vatInfo.notSubjectDescription') as string
      default:
        return ''
    }
  }

  public get firstStepCompanyVatInfo(): string | undefined {
    if (this.companyVatInfoSelection) {
      switch (this.companyVatInfoSelection.primaryInfo) {
        case CompanyVatInfoFirstStepSelection.SubjectToVat:
          return this.$t('vatInfo.subjectToVatDescription') as string
          break

        case CompanyVatInfoFirstStepSelection.TownWithoutSocialCharacter:
          return this.$t('vatInfo.townWithoutSocialCharacterDescription') as string
          break

        case CompanyVatInfoFirstStepSelection.SocialHousing:
          return this.$t('vatInfo.socialHousingDescription') as string
          break

        case CompanyVatInfoFirstStepSelection.Embassy:
          return this.$t('vatInfo.embassyDescription') as string
          break

        case CompanyVatInfoFirstStepSelection.TownWithSocialCharacter:
          return this.$t('vatInfo.townWithSocialCharacterDescription') as string
          break

        case CompanyVatInfoFirstStepSelection.HousingForDisabled:
          return this.$t('vatInfo.housingForDisabledDescription') as string
          break

        case CompanyVatInfoFirstStepSelection.RestHouse:
          return this.$t('vatInfo.restHouseDescription') as string
          break

        case CompanyVatInfoFirstStepSelection.School:
          return this.$t('vatInfo.schoolDescription') as string
          break

        default:
          break
      }
    }
    return ''
  }

  public get secondStepCompanyVatInfo(): string | undefined {
    if (this.companyVatInfoSelection) {
      switch (this.companyVatInfoSelection.secondaryInfo) {
        case CompanyVatInfoSecondStepSelection.WithDeclaration:
          return this.$t('vatInfo.withDeclarationDescription') as string
          break

        case CompanyVatInfoSecondStepSelection.WithoutDeclaration:
          return this.$t('vatInfo.withoutDeclarationDescription') as string
          break

        case CompanyVatInfoSecondStepSelection.PublicBuilding:
          return this.$t('vatInfo.publicBuildingDescription') as string
          break

        case CompanyVatInfoSecondStepSelection.PrivateBuildingMoreThanTenYears:
          return this.$t('vatInfo.privateBuildingMoreThanTenYearsDescription') as string
          break

        case CompanyVatInfoSecondStepSelection.PrivateBuildingLessThanTenYears:
          return this.$t('vatInfo.privateBuildingLessThanTenYearsDescription') as string
          break

        case CompanyVatInfoSecondStepSelection.SocialPrivateBuilding:
          return this.$t('vatInfo.socialPrivateBuildingDescription') as string
          break

        case CompanyVatInfoSecondStepSelection.PrivateBuildingForDisabled:
          return this.$t('vatInfo.privateBuildingForDisabledDescription') as string
          break

        default:
          break
      }
    }
    return ''
  }
  //#endregion
}

import { CarSpecification, ElectricVehicle } from '@/models';
import { MutationTree } from 'vuex';
import { CarSpecificationState } from './types';

export const mutations: MutationTree<CarSpecificationState> = {
  STORE(state, data: CarSpecification) {
    state.carSpecification = data
  },
  STORE_VEHICULE(state, data: ElectricVehicle) {
    state.electricVehicle = data
  },
  CLEAR(state) {
    state.carSpecification = null
    state.electricVehicle = null
  }
}


import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'cmh-providers-external-links'
})
export default class CmhProvidersExternalLinks extends Vue {
  @Prop({ type: Boolean, required: false, default: true }) public isSwdeDisplayed!: boolean
  @Prop({ type: Boolean, required: false, default: true }) public isProximusDisplayed!: boolean
  @Prop({ type: Boolean, required: false, default: true }) public isVooDisplayed!: boolean
}


import { Component, Prop } from 'vue-property-decorator'
import { AppointmentInfo, ContactInfo } from '@/models'
import { contactService } from '@/services'
import Step from '@/components/steps/Step'
import { State } from 'vuex-class'
import { dateService, timeService } from '@/services'

@Component({ name: 'send-confirmation' })
export default class SendConfirmation extends Step {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly title!: string
  @Prop({ type: Object, required: true }) public readonly contactInfo!: ContactInfo
  //#endregion

  //#region [Data]
  public email: string = ''
  @State('noAppointment', { namespace: 'smartMeterConnection' })
  public skipAppointment!: boolean
  @State('appointment', { namespace: 'smartMeterConnection/appointment' })
  public appointmentInfoState!: AppointmentInfo
  @State('fileNumber', { namespace: 'smartMeterConnection/stakeholder' })
  public fileNumber!: string | null
  @State('workRequesterId', { namespace: 'smartMeterConnection/stakeholder' })
  public workRequesterId!: string | null
  @State('requestId', { namespace: 'smartMeterConnection' })
  public requestId!: string
  //#endregion

  //#region [Computed]
  private get appointmentDate(): string {
    return dateService.displayDateText(this.appointmentInfoState.selectedSlot!.startDate!)
  }

  private get appointmentPeriod(): string {
    const fromTime = timeService.displayTime(this.appointmentInfoState.selectedSlot!.startDate!)
    const toTime = timeService.displayTime(this.appointmentInfoState.selectedSlot!.endDate!)
    return this.$t('appointment.confirmation.fromTo', [fromTime, toTime]).toString()
  }
  //#endregion

  //#region [Watch]
  public onContactInfoChanged(value: ContactInfo) {
    this.email = contactService.getContactEmail(value)
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.onContactInfoChanged(this.contactInfo)

    this.$appInsights.trackEvent('WR_SMC_StepSendConfirmation_Validated', {
      Segment: 'WR_SMC',
      WebRef: this.requestId
    })
  }
  //#endregion
}

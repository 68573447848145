import { AxiosInstance, AxiosError } from 'axios';
import { Store } from 'vuex';
import { RootState } from '@/store/types';

export function addAddressRepositoryResponseInterceptors(axios: AxiosInstance, store: Store<RootState>): number {
  const addressRepositoryInterceptor = axios.interceptors.response.use((response) => response, (error: AxiosError) => {
    const response = error.response !== undefined ? error.response : error
    if (!response.config.url?.startsWith('streets?subcityId=')) {
      store.dispatch('apiErrors/manageAddressRepository', response)
      return Promise.reject(error)
    }
    return response
  })

  return addressRepositoryInterceptor
}

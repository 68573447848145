
import InputWithValidation from '@/components/inputs/Address/InputWithValidation'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { IbanInfo } from '@/models'

@Component
export default class ContactIbanInfos extends InputWithValidation {
  @Prop({ type: Object, required: true }) public readonly value!: IbanInfo
  @Prop({ type: Boolean, required: false, default: false }) public readonly required!: boolean
  @Prop({ type: Boolean, required: false, default: false }) public readonly withHolderName!: boolean

  public get isBicVisible(): boolean {
    return !!this.value.bankAccountIban
      && this.value.bankAccountIban.length > 1
      && !this.value.bankAccountIban.toLowerCase().startsWith('be')

  }

  get bicCode(): string {
    return this.value.bankAccountBic?.toUpperCase() ?? ''
  }

  set bicCode(val: string) {
    this.value.bankAccountBic = val.toUpperCase()
  }

  @Watch('isBicVisible')
  public OnBicVisibleChanged(value: boolean) {
    if (!value) {
      this.value.bankAccountBic = null
    }
  }
}

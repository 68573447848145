import { actions } from '@/store/new-connection-cmh/offerings-selection/actions';
import { getters } from '@/store/new-connection-cmh/offerings-selection/getters';
import { mutations } from '@/store/new-connection-cmh/offerings-selection/mutations';
import { InitialState, OfferingSelectionState } from '@/store/new-connection-cmh/offerings-selection/types';
import { RootState } from '@/store/types';
import { Module } from 'vuex';

const namespaced: boolean = true;

export const offeringsSelection: Module<OfferingSelectionState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations
}

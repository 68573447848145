
import Step from '@/components/steps/Step'
import Component from 'vue-class-component'
import { Mutation, State } from 'vuex-class'
import { appointmentService, dateService } from '@/services'
import { ContactInfo, EanConnectionContractResult, EnergyProductionInfoModel } from '@/models'
import WorkFileCreation from '@/components/inputs/SmartMeterConnection/WorkFileCreation.vue'

@Component({ components: { WorkFileCreation } })
export default class AppointmentDatePreselectionStep extends Step {
  //#region [Data]
  @State('requestId', { namespace: 'smartMeterConnection' })
  public requestId!: string
  @State('connectionContract', { namespace: 'smartMeterConnection/identification' })
  public connectionContractState!: EanConnectionContractResult | null
  @State('energyProductionInfo', { namespace: 'smartMeterConnection/energyProduction' })
  public energyProductionInfoState!: EnergyProductionInfoModel | null
  @State('searchStartDate', { namespace: 'smartMeterConnection/appointment' })
  public searchStartDateState!: Date
  @State('contactInfo', { namespace: 'smartMeterConnection/contactInfo' })
  public contactInfoState!: ContactInfo

  private wishedDate: Date | null = null
  //#endregion

  //#region [Computed]
  private get minDate(): string | null {
    if (this.isProsumerWithKnownReadingMonth) {
      // When prosumer, prefill date
      this.wishedDate = appointmentService.appointmentSearchSuitableStartDate(
        new Date(),
        this.meterReadingMonthNumber! - 1
      )
    }
    // Min date: today + 21 business days
    return dateService.stringifyDate(appointmentService.appointmentSearchSuitableStartDate(new Date(), null))
  }

  private get isProsumerWithKnownReadingMonth(): boolean | null {
    return this.energyProductionInfoState?.hasEnergyProduction === 1 && !!this.meterReadingMonthNumber
  }

  private get meterReadingMonthNumber(): number | null {
    return this.connectionContractState?.yearlyReadingMonth ?? null
  }

  private get month(): string {
    return this.$t(`month.${this.meterReadingMonthNumber}`).toString()
  }

  private get previousMonth(): string {
    let month: number = this.meterReadingMonthNumber! - 1
    if (month === 0) {
      month = 12
    }
    return this.$t(`month.${month}`).toString()
  }

  private get nextMonth(): string {
    let month: number = this.meterReadingMonthNumber! + 1
    if (month === 13) {
      month = 1
    }
    return this.$t(`month.${month}`).toString()
  }
  //#endregion

  //#region [Mutation]
  @Mutation('STORE_SEARCH_START_DATE', { namespace: 'smartMeterConnection/appointment' })
  private store!: (date: Date) => void

  //#endregion

  //#region [Method]
  public mounted() {
    this.setIsBack(false)
    if (this.searchStartDateState) {
      this.wishedDate = this.searchStartDateState
    }
  }

  public async save(next: boolean, isValid: boolean) {
    if (!next) {
      return true
    }
    if (!isValid) {
      return false
    }

    this.$appInsights.trackEvent('WR_SMC_StepDatePreSelection_Validated', {
      Segment: 'WR_SMC',
      WebRef: this.requestId
    })

    this.store(this.wishedDate!)
    const workFileCreation = this.$refs.workFileCreation as WorkFileCreation
    await workFileCreation.createWorkFile()

    return true
  }

  //#endregion
}

import { actions } from '@/store/e-mobility/actions';
import { getters } from '@/store/e-mobility/getters';
import { mutations } from '@/store/e-mobility/mutations';
import { InitialState, EMobilityConnectionState } from '@/store/e-mobility/types';
import { RootState } from '@/store/types';
import { Module } from 'vuex';
import { carSpecification } from './car-specification/index'
import { identification } from './identification'
import { consumingDevices } from './consuming-device'
import { electricityInstallation } from './electricity-installation';
import { batteryRechargingCapacity } from './battery-recharging-capacity';
import { powerIncreasePackages } from './power-increase-packages'
const namespaced: boolean = true;

export const eMobility: Module<EMobilityConnectionState, RootState> = {
  namespaced,
  state: InitialState,
  getters,
  actions,
  mutations,
  modules: {
    carSpecification,
    identification,
    consumingDevices,
    electricityInstallation,
    batteryRechargingCapacity,
    powerIncreasePackages
  }
}


import { Vue, Component, Prop, Emit, Inject, Watch } from 'vue-property-decorator'
import { idGenerator, helper, meterService } from '@/services'
import ElectricityPriceCalculator from '@/components/display/ElectricityPriceCalculator.vue'
import PowerSelectBox from '@/components/inputs/Configuration/PowerSelectBox.vue'
import {
  Power,
  MeterElectricityType,
  MeterElectricityTension,
  MeterDestination,
  OtherEquipmentElectricityMeter
} from '@/models'

@Component({
  name: 'other-equipment-electricity-meter-form',
  components: {
    ElectricityPriceCalculator,
    PowerSelectBox
  }
})
export default class OtherEquipmentElectricityMeterForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: true })
  public readonly value!: OtherEquipmentElectricityMeter

  @Prop({ type: Boolean, default: false })
  public readonly hasEnergyProduction!: boolean

  @Prop({ type: Array, required: true })
  public readonly tensions!: MeterElectricityTension[]

  @Prop({ type: Array, required: true })
  public readonly tarifications!: MeterElectricityType[]
  //#endregion

  //#region [Data]
  public fieldId: string = idGenerator.getId()
  //#endregion

  //#region [Computed]
  public get powers(): number[] {
    return meterService.getIntensities(this.value.tension, meterService.otherEquipmentElectricityConfiguration)
  }

  public get ampere(): number | null {
    return this.value.power ? this.value.power.ampere : null
  }

  public set ampere(value: number | null) {
    if (!value) {
      this.value.power = null
    } else {
      this.value.power = {
        ampere: value,
        kva: meterService.getPower(this.value.tension, value)
      }
    }
  }

  public get kva(): number | null {
    return this.value.power ? this.value.power.kva : null
  }

  public get tarificationsOptions(): Array<{ text: string; value: string }> {
    const postfix = this.hasEnergyProduction ? this.$t('meter.type.double')! : ''
    const getPostfix = (type: MeterElectricityType) => (type === 'simple' || type === 'dual' ? postfix : '')

    return this.tarifications.map((t) => {
      return { text: (this.$t('meter.type.' + t) as string) + getPostfix(t), value: t }
    })
  }
  //#endregion

  //#region [Watch]
  @Watch('value.tension')
  public onTensionChange(value: MeterElectricityTension & ('mono230V' | 'tri230V' | 'tri400V')) {
    const ampere = this.ampere
    const intensities = meterService.getIntensities(value, meterService.otherEquipmentElectricityConfiguration)

    if (!ampere || intensities.indexOf(ampere) === -1) {
      this.ampere = intensities[0]
    } else {
      this.ampere = ampere
    }
  }
  //#endregion
}

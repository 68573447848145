
import Component from 'vue-class-component'
import { Emit, Vue } from 'vue-property-decorator'

@Component({ components: {} })
export default class AppointmentModificationConfirmation extends Vue {
  //#region [Emit]
  @Emit('confirmModify')
  public confirmModify() {
    return true
  }

  @Emit('close')
  private close() {
    return true
  }
  //#endregion
}

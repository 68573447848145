import { NewConnectionCmhState } from '@/store/new-connection-cmh/types';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';

export const actions: ActionTree<NewConnectionCmhState, RootState> = {
  reset({ commit }) {
    commit('offeringsSelection/CLEAR')
    commit('cmhVatInfo/CLEAR')
    commit('cmhFiles/CLEAR')
    commit('cmhEnergyProductionConfiguration/CLEAR')
    commit('cmhEnergyConfiguration/CLEAR')
    commit('cmhContact/CLEAR')
    commit('cmhAddress/CLEAR')
    commit('RESET')
  }
}


import { Vue, Component, Prop, Emit, Watch, Inject } from 'vue-property-decorator'
import { Identification } from '@/models'
import { YesNoMaybeSelection } from '@ores/vue-library'

@Component
export default class IdentificationForm extends Vue {
  //#region [Property]
  @Prop({ type: Object, required: false }) public readonly value!: Identification | null
  //#endregion

  //#region [Computed]
  public get worksFileId(): string | null {
    return this.value ? this.value.worksFileId : null
  }
  public set worksFileId(val: string | null) {
    this.change(val, this.eanCode)
  }

  public get eanCode(): string | null {
    return this.value ? this.value.eanCode : null
  }
  public set eanCode(val: string | null) {
    this.change(this.worksFileId, val)
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public change(worksFileId: string | null, eanCode: string | null): Identification | null {
    if (worksFileId !== null || eanCode !== null) {
      return { worksFileId, eanCode }
    } else {
      return null
    }
  }
  //#endregion
}

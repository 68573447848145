
import { Vue, Component, Prop } from 'vue-property-decorator'
import { PersonalInfo } from '@/models'

@Component({ name: 'send-confirmation' })
export default class SendConfirmation extends Vue {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly title!: string
  @Prop({ type: Object, required: true }) public readonly personalInfo!: PersonalInfo
  //#endregion

  //#region [Data]
  public email: string = ''
  //#endregion

  //#region [Watch]
  public onPersonalInfoChanged(value: PersonalInfo) {
    this.email = value.email!
  }
  //#endregion

  //#region [Method]
  public mounted() {
    this.onPersonalInfoChanged(this.personalInfo)
  }
  //#endregion
}

import { AxiosInstance, AxiosError } from 'axios';
import { Store } from 'vuex';
import { RootState } from '@/store/types';

export function addEmobilityResponseInterceptors(axios: AxiosInstance, store: Store<RootState>): number {
  const EmobilityInterceptor = axios.interceptors.response.use((response) => response, (error: AxiosError) => {
    const response = error.response !== undefined ? error.response : error
    if (!response.config.url?.startsWith('api/ElectricMobility/grid-access-point-info')) {
      store.dispatch('apiErrors/manageEmobility', response)
      return Promise.reject(error)
    }
    return response
  })

  return EmobilityInterceptor
}
